import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { NotifyModule } from '../notify/notify.module';
import { LicenseNotificationComponent } from './license-notification.component';

export * from './license-notification.component';

@NgModule({
    imports: [CommonModule, NotifyModule],
    exports: [LicenseNotificationComponent],
    declarations: [LicenseNotificationComponent],
})
export class LicenseNotificationModule {}
