import { CommonModule } from '@angular/common';
import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';

import { NotifyComponent } from './notify.component';

export * from './notify.component';
export * from './notify.service';

@NgModule({
    imports: [CommonModule],
    exports: [NotifyComponent],
    declarations: [NotifyComponent],
    schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class NotifyModule {}
