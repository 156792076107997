<core-form [data]="form">
    <div class="grid" style="--template: 1fr var(--third)">
        <core-validate
            [serverErrorsMap]="{
                exists: '_$$.filterTemplate.errors.name.exists'
            }"
        >
            <sl-input
                name="name"
                [label]="'$.name' | translate"
                [placeholder]="
                    '_$$.filterTemplate.placeholder.input' | translate
                "
            ></sl-input>
        </core-validate>

        <core-validate>
            <sl-input
                name="key"
                [placeholder]="
                    '_$$.filterTemplate.placeholder.inputKey' | translate
                "
            >
                <div slot="label">
                    {{ '_$$.filterTemplate.key' | translate }}

                    <sl-tooltip
                        hoist
                        [content]="
                            '_$$.filterTemplate.sharedFilterValues' | translate
                        "
                    >
                        <sl-icon name="question-circle"></sl-icon>
                    </sl-tooltip>
                </div>
            </sl-input>
        </core-validate>
    </div>

    <div class="grid" style="--template: var(--third)">
        <plmt-select
            name="position"
            [label]="'_$$.filterTemplate.position' | translate"
        >
            <sl-option
                *ngFor="let position of positions"
                [value]="position.value"
            >
                {{ position.label | translate }}
            </sl-option>
        </plmt-select>
    </div>

    <div class="grid" style="--template: var(--third) 1fr">
        <core-validate>
            <plmt-select
                name="type"
                [label]="'_$$.filterTemplate.type' | translate"
                [placeholder]="
                    '_$$.filterTemplate.placeholder.select' | translate
                "
            >
                <sl-option
                    *ngFor="let type of filterTemplateTypes"
                    [value]="type.value"
                >
                    {{ type.label | translate }}
                </sl-option>
            </plmt-select>
        </core-validate>

        <core-validate>
            <plmt-select
                name="sub_type"
                [label]="'_$$.filterTemplate.ui' | translate"
                [placeholder]="
                    '_$$.filterTemplate.placeholder.select' | translate
                "
                [disabled]="!form.value.type"
            >
                <sl-option
                    *ngFor="let item of filterUITypeOptionsMap[type]"
                    [value]="item.value"
                >
                    {{ item.label | translate }}
                </sl-option>
            </plmt-select>
        </core-validate>
    </div>

    <div
        *ngIf="state === FilterState.CommonType"
        class="grid"
        [style.--template]="
            type === FilterTemplateType.Date
                ? 'var(--third) 1fr'
                : 'repeat(3, var(--third))'
        "
    >
        <core-validate>
            <plmt-select
                name="action"
                [label]="'_$$.filterTemplate.filterMethod' | translate"
                [placeholder]="
                    '_$$.filterTemplate.placeholder.select' | translate
                "
                [disabled]="filterMethods.length === 0"
            >
                <sl-option
                    *ngFor="let method of filterMethods"
                    tooltipify
                    [value]="method"
                >
                    {{ '$$.filters.method.' + method | translate }}
                </sl-option>
            </plmt-select>
        </core-validate>

        <core-validate
            #validateComponent
            [class.default-value-validate]="
                formValue.type === FilterTemplateType.Date
            "
        >
            <plmt-filter-value-default
                *ngIf="canShowFilterValueDefault"
                name="default_value"
                hasValueName="has_default_value"
                [uiType]="subType"
                [validationError]="validateComponent.hasErrors"
            ></plmt-filter-value-default>
        </core-validate>
    </div>

    <plmt-extended-settings
        #extendedSettings
        *ngIf="canShowExtendedSettings"
        [filterMethods]="filterMethods"
    ></plmt-extended-settings>
</core-form>

<core-notify #notify>
    {{ '_$$.filterTemplate.' + form.state | translate }}
</core-notify>
