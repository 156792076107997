export const bounceIn = [{
  offset: 0,
  opacity: '0',
  transform: 'scale3d(0.3, 0.3, 0.3)'
}, {
  offset: 0,
  easing: 'cubic-bezier(0.215, 0.61, 0.355, 1)'
}, {
  offset: 0.2,
  transform: 'scale3d(1.1, 1.1, 1.1)'
}, {
  offset: 0.2,
  easing: 'cubic-bezier(0.215, 0.61, 0.355, 1)'
}, {
  offset: 0.4,
  transform: 'scale3d(0.9, 0.9, 0.9)'
}, {
  offset: 0.4,
  easing: 'cubic-bezier(0.215, 0.61, 0.355, 1)'
}, {
  offset: 0.6,
  opacity: '1',
  transform: 'scale3d(1.03, 1.03, 1.03)'
}, {
  offset: 0.6,
  easing: 'cubic-bezier(0.215, 0.61, 0.355, 1)'
}, {
  offset: 0.8,
  transform: 'scale3d(0.97, 0.97, 0.97)'
}, {
  offset: 0.8,
  easing: 'cubic-bezier(0.215, 0.61, 0.355, 1)'
}, {
  offset: 1,
  opacity: '1',
  transform: 'scale3d(1, 1, 1)'
}, {
  offset: 1,
  easing: 'cubic-bezier(0.215, 0.61, 0.355, 1)'
}];