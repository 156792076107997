import { Component, Input } from '@angular/core';
import { Notification } from '../../interfaces/notification';
import { EventLevel, EventType } from 'plmt-core-library';

@Component({
    selector: 'app-message-icon',
    templateUrl: './message-icon.component.html',
    styleUrls: ['./message-icon.component.less'],
})
export class MessageIconComponent {
    @Input()
    notification!: Notification;

    readonly EventLevel = EventLevel;

    get warningCondition(): boolean {
        const detailErrorCondition =
            this.notification.event_type === EventType.ImportFinished &&
            !!this.notification.event_data.not_found_project_links;

        return detailErrorCondition;
    }
}
