import { HttpErrorResponse } from '@angular/common/http';
import {
    AfterViewInit,
    ChangeDetectorRef,
    Directive,
    Input,
    OnInit,
    Renderer2,
} from '@angular/core';
import { SlMenu } from '@shoelace-style/shoelace';
import { WatchChanges } from 'ng-onpush';
import { SubscribableDirective } from 'ngx-subscribable';
import { catchError, finalize, fromEvent, of, switchMap, tap } from 'rxjs';

import { DataSearchProvider } from '../../interfaces/rest-api';
import { AlertService } from '../alert/alert.service';
import { MultiSelectComponent } from './multi-select.component';

@Directive({
    selector: 'core-multi-select:not(suggestions)[suggestionsProvider]',
})
export class MultiSelectLoadableDirective
    extends SubscribableDirective
    implements OnInit, AfterViewInit
{
    static readonly threshold = 150;

    @Input()
    suggestionsProvider!: DataSearchProvider<any>;

    loader: HTMLElement = this.renderer.createElement('div');

    @WatchChanges()
    private loading = false;

    constructor(
        private changeDetectorRef: ChangeDetectorRef,
        private multiSelect: MultiSelectComponent,
        private renderer: Renderer2,
        private alertService: AlertService,
    ) {
        super();
    }

    ngOnInit(): void {
        this.subscriptions = [
            this.multiSelect.onSearch
                .pipe(
                    tap(() => {
                        this.multiSelect.suggestions = [];
                        this.multiSelect.loading = true;
                    }),
                    switchMap((search) =>
                        this.suggestionsProvider(search, 0).pipe(
                            tap(({ rows, total }) => {
                                this.multiSelect.suggestions = rows;
                                this.changeDetectorRef.markForCheck();

                                this.setLoaderVisibility(total);
                            }),
                            catchError((responseError: HttpErrorResponse) => {
                                this.setLoaderVisibility(
                                    this.multiSelect.suggestions.length,
                                );

                                this.alertService.show.emit({
                                    responseError,
                                    key: '_$.multiSelect.loadable.error.forbidden',
                                });

                                return of(null);
                            }),
                            finalize(() => {
                                this.multiSelect.loading = false;
                            }),
                        ),
                    ),
                )
                .subscribe(),
        ];
    }

    ngAfterViewInit(): void {
        const slMenu = this.multiSelect.slDropdown.nativeElement.querySelector(
            'sl-menu',
        ) as SlMenu;

        this.initLoader(slMenu);

        this.subscriptions.push(
            fromEvent(slMenu, 'scroll').subscribe(() => {
                if (this.loading) return;
                if (this.loader.classList.contains('hidden')) return;

                const { clientHeight, scrollHeight, scrollTop } = slMenu;

                const threshold =
                    scrollHeight -
                    clientHeight -
                    MultiSelectLoadableDirective.threshold;

                if (scrollTop > threshold) this.loadNextPart();
            }),
        );
    }

    private initLoader(parent: HTMLElement): void {
        this.loader.classList.add('spinner-container');

        const spinner = this.renderer.createElement('sl-spinner');

        this.renderer.appendChild(this.loader, spinner);
        this.renderer.appendChild(parent, this.loader);
    }

    private setLoaderVisibility(total: number): void {
        const hidden = this.multiSelect.suggestions.length === total;

        if (hidden) this.loader.classList.add('hidden');
        else this.loader.classList.remove('hidden');
    }

    private loadNextPart(): void {
        this.loading = true;

        const { value: search } = this.multiSelect.slInput.nativeElement;
        const offset = this.multiSelect.suggestions.length; // + 1

        this.suggestionsProvider(search, offset).subscribe({
            next: ({ rows, total }) => {
                this.multiSelect.suggestions.push(...rows);

                this.setLoaderVisibility(total);

                this.loading = false;
            },
            error: (responseError) => {
                this.alertService.show.emit({
                    responseError,
                    key: '_$.multiSelect.loadable.error.forbidden',
                });
            },
        });
    }
}
