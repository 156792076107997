import { CommonModule } from '@angular/common';
import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { ShoelaceModule } from 'shoelace-style-angular';

import { TooltipifyModule } from '../../modules/tooltipify/tooltipify.module';
import { OverflowModule } from '../overflow/overflow.module';
import { SelectSuffixModule } from '../select-suffix/select-suffix.module';
import {
    MultiSelectControlDirective,
    MultiSelectNameDirective,
} from './form-control.directive';
import { MultiSelectLoadableDirective } from './loadable.directive';
import { MultiSelectComponent } from './multi-select.component';
import { AutoSizeAvailableHeightDirectiveModule } from 'src/shared/directives/auto-size-available-height.directive';

export * from './form-control.directive';
export * from './loadable.directive';
export * from './multi-select.component';

@NgModule({
    imports: [
        CommonModule,
        OverflowModule,
        SelectSuffixModule,
        ShoelaceModule,
        TooltipifyModule,
        AutoSizeAvailableHeightDirectiveModule,
    ],
    exports: [
        MultiSelectComponent,
        MultiSelectControlDirective,
        MultiSelectLoadableDirective,
        MultiSelectNameDirective,
    ],
    declarations: [
        MultiSelectComponent,
        MultiSelectControlDirective,
        MultiSelectLoadableDirective,
        MultiSelectNameDirective,
    ],
    schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class MultiSelectModule {}
