import { Injectable } from '@angular/core';
import { not } from 'logical-not';

const componentPath = 'assets/core-library/page-loading/component.js';
@Injectable()
export class PageLoadingService {
    private cached!: HTMLElement;

    private get element(): HTMLElement {
        if (not(this.cached)) {
            this.cached = document.querySelector('core-page-loading')!;

            if (not(this.cached)) {
                const appLoading = document.createElement('script');

                appLoading.setAttribute('type', 'module');
                appLoading.setAttribute('src', componentPath);

                document.body.appendChild(appLoading);
            }
        }

        return this.cached;
    }

    isHidden(): boolean {
        return this.element === null
            ? true
            : this.element.classList.contains('hidden');
    }

    showAppLoading(): void {
        this.element?.classList.remove('hidden');
    }

    hideAppLoading(): void {
        this.element?.classList.add('hidden');
    }

    removeAppLoading(): void {
        const scriptLoading = document.querySelector('core-page-loading');

        if (scriptLoading) document.body.removeChild(scriptLoading);
    }
}
