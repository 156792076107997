<button
    class="{{ variant }} {{ size }}"
    [ngClass]="{
        active,
        icon:icon === ''
    }"
    [type]="submit === '' ? 'submit' : 'button'"
    [disabled]="disabled"
    (click)="buttonClick.emit()"
>
    <div class="spinner" *ngIf="loading">
        <div>
            <sl-spinner></sl-spinner>
        </div>
    </div>

    <div class="content">
        <ng-content></ng-content>
    </div>
</button>
