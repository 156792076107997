import { AbstractControl } from '@angular/forms';
import { Message } from 'ng-to-parent';

export const provideControlByName = new Message<ProvideControlByName>();
export const validatable = new Message<AbstractControl>();

export interface ProvideControlByName {
    path: string;

    provider(control: AbstractControl): void;
}
