import { Inject, Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { CoreApi } from '../modules/rest/api-injectors';
import { RestService } from '../modules/rest/rest.service';

@Injectable({ providedIn: 'root' })
export class LogoutApiService {
    constructor(@Inject(CoreApi) private restService: RestService) {}

    logout(): Observable<{ identity_provider_logout_url?: string }> {
        return this.restService.get('/auth/logout');
    }
}
