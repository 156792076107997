import { Injectable } from '@angular/core';

import { NotifyComponent } from './notify.component';

@Injectable({ providedIn: 'root' })
export class NotifyInternalService {
    private readonly map: Record<string, NotifyComponent> = Object.create(null);

    append(component: NotifyComponent, id: string): void {
        this.map[id] = component;
    }

    remove(id: string): void {
        delete this.map[id];
    }

    getBy(id: string): NotifyComponent | null {
        return this.map[id] || null;
    }
}
