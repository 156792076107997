import { Directive, HostListener, NgModule } from '@angular/core';
import { SlDialog } from '@shoelace-style/shoelace';

enum DialogStatus {
    Open = 'open',
    Closed = 'closed',
}

@Directive({ selector: 'sl-dialog[closeParentDialog]' })
export class CloseParentDialogDirective {
    @HostListener('sl-show', ['$event.target'])
    onShow(target: EventTarget): void {
        this.dialogAction(target, DialogStatus.Open);
    }

    @HostListener('sl-hide', ['$event'])
    onHide(event: Event): void {
        event.stopPropagation();

        const target: EventTarget = event.target!;

        this.dialogAction(target, DialogStatus.Closed);
    }

    dialogAction(target: EventTarget, status: DialogStatus): void {
        if (!(target instanceof SlDialog)) return;

        const slDialog = document.querySelector(
            `sl-dialog[${status}]`,
        ) as SlDialog;

        const coreDialog = document.querySelector(
            'core-popup[popup-name="core-dialog-modal"]',
        );

        const dialog = slDialog || coreDialog;

        if (!dialog) return;

        const condition = status === DialogStatus.Open;

        dialog.style.visibility = condition ? 'hidden' : '';

        if (condition && slDialog) {
            dialog.setAttribute(DialogStatus.Closed, '');
        } else {
            dialog.removeAttribute(DialogStatus.Closed);
        }
    }
}

@NgModule({
    exports: [CloseParentDialogDirective],
    declarations: [CloseParentDialogDirective],
})
export class CloseParentDialogDirectiveModule {}
