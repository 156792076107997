import { SlTooltip } from '@shoelace-style/shoelace';
import { not } from 'logical-not';
import { Observable, finalize, first, fromEvent, switchMap, tap } from 'rxjs';

export const scope = {} as {
    tooltip: SlTooltip;
    positioner: HTMLElement;
};

export function tooltipify(
    interactor: HTMLElement,
    textProvider: HTMLElement,
    tester: () => boolean,
): Observable<unknown> {
    return fromEvent(interactor, 'mouseenter').pipe(
        tap(() => {
            if (not(tester())) return;

            const { tooltip, positioner } = scope;
            const { left, top, height } = interactor.getBoundingClientRect();

            positioner.style.left = `${left}px`;
            positioner.style.top = `${top}px`;
            positioner.style.height = `${height}px`;

            tooltip.content = textProvider.textContent!;
            tooltip.show();
        }),
        switchMap(() =>
            fromEvent(interactor, 'mouseleave').pipe(
                first(),
                finalize(() => {
                    scope.tooltip.hide();
                }),
            ),
        ),
    );
}
