import { Message } from 'ng-to-parent';
import { DataOptionFilter } from '../../../interfaces/data-option';
import { FilterValueState } from '../../filter-value/filter-value.component';

export const defaultRule: DataOptionFilter = {
    logical: void 0,
    column_id: 0,
    action: void 0 as any,
    value: void 0,
};

export const defaultGroup: DataOptionFilter = {
    logical: void 0,
    child: [],
} as any;

export const emptyFilterValueState: FilterValueState = {
    value: null,
    hasValue: false,
};

export const applyChanges = new Message<DataOptionFilter[]>();
export const resetToOldForm = new Message<void>();

export const dataId = 'data-id';
