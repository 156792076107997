import { NgModule } from '@angular/core';

import { ListItemComponent } from './list-item/list-item.component';
import { ListComponent } from './list.component';

export * from './list-item/list-item.component';
export * from './list.component';

@NgModule({
    exports: [ListComponent, ListItemComponent],
    declarations: [ListComponent, ListItemComponent],
})
export class ListModule {}
