import { Format_Server } from '../../interfaces/column.format';
import { GetEmptyFormatSettings } from './formatter-settings';

export class Arounds {
    static getEmpty: GetEmptyFormatSettings = () => {
        return {
            prefix: '',
            suffix: '',
        };
    };

    readonly prefix: string;
    readonly suffix: string;

    constructor(source: Format_Server) {
        this.prefix = source.prefix || '';
        this.suffix = source.suffix || '';
    }

    format(source: string): string {
        return `${this.prefix}${source}${this.suffix}`;
    }
}
