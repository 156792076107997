import { HttpErrorResponse } from '@angular/common/http';
import {
    AfterViewInit,
    Component,
    ElementRef,
    EventEmitter,
    Input,
    OnChanges,
    Output,
    SimpleChanges,
    ViewChild,
} from '@angular/core';
import { SlInput, SlMenuItem } from '@shoelace-style/shoelace';
import { not } from 'logical-not';
import { WatchChanges } from 'ng-onpush';
import { SubscribableComponent } from 'ngx-subscribable';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';

import { slDropdownKeys } from '../../constants/sl-dropdown-keys';
import {
    CompareFn,
    GetLabel,
    GetValue,
} from '../../interfaces/items-operations';
import { DataSearchProvider, ResponseArray } from '../../interfaces/rest-api';
import { Debounce } from '../../tools/debounce';
import { valueOf } from '../../tools/value-of';
import { checkIfHasDefaultValue } from '../filter-value/null.helper';
import { AlertService } from '../alert/alert.service';

type Suggestion = any;

const DEFAULT_DROPDOWN_HEIGHT = 306;

@Component({
    selector: 'core-suggest',
    templateUrl: './suggest.component.html',
    styleUrls: ['./suggest.component.less'],
})
export class SuggestComponent
    extends SubscribableComponent
    implements AfterViewInit, OnChanges
{
    @Input()
    label = '';

    @Input()
    placeholder = '';

    @Input()
    disabled = false;

    @Input()
    selected?: Suggestion;

    @Input()
    suggestionsProvider!: DataSearchProvider<Suggestion>;

    @Input()
    size: SlInput['size'] = 'medium';

    @Input()
    clearable: boolean | '' = false;

    @Input()
    viewKey = 'name';

    @Input()
    uniqKey = 'id';

    @Input()
    errorKey = '_$.suggest.error.forbidden';

    @Input()
    dropdownHeight = DEFAULT_DROPDOWN_HEIGHT;

    @Input()
    additionalSuggestions: Suggestion[] = [];

    @Input()
    getLabelFn: GetLabel<Suggestion> = (item) => {
        return item[this.viewKey];
    };

    @Input()
    getValueFn: GetValue<Suggestion, any> = (item) => item[this.uniqKey];

    @Input()
    compareFn: CompareFn<Suggestion> = (a, b) =>
        this.getValueFn(a) === this.getValueFn(b);

    @Input()
    cache = true;

    @Output()
    onSelect = new EventEmitter<Suggestion>();

    @Output()
    onClear = new EventEmitter<void>();

    @ViewChild('slInput', { static: true })
    inputRef!: ElementRef<SlInput>;

    @WatchChanges()
    show = false;

    @WatchChanges()
    suggestions: Suggestion[] = [];
    suggestionsTotal = 0;

    width = '0px';

    private touched = false;

    readonly loadMore = (): Observable<ResponseArray<Suggestion>> => {
        const offset = this.suggestions.length;
        const search = this.touched ? this.inputRef.nativeElement.value : '';

        return this.suggestionsProvider(search, offset).pipe(
            tap(({ rows, total }) => {
                const newArr = [];
                newArr.push(...this.suggestions, ...rows);
                this.suggestions = newArr;
                this.suggestionsTotal = total;
            }),
        );
    };

    get showClearIcon(): boolean {
        if (this.clearable || this.clearable === '')
            return checkIfHasDefaultValue(this.selected);

        return false;
    }

    get allSuggestions(): Suggestion[] {
        return this.additionalSuggestions.concat(this.suggestions);
    }

    constructor(
        private hostRef: ElementRef<HTMLElement>,
        private alertService: AlertService,
    ) {
        super();
    }

    ngAfterViewInit(): void {
        const slClosableElement = this.hostRef.nativeElement.closest(
            'sl-dialog, sl-drawer',
        );

        slClosableElement?.addEventListener('sl-show', (event) => {
            if (event.target === slClosableElement && this.selected) {
                this.inputRef.nativeElement.value = this.getLabelFn(
                    this.selected,
                );
            }
        });

        slClosableElement?.addEventListener('sl-after-hide', (event) => {
            if (event.target === slClosableElement) {
                this.inputRef.nativeElement.value = '';
                this.touched = false;
                this.suggestions = [];
            }
        });

        if (checkIfHasDefaultValue(this.selected)) {
            this.inputRef.nativeElement.value = this.getLabelFn(this.selected);
        }
    }

    ngOnChanges(changes: SimpleChanges): void {
        if ('selected' in changes) {
            if (this.inputRef) {
                this.inputRef.nativeElement.value = checkIfHasDefaultValue(
                    this.selected,
                )
                    ? this.getLabelFn(this.selected)
                    : '';
            }
        }
    }

    onSelectItem(event: CustomEvent<{ item: SlMenuItem }>): void {
        const selected = this.allSuggestions[event.detail.item.value as any];

        this.inputRef.nativeElement.value = this.getLabelFn(selected);

        this.onSelect.emit(selected);
    }

    @Debounce(300)
    onInput(event: Event): void {
        this.touched = true;

        if (not(valueOf(event))) this.clear();

        this.loadNewRows();
    }

    onFocus(): void {
        this.show = true;

        if (
            not(this.cache) ||
            (not(this.touched) && this.suggestions.length === 0)
        ) {
            this.loadNewRows();
        }
    }

    onClick(event: Event): void {
        event.stopPropagation();

        if (this.disabled) return;

        if (not(this.show)) this.show = true;
    }

    onKeyDown(event: KeyboardEvent): void {
        if (slDropdownKeys.includes(event.key)) return;

        event.stopPropagation();
    }

    onWrapperMouseDown(event: Event) {
        if (this.show) event.stopPropagation();
    }

    onDropdownShow(): void {
        const { width } = this.inputRef.nativeElement.getBoundingClientRect();

        this.width = `${width}px`;
    }

    clear(): void {
        this.inputRef.nativeElement.value = '';

        this.onClear.emit();
        this.onSelect.emit(null);
    }

    private loadNewRows(): void {
        const search = this.touched ? this.inputRef.nativeElement.value : '';
        const { suggestionsProvider } = this;
        this.suggestions = [];

        suggestionsProvider(search, 0).subscribe({
            next: ({ rows, total }) => {
                this.suggestions = rows;
                this.suggestionsTotal = total;
            },
            error: (responseError: HttpErrorResponse) => {
                this.alertService.show.emit({
                    responseError,
                    key: this.errorKey,
                });
            },
        });
    }
}
