import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import {
    PageTranslationsGuard,
    providePageTranslations,
    TranslateModule,
} from '../../modules/translate/translate.module';
import { PageErrorComponent } from './page-error.component';
import { ErrorStateModule } from '../../components/error-state/error-state.module';

export * from './page-error.component';

@NgModule({
    imports: [
        RouterModule.forChild([
            {
                path: '',
                component: PageErrorComponent,
                canActivate: [PageTranslationsGuard],
            },
        ]),
        TranslateModule,
        ErrorStateModule,
    ],
    declarations: [PageErrorComponent],
    providers: [
        providePageTranslations({
            sources: ['assets/core-library/i18n/no-access-page/'],
        }),
    ],
})
export class PageErrorModule {}
