import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';

import { LazyLoadingComponent } from './lazy-loading.component';

export * from './lazy-loading.component';

@NgModule({
    exports: [LazyLoadingComponent],
    declarations: [LazyLoadingComponent],
    schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class LazyLoadingModule {}
