import { EventEmitter } from '@angular/core';
import { AbstractControl } from '@angular/forms';
import { Message } from 'ng-to-parent';

export const showErrorsFor = new EventEmitter<{
    form: AbstractControl;
    currentControl: boolean;
}>();

export const serverErrorKey = 'serverError';

export const sendOptionsKey = Symbol();

export const maybeExtraControl = new Message<MaybeExtraControl>();

export interface MaybeExtraControl {
    form: AbstractControl;
    control: AbstractControl;

    onTrue(dispose: () => void): void;
}
