import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { ShoelaceModule } from 'shoelace-style-angular';

import { ColumnTypeIconComponent } from './column-type-icon.component';
import { ColumnTypeToIconNamePipe } from './column-type-to-icon-name.pipe';

export * from './column-type-icon.component';

@NgModule({
    imports: [ShoelaceModule],
    exports: [ColumnTypeIconComponent, ColumnTypeToIconNamePipe],
    declarations: [ColumnTypeIconComponent, ColumnTypeToIconNamePipe],
    schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class ColumnTypeIconModule {}
