<div class="container" [hidden]="accessScreen">
    <core-filter-template
        #filterTemplate
        *ngIf="isOpen"
        [onFilterIsLoaded]="onFilterIsLoaded"
        [onModalClose]="onModalClose"
    ></core-filter-template>
</div>

<core-access
    [settings]="accessSettings"
    [class.hidden]="!accessScreen"
></core-access>

<div class="footer">
    <sl-button
        *ngIf="canChangeAccess"
        variant="text"
        [test-id]="testId"
        (click)="accessScreen = !accessScreen"
    >
        {{ contentSwitcherText | translate }}
    </sl-button>

    <div class="controls" [class.hide]="accessScreen">
        <sl-button
            outline
            variant="primary"
            test-id="Cancel"
            (click)="isOpen = false"
        >
            {{ '$.cancel' | translate }}
        </sl-button>

        <sl-button
            variant="primary"
            [test-id]="testIdApply"
            [loading]="submitting"
            (click)="submit()"
        >
            {{ saveButtonText | translate }}
        </sl-button>
    </div>
</div>
