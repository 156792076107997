<div class="left-border {{ borderType }}"></div>

<div [class.margin]="!isLast">
    <ng-content select="[action-buttons]"></ng-content>

    <div #rule class="rule-row" [attr.data-id]="filter.value.uid">
        <core-validate>
            <core-select
                [searchProvider]="searchSelectProvider"
                [control]="columnIdControl"
                [selected]="selectedColumn"
                [visibleOverlay]="false"
                [placeholder]="'_$$.filters.label.column' | translate"
            ></core-select>
        </core-validate>

        <core-validate>
            <core-select
                [options]="filterMethodOptions"
                [shouldSyncWidth]="false"
                [disabled]="!columnIdControl.value"
                [control]="actionControl"
                [visibleOverlay]="false"
                [placeholder]="'_$$.filters.placeholder.action' | translate"
            ></core-select>
        </core-validate>

        <core-validate
            *ngIf="actionControl.value && column; else emptyFilter"
            class="value-block"
        >
            <local-filter-value
                [state]="valueState"
                [datasetId]="(localFiltersService.datasetId$ | async)!"
                [column]="column"
                [filterMethod]="actionControl.value"
                (change)="onValueStateChange($event)"
            ></local-filter-value>

            <core-input
                class="hidden"
                [control]="filter.get('value')!"
            ></core-input>
        </core-validate>

        <ng-content select="[logical-button]"></ng-content>

        <ng-content select="[drag-button]"></ng-content>
    </div>
</div>

<ng-template #emptyFilter>
    <core-validate>
        <core-input
            placeholder=""
            class="value-block"
            [disabled]="true"
            [control]="filter.get('value')!"
        ></core-input>
    </core-validate>
</ng-template>
