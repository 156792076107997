import { CommonModule } from '@angular/common';
import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { ShoelaceModule } from 'shoelace-style-angular';

import { OverflowInternalComponent } from './overflow-internal/overflow-internal.component';
import { OverflowComponent } from './overflow.component';
import { OverflowDirective } from './overflow.directive';

export * from './overflow.component';
export * from './overflow.directive';

@NgModule({
    imports: [CommonModule, ShoelaceModule],
    exports: [OverflowComponent, OverflowDirective],
    declarations: [
        OverflowInternalComponent,
        OverflowComponent,
        OverflowDirective,
    ],
    schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class OverflowModule {}
