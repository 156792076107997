import { FilterMethod } from '../enums/filter';
import { FilterTemplateUIType } from '../enums/filter-ui-type';

export const selectFilterMethods = [FilterMethod.Equal, FilterMethod.NotEqual];
export const multiSelectFilterMethods = [
    FilterMethod.InList,
    FilterMethod.NotInList,
];

type FilterMethodsMap = Partial<Record<FilterTemplateUIType, FilterMethod[]>>;

const stringMethods: FilterMethod[] = [
    FilterMethod.Equal,
    FilterMethod.NotEqual,
    FilterMethod.Like,
    FilterMethod.Like$Not,
    FilterMethod.Like$CaseIgnore,
    FilterMethod.Like$Not$CaseIgnore,
    FilterMethod.StartsLike,
    FilterMethod.StartsLike$Not,
    FilterMethod.StartsLike$CaseIgnore,
    FilterMethod.StartsLike$Not$CaseIgnore,
    FilterMethod.EndsLike,
    FilterMethod.EndsLike$Not,
    FilterMethod.EndsLike$CaseIgnore,
    FilterMethod.EndsLike$Not$CaseIgnore,
];

export const searchFilterMethodsMap: FilterMethodsMap = {
    [FilterTemplateUIType.Search]: stringMethods,
};

export const numberFilterMethodsMap: FilterMethodsMap = {
    [FilterTemplateUIType.Number]: [
        FilterMethod.Equal,
        FilterMethod.NotEqual,
        FilterMethod.GreaterThen,
        FilterMethod.GreaterThenOrEqual,
        FilterMethod.LessThen,
        FilterMethod.LessThenOrEqual,
    ],
    [FilterTemplateUIType.NumberRange]: [
        FilterMethod.Range,
        FilterMethod.Range$Not,
    ],
};

const dateFilterMethods: FilterMethod[] = [
    FilterMethod.Equal,
    FilterMethod.NotEqual,
    FilterMethod.GreaterThen,
    FilterMethod.GreaterThenOrEqual,
    FilterMethod.LessThen,
    FilterMethod.LessThenOrEqual,
];
const dateRangeFilterMethods: FilterMethod[] = [
    FilterMethod.DateRange,
    FilterMethod.DateRange$Not,
];

export const rangeFilterMethods: FilterTemplateUIType[] = [
    FilterTemplateUIType.DatePeriod,
    FilterTemplateUIType.DateSearchPeriod,
    FilterTemplateUIType.DateTimePeriod,
    FilterTemplateUIType.DateTimeSearchPeriod,
    FilterTemplateUIType.NumberRange,
];

export const dateFilterMethodsMap: FilterMethodsMap = {
    [FilterTemplateUIType.DateCalendar]: dateFilterMethods,
    [FilterTemplateUIType.DatePeriod]: dateRangeFilterMethods,
    [FilterTemplateUIType.DateSearch]: dateFilterMethods,
    [FilterTemplateUIType.DateSearchPeriod]: dateRangeFilterMethods,
    [FilterTemplateUIType.DateTimeCalendar]: dateFilterMethods,
    [FilterTemplateUIType.DateTimePeriod]: dateRangeFilterMethods,
    [FilterTemplateUIType.DateTimeSearch]: dateFilterMethods,
    [FilterTemplateUIType.DateTimeSearchPeriod]: dateRangeFilterMethods,
};
