import { ColumnType } from '../../../enums/dataset';
import { FilterMethod } from '../../../enums/filter';

export function getColumnIcon(baseType: ColumnType): string {
    switch (baseType) {
        case ColumnType.String:
            return 'match_case';
        case ColumnType.Number:
            return 'numbers';
        case ColumnType.Boolean:
            return 'toggle_on';
        case ColumnType.Date:
            return 'calendar_today';
    }
}

export function getFilterTypeIcon(type: FilterMethod): string {
    switch (type) {
        case FilterMethod.Equal:
            return 'filter_type_equal';
        case FilterMethod.NotEqual:
            return 'filter_type_not_equal';
        case FilterMethod.GreaterThen:
            return 'filter_type_greater_than';
        case FilterMethod.GreaterThenOrEqual:
            return 'filter_type_greater_than_equal';
        case FilterMethod.LessThen:
            return 'filter_type_less_than';
        case FilterMethod.LessThenOrEqual:
            return 'filter_type_less_than_equal';
        case FilterMethod.InList:
            return 'filter_type_in_list';
        case FilterMethod.NotInList:
            return 'filter_type_not_in_list';
        case FilterMethod.Like:
            return 'filter_type_like_fill';
        case FilterMethod.Like$CaseIgnore:
            return 'filter_type_like_case_ignore_fill';
        case FilterMethod.Like$Not:
            return 'filter_type_like_not_fill';
        case FilterMethod.Like$Not$CaseIgnore:
            return 'filter_type_like_not_case_ignore_fill';
        case FilterMethod.StartsLike:
            return 'filter_type_starts_like';
        case FilterMethod.StartsLike$CaseIgnore:
            return 'filter_type_starts_like_case_ignore';
        case FilterMethod.StartsLike$Not:
            return 'filter_type_starts_like_not';
        case FilterMethod.StartsLike$Not$CaseIgnore:
            return 'filter_type_starts_like_not_case_ignore';
        case FilterMethod.EndsLike:
            return 'filter_type_ends_like';
        case FilterMethod.EndsLike$CaseIgnore:
            return 'filter_type_ends_like_case_ignore';
        case FilterMethod.EndsLike$Not:
            return 'filter_type_ends_like_not';
        case FilterMethod.EndsLike$Not$CaseIgnore:
            return 'filter_type_ends_like_not_case_ignore';
        case FilterMethod.DateRange:
        case FilterMethod.Range:
            return 'filter_type_range';
        case FilterMethod.DateRange$Not:
        case FilterMethod.Range$Not:
            return 'filter_type_out_of_range';
    }
}
