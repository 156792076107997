import {
    ChangeDetectorRef,
    Component,
    ElementRef,
    EventEmitter,
    Input,
    OnInit,
    Output,
    ViewChild,
} from '@angular/core';
import { AbstractControl } from '@angular/forms';
import { isEqual, isUndefined } from 'lodash';
import { WatchChanges } from 'ng-onpush';
import { SubscribableComponent } from 'ngx-subscribable';
import { of } from 'rxjs';

import { FilterMethod } from '../../../enums/filter';
import { Column } from '../../../interfaces/dataset';
import { TranslateService } from '../../../modules/translate/translate.service';
import { FilterValueState } from '../../filter-value/filter-value.component';
import { convertNullValuesToMarker } from '../../filter-value/null.helper';
import {
    SelectOptionSearchProvider,
    SelectOptionValue,
} from '../../ui-kit-components/select/select.component';
import { LocalFiltersTypeService } from '../services/filter-type.service';
import { LocalFiltersService } from '../services/local-filters.service';
import { emptyFilterValueState } from '../tools/consts';
import { getColumnIcon, getFilterTypeIcon } from '../tools/icon.tools';
import { BorderType, FilterControlNames } from '../tools/types';
import { IsColumnResultUnsavedPipe } from '../../../pipes/save-in-dataset.pipe';

@Component({
    selector: 'core-filter-rule',
    templateUrl: './filter-rule.component.html',
    styleUrls: ['./filter-rule.component.less'],
    providers: [IsColumnResultUnsavedPipe],
})
export class FilterRuleComponent
    extends SubscribableComponent
    implements OnInit
{
    @Input()
    filter!: AbstractControl;

    @Input()
    isLast!: boolean;

    @Input()
    borderType!: BorderType | '';

    @Output()
    deleteFilter = new EventEmitter<void>();

    @ViewChild('rule')
    readonly rule!: ElementRef<HTMLDivElement>;

    @WatchChanges()
    column?: Column;

    @WatchChanges()
    valueState: FilterValueState = emptyFilterValueState;

    @WatchChanges()
    selectedColumn?: SelectOptionValue<number>;

    filterMethodOptions: SelectOptionValue<FilterMethod>[] = [];

    filterMethodTypes: FilterMethod[] = [];

    actionControl!: AbstractControl;
    columnIdControl!: AbstractControl;

    columnOptions: SelectOptionValue<number>[] = [];

    get columns(): Column[] {
        return this.localFiltersService.columns$.value;
    }

    //на запрос за колонками нет search поэтому берем колонки из сервиса
    readonly searchSelectProvider: SelectOptionSearchProvider = (search) => {
        const rows = this.columnOptions.filter(({ label }) => {
            const labelLowerCase = label.toLocaleLowerCase();
            const searchLowerCase = search.toLocaleLowerCase();

            return labelLowerCase.includes(searchLowerCase);
        });

        return of({
            rows,
            total: rows.length,
        });
    };

    constructor(
        public localFiltersService: LocalFiltersService,
        private filterEditService: LocalFiltersTypeService,
        private changeDetectorRef: ChangeDetectorRef,
        private translateService: TranslateService,
        private isColumnResultUnsavedPipe: IsColumnResultUnsavedPipe,
    ) {
        super();
    }

    ngOnInit(): void {
        this.columnOptions = this.getColumnOptions();

        this.actionControl = this.filter.get('action')!;
        this.columnIdControl = this.filter.get('column_id')!;

        this.parseExistValues();

        this.subscriptions = [
            this.columnIdControl.valueChanges.subscribe((id?: number) => {
                this.column = this.getColumnBy(id);

                this.resetControls([
                    FilterControlNames.Action,
                    FilterControlNames.Value,
                ]);

                this.setFilterMethods();
            }),

            this.actionControl.valueChanges.subscribe(() => {
                this.resetControls([FilterControlNames.Value]);

                this.changeDetectorRef.markForCheck();
            }),
        ];
    }

    onValueStateChange(state: FilterValueState): void {
        this.valueState = state;

        this.filter.patchValue({ value: state.value });
    }

    private setFilterMethods(): void {
        if (this.column) {
            this.filterMethodTypes =
                this.filterEditService.filterMethodsByColumnType[
                    this.column.base_type
                ];

            this.filterMethodOptions = this.getFilterTypeOptions();

            this.changeDetectorRef.detectChanges();
        }
    }

    private resetControls(names: FilterControlNames[]): void {
        names.forEach((name) => {
            const control = this.filter.get(name);

            if (control && control.value) control.reset();

            if (name === FilterControlNames.Value) {
                this.valueState = emptyFilterValueState;
            }
        });
    }

    private parseExistValues(): void {
        if (isUndefined(this.column)) {
            const columnIdValue = this.columnIdControl.value;

            if (!columnIdValue) return;

            this.column = this.getColumnBy(columnIdValue);

            this.selectedColumn = this.columnOptions.find(
                ({ value }) => value === columnIdValue,
            );

            this.setValueState();

            if (isUndefined(this.selectedColumn)) this.deleteFilter.emit();
        }

        if (this.filterMethodTypes.length === 0) this.setFilterMethods();
    }

    private getColumnBy(id?: number): Column | undefined {
        return id ? this.columns.find((column) => column.id === id) : undefined;
    }

    private getFilterTypeOptions(): SelectOptionValue<FilterMethod>[] {
        return this.filterMethodTypes.map((type) => ({
            value: type,
            label: this.translateService.getTranslation(
                '$$.filters.method.' + type,
            ),
            customIcon: 'filters/' + getFilterTypeIcon(type),
        }));
    }

    private getColumnOptions(): SelectOptionValue<number>[] {
        return this.columns.map((col) => ({
            value: col.id,
            label: col.name,
            disabled: this.isColumnResultUnsavedPipe.transform(col),
            prefixIcon: getColumnIcon(col.base_type),
        }));
    }

    private setValueState(): void {
        const valueByControl = this.filter.get('value')?.value;
        const valueWithNullMarker = convertNullValuesToMarker(valueByControl);

        let value;

        switch (this.actionControl.value) {
            case FilterMethod.InList:
            case FilterMethod.NotInList:
                value = Array.isArray(valueWithNullMarker)
                    ? valueWithNullMarker
                    : [valueWithNullMarker];
                break;
            default:
                value = valueWithNullMarker;
        }

        this.onValueStateChange({
            hasValue: true,
            value,
        });
    }
}
