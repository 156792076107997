import { Component, Input } from '@angular/core';

import { BaseUrlService } from '../../../services/base-url.service';
import { parseUrl } from '../../../tools/url';

@Component({
    selector: 'core-navigation-item',
    templateUrl: './navigation-item.component.html',
    styleUrls: ['./navigation-item.component.less'],
})
export class NavigationItemComponent {
    @Input()
    href = '/';

    count = 0;

    constructor(private baseUrlService: BaseUrlService) {}

    isActive(routerLink: string): boolean {
        const baseUrl = this.baseUrlService.resolve(location.href)!;
        const path = parseUrl(baseUrl)!.pathname;

        return path.startsWith(routerLink);
    }
}
