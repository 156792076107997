import { MonoTypeOperatorFunction, Observable, Subscription } from 'rxjs';

export function skipAfter<T>(
    observable: Observable<unknown>,
): MonoTypeOperatorFunction<T> {
    return (source: Observable<T>) =>
        new Observable((subscriber) => {
            let skip = false;

            const subscriptions: Subscription[] = [
                source.subscribe((value) => {
                    if (skip) skip = false;
                    else subscriber.next(value);
                }),
                observable.subscribe(() => {
                    skip = true;
                }),
            ];

            return () =>
                subscriptions.forEach((subscription) =>
                    subscription.unsubscribe(),
                );
        });
}
