import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { InputComponent } from './input.component';
import { TranslateModule } from '../../../modules/translate/translate.module';
import { InputSearchDirective } from './input-search.directive';
import { MaterialIconComponent } from '../material-icon/material-icon.component';

export * from './input.component';

@NgModule({
    imports: [MaterialIconComponent, TranslateModule, CommonModule],
    exports: [InputComponent, InputSearchDirective],
    declarations: [InputComponent, InputSearchDirective],
})
export class InputModule {}
