import { Directive, Input, OnInit } from '@angular/core';
import { SubscribableDirective } from 'ngx-subscribable';

import { getHost } from '../../tools/get-host';
import { tooltipify } from './tooltipify.internal';

@Directive({ selector: '[multiLineTooltipify]' })
export class MultiLineTooltipifyDirective
    extends SubscribableDirective
    implements OnInit
{
    @Input()
    multiLineTooltipify: '' = '';

    private readonly host = getHost();

    ngOnInit(): void {
        const { host } = this;

        this.subscriptions = [
            tooltipify(
                host,
                host,
                () => host.scrollWidth > host.clientWidth,
            ).subscribe(),
        ];
    }
}
