import { EventEmitter, Inject, Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { WebsocketEventType } from '../enums/websocket-event-type';
import { ApiBaseUrls } from '../modules/rest/interfaces';
import { API_BASE_URLS } from '../modules/rest/tokens';
import { AppUserService } from './app-user.service';
import { StorageService } from './storage.service';

const RECONNECTION_LIMIT = 3;
const RECONNECTION_TIMEOUT = 1000;

@Injectable({ providedIn: 'root' })
export class WebSocketService {
    private readonly message = new EventEmitter<WebSocketData>();

    private socket?: WebSocket;
    private reconnections = 0;

    constructor(
        @Inject(API_BASE_URLS) private apiBaseUrls: ApiBaseUrls,
        storageService: StorageService,
        private appUserService: AppUserService,
    ) {
        storageService.authToken.subscribe((token) => {
            if (token) {
                this.socket = this.createSocket(token);
            } else {
                this.closeSocket();
            }
        });
    }

    listen<Message>(type: WebsocketEventType): Observable<Message> {
        return new Observable((subscriber) => {
            const subscription = this.message.subscribe(
                ({ event, payload }) => {
                    if (event === type) subscriber.next(payload);
                },
            );

            return () => subscription.unsubscribe();
        });
    }

    createdSharedSocket(sharedHash: string): void {
        if (sharedHash) {
            this.socket = this.createSocket(sharedHash, true);
        } else {
            this.closeSocket();
        }
    }

    private createSocket(token: string, isShared = false): WebSocket {
        const protocol = location.protocol === 'https:' ? 'wss:' : 'ws:';
        const base = this.apiBaseUrls.gateway;
        const query = isShared ? 'shared_hash' : 'token';

        const url = `${protocol}//${location.host}${base}/v1/ws?${query}=${token}`;

        const socket = new WebSocket(url);

        socket.onmessage = ({ data }) => {
            const webSocketData = JSON.parse(data);
            const { uuid } = webSocketData.payload;

            if (uuid) {
                this.appUserService.clientUuid.next(uuid);
            }

            try {
                this.message.emit(webSocketData);
            } catch (_) {}
        };

        socket.onclose = () => {
            if (this.socket !== socket) return;
            if (++this.reconnections > RECONNECTION_LIMIT) return;
            if (socket.readyState === socket.OPEN) return;

            setTimeout(() => {
                this.socket = this.createSocket(token);
            }, RECONNECTION_TIMEOUT);
        };

        return socket;
    }

    private closeSocket(): void {
        this.socket?.close();

        this.reconnections = 0;
        this.socket = void 0;
        this.appUserService.clientUuid.next(void 0);
    }
}

interface WebSocketData {
    event: WebsocketEventType;
    payload: any;
}
