import { CdkDragDrop, CdkDragMove } from '@angular/cdk/drag-drop';
import { Injectable } from '@angular/core';
import {
    AbstractControl,
    FormGroup,
    UntypedFormArray,
    Validators,
} from '@angular/forms';
import { cloneDeep, isNull, isUndefined } from 'lodash';
import { BehaviorSubject } from 'rxjs';
import { v4 as uuidv4 } from 'uuid';

import { SearchParam } from '../../../enums/search-param';
import { Column } from '../../../interfaces/dataset';
import { Form, FormService } from '../../../modules/form/form.service';
import { deepSearchByProperty } from '../../../tools/deep-search-by-property';
import { dataId } from '../tools/consts';
import { DataOptionFilterMapped, DropAction, DropInfo } from '../tools/types';
import { DataOptionFilter } from '../../../interfaces/data-option';

@Injectable({ providedIn: 'root' })
export class LocalFiltersService {
    form = new UntypedFormArray([]);

    dropTargetIds: string[] = [];

    dropActionTodo: DropInfo = null as any;

    columns$ = new BehaviorSubject<Column[]>([]);
    datasetId$ = new BehaviorSubject<number>(0);

    constructor(private formService: FormService) {
        this.form.valueChanges.subscribe(() => {
            this.dropTargetIds = [];

            this.prepareDragDrop(this.form.value);
        });
    }

    defaultRuleForm(): Form<DataOptionFilterMapped> {
        return this.formService.form<DataOptionFilterMapped>({
            uid: [uuidv4()],
            logical: [],
            column_id: [null, [Validators.required]],
            action: [null, [Validators.required]],
            value: [null, [Validators.required]],
        });
    }

    defaultGroupForm(): Form<DataOptionFilterMapped> {
        return this.formService.form<DataOptionFilterMapped>({
            uid: [uuidv4()],
            logical: [],
            child: new UntypedFormArray([]),
        });
    }

    addFilters(
        filters: DataOptionFilter[],
        needUid = true,
    ): Form<DataOptionFilter>[] {
        const formGroups: FormGroup[] = [];

        filters.forEach((filter) => {
            if ('child' in filter && !isNull(filter['child'])) {
                const groupForm = cloneDeep(this.defaultGroupForm());

                groupForm.get('logical')!.patchValue(filter.logical);

                if (!needUid) groupForm.removeControl('uid');

                this.addFilters(filter.child!, needUid).forEach((item) =>
                    (groupForm.get('child') as UntypedFormArray).push(item),
                );

                formGroups.push(groupForm);
            } else {
                formGroups.push(this.formToAddFilter(filter, needUid));
            }
        });

        return formGroups as any;
    }

    formToAddFilter(filter: DataOptionFilter, needUid = true): FormGroup {
        const ruleForm = cloneDeep(this.defaultRuleForm());

        if (!needUid) ruleForm.removeControl('uid');

        ruleForm.patchValue(filter as any);

        return ruleForm;
    }

    dragMoved(event: CdkDragMove<any>): void {
        const { x: xPosition, y: yPosition } = event.pointerPosition;

        const element = document.elementFromPoint(xPosition, yPosition);

        if (!element) {
            this.clearDragInfo();

            return;
        }

        const container = element.attributes.getNamedItem(dataId)
            ? element
            : element.closest(`[${dataId}]`);

        if (!container) {
            this.clearDragInfo();

            return;
        }
        const trackedContainerUid = container.getAttribute(dataId)!;

        this.dropActionTodo = { trackedContainerUid };

        const targetRect = container.getBoundingClientRect();
        const oneThird = targetRect.height / 3;

        let action: DropAction;

        switch (true) {
            case yPosition - targetRect.top < oneThird:
                action = DropAction.Before;
                break;
            case yPosition - targetRect.top > 2 * oneThird:
                action = DropAction.After;
                break;
            default:
                if (this.getItemByUid(trackedContainerUid).value.child) {
                    action = DropAction.Inside;
                }
        }

        this.dropActionTodo.action = action!;

        this.showDragInfo();
    }

    drop(event: CdkDragDrop<any>): void {
        if (
            isNull(this.dropActionTodo) ||
            isUndefined(this.dropActionTodo.action)
        ) {
            this.dropActionTodo = null as any;

            return;
        }

        const { action, trackedContainerUid } = this.dropActionTodo;

        const draggedNestedForm = event.item.data;
        const draggedItemUid = draggedNestedForm.value.uid;
        const parentItemUid = event.previousContainer.id;
        const targetListUid = this.getParentNodeUid(
            trackedContainerUid,
            this.form.value,
            'main',
        )!;

        this.prepareDragItem(draggedNestedForm);

        if (isNull(targetListUid)) return;

        const oldItemContainer = this.getItemByUid(parentItemUid);
        const oldChildrenForm = (
            parentItemUid == 'main'
                ? oldItemContainer
                : oldItemContainer.get('child')
        ) as UntypedFormArray;
        const oldChildren = oldChildrenForm.value;

        const newContainer = this.getItemByUid(targetListUid);
        const newChildrenForm = (
            targetListUid == 'main' ? newContainer : newContainer.get('child')
        ) as UntypedFormArray;

        const index = oldChildren.findIndex(
            (c: DataOptionFilterMapped) => c.uid === draggedItemUid,
        );

        oldChildrenForm.removeAt(index);

        switch (action) {
            case DropAction.Before:
            case DropAction.After:
                const newChildren = newChildrenForm.value;

                const targetIndex = newChildren.findIndex(
                    (c: DataOptionFilterMapped) =>
                        c.uid === trackedContainerUid,
                );

                const indexByPosition =
                    action === DropAction.Before
                        ? targetIndex
                        : targetIndex + 1;

                newChildrenForm.insert(indexByPosition, draggedNestedForm);

                break;

            case DropAction.Inside:
                const nestedContainer = this.getItemByUid(
                    trackedContainerUid,
                ).get('child') as UntypedFormArray;

                nestedContainer.push(draggedNestedForm);

                break;
        }

        this.clearDragInfo(true);
    }

    private getItemByUid(uid: string): AbstractControl {
        if (uid === 'main') return this.form;

        const nestedFormPath = deepSearchByProperty(
            this.form.value,
            'uid',
            SearchParam.Path,
            '',
            uid,
        );

        return this.form.get(nestedFormPath.slice(0, -4))!;
    }

    private getParentNodeUid(
        uid: string,
        nodesToSearch: DataOptionFilterMapped[],
        parentUid: string,
    ): string | null {
        for (const node of nodesToSearch) {
            if (node.uid == uid) return parentUid;

            if (node.child) {
                const parentNode = this.getParentNodeUid(
                    uid,
                    node.child,
                    node.uid,
                );

                if (parentNode) return parentNode;
            }
        }

        return null;
    }

    private prepareDragDrop(nodes: DataOptionFilterMapped[]): void {
        nodes.forEach((node) => {
            if (node.child) {
                if (node.child.length) this.dropTargetIds.push(node.uid);

                this.prepareDragDrop(node.child);
            }
        });
    }

    private prepareDragItem(dragItem: FormGroup): void {
        const child = dragItem.get('child') as UntypedFormArray;

        if (child && child.value.length) {
            child.controls.forEach((control: any) =>
                this.prepareDragItem(control),
            );
        } else {
            if (!child) {
                this.clearRuleForm(dragItem);
            }
        }
    }

    private clearRuleForm(ruleForm: FormGroup): void {
        const ruleFormCopy = this.defaultRuleForm();

        ruleFormCopy.patchValue(ruleForm.value);

        ['column_id', 'action', 'value'].forEach((name) => {
            ruleForm.removeControl(name);
            ruleForm.setControl(name, ruleFormCopy.get(name));
        });
    }

    private showDragInfo(): void {
        this.clearDragInfo();

        const { action, trackedContainerUid } = this.dropActionTodo;

        if (this.dropActionTodo && action) {
            const uidElement = document.querySelector(
                `[${dataId}="${trackedContainerUid}"`,
            );

            const element =
                action === DropAction.Inside
                    ? uidElement
                    : uidElement?.parentElement;

            element?.classList.add('border-' + action);
        }
    }

    private clearDragInfo(dropped = false): void {
        if (dropped) this.dropActionTodo = null as any;

        const clearClasses = ['border-before', 'border-after', 'border-inside'];

        clearClasses.forEach((item) => {
            document
                .querySelectorAll('.' + item)
                .forEach((element) => element.classList.remove(item));
        });
    }
}
