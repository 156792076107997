import { Injectable, inject } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivateFn, Router, UrlTree } from '@angular/router';

import { BaseUrlService } from '../../services/base-url.service';
import { StorageService } from '../../services/storage.service';

interface AuthTokenPageQueryParams {
    token?: string;
    redirect?: string;
}

export const AuthTokenPageGuard: CanActivateFn = (route) => {
    const baseUrlService = inject(BaseUrlService);
    const router = inject(Router);
    const storageService = inject(StorageService);

    const { token, redirect }: AuthTokenPageQueryParams = route.queryParams;

    if (token) storageService.authToken.next(token);

    if (redirect) {
        const url = decodeURIComponent(redirect) + location.hash;

        return router.parseUrl(baseUrlService.resolve(url) || '/');
    }

    return router.parseUrl('/');
};
