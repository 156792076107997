import { Component, Input } from '@angular/core';

@Component({
    selector: 'core-spinner',
    templateUrl: './spinner.component.html',
    styleUrls: ['./spinner.component.less'],
})
export class SpinnerComponent {
    @Input()
    isLoading = false;

    @Input()
    isEmpty = false;

    @Input()
    hideContentWhileLoading = false;
}
