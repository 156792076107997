import { CommonModule } from '@angular/common';
import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';

import { HeaderComponent } from './header.component';

export * from './header.component';

@NgModule({
    imports: [CommonModule, RouterModule],
    exports: [HeaderComponent],
    declarations: [HeaderComponent],
    schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class HeaderModule {}
