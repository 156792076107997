import { AfterViewInit, Directive, ElementRef, NgModule } from '@angular/core';
import { SlDropdown } from '@shoelace-style/shoelace';
import { SubscribableDirective } from 'ngx-subscribable';
import { fromEvent } from 'rxjs';

@Directive({
    selector: 'sl-dropdown [with-submenu]',
})
export class SubmenuDirective
    extends SubscribableDirective
    implements AfterViewInit
{
    constructor(private hostRef: ElementRef<SlDropdown>) {
        super();
    }

    ngAfterViewInit(): void {
        const dropdown = this.hostRef.nativeElement;
        const childDropdowns = dropdown.querySelectorAll('sl-dropdown');

        childDropdowns.forEach((childDropdown) => {
            childDropdown.hoist = true;
            childDropdown.placement = 'right-start';

            this.subscriptions = [
                fromEvent<MouseEvent>(childDropdown, 'mouseenter').subscribe(
                    ({ target }) => {
                        if (target === childDropdown) childDropdown.show();
                    },
                ),
                fromEvent<MouseEvent>(childDropdown, 'mouseleave').subscribe(
                    ({ target }) => {
                        if (target === childDropdown) childDropdown.hide();
                    },
                ),
                fromEvent<MouseEvent>(childDropdown, 'click').subscribe(
                    (event) => event.stopPropagation(),
                ),
            ];
        });
    }
}

@NgModule({
    exports: [SubmenuDirective],
    declarations: [SubmenuDirective],
})
export class SubmenuDirectiveModule {}
