import {CUSTOM_ELEMENTS_SCHEMA, NgModule} from '@angular/core';
import { CommonModule } from '@angular/common';
import { BetaBadgeComponent } from './beta-badge.component';
import { ShoelaceModule } from 'shoelace-style-angular';
import { TranslateModule } from '../../modules/translate/translate.module';

@NgModule({
    declarations: [BetaBadgeComponent],
    imports: [CommonModule, TranslateModule, ShoelaceModule],
    exports: [BetaBadgeComponent],
    schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class BetaBadgeModule {}
