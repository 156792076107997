import { Component, Input } from '@angular/core';

@Component({
    selector: 'plmt-empty-state',
    templateUrl: './empty-state.component.html',
    styleUrls: ['./empty-state.component.less'],
})
export class EmptyStateComponent {
    @Input()
    description!: string;
}
