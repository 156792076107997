import {
    ChangeDetectorRef,
    Component,
    OnInit,
    Output,
    EventEmitter,
} from '@angular/core';
import { WatchChanges } from 'ng-onpush';
import { SubscribableComponent } from 'ngx-subscribable';
import { tap } from 'rxjs';

import { NotificationsApiService } from './api/notifications-api.service';
import { Notification } from './interfaces/notification';
import { NavigationService } from '../navigation.service';
import { ResponseArray } from '../../../interfaces/rest-api';
import { NotifyService } from '../../notify/notify.service';
import { AlertService } from '../../alert/alert.service';

export enum NotificationState {
    Main = 'main',
    Settings = 'settings',
}

@Component({
    selector: 'plmt-notification',
    templateUrl: './notification.component.html',
    styleUrls: ['./notification.component.less'],
})
export class NotificationComponent
    extends SubscribableComponent
    implements OnInit
{
    @Output()
    onSetUntouched = new EventEmitter<number>();

    readonly NotificationState = NotificationState;

    readonly loadMoreMessages = () =>
        this.notificationsApiService
            .list({
                offset: this.messages.rows.length,
            })
            .pipe(
                tap(({ rows, unviewed_total }) => {
                    this.messages.rows.push(...rows);

                    this.setUntouched(unviewed_total);
                }),
            );

    readonly loadMoreUntouched = () =>
        this.notificationsApiService
            .list({
                is_viewed: false,
                offset: this.messages.rows.length,
            })
            .pipe(
                tap(({ rows, unviewed_total }) => {
                    this.untouchedMessages.rows.push(...rows);

                    this.setUntouched(unviewed_total);
                }),
            );

    state = NotificationState.Main;

    @WatchChanges()
    untouched = 0;
    untounchedBadgeLabel = ';';

    @WatchChanges()
    loading = false;

    messages: ResponseArray<Notification> = {
        rows: [],
        total: 0,
    };
    messagesLoading = false;

    untouchedMessages: ResponseArray<Notification> = {
        rows: [],
        total: 0,
    };
    untouchedLoading = false;

    isOpen = false;
    isShowBadge = true;

    iconName = 'bell';

    constructor(
        private changeDetectorRef: ChangeDetectorRef,
        private notificationsApiService: NotificationsApiService,
        private notifyService: NotifyService,
        private alertService: AlertService,
        private navigationService: NavigationService,
    ) {
        super();
    }

    ngOnInit(): void {
        this.notificationsApiService
            .getUntouchedCount()
            .subscribe((count) => this.setUntouched(count));

        this.subscriptions = [
            this.notifyService.messages.subscribe((notification) => {
                this.setUntouched(this.untouched + 1);

                this.messages.rows.unshift(notification);
                this.messages.total += 1;

                this.untouchedMessages.rows.unshift(notification);
                this.untouchedMessages.total += 1;
            }),

            this.navigationService.isOpenMenu.subscribe((isOpen) => {
                this.isShowBadge = !isOpen;
            }),

            this.navigationService.isDisplayMenu.subscribe((isDisplay) => {
                this.iconName = isDisplay ? 'bell' : 'bell-fill';
            }),
        ];
    }

    init(): void {
        this.state = NotificationState.Main;
        this.loading = true;

        this.toMain();
    }

    toMain(): void {
        this.messagesLoading = true;

        this.notificationsApiService.list().subscribe(({ rows, total }) => {
            const unviewed_total = rows.filter((row) => !row.is_viewed).length;

            this.loading = false;
            this.messagesLoading = false;

            this.messages = { rows, total };

            this.setUntouched(unviewed_total);
        });
    }

    toUntouched(): void {
        this.untouchedLoading = true;

        this.notificationsApiService
            .list({ is_viewed: false })
            .subscribe(({ rows, total, unviewed_total }) => {
                this.untouchedLoading = false;

                this.untouchedMessages = { rows, total };

                this.setUntouched(unviewed_total);
            });
    }

    onTabChange(event: CustomEvent<{ name: 'all' | 'untouched' }>): void {
        const { name } = event.detail;

        if (name === 'untouched') this.toUntouched();
        else this.toMain();
    }

    clear(): void {
        this.setUntouched(0);

        this.messages = { rows: [], total: 0 };
    }

    touch(count: number): void {
        const untachedCount = this.untouched - count;
        this.setUntouched(untachedCount > 0 ? untachedCount : 0);
    }

    remove(id: number, target: ResponseArray<Notification>): void {
        this.notificationsApiService.delete(id).subscribe({
            next: () => {
                this.alertService.show.emit({
                    variant: 'success',
                    key: '_$.navigation.notification.delete',
                });
                target.total -= 1;
                target.rows = target.rows.filter((item) => item.id !== id);
                this.changeDetectorRef.markForCheck();
            },
            error: (response) => {
                this.alertService.show.emit({
                    responseError: response,
                    key: '_$.navigation.notification.delete.error',
                });
            },
        });
    }

    private setUntouched(count = 0): void {
        this.untouched = count;
        this.untounchedBadgeLabel = '';

        this.onSetUntouched.emit(this.untouched);

        if (count > 0)
            this.untounchedBadgeLabel = count < 100 ? `${count}` : '99+';
    }
}
