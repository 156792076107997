import { Component, HostListener, Input, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { WatchChanges } from 'ng-onpush';
import { SubscribableComponent } from 'ngx-subscribable';
import { TableFilterItem, TableFilterType } from './table-filter.interfaces';
import { FormService } from '../../../modules/form/form.service';
import { stringValidator } from '../../../validators/string.validator';

@Component({
    selector: 'core-table-filter',
    templateUrl: './table-filter.component.html',
    styleUrls: ['./table-filter.component.less'],
})
export class TableFilterComponent
    extends SubscribableComponent
    implements OnInit
{
    @Input()
    config: TableFilterItem[] = [];
    @Input()
    label = '_$.tableFilter.title';

    @HostListener('document:keydown.enter')
    keyDown(): void {
        if (this.isShown && this.isOpen) {
            this.apply();
        }
    }

    @WatchChanges()
    isOpen = false;

    //NOTE:
    //нужно для того что бы нажатие на enter было доступно только после анимации
    isShown = false;

    isApplied = false;

    readonly TableFilterType = TableFilterType;

    private restQueryParams: any = {};
    private configMap: any = {
        search: true,
    };

    form = this.formService.form<{
        search: string;
    }>({
        search: [null, [stringValidator({ min: -1, max: 250 })]],
    });

    constructor(
        private activatedRoute: ActivatedRoute,
        private formService: FormService,
        private router: Router,
    ) {
        super();
    }

    ngOnInit(): void {
        this.subscriptions = [
            this.activatedRoute.queryParams.subscribe((params) => {
                this.isApplied = false;

                Object.entries(params).forEach(([key, value]) => {
                    if (key in this.configMap) {
                        this.form.patchValue({ search: value });
                        this.isApplied = true;
                    } else {
                        this.restQueryParams[key] = value;
                    }
                });

                Object.keys(this.restQueryParams).forEach((key) => {
                    if (key in params) return;

                    delete this.restQueryParams[key];
                });
            }),
        ];
    }

    showModal(): void {
        this.form.reset();

        Object.entries(this.activatedRoute.snapshot.queryParams).forEach(
            ([key, value]) => {
                if (key in this.configMap) {
                    this.form.patchValue({ search: value });
                }
            },
        );

        this.isOpen = true;
    }

    apply(): void {
        if (this.form.invalid && this.isOpen) {
            this.formService.showLocalErrors(this.form);
            return;
        }
        this.router.navigate([], {
            queryParams: {
                search: this.form.value.search || null,
                ...this.restQueryParams,
                offset: null,
            },
        });
        this.isOpen = false;
    }

    reset(): void {
        this.form.reset();
        this.apply();
    }
}
