import { EnumMap, UnionFrom } from 'ts-type-from-enum';

import { EventLevel } from '../enums/event-level';
import { EventType } from '../enums/event-type';
import { ExportedEntity } from '../enums/exported-entity';

export interface NotificationDownloadable {
    storage_path: string;
    filename: string;
}

export enum EventEntityType {
    Project = 'project',
}

export interface ProjectImportData {
    entity: EventEntityType;
    id: number;
    name: string;
}

export type NotificationEventDataVariant = EnumMap<
    EventType,
    {
        [EventType.DatasetLoaded]: {
            id: number;
            name: string;
        };

        [EventType.DatasetLocalGc]: {
            removed_entities_count: number;
            error: string;
        };

        [EventType.DatasetUpdated]: {
            id: number;
            name: string;
        };

        [EventType.LogsExported]: NotificationDownloadable & {
            error: string;
        };

        [EventType.ProjectExported]: NotificationDownloadable & {
            project_name: string;
            layer_name: string;
        };

        [EventType.ReportReady]: NotificationDownloadable & {
            id: number;
            name: string;
        };

        [EventType.WidgetExported]: NotificationDownloadable & {
            widget_name: string;
        };

        [EventType.EntityExported]: NotificationDownloadable & {
            entity_type: EventEntityType;
        };

        [EventType.ImportFinished]: NotificationDownloadable & {
            error: string;
            not_found_project_links?: string[] | null;
            head_elements: ProjectImportData[] | null;
        };
    }
>;

export type NotificationFor<Type extends EventType> = {
    id: number;
    event_type: Type;
    event_data: NotificationEventDataVariant[Type];
    event_level: EventLevel;
    error?: string;
    is_viewed: boolean;
    create_date: string;
};

export type Notification = UnionFrom<{
    [Type in EventType]: NotificationFor<Type>;
}>;

export type ExportReady = NotificationDownloadable & {
    exported_entity: ExportedEntity;
    error?: string;
};
