import { Injectable } from "@angular/core";

@Injectable({ providedIn: 'root' })
export class ClipboardService {

    // clipboard требует наличия защищенного адреса (https или localhost)
    write(text: string): void {
        if (navigator.clipboard) {
            navigator.clipboard.writeText(text);
        } else {
            const hiddenTextArea = document.createElement('textarea');
            hiddenTextArea.value = text;

            hiddenTextArea.style.opacity = '0';

            document.body.prepend(hiddenTextArea);
            hiddenTextArea.select();

            document.execCommand('copy');

            hiddenTextArea.remove();
        }
    }
}
