import { Component, EventEmitter, Output } from '@angular/core';

@Component({
    selector: 'filter-clear-modal',
    templateUrl: './filter-clear-modal.component.html',
    styleUrls: ['./filter-clear-modal.component.less'],
})
export class FilterClearModalComponent {
    @Output()
    clear = new EventEmitter<void>();

    isOpen = false;

    open(): void {
        this.isOpen = true;
    }

    clearFilters(): void {
        this.clear.emit();

        this.isOpen = false;
    }
}
