import { Directive, NgModule, OnInit } from '@angular/core';
import { SlInput } from '@shoelace-style/shoelace';
import { SubscribableDirective } from 'ngx-subscribable';
import { getHost } from 'plmt-core-library';
import { fromEvent } from 'rxjs';

@Directive({
    selector: 'sl-input[autocompleteOff]',
})
export class AutoCompleteOffDirective
    extends SubscribableDirective
    implements OnInit
{
    private host: SlInput = getHost();

    constructor() {
        super();
    }

    ngOnInit(): void {
        const { host } = this;

        host.setAttribute('readonly', '');

        this.subscriptions = [
            fromEvent(host, 'sl-focus').subscribe(() => {
                host.removeAttribute('readonly');
            }),
        ];
    }
}

@NgModule({
    exports: [AutoCompleteOffDirective],
    declarations: [AutoCompleteOffDirective],
})
export class AutoCompleteOffDirectiveModule {}
