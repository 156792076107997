import { TranslateType } from './translate.enums';

export class Composer {
    private [TranslateType.Main] = Object.create(this.basePrototype);
    private [TranslateType.Page] = Object.create(this[TranslateType.Main]);

    constructor(private readonly basePrototype: any = null) {}

    get(type: TranslateType): any {
        return this[type];
    }

    set(type: TranslateType, value: any): any {
        switch (type) {
            case TranslateType.Main:
                this[TranslateType.Main] = Object.setPrototypeOf(
                    value,
                    this.basePrototype,
                );

                Object.setPrototypeOf(
                    this[TranslateType.Page],
                    this[TranslateType.Main],
                );

                break;
            case TranslateType.Page:
                this[TranslateType.Page] = Object.setPrototypeOf(
                    value,
                    this[TranslateType.Main],
                );
                break;
        }

        return this[TranslateType.Page];
    }
}
