import { inject } from '@angular/core';
import { CanActivateFn, Router } from '@angular/router';

import {
    accessDeniedPath,
    dashboardRoutePath,
    managerRoutePath,
} from '../constants/route-path';
import { ModuleName } from '../enums/module';
import { AccessRightsService } from '../services/access-rights.service';
import { PageErrorService } from '../services/page-error.service';
import { RouteData } from '../tools/route-date';

export const AccessRightsGuard: CanActivateFn = (route) => {
    const accessRightsService = inject(AccessRightsService);
    const pageErrorService = inject(PageErrorService);
    const router = inject(Router);

    const { accessRights } = route.data as RouteData;

    if (accessRights) {
        const { rights, noAccessRedirect } = accessRights;

        let moduleName: ModuleName = ModuleName.Dashboard;
        let moduleRights: number[] = [];

        Object.entries(rights).forEach(([key, value]) => {
            moduleName = key as ModuleName;
            moduleRights = value;
        });

        const hasAccess = accessRightsService.checkAccessRights(moduleRights);

        const hasAccessToModule = accessRightsService.modulesAccess.value.find(
            (module) => module.module_name === moduleName,
        )?.module_access;

        if (hasAccess) return true;

        if (noAccessRedirect) {
            const link = new URL(noAccessRedirect, location.origin);

            if (link.hostname !== location.hostname) {
                location.href = noAccessRedirect;

                return false;
            }

            return router.parseUrl(noAccessRedirect);
        }

        const routePath = getRoutePath(moduleName, hasAccessToModule!);

        pageErrorService.navigate(accessDeniedPath, routePath);

        return false;
    }

    return true;
};

function getRoutePath(
    moduleName: ModuleName,
    hasAccessToModule: boolean,
): string {
    switch (moduleName) {
        case ModuleName.Manager:
            return hasAccessToModule ? managerRoutePath : dashboardRoutePath;
        case ModuleName.Dashboard:
            return hasAccessToModule ? dashboardRoutePath : managerRoutePath;
        default:
            return dashboardRoutePath;
    }
}
