import { ChangeDetectorRef, Component } from '@angular/core';
import { ToParent } from 'ng-to-parent';

import { tap } from 'rxjs';
import { DATA_VIEW_PATH } from '../../../constants/filter-default-value-keys';
import { Format_Server } from '../../../interfaces/column.format';
import { DataSearchProvider } from '../../../interfaces/rest-api';
import { FormControlService } from '../../../services/form-control.service';
import { FilterValueComponent } from '../../filter-value/filter-value.component';
import { MultiSelectMapperType } from '../../ui-kit-components/multi-select-next/multi-select-next.component';
import { SelectOptionValue } from '../../ui-kit-components/select/select.component';
import { LocalFiltersTypeService } from '../services/filter-type.service';

export * from './components/has-time/has-time.component';
export * from './components/range/range-filter.component';

@Component({
    selector: 'local-filter-value',
    templateUrl: './local-filter-value.component.html',
    styleUrls: ['./local-filter-value.component.less'],
    providers: [FormControlService, ToParent],
})
export class LocalFilterValueComponent extends FilterValueComponent {
    constructor(
        localFiltersTypeService: LocalFiltersTypeService,
        cdr: ChangeDetectorRef,
    ) {
        super(localFiltersTypeService, cdr);
    }

    readonly provider: DataSearchProvider<any> = (search, offset) =>
        this.nullMappingProvider(search, offset).pipe(
            tap(({ rows }) => {
                rows.forEach((value, i) => {
                    rows[i] = value[DATA_VIEW_PATH];
                });
            }),
        );

    readonly mapper: MultiSelectMapperType = (item: any) => {
        return {
            label: item,
            value: item,
        };
    };

    formatter: Format_Server | null = null;

    emitFromNumberInput(event: string): void {
        const value = parseFloat(event);

        if (Number.isNaN(value)) (this as any).reset();
        else this.emitChange(value);
    }

    toMultiSelectItem(): ReturnType<MultiSelectMapperType>[] {
        const { toArray, mapper, value } = this;

        const arrayValue = toArray(value);

        return arrayValue.map(mapper);
    }

    toSelectItem(): SelectOptionValue<any> {
        const { mapper, value } = this;

        return [value].map(mapper)[0];
    }
}
