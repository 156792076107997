import { AbstractControl, ValidatorFn } from '@angular/forms';

export interface DateValidatorParams {
    min?: string;
    max?: string;
}

export interface DateValidatorMinLengthError {
    minDate: {
        requiredValue: string;
    };
}

export interface DateValidatorMaxLengthError {
    maxDate: {
        requiredValue: string;
    };
}

export type DateValidatorError =
    | DateValidatorMinLengthError
    | DateValidatorMaxLengthError;

export function dateValidator(params?: DateValidatorParams): ValidatorFn {
    return (control: AbstractControl): DateValidatorError | null => {
        if (control.value) {
            const value = new Date(control.value);
            const option: Intl.DateTimeFormatOptions = {
                day: 'numeric',
                month: 'numeric',
                year: 'numeric',
            };

            if (params?.min) {
                const min = new Date(params.min);
                if (min > value)
                    return {
                        minDate: {
                            requiredValue: min.toLocaleString('ru', option),
                        },
                    };
            }

            if (params?.max) {
                const max = new Date(params.max);

                if (max < value)
                    return {
                        maxDate: {
                            requiredValue: max.toLocaleString('ru', option),
                        },
                    };
            }
        }

        return null;
    };
}
