import { inject } from '@angular/core';
import { CanActivateFn } from '@angular/router';
import { catchError, map, of } from 'rxjs';

import { PageDataApiService } from '../api/page-data-api.service';
import { AppService } from '../services/app.service';
import { LicenseService } from '../services/license.service';
import { LogoutService } from '../services/logout.service';
import { PageDataService } from '../services/page-data.service';

const SLASH = '/';

export const InitializeGuard: CanActivateFn = (_, state) => {
    const appService = inject(AppService);
    const logoutService = inject(LogoutService);
    const licenseService = inject(LicenseService);
    const pageDataApiService = inject(PageDataApiService);
    const pageDataService = inject(PageDataService);

    if (appService.hasUser()) return true;

    return pageDataApiService.getPage().pipe(
        map(({ row: page }) => {
            pageDataService.page.next(page);

            licenseService.verify(page);

            if (page.user) return true;

            const params =
                state.url === SLASH
                    ? { withBackwardRedirect: false }
                    : undefined;

            logoutService.logout(params);

            return false;
        }),
        catchError(() => of(false)),
    );
};
