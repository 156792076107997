import { NgModule } from '@angular/core';
import { NavigationStart, Router, RouterModule } from '@angular/router';
import { SlTooltip } from '@shoelace-style/shoelace';

import { MultiLineTooltipifyDirective } from './multiline-tooltipify.directives';
import { TooltipifyDirective } from './tooltipify.directives';
import { scope } from './tooltipify.internal';

export * from './multiline-tooltipify.directives';
export * from './tooltipify.directives';

@NgModule({
    imports: [RouterModule],
})
export class TooltipifyMainModule {
    constructor(router: Router) {
        const tooltip: SlTooltip = document.createElement('sl-tooltip');
        tooltip.setAttribute('hoist', 'true');
        tooltip.setAttribute('placement', 'top-start');

        const positioner: HTMLElement = document.createElement('div');
        positioner.style.position = 'fixed';

        tooltip.appendChild(positioner);
        document.body.appendChild(tooltip);

        scope.tooltip = tooltip;
        scope.positioner = positioner;

        router.events.subscribe((event) => {
            if (event instanceof NavigationStart) tooltip.hide();
        });
    }
}

@NgModule({
    exports: [MultiLineTooltipifyDirective, TooltipifyDirective],
    declarations: [MultiLineTooltipifyDirective, TooltipifyDirective],
})
export class TooltipifyModule {}
