<div class="outer-container">
    <div
        class="card"
        [class.hidden]="loading"
        [class.sticky-header]="stickyHeader"
        [style.max-height]="tableMaxHeight"
    >
        <table>
            <ng-content></ng-content>
        </table>

        <div class="spinner" [class._show]="loading">
            <sl-spinner></sl-spinner>
        </div>
    </div>
    <ng-content select="[slot=footer]" class="footer"></ng-content>
</div>

<core-pagination
    *ngIf="routing"
    routing
    [limit]="limit"
    [total]="total"
></core-pagination>
