import { Format_Server } from '../../../interfaces/column.format';
import { Arounds } from '../arounds';
import { GetEmptyFormatSettings } from '.';

export class StringFormatSettings {
    static getEmpty: GetEmptyFormatSettings = () => {
        return {};
    };

    readonly arounds: Arounds;
    readonly nullText: string;

    constructor(source: Format_Server) {
        this.arounds = new Arounds(source);
        this.nullText = source.null_format;
    }
}
