import {
    Directive,
    Input,
    OnInit,
    TemplateRef,
    ViewContainerRef,
} from '@angular/core';
import { SlTooltip } from '@shoelace-style/shoelace';

import { OverflowInternalComponent } from './overflow-internal/overflow-internal.component';

export interface OverflowParams {
    content?: string;
    placement?: SlTooltip['placement'];
}

@Directive({ selector: '[overflow]' })
export class OverflowDirective implements OnInit {
    @Input()
    set overflow(value: OverflowParams | '') {
        if (typeof value === 'string') value = {} as OverflowParams;

        this.params = value;

        if (this.component) this.update();
    }

    private component?: OverflowInternalComponent;
    private params: OverflowParams = {};

    constructor(
        private templateRef: TemplateRef<any>,
        private viewContainerRef: ViewContainerRef,
    ) {}

    ngOnInit(): void {
        const componentRef = this.viewContainerRef.createComponent(
            OverflowInternalComponent,
        );

        componentRef.instance.content = this.templateRef;

        this.component = componentRef.instance;

        this.update();
    }

    private update(): void {
        this.component!.params = this.params;
    }
}
