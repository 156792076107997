import { Inject, Injectable } from '@angular/core';
import { not } from 'logical-not';
import { map, Observable } from 'rxjs';

import { ENVIRONMENT, Environment } from '../../constants/environment';
import { TranslateType } from './translate.enums';
import { translations, translationsComposer } from './translate.internal';
import {
    KeyInterpolationDefaultItem,
    keyInterpolationDefaultToken,
} from './translate.internal.key-interpolation-default';

@Injectable({ providedIn: 'root' })
export class TranslateService {
    constructor(@Inject(ENVIRONMENT) private environment: Environment) {}

    getTranslation(key: string, params?: any): string {
        if (not(key)) return '';

        const translation = translations.value[key];

        if (translation) {
            if (typeof translation !== 'string') {
                if (this.environment !== Environment.Production)
                    console.error(
                        `перевод по ключу "${key}" не является строкой`,
                    );

                return this.environment === Environment.Production ? '' : key;
            }

            if (not(params)) return translation;

            return translation.replace(
                /\{\{(.+?)\}\}/g,
                (_: any, key: string) => {
                    if (params[key] === 0) return '0';

                    return params[key] || '';
                },
            );
        }

        tryDefaults: {
            const defaults = getDefaults();

            for (let i = 0, lim = defaults.length; i < lim; i++) {
                const { tester, value } = defaults[i];

                if (tester.test(key)) return value;
            }
        }

        if (this.environment !== Environment.Production)
            console.error(`перевод по ключу "${key}" не найден`);

        return this.environment === Environment.Production ? '' : key;
    }

    watchTranslation(key: string, params?: any): Observable<string> {
        return translations.pipe(map(() => this.getTranslation(key, params)));
    }
}

function getDefaults(): KeyInterpolationDefaultItem[] {
    const page =
        translationsComposer.get(TranslateType.Page)[
            keyInterpolationDefaultToken
        ] || [];
    const main =
        translationsComposer.get(TranslateType.Main)[
            keyInterpolationDefaultToken
        ] || [];

    return [].concat(page, main);
}
