<ng-container *ngIf="!warningCondition" [ngSwitch]="notification.event_level">
    <sl-icon
        *ngSwitchCase="EventLevel.Info"
        name="info-circle"
        class="info"
    ></sl-icon>

    <sl-icon
        *ngSwitchCase="EventLevel.Success"
        name="check-circle"
        class="success"
    ></sl-icon>

    <sl-icon
        *ngSwitchCase="EventLevel.Error"
        name="x-circle"
        class="error"
    ></sl-icon>
</ng-container>

<ng-container *ngIf="warningCondition">
    <sl-icon name="exclamation-circle" class="warning"></sl-icon>
</ng-container>
