import { Observable } from 'rxjs';

export enum TableFilterType {}

export interface TableFilterBaseItem {
    type: TableFilterType;
    name: string;
    label: Observable<string>;
}

export type TableFilterItem = any;
