import { NgModule } from '@angular/core';

import { TranslateModule } from '../translate/translate.module';
import {
    CoreControlByNameComponents,
    CoreControlComponents,
} from './control-component';
import { FormService } from './form.service';
import { FormComponent } from './form/form.component';
import { SlControlDirective, SlNameDirective } from './sl-elements.directive';
import { SlInputNumberDirective } from './sl-input-number.directive';
import { ValidateComponent } from './validate/validate.component';

export * from './control-component';
export * from './core-form';
export * from './form/form.component';
export * from './validate/validate.component';
export * from './form.service';
export * from './provide-control';
export * from './sl-elements.directive';
export * from './sl-input-number.directive';

@NgModule({
    imports: [TranslateModule],
    exports: [
        CoreControlByNameComponents,
        FormComponent,
        SlControlDirective,
        SlInputNumberDirective,
        SlNameDirective,
        CoreControlComponents,
        ValidateComponent,
    ],
    declarations: [
        CoreControlComponents,
        CoreControlByNameComponents,
        FormComponent,
        SlControlDirective,
        SlInputNumberDirective,
        SlNameDirective,
        ValidateComponent,
    ],
    providers: [FormService],
})
export class FormModule {}
