import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
    providedIn: 'root',
})
export class PageService {
    get loading(): boolean {
        return this.loading$.value;
    }

    set loading(value: boolean) {
        this.loading$.next(value);
    }

    loading$ = new BehaviorSubject<boolean>(false);
}
