<sl-dialog
    [label]="'_$.navigation.changePassword.title' | translate"
    [(open)]="isOpen"
>
    <core-form [data]="passwordForm">
        <core-validate
            [serverErrorsMap]="{
                'wrong-password': '_$.navigation.changePassword.notEqual'
            }"
        >
            <sl-input
                #password
                name="old_password"
                type="password"
                password-toggle
                [label]="'_$.navigation.changePassword.oldPassword' | translate"
            ></sl-input>
        </core-validate>

        <core-validate>
            <sl-input
                #password
                name="new_password"
                type="password"
                password-toggle
            >
                <span slot="label">
                    <span>
                        {{
                            '_$.navigation.changePassword.newPassword'
                                | translate
                        }}
                    </span>

                    <core-tooltip key="_$.navigation.profile.passwordTooltip">
                        <sl-icon name="question-circle"></sl-icon>
                    </core-tooltip>
                </span>
            </sl-input>
        </core-validate>

        <core-validate>
            <sl-input
                #password
                type="password"
                password-toggle
                [label]="
                    '_$.navigation.changePassword.repeatNewPassword' | translate
                "
                [control]="confirmControl"
            ></sl-input>
        </core-validate>

        <footer>
            <sl-button
                variant="primary"
                test-id="Cancel"
                outline
                (click)="hide()"
            >
                {{ '$.cancel' | translate }}
            </sl-button>

            <sl-button
                type="submit"
                variant="primary"
                test-id="Save"
            >
                {{ '$.save' | translate }}
            </sl-button>
        </footer>
    </core-form>
</sl-dialog>
