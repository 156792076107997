import { Directive, Input } from '@angular/core';

import { FilterTemplateDataset } from 'src/shared/interfaces/filter-template';
import { DataOptionFilter } from '../../../shared/interfaces/data-option';
import {
    ColumnSearchRequest,
    DataApiService,
} from '../../api/data-api.service';
import { ColumnType } from '../../enums/dataset';
import { Column } from '../../interfaces/dataset';
import { DataSearchProvider } from '../../interfaces/rest-api';
import { LocalFilterValueComponent } from '../local-filters/local-filter-value/local-filter-value.component';
import { FilterValueComponent } from './filter-value.component';

@Directive({
    selector: 'core-filter-value[datasetId][column]',
})
export class FilterValueColumnDirective {
    @Input()
    datasetId!: number;

    @Input()
    keyColumnId?: number;

    @Input()
    filters: DataOptionFilter[] = [];

    @Input()
    set column(column: Column) {
        this.columnId = column.id;

        this.filterValueComponent.columnType = this.columnBaseType =
            column.base_type;

        if (this.filterValueComponent instanceof LocalFilterValueComponent) {
            this.filterValueComponent.formatter = column.formatter;
        }
    }

    @Input()
    sortSettings?: Pick<FilterTemplateDataset, 'order_by' | 'order_direction'>;

    readonly dataProvider: DataSearchProvider<string> = (search, offset) => {
        const dataParams: ColumnSearchRequest = {
            dataset_id: this.datasetId,
            column_id: this.columnId,
            column_base_type: this.columnBaseType,
            key_column_id: this.keyColumnId,
            search,
            offset,
            limit: 30,
            ...this.sortSettings,
        };

        if (this.isUsuallyFilterComponent) {
            dataParams.filters = this.filters;
        }

        return this.dataApiService.columnSearch(dataParams);
    };

    private columnId = 0;
    private columnBaseType = ColumnType.String;
    private isUsuallyFilterComponent = false;

    constructor(
        private dataApiService: DataApiService,
        private filterValueComponent: FilterValueComponent,
    ) {
        this.isUsuallyFilterComponent = !(
            this.filterValueComponent instanceof LocalFilterValueComponent
        );

        this.filterValueComponent.dataProvider = this.dataProvider;
    }
}
