<sl-button class="main-button" (click)="showModal()">
    <sl-icon name="search"></sl-icon>
</sl-button>
<sl-icon-button
    *ngIf="isApplied"
    name="x"
    class="badge"
    (click)="reset()"
></sl-icon-button>

<sl-dialog
    #dialog
    class="center"
    [(open)]="isOpen"
    [label]="label | translate"
    (sl-after-show)="isShown = true"
    (sl-after-hide)="isShown = false"
>
    <core-form [data]="form">
        <div class="grid-container">
            <core-validate>
                <sl-input
                    clearable
                    autofocus
                    name="search"
                    [placeholder]="
                        '_$.tableFilter.search.placeholder' | translate
                    "
                >
                    <sl-icon
                        library="core"
                        name="search-bold"
                        slot="prefix"
                    ></sl-icon>
                </sl-input>
            </core-validate>

            <sl-button variant="primary" test-id="Apply" (click)="apply()">
                {{ '$.find' | translate }}
            </sl-button>
        </div>
        <div *ngIf="isApplied && isOpen" class="footer">
            <sl-button
                test-id="Reset"
                variant="text"
                (click)="reset()"
            >
                {{ '$.reset' | translate }}
            </sl-button>
        </div>
    </core-form>
</sl-dialog>
