import { Component, Input } from '@angular/core';

@Component({
    selector: 'app-beta-badge',
    templateUrl: './beta-badge.component.html',
    styleUrls: ['./beta-badge.component.less'],
})
export class BetaBadgeComponent {
    @Input()
    tooltipPlacement: 'right' | 'top' = 'top';
}
