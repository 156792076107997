import { Inject, Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { Page } from '../interfaces/page';
import { ResponseItem } from '../interfaces/rest-api';
import { GatewayApi } from '../modules/rest/api-injectors';
import { RestService } from '../modules/rest/rest.service';

@Injectable()
export class PageDataApiService {
    constructor(@Inject(GatewayApi) private restService: RestService) {}

    getPage(): Observable<ResponseItem<Page>> {
        return this.restService.get('/data/page');
    }
}
