import { Directive, Inject, Input, OnInit, Optional } from '@angular/core';
import { AbstractControl } from '@angular/forms';
import {
    SlCheckbox,
    SlInput,
    SlRadio,
    SlSelect,
    SlSwitch,
    SlTextarea,
} from '@shoelace-style/shoelace';
import { not } from 'logical-not';
import { ToParent } from 'ng-to-parent';
import { Observable, Subscription, fromEvent, map } from 'rxjs';

import {
    PLMT_SELECT_ACCESSOR,
    PlmtSelectComponent,
} from '../../plmt-ui-components/plmt-select/plmt-select.component';
import {
    FormControlAdapter,
    FormControlName,
    FormControlService,
} from '../../services/form-control.service';
import { getHost } from '../../tools/get-host';

type SlElement =
    | SlCheckbox
    | SlInput
    | SlRadio
    | SlSelect
    | SlSwitch
    | SlTextarea;
type GetValue = () => any;
type SetValue = (value: any) => void;

@Directive({
    selector: `
        sl-checkbox[control],
        sl-color-picker[control],
        sl-input[control],
        sl-radio[control],
        sl-range[control],
        sl-select[control],
        plmt-select[control],
        sl-switch[control],
        sl-textarea[control],
    `,
    providers: [FormControlService, ToParent],
})
export class SlControlDirective implements OnInit {
    @Input()
    control!: AbstractControl;

    private readonly host = getHost() as SlElement;

    constructor(
        @Optional()
        @Inject(PLMT_SELECT_ACCESSOR)
        private plmtSelect: PlmtSelectComponent,
        private formControlService: FormControlService,
    ) {}

    ngOnInit(): void {
        const { host, control } = this;

        this.formControlService.provide(control, () =>
            createAdapter(this.plmtSelect || host),
        );

        resetOnClear(host, control);
    }
}

@Directive({
    selector: `
        sl-checkbox[name],
        sl-color-picker[name],
        sl-input[name],
        sl-radio-group[name],
        sl-range[name],
        sl-select[name],
        plmt-select[name],
        sl-switch[name],
        sl-textarea[name],
    `,
    providers: [FormControlService, ToParent],
})
export class SlNameDirective implements OnInit {
    @Input()
    name!: FormControlName;

    private readonly host = getHost() as SlElement;

    constructor(
        @Optional()
        @Inject(PLMT_SELECT_ACCESSOR)
        private plmtSelect: PlmtSelectComponent,
        private formControlService: FormControlService,
    ) {}

    ngOnInit(): void {
        const { host } = this;

        this.formControlService.provide(this.name, () =>
            createAdapter(this.plmtSelect || host),
        );

        this.formControlService.getControl(this.name, (control) =>
            resetOnClear(host, control),
        );
    }
}

function createAdapter(
    element: SlElement | PlmtSelectComponent,
): FormControlAdapter {
    const { getValue, setValue } = createValueManage(element);

    if (element instanceof PlmtSelectComponent) {
        return {
            createValueStream: () => element.slChangeEvent.pipe(map(getValue)),

            setValue,

            requiredCallback() {
                element.host.classList.add('required');
            },
        };
    }

    return {
        createValueStream: isClearableSelect(element)
            ? getClearableSelectValueStream(element, getValue)
            : () =>
                  fromEvent(element, getChangeEventName(element)).pipe(
                      map(getValue),
                  ),

        setValue,

        requiredCallback() {
            element.classList.add('required');
        },
    };
}

function getChangeEventName(host: SlElement): string {
    return host instanceof SlInput || host instanceof SlTextarea
        ? 'sl-input'
        : 'sl-change';
}

function createValueManage(host: SlElement | PlmtSelectComponent): {
    getValue: GetValue;
    setValue: SetValue;
} {
    if (host instanceof SlCheckbox || host instanceof SlSwitch)
        return {
            getValue() {
                const { value, checked } = host;

                if (value) return checked ? value : null;
                else return checked;
            },
            setValue(value) {
                if (host.value) host.checked = value === host.value;
                else host.checked = Boolean(value);
            },
        };

    if (host instanceof SlSelect || host instanceof PlmtSelectComponent)
        return {
            getValue() {
                const { value } = host;

                if (typeof value === 'undefined') return null;

                return value;
            },
            setValue(value) {
                if (host.multiple && not(Array.isArray(value))) value = [];

                host.value = value;
            },
        };

    return {
        getValue() {
            return host.value;
        },
        setValue(value) {
            host.value = value ?? '';
        },
    };
}

function isClearableSelect(element: SlElement): element is SlSelect {
    return element instanceof SlSelect && element.clearable;
}

function getClearableSelectValueStream(
    element: SlSelect,
    getValue: GetValue,
): FormControlAdapter['createValueStream'] {
    return () =>
        new Observable((subscriber) => {
            let cleared = false;

            const subscriptions: Subscription[] = [
                fromEvent(element, 'sl-change').subscribe(() => {
                    if (cleared) cleared = false;
                    else subscriber.next(getValue());
                }),

                fromEvent(element, 'sl-clear').subscribe(() => {
                    cleared = true;
                }),
            ];

            return () =>
                subscriptions.forEach((subscription) =>
                    subscription.unsubscribe(),
                );
        });
}

function resetOnClear(element: SlElement, control: AbstractControl): void {
    if (not(isClearableSelect)) return;

    element.addEventListener('sl-clear', () => control.reset());
}
