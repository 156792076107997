import { CommonModule } from '@angular/common';
import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';

import { TranslateModule } from '../../modules/translate/translate.module';

import { TestIdDirectiveModule } from '../../directives/test-id.directive';
import { FileSourceComponent } from './file-source/file-source.component';
import { FileComponent } from './file.component';
import { FilePickerComponent } from './file-picker/file-picker.component';

export * from './file-source/file-source.component';
export * from './file.component';

@NgModule({
    imports: [CommonModule, TestIdDirectiveModule, TranslateModule],
    exports: [FileComponent, FileSourceComponent],
    declarations: [FileComponent, FileSourceComponent, FilePickerComponent],
    schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class FileModule {}
