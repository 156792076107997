export enum FilterTemplateUIType {
    Checkbox = 'checkbox',
    DateCalendar = 'calendar',
    DatePeriod = 'calendar-period',
    DateSearch = 'datepicker',
    DateSearchPeriod = 'datepicker-period',
    DateTimeCalendar = 'datetime-calendar',
    DateTimePeriod = 'datetime-calendar-period',
    DateTimeSearch = 'datetime-datepicker',
    DateTimeSearchPeriod = 'datetime-datepicker-period',
    Number = 'number',
    NumberRange = 'range',
    Radio = 'radio',
    Search = 'search',
    Select = 'list',
    TreeView = 'treeView',
}
