import { Injectable, Injector } from '@angular/core';
import { not } from 'logical-not';
import { BehaviorSubject } from 'rxjs';

import { ModuleName } from '../enums/module';
import { Module } from '../interfaces/module';
import { User } from '../interfaces/user';
import { BaseUrlService } from './base-url.service';
import { InternalThemeService } from './internal.theme.service';
import { PageDataService } from './page-data.service';
import { CURRENT_MODULE_NAME_TOKEN } from '../tokens/module-token';
import { AppUserService } from './app-user.service';

@Injectable({ providedIn: 'root' })
export class AppService {
    static localhostAuthRedirect = '/';

    modules = new BehaviorSubject<Module[]>([]);
    currentModule: Module = {} as any;
    managerModule: Module = {} as any;
    managerUrl = '/';
    beta = false;

    theme = this.internalThemeService.themeProvider;

    get baseUrl(): string {
        return this.baseUrlService.baseUrl;
    }

    get user(): BehaviorSubject<User> {
        return this.appUserService.user;
    }

    get isSuperUser(): boolean {
        return this.user.value.super_user;
    }

    get userRoleId(): number {
        return this.user.value.role_id;
    }

    constructor(
        private injector: Injector,
        private baseUrlService: BaseUrlService,
        private internalThemeService: InternalThemeService,
        private appUserService: AppUserService,
        pageDataService: PageDataService,
    ) {
        pageDataService.page.subscribe((page) => {
            this.managerUrl = page.core_url;
            this.beta = Boolean(page.beta);

            if (location.hostname === 'localhost')
                this.managerUrl = AppService.localhostAuthRedirect;

            if (this.managerUrl.startsWith('/')) {
                const url = new URL(this.managerUrl, location.origin);

                this.managerUrl = url.href;
            }

            // Для тестировщиков:
            forTests: {
                const beta = localStorage.getItem('beta');

                if (beta) this.beta = beta === 'true';
            }

            this.modules.next(page.app_modules);

            if (page.user) this.user.next(page.user);
        });

        this.modules.subscribe((modules) => {
            this.currentModule = null as any;
            this.managerModule = null as any;

            this.getCurrentModule();
            if (not(this.managerModule)) {
                const managerModule = modules.find(
                    (module) => module.name === ModuleName.Manager,
                );
                if (managerModule) this.managerModule = managerModule;
            }
        });
    }

    hasUser(): boolean {
        return Boolean(this.user.value.id);
    }

    private getCurrentModule(): void {
        const currentModuleName = this.injector.get(CURRENT_MODULE_NAME_TOKEN);
        const module = this.modules.value.find(
            (module) => module.name === currentModuleName,
        );
        if (module) this.currentModule = module;
    }
}
