<sl-dropdown
    #slDropdown
    hoist
    placement="bottom-start"
    [(open)]="show"
    [distance]="2"
    [availableHeight]="dropdownHeight"
    (show)="onDropdownShow()"
>
    <sl-input
        #slInput
        slot="trigger"
        autocompleteOff
        [attr.open]="show"
        [size]="size"
        [disabled]="disabled"
        [label]="label"
        [placeholder]="placeholder"
        (sl-input)="onInput($event)"
        (sl-focus)="onFocus()"
        (click)="onClick($event)"
        (keydown)="onKeyDown($event)"
    >
        <core-select-suffix
            [dropdownTarget]="slDropdown"
            [showClearIcon]="showClearIcon"
            [disabled]="disabled"
            (onDropdown)="slInput.focus()"
            (onClear)="clear()"
        ></core-select-suffix>
    </sl-input>

    <div
        class="listbox"
        [class.hidden]="suggestions.length === 0"
        [style.width]="width"
    >
        <sl-menu #slMenu (sl-select)="onSelectItem($event)">
            <sl-menu-item
                *ngFor="let item of allSuggestions; index as i"
                tooltipify
                type="checkbox"
                [value]="i"
                [checked]="selected ? compareFn(item, selected) : false"
            >
                {{ getLabelFn(item) }}
            </sl-menu-item>

            <core-lazy-loading
                *ngIf="
                    show &&
                    suggestions.length &&
                    suggestions.length !== suggestionsTotal
                "
                [load]="loadMore"
            >
            </core-lazy-loading>
        </sl-menu>
    </div>
</sl-dropdown>
