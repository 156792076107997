import { Injectable } from '@angular/core';

import { StorageService } from '../../services/storage.service';
import { PluralRule } from './plural.enum';
import { TranslateService } from './translate.service';

@Injectable()
export class PluralService {
    private pluralRules!: Intl.PluralRules;

    constructor(
        private translateService: TranslateService,
        storageService: StorageService,
    ) {
        storageService.lang.subscribe((lang) => {
            this.pluralRules = new Intl.PluralRules(lang);
        });
    }

    pluralize(key: string, n: number): string {
        const rule = this.pluralRules.select(n);

        return (
            this.translateService.getTranslation(`${key}.${rule}`, { n }) ||
            this.translateService.getTranslation(`${key}.${PluralRule.Many}`, {
                n,
            })
        );
    }
}
