import { Directive, Input, OnInit } from '@angular/core';
import { AbstractControl } from '@angular/forms';
import { ToParent } from 'ng-to-parent';
import { map } from 'rxjs';

import {
    FormControlAdapter,
    FormControlName,
    FormControlService,
} from '../../services/form-control.service';
import { SuggestComponent } from './suggest.component';

@Directive({
    selector: 'core-suggest[control]',
    providers: [FormControlService, ToParent],
})
export class SuggestControlDirective implements OnInit {
    @Input()
    control!: AbstractControl;

    constructor(
        private formControlService: FormControlService,
        private suggestComponent: SuggestComponent,
    ) {}

    ngOnInit(): void {
        this.formControlService.provide(this.control, () =>
            createAdapter(this.suggestComponent),
        );
    }
}

@Directive({
    selector: 'core-suggest[name]',
    providers: [FormControlService, ToParent],
})
export class SuggestNameDirective implements OnInit {
    @Input()
    name!: FormControlName;

    constructor(
        private formControlService: FormControlService,
        private suggestComponent: SuggestComponent,
    ) {}

    ngOnInit(): void {
        this.formControlService.provide(this.name, () =>
            createAdapter(this.suggestComponent),
        );
    }
}

function createAdapter(suggestComponent: SuggestComponent): FormControlAdapter {
    const { getValueFn } = suggestComponent;

    return {
        createValueStream: () =>
            suggestComponent.onSelect.pipe(map(getValueFn)),

        setValue() {},

        requiredCallback: () =>
            suggestComponent.inputRef?.nativeElement.classList.add('required'),
    };
}
