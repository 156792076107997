import { TreeBindingMode } from '../enums/tree-binding-mode';
import { intersectionWith, isEqual, map, pick } from 'lodash';
import {
    ROOT_VALUE,
    TreeDataColumn,
    TreeNode,
    TreeSelectConfig,
} from '../interfaces/tree-view.interface';
import { KeyView } from '../../../interfaces/filter-template';
import { LayerBinding } from '../../../interfaces/layer-binding.interface';
import { BindingType } from '../../../enums/binding-type';
import { getFilterValue } from '../../../helpers/get-filter-value';

export function getColumnByNodeLevel(
    treeSelectConfig: TreeSelectConfig,
    level: number,
): TreeDataColumn {
    const columns = treeSelectConfig.columns.filter((column) => column.view_id);
    return columns[level];
}

export function getNodeLevel(node?: TreeNode, level = -1): number {
    if (!node?.data) return level;
    return getNodeLevel(node.parent, level + 1);
}

export function childNodeHasItems(
    treeSelectConfig: TreeSelectConfig,
    parent?: TreeNode,
) {
    const childNodeLevel = getNodeLevel(parent) + 1;
    const maxLevel =
        treeSelectConfig.columns.filter((column) => column.view_id).length - 1;
    return childNodeLevel < maxLevel;
}

export function handleTreeBindings(
    treeBindings: LayerBinding[],
): LayerBinding[] {
    const bindingMode = getTreeBindingMode(treeBindings);

    if (bindingMode === TreeBindingMode.OneColumn) {
        return [treeBindings[0]];
    } else {
        return treeBindings;
    }
}

export function getNodesAsKeyValueArray(node: TreeNode): KeyView[] {
    const views: KeyView[] = [];

    let parent = node;
    while (parent && parent.data) {
        views.push({
            view: parent.data.originalView,
            key: parent.data.key,
        });
        parent = parent.parent as TreeNode;
    }

    return views;
}

export function getIdByViewFromTreeNodes(
    treeNodes: TreeNode[],
    originalView: string,
): string {
    return (
        treeNodes.find((node) => node.data.originalView === originalView)?.data
            .id || ''
    );
}

export function getTreeBindingMode(
    treeBindings?: LayerBinding[],
): TreeBindingMode {
    if (!treeBindings) return TreeBindingMode.SeveralColumns;

    const pickedProperties = [
        'settings_item_uid',
        'bind_with_uid',
        'column_id',
    ];
    const pickedItems = map(treeBindings, (binding) =>
        pick(binding, pickedProperties),
    );

    // проверяем, что во всех объектах поля из pickedProperties одинаковые
    // это значит, что все колонки иерархического фильтра связаны с одной и той же колонкой виджета
    const allColumnsAreEqual =
        intersectionWith(pickedItems, isEqual).length === 1;

    return allColumnsAreEqual
        ? TreeBindingMode.OneColumn
        : TreeBindingMode.SeveralColumns;
}

export function getCorrectTreeFilterValue(
    filterValue: KeyView[],
    bindings: LayerBinding[],
    index: number,
    bindingType: BindingType,
): any {
    if (!filterValue) return getFilterValue(filterValue, bindingType);

    const bindingMode = getTreeBindingMode(bindings);
    const value =
        bindingMode === TreeBindingMode.OneColumn
            ? filterValue[filterValue.length - 1]
            : filterValue[index];

    return getFilterValue(value, bindingType);
}

export function createMockTreeData(defaultValue: KeyView[]): TreeNode[] {
    const mockData: TreeNode[] = [];

    defaultValue.forEach((defaultValue, index) => {
        const id = Array.from(Array(index + 1).keys()).join('/');
        const mock = {
            key: id,
            parent:
                index === 0
                    ? {
                          key: ROOT_VALUE,
                      }
                    : mockData[index - 1],
            data: {
                id,
                view: defaultValue.view,
                parentId: index === 0 ? ROOT_VALUE : mockData[index - 1].key,
            },
        } as TreeNode;

        mockData.push(mock);
    });

    return mockData;
}
