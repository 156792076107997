import { Component, EventEmitter, Output, Input } from '@angular/core';
import { map } from 'rxjs';

import { SortDirection } from '../../../enums/sort';
import { RequestArray } from '../../../interfaces/rest-api';

export type SortState = Pick<RequestArray, 'order_by' | 'order_direction'>;

@Component({
    selector: 'core-table-sort:not([routing])',
    templateUrl: './table-sort.component.html',
    styleUrls: ['./table-sort.component.less'],
})
export class TableSortComponent {
    @Input()
    by = '';

    @Input()
    direction?: SortDirection | null;

    @Output()
    directionChange = new EventEmitter<SortDirection | null>();

    @Output()
    change = this.directionChange.pipe(
        map<SortDirection | null, SortState>((direction) => ({
            order_by: this.by,
            order_direction: direction,
        })),
    );

    changeDirection(): void {
        switch (this.direction) {
            case SortDirection.Desc:
                this.directionChange.emit(SortDirection.Asc);
                break;
            case SortDirection.Asc:
                this.directionChange.emit(null);
                break;
            default:
                this.directionChange.emit(SortDirection.Desc);
                break;
        }
    }
}
