import { Injectable } from '@angular/core';
import not from 'logical-not';

import { FormatNumberAbbreviation } from '../../../enums/column.format';
import { TranslateService } from '../../../modules/translate/translate.service';
import { NumberFormatSettings } from '../formatter-settings/number.formatter-settings';
import { Formatter } from './formatter';

const { Kilo, Mega, Giga } = FormatNumberAbbreviation;

const abbreviateMap: Record<FormatNumberAbbreviation, number> = {
    [Kilo]: Math.pow(10, Kilo),
    [Mega]: Math.pow(10, Mega),
    [Giga]: Math.pow(10, Giga),
};

@Injectable({ providedIn: 'root' })
export class NumberFormatter implements Formatter<NumberFormatSettings> {
    constructor(private translateService: TranslateService) {}

    format(formatSettings: NumberFormatSettings, source: any): string {
        const { abbreviate, arounds, toFixed, useGrouping, nullText } =
            formatSettings;

        if (source === null) {
            if (not(nullText)) return '';
            else return nullText;
        }

        if (typeof source !== 'number') return String(source);

        let number: number = source;
        let suffix = '';

        if (abbreviate) {
            number /= abbreviateMap[abbreviate] || 1;
            suffix = this.translateService.getTranslation(
                '$.format.numberAbbr.' + abbreviate,
            );
        }

        let string = '';

        if (toFixed === null)
            string = useGrouping ? number.toLocaleString('ru') : String(number);
        else {
            string = number.toFixed(toFixed).replace('.', ',');

            if (useGrouping)
                string = string.replace(/\d+/, (digits) =>
                    digits.length > 3
                        ? Number(digits).toLocaleString('ru')
                        : digits,
                );
        }

        return arounds.format(string + suffix);
    }
}
