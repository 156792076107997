<sl-checkbox size="small" [checked]="checked" (sl-change)="onCheck()">
    {{ '_$$.columnFormatting.label.null' | translate }}
</sl-checkbox>

<sl-input
    #nullInput
    *ngIf="checked"
    [value]="nullFormat"
    [placeholder]="'_$$.columnFormatting.label.null.placeholder' | translate"
    (sl-input)="onInput($event)"
>
</sl-input>
