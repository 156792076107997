import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TreeViewComponent } from './tree-view.component';
import {
    DxDropDownBoxModule,
    DxScrollViewModule,
    DxTreeListModule,
} from 'devextreme-angular';
import { TreeViewApiService } from './services/tree-view-api.service';
import { TranslateModule } from '../../modules/translate/translate.module';
import { ShoelaceModule } from 'shoelace-style-angular';

export * from './helpers/tree-view.helper';
export * from './interfaces/tree-view.interface';
export * from './services/tree-view.service';
export * from './enums/tree-binding-mode';

@NgModule({
    providers: [TreeViewApiService],
    declarations: [TreeViewComponent],
    imports: [
        CommonModule,
        DxTreeListModule,
        DxDropDownBoxModule,
        DxScrollViewModule,
        TranslateModule,
        ShoelaceModule,
    ],
    exports: [TreeViewComponent],
    schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class TreeViewModule {}
