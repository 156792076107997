import { Component, EventEmitter, HostListener, Input, Output } from '@angular/core';
import { SlPopup } from '@shoelace-style/shoelace';
import { getHost } from 'plmt-core-library';

type HintPopupVariant = 'information' | 'negative' | 'positive' | 'warning';

@Component({
    selector: 'core-hint-popup',
    templateUrl: './hint-popup.component.html',
    styleUrls: ['./hint-popup.component.less'],
})
export class HintPopupComponent {
    @Input()
    flip = true;

    @Input()
    arrow = true;

    @Input()
    active = false;

    @Input()
    distance = 10;

    @Input()
    label = '';

    @Input()
    description = '';

    @Input()
    icon = '';

    @Input()
    buttonCancelText = '';

    @Input()
    buttonApplyText = '';

    @Input()
    width = '100%';

    @Input()
    variant: HintPopupVariant = 'information';

    @Input()
    placement: SlPopup['placement'] = 'top-start';

    @Input()
    arrowPlacement: SlPopup['arrowPlacement'] = 'start';

    @Output()
    onCancel = new EventEmitter<void>();

    @Output()
    onApply = new EventEmitter<void>();

    private readonly host = getHost();

    @HostListener('document:click', ['$event.target'])
    clickout(target: HTMLElement) {
        if (!this.host.contains(target)) {
            this.close();
        }
    }

    cancel(): void {
        this.onCancel.emit();

        this.close();
    }

    apply(): void {
        this.onApply.emit();

        this.close();
    }

    private close(): void {
        this.active = false;
    }
}
