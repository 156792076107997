<core-form [data]="form">
    <sl-input
        *ngIf="!hasOffset"
        [label]="label"
        [max]="maxDateValue"
        [type]="dateFormat"
        [value]="value"
        [class.no-error]="!validationError"
        (sl-input)="change.emit($event)"
    ></sl-input>

    <ng-container *ngIf="hasOffset">
        <div class="row">
            <plmt-select
                name="operator"
                hoist
                [label]="label"
                [class.no-error]="!validationError"
            >
                <sl-option
                    *ngFor="let operator of operators"
                    [value]="operator"
                >
                    {{ '_$$.filters.operator.' + operator | translate }}
                </sl-option>
            </plmt-select>
            <div class="col">
                <sl-input
                    type="number"
                    name="offset"
                    counterInput
                    [min]="0"
                    [class.no-error]="!validationError"
                    (sl-change)="changeOffsetValue($event)"
                ></sl-input>

                <div class="period">
                    <span class="date"
                        >=
                        <ng-container
                            *ngIf="
                                dateFormat === DateFormat.Date;
                                else datetime
                            "
                        >
                            {{ dateValue | date: 'date' }}
                        </ng-container>

                        <ng-template #datetime>
                            {{ dateValue | date: 'date' }}
                            {{ dateValue | date: 'timeFull' }}
                        </ng-template>
                    </span>

                    <plmt-select
                        #periodSelect
                        name="period"
                        class="period"
                        hoist
                        [class.no-error]="!validationError"
                    >
                        <sl-option
                            *ngFor="let period of periods"
                            [value]="period"
                        >
                            {{
                                form.getRawValue().offset | i18DatePipe: period
                            }}
                        </sl-option>
                    </plmt-select>
                </div>
            </div>
        </div>
    </ng-container>

    <sl-switch
        size="small"
        [checked]="hasOffset"
        [class.error]="validationError"
        (sl-change)="toggleOffset()"
    >
        {{ '_$$.filters.label.dateOffset' | translate }}
        <sl-tooltip
            hoist
            [content]="'_$$.filters.tooltip.dateOffset' | translate"
        >
            <sl-icon name="question-circle"></sl-icon>
        </sl-tooltip>
    </sl-switch>
</core-form>
