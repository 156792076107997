import {
    HttpClient,
    HttpErrorResponse,
    HttpStatusCode,
} from '@angular/common/http';
import {
    OnInit,
    AfterViewInit,
    Component,
    ElementRef,
    Output,
    ViewChild,
} from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { SlSelect } from '@shoelace-style/shoelace';
import { WatchChanges } from 'ng-onpush';
import { SubscribableComponent } from 'ngx-subscribable';
import { enumToArray } from 'ts-enum-to-array';

import { LogoutApiService } from '../../../api/logout-api.service';
import { RoleApiService } from '../../../api/role-api.service';
import { UserApiService, UserSaveRequest } from '../../../api/user-api.service';
import { Lang } from '../../../enums/lang';
import { Mode } from '../../../enums/mode';
import { ModuleName } from '../../../enums/module';
import { Group } from '../../../interfaces/group';
import { User } from '../../../interfaces/user';
import { FormService } from '../../../modules/form/form.service';
import { TranslateService } from '../../../modules/translate/translate.service';
import { AccessRightsService } from '../../../services/access-rights.service';
import { AppUserService } from '../../../services/app-user.service';
import { AppService } from '../../../services/app.service';
import { LogoutService } from '../../../services/logout.service';
import { StorageService } from '../../../services/storage.service';
import { emailValidator } from '../../../validators/email.validator';
import { required } from '../../../validators/required.validator';
import { stringValidator } from '../../../validators/string.validator';
import { AlertService } from '../../alert/alert.service';
import { TokenComponent } from './token/token.component';
import { NavigationService } from '../navigation.service';
import { AuthType } from '../../../enums/auth';
import { toIds } from '../../../tools/to-ids';

const userAccess = 32;
const MAX_LENGTH_NAME = 50;

@Component({
    selector: 'plmt-profile',
    templateUrl: './profile.component.html',
    styleUrls: ['./profile.component.less'],
})
export class ProfileComponent
    extends SubscribableComponent
    implements OnInit, AfterViewInit
{
    @Output()
    isOpen = false;

    @ViewChild('langSelect', { static: true })
    langSelect!: ElementRef<SlSelect>;

    @ViewChild('tokenModal')
    tokenModal!: TokenComponent;

    @WatchChanges()
    groups: Group[] = [];

    @WatchChanges()
    pathAvatar = '';

    @WatchChanges()
    displayRole = '';

    @WatchChanges()
    hideActionButtons = true;

    @WatchChanges()
    isUserAccess: boolean | undefined = false;

    userForm = this.formService
        .create<UserSaveRequest>({
            id: [],
            username: [
                null,
                [
                    required,
                    Validators.pattern(/^[a-zA-Z0-9_.@\-]+$/),
                    stringValidator({ min: 2, max: Infinity }),
                ],
            ],
            password: [],
            email: [null, [required, emailValidator]],
            image_path: [],
            first_name: [
                null,
                [required, stringValidator({ min: 1, max: MAX_LENGTH_NAME })],
            ],
            last_name: [
                null,
                [required, stringValidator({ min: 1, max: MAX_LENGTH_NAME })],
            ],
            middle_name: [
                null,
                [stringValidator({ min: -1, max: MAX_LENGTH_NAME })],
            ],
            role_id: [],
            super_user: [],
            groups: new FormControl([]),
        })
        .setup({
            method: this.userApiService.edit,
            methodContext: this.userApiService,
            success: ({ row: user }) => {
                this.hideActionButtons = true;
                this.appService.user.next(user);
                this.alertService.show.emit({
                    variant: 'success',
                    key: '_$.navigation.profile.saveSuccess',
                });
            },
            error: (response: HttpErrorResponse) => {
                this.alertService.show.emit({
                    responseError: response,
                    key: '_$.navigation.profile.saveDanger',
                });
            },
        });

    isOpenIdAuthMethod = false;

    readonly langs = enumToArray(Lang);
    readonly MAX_LENGTH_NAME = MAX_LENGTH_NAME;

    get user(): User {
        return this.appService.user.value;
    }

    get lang(): StorageService['lang'] {
        return this.storageService.lang;
    }

    get isDarkMode(): boolean {
        return this.storageService.mode.value === Mode.Dark;
    }

    constructor(
        private accessRightsService: AccessRightsService,
        private alertService: AlertService,
        private appService: AppService,
        private appUserService: AppUserService,
        private authApiService: LogoutApiService,
        private formService: FormService,
        private logoutService: LogoutService,
        private navigationService: NavigationService,
        private roleApiService: RoleApiService,
        private storageService: StorageService,
        private translateService: TranslateService,
        private userApiService: UserApiService,
        private httpClient: HttpClient,
    ) {
        super();
        // временно скрыть
        // const langSelectFix = translations.pipe(
        //     skip(1),
        //     map(() => {
        //         const select = this.langSelect.nativeElement;
        //         const { value } = select;

        //         select.value = '';

        //         return { select, value };
        //     }),
        //     debounceTime(0),
        //     tap(({ select, value }) => {
        //         select.value = value;
        //     }),
        // );

        // this.subscriptions = [langSelectFix.subscribe()];
    }

    ngOnInit(): void {
        this.subscriptions = [
            this.userForm.valueChanges.subscribe(() => {
                this.hideActionButtons = false;
            }),
        ];
    }

    ngAfterViewInit(): void {
        // временно скрыть
        // this.langSelect.nativeElement.value = this.storageService.lang.value;
    }

    onShow(): void {
        const { user } = this;

        this.hideActionButtons = true;
        this.isUserAccess = this.accessRightsService.checkAccessRights(
            [userAccess],
            ModuleName.Manager,
        );

        this.userApiService.me().subscribe(({ row: user }) => {
            const superUser = user.super_user;

            this.isOpenIdAuthMethod = user.auth_method === AuthType.OpenID;

            this.patchUserForm(user);

            this.hideActionButtons = true;
            this.groups = user.groups;

            if (superUser)
                this.displayRole = this.translateService.getTranslation(
                    '_$.navigation.profile.superuser',
                );
            else {
                if (user.role_id) {
                    this.updateDisplayRole();
                } else {
                    this.displayRole = this.translateService.getTranslation(
                        '_$.navigation.profile.noRole',
                    );
                }
            }
        });
    }

    logout(): void {
        this.authApiService.logout().subscribe({
            next: ({ identity_provider_logout_url: url }) => {
                if (url) {
                    this.handleIdentityProviderLogoutUrl(url);
                } else {
                    this.handleLogout();
                }
            },
            error: (response: HttpErrorResponse) => {
                this.alertService.show.emit({
                    responseError: response,
                    key: '_$.navigation.profile.logout.danger',
                });
            },
        });
    }
    // временно скрыть
    // changeLang(lang: Lang): void {
    //     if (lang && lang !== this.lang.value) {
    //         this.storageService.lang.next(lang);
    //     }
    // }

    changeMode(isDark: boolean): void {
        const mode = isDark ? Mode.Dark : Mode.Light;
        const html = document.querySelector('html')!;

        this.storageService.mode.next(mode);

        html.classList.remove(isDark ? Mode.Light : Mode.Dark);
        html.classList.add(mode);
    }

    setAvatar(pathAvatar: string): void {
        this.userForm.patchValue({
            image_path: pathAvatar,
        });
        this.userApiService.edit(this.userForm.getRawValue()).subscribe({
            next: ({ row }) => {
                this.appService.user.next(row);
                this.pathAvatar = pathAvatar;

                this.hideActionButtons = true;
                this.alertService.show.emit({
                    variant: 'success',
                    key: '_$.navigation.profile.avatar.saveSuccess',
                });
            },
            error: (response: HttpErrorResponse) => {
                this.alertService.show.emit({
                    responseError: response,
                    key: '_$.navigation.profile.avatar.saveDanger',
                });
            },
        });
    }

    cancelChanges(): void {
        this.patchUserForm(this.user);

        this.hideActionButtons = true;
    }

    updateToken(): void {
        this.userApiService.updateToken().subscribe({
            next: ({ token }) => {
                this.alertService.show.emit({
                    variant: 'success',
                    key: '_$.navigation.profile.updateToken.success',
                });

                this.tokenModal.show(token);
            },
            error: (response: HttpErrorResponse) => {
                this.alertService.show.emit({
                    responseError: response,
                    key: '_$.navigation.profile.updateToken.danger',
                });
            },
        });
    }

    private handleIdentityProviderLogoutUrl(
        identity_provider_logout_url: string,
    ): void {
        this.httpClient.get(identity_provider_logout_url).subscribe(
            () => {
                this.handleLogout();
            },
            (error) => {
                if (error.status === HttpStatusCode.Ok) {
                    window.location.href = identity_provider_logout_url;
                }
                this.handleLogout();
            },
        );
    }

    private handleLogout(): void {
        this.navigationService.isDisplayMenu.next(true);
        this.appUserService.user.next({} as any);
        this.logoutService.logout({
            withBackwardRedirect: false,
        });
    }

    private updateDisplayRole(): void {
        this.roleApiService
            .getPublic(this.user.role_id)
            .subscribe(({ row }) => {
                this.displayRole = row.name;
            });
    }

    private patchUserForm(user: User): void {
        const groups = (user.groups ?? []).map(toIds);

        this.userForm.patchValue({
            ...user,
            groups,
        });
    }
}
