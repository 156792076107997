import { Component, EventEmitter, Input, Output } from '@angular/core';
import { enumToArray } from 'ts-enum-to-array';

import { AccessRight } from '../../../enums/access';

@Component({
    selector: 'core-access-right-select',
    templateUrl: './access-right-select.component.html',
    styleUrls: ['./access-right-select.component.less'],
})
export class AccessRightSelectComponent {
    @Input()
    value!: AccessRight;

    @Output()
    valueChange = new EventEmitter<AccessRight>();

    rights = enumToArray(AccessRight).filter(
        (item) => item !== AccessRight.NotAllowed,
    );
}
