<sl-select
    #select
    optionSelectedStyle
    [hoist]="hoist === ''"
    [clearable]="clearable === ''"
    [size]="size"
    [pill]="pill"
    [filled]="filled"
    [disabled]="disabled"
    [multiple]="multiple"
    [value]="selectValue"
    [placement]="placement"
    [placeholder]="placeholder"
    [maxOptionsVisible]="maxOptionsVisible"
    [availableHeight]="dropdownHeight"
    (sl-change)="emitChange($event)"
    (sl-clear)="emitUsuallyEvents($event)"
    (sl-focus)="emitUsuallyEvents($event); select.classList.add('focused')"
    (sl-blur)="emitUsuallyEvents($event); select.classList.remove('focused')"
>
    <div *ngIf="label" class="label-container" slot="label">
        <span>{{ label }}</span>
        <app-beta-badge
            *ngIf="showBetaBadge"
            tooltipPlacement="right"
        ></app-beta-badge>
    </div>
    <ng-content></ng-content>
    <sl-icon
        slot="expand-icon"
        library="platform"
        name="chevron-down-short"
    ></sl-icon>
</sl-select>
