import { ValidatorFn } from '@angular/forms';
import { not } from 'logical-not';

import { settingsMap } from './sl-input-number.internal';

export function slInputNumberValidator(_?: never): ValidatorFn {
    return (control) => {
        const settings = settingsMap.get(control);

        if (not(settings)) return null;

        const { number, min, max } = settings;

        return number?.(control) || min?.(control) || max?.(control) || null;
    };
}

/*
    Хак: (_?: never)
    Без него typescript считает тип slInputNumberValidator равным ValidatorFn, т.е.

    const x: ValidatorFn = slInputNumberValidator; // работает корректно,

    хотя должна быть ошибка, потому что использовать нужно так:

    const x: ValidatorFn = slInputNumberValidator();
*/
