import { Observable } from 'rxjs';

export function resizeObserver(element: HTMLElement): Observable<DOMRect> {
    return new Observable((subscriber) => {
        const resizeObserver = new ResizeObserver((entries) => {
            for (let entry of entries)
                if (entry.target === element) {
                    subscriber.next(element.getBoundingClientRect());

                    break;
                }
        });

        resizeObserver.observe(element);

        return () => resizeObserver.unobserve(element);
    });
}
