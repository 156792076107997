import {
    FormatDateUnit,
    Format_Server,
} from '../../../interfaces/column.format';
import { Arounds } from '../arounds';
import { defaultUnits } from './date.format-settings.constants';
import { GetEmptyFormatSettings } from '.';

export class DateFormatSettings {
    static getEmpty: GetEmptyFormatSettings = () => {
        return {
            date_format: defaultUnits,
        };
    };

    readonly arounds: Arounds;
    readonly units: Omit<FormatDateUnit, 'disabled'>[];
    readonly nullText: string;

    constructor(source: Format_Server) {
        this.arounds = new Arounds(source);
        this.units = [];
        this.nullText = source.null_format;

        const units = source.date_format || [];

        units.forEach((unit) => {
            if (unit.disabled) return;

            const { type, value, suffix } = unit;

            this.units.push({ type, value, suffix });
        });
    }
}

type Omit<T, K extends keyof T> = { [P in Exclude<keyof T, K>]: T[P] };
