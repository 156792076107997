export enum AccessRight {
    NotAllowed,
    Read,
    Edit,
    Full,
}

// export enum ModuleRightManager {
//     DatasourceView = 1, // Источники просмотр
//     DatasourceFullAccess = 2, // Источники полный доступ

//     DatasetView = 4, // Датасеты просмотр
//     DatasetFullAccess = 8, // Датасеты полный доступ

//     Roles = 16, // Доступ к ролям
//     UserAccess = 32, // Доступ к пользователям
//     GroupAccess = 64, // Доступ к группам

//     License = 128, // Доступ к лицензии и информации о системе
//     Plugins = 256, // Доступ к подключенным плагинам
//     Modules = 512, // Доступ к подключенным модулям
//     Logs = 1024, // Доступ к логированию действий
// }

// export enum ModuleRightDashboard {
//     ProjectView = 1, // Просмотр проектов
//     ProjectFullAccess = 2, // Полный доступ к проектам
//     WidgetView = 4, // Просмотр виджетов
//     WidgetFullAccess = 8, // Полный доступ к виджетам
//     Component = 16, // Доступ к компонентам
// }

// export enum ModuleRightReport {
//     View = 1, // Просмотр папок/файлов
//     FullAccess = 2, // Полный доступ к репортам
// }
