import {
    Component,
    EventEmitter,
    Input,
    OnInit,
    Output,
    TemplateRef,
    ViewChild,
} from '@angular/core';
import { not } from 'logical-not';
import { WatchChanges } from 'ng-onpush';
import { SubscribableComponent } from 'ngx-subscribable';
import { fromEvent } from 'rxjs';

import { FileSourceComponent } from './file-source/file-source.component';
import { AlertService } from '../alert/alert.service';

export interface ErrorDescription {
    hasDescription?: boolean;
    error: string;
}

export enum LoadingStatus {
    Default,
    Success,
    Reject,
    Loading,
}

@Component({
    selector: 'core-file',
    templateUrl: './file.component.html',
    styleUrls: ['./file.component.less'],
})
export class FileComponent extends SubscribableComponent implements OnInit {
    @Input()
    description: string[] = [
        '_$.file.uploadFile.description',
        '_$.file.uploadFile.description.subtitle',
    ];
    @Input()
    accept = '';

    @Input()
    loadStatus = LoadingStatus.Default;

    @Input()
    hideDescription = false;

    @Input()
    customLoadButton: TemplateRef<any> | null = null;

    @Input()
    extraButton: TemplateRef<any> | null = null;

    @Input()
    errorDescription: ErrorDescription = {
        hasDescription: false,
        error: '_$.filePicker.uploadError',
    };

    @Input()
    fileName = '';

    @Input()
    acceptedText = '';

    @Output()
    onPick = new EventEmitter<File>();

    @ViewChild(FileSourceComponent)
    item!: FileSourceComponent;

    @WatchChanges()
    showDropZone = false;

    @WatchChanges()
    activeDropZoneItem?: FileSourceComponent;

    readonly LoadingStatus = LoadingStatus;

    constructor(private alertService: AlertService) {
        super();
    }

    ngOnInit(): void {
        this.subscriptions = [
            fromEvent<DragEvent>(window, 'dragover').subscribe((event) => {
                event.preventDefault();

                if (not(this.showDropZone)) this.showDropZone = true;
            }),
            fromEvent<DragEvent>(window, 'dragleave').subscribe((event) => {
                event.preventDefault();

                if (event.x === 0 && event.y === 0) this.showDropZone = false;
            }),
            fromEvent<DragEvent>(window, 'drop').subscribe((event) => {
                event.preventDefault();

                this.showDropZone = false;
                this.activeDropZoneItem = undefined;
            }),
        ];
    }

    pick(file: File): void {
        this.fileName = file.name;

        if (not(this.isValidFile(file))) {
            this.loadStatus = LoadingStatus.Reject;
            this.alertService.show.emit({
                key: '_$.filePicker.acceptError',
                variant: 'danger',
                params: {
                    accepted: this.acceptedText || this.getParseAcception(),
                },
            });
        } else this.onPick.emit(file);
    }

    getParseAcception(): string {
        return this.accept.replace(/[.]/g, '');
    }

    private isValidFile(file: File): boolean {
        if (not(this.accept)) return true;

        return this.accept
            .trim()
            .split(/\s*,\s*/)
            .some((type) => this.isValidFileType(file, type));
    }

    private isValidFileType(file: File, type: string): boolean {
        if (type.startsWith('.')) return file.name.endsWith(type);
        if (type.endsWith('*')) return file.type.startsWith(type.slice(0, -1));

        return file.type === type;
    }
}
