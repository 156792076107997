import { NgModule } from '@angular/core';

import { TranslateModule } from '../../modules/translate/translate.module';
import { ErrorStateComponent } from './error-state.component';

export * from './error-state.component';

@NgModule({
    imports: [TranslateModule],
    exports: [ErrorStateComponent],
    declarations: [ErrorStateComponent],
})
export class ErrorStateModule {}
