import {
    AfterViewInit,
    Component,
    ElementRef,
    EventEmitter,
    HostBinding,
    Input,
    OnChanges,
    Output,
    SimpleChanges,
    ViewChild,
    forwardRef,
} from '@angular/core';
import { isEqual } from 'lodash';
import { WatchChanges } from 'ng-onpush';
import { SubscribableComponent } from 'ngx-subscribable';
import { tap, throttleTime } from 'rxjs';

import { ComponentSize } from '../../../interfaces/core-library-components';
import { DataProvider, DataSearchProvider } from '../../../interfaces/rest-api';
import {
    ControlComponent,
    ControlComponentRef,
} from '../../../modules/form/control-component';
import { TranslateService } from '../../../modules/translate/translate.service';
import { Debounce } from '../../../tools/debounce';
import { resizeObserver } from '../../../tools/resize-observer';
import { trackByIndexFn } from '../../../tools/track-by-index';
import { MultiSelectItem } from '../../multi-select/multi-select.component';
import { toMultiSelectItems } from '../tools/select-operator';

export type MultiSelectItemNext<Value = any> = {
    label: string;
    value: Value;
};

export type MultiSelectMapperType = <Value, T>(
    item: T,
) => MultiSelectItemNext<Value>;

const maxWidthOverflowenTag = 60;

@Component({
    selector: 'core-multi-select-next',
    templateUrl: './multi-select-next.component.html',
    styleUrls: ['./multi-select-next.component.less'],
    providers: [
        {
            provide: ControlComponentRef,
            useValue: forwardRef(() => MultiSelectNextComponent),
        },
    ],
})
export class MultiSelectNextComponent
    extends SubscribableComponent
    implements AfterViewInit, OnChanges, ControlComponent
{
    @Input()
    tagsInOneLine?: '';

    @Input()
    label = '';

    @Input()
    placeholder = '';

    @Input()
    selected: MultiSelectItemNext[] = [];

    @Input()
    dataProvider?: DataProvider<MultiSelectItemNext>;

    @Input()
    searchProvider?: DataSearchProvider<MultiSelectItemNext>;

    @Input()
    mapper?: MultiSelectMapperType;

    @Input()
    returnValueMapper: <Value>(item: MultiSelectItemNext<Value>) => any = (
        item,
    ) => item.value;

    @Input()
    size: ComponentSize = 'medium';

    @Output()
    changeValue = new EventEmitter<ReturnType<typeof this.returnValueMapper>>();

    readonly checked = new Set<any>();

    readonly trackByFn = trackByIndexFn;

    readonly more = () => {
        const { dataProvider, searchProvider, mapper } = this;

        const offset = this.options.length;
        let provider = searchProvider
            ? searchProvider(this.search, offset)
            : dataProvider!(offset);

        if (mapper) provider = toMultiSelectItems(mapper)(provider);

        return provider.pipe(
            tap(({ rows, total }) => {
                this.options.push(...rows);
                this.optionsTotal = total;
            }),
        );
    };

    @ViewChild('dropdownTarget')
    private dropdownTargetRef!: ElementRef<HTMLElement>;

    @ViewChild('tags')
    private tagsRef!: ElementRef<HTMLElement>;

    @ViewChild('moreTagsText')
    private moreTagsTextRef!: ElementRef<HTMLElement>;

    @HostBinding('class.has-values')
    private get _(): boolean {
        return this.selected.length > 0;
    }

    @WatchChanges()
    optionsTotal = -1;

    private search = '';

    options: MultiSelectItemNext[] = [];

    get oneLineCondition(): boolean {
        return this.tagsInOneLine === '';
    }

    constructor(private translateService: TranslateService) {
        super();
    }

    ngAfterViewInit(): void {
        const tagsBox = this.tagsRef.nativeElement;

        this.subscriptions.push(
            resizeObserver(tagsBox)
                .pipe(throttleTime(100))
                .subscribe(() => this.hideOverflowenTags()),
        );
    }

    ngOnChanges(changes: SimpleChanges): void {
        if ('selected' in changes) {
            this.checked.clear();

            this.selected.forEach((item) => this.checked.add(item.value));

            const { currentValue, previousValue } = changes['selected'];

            if (this.tagsRef && !isEqual(currentValue, previousValue)) {
                this.hideOverflowenTags();
            }
        }
    }

    isChecked(value: any): boolean {
        return Array.from(this.checked.values()).some((item) =>
            isEqual(item, value),
        );
    }

    onDropdownShow(): void {
        this.resetOptions();
    }

    toggle(item: MultiSelectItemNext): void {
        if (this.checked.has(item.value)) this.unselect(item);
        else this.select(item);
    }

    @Debounce(300)
    onSearch(search: string): void {
        this.search = search;

        this.resetOptions();
    }

    unselect(item: MultiSelectItem): void {
        this.checked.delete(item.value);

        const next = this.selected.filter(({ value }) => value !== item.value);

        this.emitValue(next);
    }

    clear(event: Event): void {
        event.stopPropagation();

        this.emitValue([]);
    }

    private select(item: MultiSelectItemNext): void {
        this.emitValue([...this.selected, item]);
    }

    private emitValue(value: MultiSelectItemNext[]): void {
        this.changeValue.emit(
            value.map((item) => this.returnValueMapper(item)),
        );
    }

    private resetOptions(): void {
        this.options = [];
        this.optionsTotal = -1;
    }

    @Debounce()
    private hideOverflowenTags(): void {
        const tagsWrapper = this.tagsRef.nativeElement;
        const widthTagsWrapper = getWidth(tagsWrapper);

        const button = this.dropdownTargetRef.nativeElement;
        const bottom =
            getBottom(button) -
            parseFloat(getComputedStyle(button).paddingBottom);

        const tags = tagsWrapper.children as unknown as HTMLElement[];

        const lim = tags.length - 1;
        const moreTag = tags[lim];

        if (this.oneLineCondition) changeWidthFirstTag(tags, widthTagsWrapper);

        for (let i = 0; i < lim; i++) {
            const tag = tags[i];

            tag.classList.remove('hidden');

            if (this.oneLineCondition) {
                if (i === 0) continue;

                const tagsWidth = tagsOneLineWidth(i, tags);

                const wrapperCondition =
                    tagsWidth + maxWidthOverflowenTag < widthTagsWrapper;

                if (wrapperCondition) continue;
            } else {
                const tagCondition = getBottom(tag) <= bottom;

                if (tagCondition) continue;
            }

            for (let j = i; j < lim; j++) tags[j].classList.add('hidden');

            this.setMoreTagsText(lim - i);

            moreTag.classList.remove('hidden');

            if (!this.oneLineCondition) {
                while (getBottom(moreTag) > bottom)
                    tags[--i].classList.add('hidden');

                this.setMoreTagsText(lim - i);
            }

            return;
        }

        moreTag.classList.add('hidden');
    }

    private setMoreTagsText(count: number): void {
        const element = this.moreTagsTextRef.nativeElement;

        element.textContent = this.translateService.getTranslation(
            '_$.multiSelect.more',
            { count: count > 99 ? '+99' : count },
        );
    }
}

function getBottom(element: HTMLElement): number {
    const { bottom } = element.getBoundingClientRect();

    return Math.round(bottom);
}

function getWidth(element: HTMLElement): number {
    const { width } = element.getBoundingClientRect();

    return Math.round(width);
}

function indexArr(count: number): number[] {
    return Array.from(Array(count + 1), (_, index) => index);
}

function tagsOneLineWidth(i: number, tags: HTMLElement[]): number {
    const gap = 4;

    return indexArr(i).reduce(
        (acc, index) => acc + getWidth(tags[index]) + gap,
        0,
    );
}

function changeWidthFirstTag(
    tags: HTMLElement[],
    widthTagsWrapper: number,
): void {
    const firstTag = tags[0];

    firstTag.style.width = '';

    const widthFirstTag = getWidth(firstTag);

    const changeWidthCondition =
        widthFirstTag + maxWidthOverflowenTag >= widthTagsWrapper &&
        tags.length > 2;

    firstTag.style.width = changeWidthCondition
        ? widthTagsWrapper - maxWidthOverflowenTag + 'px'
        : '';
}
