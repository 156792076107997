import {
    Component,
    ElementRef,
    EventEmitter,
    HostBinding,
    HostListener,
    Input,
    OnInit,
    Output,
} from '@angular/core';
import { SlDropdown } from '@shoelace-style/shoelace';
import { SubscribableComponent } from 'ngx-subscribable';
import { fromEvent } from 'rxjs';

export const DropdownToggleIcon = 'chevron-down-short';

@Component({
    selector: 'core-select-suffix',
    templateUrl: './select-suffix.component.html',
    styleUrls: ['./select-suffix.component.less'],
})
export class SelectSuffixComponent
    extends SubscribableComponent
    implements OnInit
{
    @Input()
    icon = DropdownToggleIcon;

    @Input()
    library = 'platform';

    @Input()
    showClearIcon = false;

    @Input()
    dropdownTarget?: SlDropdown;

    @Input()
    @HostBinding('class.disabled')
    disabled = false;

    @Output()
    onIconClick = new EventEmitter<void>();

    @Output()
    onClear = new EventEmitter<void>();

    @Output()
    onDropdown = new EventEmitter<void>();

    dropped = false;

    readonly DropdownToggleIcon = DropdownToggleIcon;

    @HostBinding('slot')
    slot = 'suffix';

    constructor(private hostRef: ElementRef<HTMLElement>) {
        super();
    }

    ngOnInit() {
        const host = this.hostRef.nativeElement;
        const dropdown = this.dropdownTarget;

        if (dropdown)
            this.subscriptions.push(
                fromEvent(host, 'click', { capture: true }).subscribe(
                    (event) => {
                        if (this.disabled) event.stopPropagation();
                    },
                ),

                fromEvent(dropdown, 'sl-show').subscribe((event) => {
                    if (event.target === dropdown) this.dropped = true;
                }),
                fromEvent(dropdown, 'sl-hide').subscribe((event) => {
                    if (event.target === dropdown) this.dropped = false;
                }),
            );
    }

    @HostListener('click', ['$event'])
    stopClickEvent(event: Event): void {
        event.stopPropagation();
    }

    onClick(): void {
        if (this.dropdownTarget) {
            if (this.dropped) this.dropdownTarget.hide();
            else this.onDropdown.emit();
        }

        this.onIconClick.emit();
    }
}
