import { not } from 'logical-not';
import { filter, MonoTypeOperatorFunction } from 'rxjs';

const ignoreKey = Symbol();
const timerKey = Symbol();

export function SetIgnoreFor(name: string): MethodDecorator {
    return function (instance: any, property: string | symbol) {
        const origin = instance[property] as Function;

        return {
            value(this: any, ...args: any[]): void {
                if (not(this[ignoreKey])) {
                    this[ignoreKey] = {};
                    this[timerKey] = {};
                }

                clearTimeout(this[timerKey][name]);

                this[ignoreKey][name] = true;

                origin.apply(this, args);

                this[timerKey][name] = setTimeout(
                    () => (this[ignoreKey][name] = false),
                );
            },
        } as any;
    };
}

export function ignorable<T>(
    instance: any,
    name: string,
): MonoTypeOperatorFunction<T> {
    return filter(() =>
        name in (instance[ignoreKey] || {})
            ? not(instance[ignoreKey][name])
            : true,
    );
}
