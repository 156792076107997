import { NgModule, Pipe, PipeTransform } from '@angular/core';
import { not } from 'logical-not';

import { User, UserPublic } from '../interfaces/user';

type Formatter = 'full' | 'initials';

@Pipe({ name: 'username' })
export class UsernamePipe implements PipeTransform {
    transform(
        user: User | UserPublic | string | null | undefined,
        formatter: Formatter = 'initials',
    ): string {
        if (not(user)) return '';

        const fullname = typeof user === 'string' ? user : userToString(user);

        switch (formatter) {
            case 'full':
                return fullname;
            case 'initials': {
                const fullName = fullname.split(' ');
                const firstName = toInitial(fullName[1]);
                const middleName = toInitial(fullName[2]);

                return `${fullName[0]} ${firstName}${middleName}`;
            }
        }
    }
}

function userToString(user: User | UserPublic): string {
    return [user.last_name, user.first_name, user.middle_name]
        .filter(Boolean)
        .join(' ');
}

function toInitial(source: string): string {
    return source ? `${source[0]}.` : '';
}

@NgModule({
    exports: [UsernamePipe],
    declarations: [UsernamePipe],
})
export class UsernamePipeModule {}
