import { Inject, Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { Group, GroupPublic } from '../interfaces/group';
import {
    RequestArray,
    ResponseItem,
    ResponseArray,
} from '../interfaces/rest-api';
import { CoreApi } from '../modules/rest/api-injectors';
import { RestService } from '../modules/rest/rest.service';

@Injectable({ providedIn: 'root' })
export class GroupApiService {
    constructor(@Inject(CoreApi) private restService: RestService) {}

    get(id: number): Observable<ResponseItem<Group>> {
        return this.restService.get(`/group/${id}`);
    }

    list(params?: RequestArray): Observable<ResponseArray<Group>> {
        return this.restService.get('/group', params);
    }

    public(query: RequestGroupList = {}): Observable<ResponseArray<GroupPublic>> {
        return this.restService.get('/group/public', query);
    }

    save(data: GroupSaveRequest): Observable<ResponseItem<Group>> {
        if (data.id) {
            return this.restService.put(`/group/${data.id}`, data);
        } else {
            return this.restService.post('/group', data);
        }
    }

    deleteList(ids: number[]): Observable<any> {
        return this.restService.delete(`/group`, {
            ids,
        });
    }
}

export interface RequestGroupList extends RequestArray {
    include_ids?: number[];
    name?: string;
}

export type GroupSaveRequest = Pick<
    Group,
    'id' | 'name' | 'comment'
> & {
    users: number[];
};
