<svg
    class="up"
    width="6"
    height="5"
    viewBox="0 0 6 5"
    xmlns="http://www.w3.org/2000/svg"
>
    <path
        d="M3.43301 4.25C3.24056 4.58333 2.75944 4.58333 2.56699 4.25L0.834937 1.25C0.642487 0.916667 0.88305 0.5 1.26795 0.5L4.73205 0.5C5.11695 0.5 5.35751 0.916666 5.16506 1.25L3.43301 4.25Z"
        fill="currentColor"
    />
</svg>

<svg
    class="down"
    width="6"
    height="5"
    viewBox="0 0 6 5"
    xmlns="http://www.w3.org/2000/svg"
>
    <path
        d="M3.43301 4.25C3.24056 4.58333 2.75944 4.58333 2.56699 4.25L0.834937 1.25C0.642487 0.916667 0.88305 0.5 1.26795 0.5L4.73205 0.5C5.11695 0.5 5.35751 0.916666 5.16506 1.25L3.43301 4.25Z"
        fill="currentColor"
    />
</svg>
