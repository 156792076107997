import { nothing as t } from "../lit-html.js";
import { AsyncDirective as i } from "../async-directive.js";
import { directive as s } from "../directive.js";
/**
 * @license
 * Copyright 2020 Google LLC
 * SPDX-License-Identifier: BSD-3-Clause
 */
const e = () => new h();
class h {}
const o = new WeakMap(),
  n = s(class extends i {
    render(i) {
      return t;
    }
    update(i, [s]) {
      const e = s !== this.Y;
      return e && void 0 !== this.Y && this.rt(void 0), (e || this.lt !== this.ct) && (this.Y = s, this.ht = i.options?.host, this.rt(this.ct = i.element)), t;
    }
    rt(t) {
      if (this.isConnected || (t = void 0), "function" == typeof this.Y) {
        const i = this.ht ?? globalThis;
        let s = o.get(i);
        void 0 === s && (s = new WeakMap(), o.set(i, s)), void 0 !== s.get(this.Y) && this.Y.call(this.ht, void 0), s.set(this.Y, t), void 0 !== t && this.Y.call(this.ht, t);
      } else this.Y.value = t;
    }
    get lt() {
      return "function" == typeof this.Y ? o.get(this.ht ?? globalThis)?.get(this.Y) : this.Y?.value;
    }
    disconnected() {
      this.lt === this.ct && this.rt(void 0);
    }
    reconnected() {
      this.rt(this.ct);
    }
  });
export { e as createRef, n as ref };
