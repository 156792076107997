<div *ngIf="isLoading" class="spinner">
    <sl-spinner></sl-spinner>
</div>

<div
    class="container"
    [class.empty]="isEmpty"
    [hidden]="hideContentWhileLoading && isLoading"
>
    <ng-content></ng-content>
</div>
