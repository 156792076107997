import { Component } from '@angular/core';
import { FormControl, FormGroup, UntypedFormArray } from '@angular/forms';
import { isNull } from 'lodash';
import { ToParent } from 'ng-to-parent';

import { FilterLogical } from '../../../enums/filter';
import { FormService } from '../../../modules/form/form.service';
import { LocalFiltersService } from '../services/local-filters.service';
import { applyChanges, resetToOldForm } from '../tools/consts';
import { DataOptionFilterMapped } from '../tools/types';
import { PreventClose } from 'plmt-core-library';

@Component({
    selector: 'core-filter-modal',
    templateUrl: './filter-modal.component.html',
    styleUrls: ['./filter-modal.component.less'],
    providers: [ToParent],
})
export class FilterModalComponent {
    isOpen = false;

    private isSubmit = false;

    readonly PreventClose = PreventClose;

    get form(): UntypedFormArray {
        return this.localFiltersService.form;
    }

    constructor(
        private toParent: ToParent,
        public localFiltersService: LocalFiltersService,
        public formService: FormService,
    ) {}

    open(): void {
        this.isOpen = true;
    }

    addRule(): void {
        this.addEntity(this.localFiltersService.defaultRuleForm());
    }

    addGroup(): void {
        this.addEntity(this.localFiltersService.defaultGroupForm());
    }

    addEntity(form: FormGroup): void {
        this.localFiltersService.form.push(form);
    }

    submit(): void {
        if (this.form.invalid) {
            this.formService.showLocalErrors(this.form);

            return;
        }

        if (this.form.value.length) {
            this.localFiltersService.form = this.toChildForm();
        }

        const { value } = this.form;

        this.filterForm(value);

        this.toParent.send(applyChanges, value);

        this.isSubmit = true;
        this.isOpen = false;
    }

    onHide(): void {
        if (!this.isSubmit) {
            this.form.clear();

            this.toParent.send(resetToOldForm, void 0);
        } else {
            this.isSubmit = false;
        }
    }

    private filterForm(form: Partial<DataOptionFilterMapped>[]): void {
        form.forEach((item) => {
            if ('uid' in item) delete item.uid;

            if (isNull(item.logical)) delete item.logical;

            if ('child' in item) this.filterForm(item.child!);
        });
    }

    private toChildForm(): UntypedFormArray {
        const childForm = this.formService.form<any>({
            logical: [FilterLogical.And],
        });

        childForm.addControl('child', this.form);

        return new UntypedFormArray([childForm]);
    }
}
