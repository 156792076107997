import { Component, Input } from '@angular/core';
import { not } from 'logical-not';
import { Observable } from 'rxjs';

import { StorageService } from '../../services/storage.service';

@Component({
    selector: 'core-relative-time',
    templateUrl: './relative-time.component.html',
    styleUrls: ['./relative-time.component.less'],
})
export class RelativeTimeComponent {
    @Input()
    set date(value: string | number) {
        this.dateValue = null;

        if (value) {
            const date = new Date(value);

            if (not(isNaN(+date))) this.dateValue = date;
        }
    }

    dateValue: Date | null = null;

    get isRelative(): boolean {
        if (not(this.dateValue)) return false;

        const dayAgo = new Date();
        dayAgo.setDate(dayAgo.getDate() - 1);

        return this.dateValue > dayAgo;
    }

    get locale(): Observable<string> {
        return this.storageService.lang;
    }

    constructor(private storageService: StorageService) {}
}
