import {
    AfterViewInit,
    Component,
    ElementRef,
    EventEmitter,
    OnInit,
    ViewChild,
} from '@angular/core';
import { SlInput } from '@shoelace-style/shoelace';
import { not } from 'logical-not';
import { ToParent } from 'ng-to-parent';

import { Format_Server } from '../../../interfaces/column.format';
import { FormControlService } from '../../../services/form-control.service';
import { valueOf } from '../../../tools/value-of';

type Value = Format_Server['digit_capacity'];

const defaultPrecision = 2;

@Component({
    selector: 'plmt-to-fixed',
    templateUrl: './to-fixed.component.html',
    styleUrls: ['./to-fixed.component.less'],
    providers: [FormControlService, ToParent],
})
export class ToFixedComponent implements OnInit, AfterViewInit {
    value = new EventEmitter<Value>();

    checked = false;
    precision: number | '' = defaultPrecision;

    @ViewChild('precisionInput', { static: true })
    private precisionInput?: ElementRef<SlInput>;

    constructor(private formControlService: FormControlService) {}

    ngOnInit(): void {
        this.formControlService.provide('digit_capacity', () => ({
            createValueStream: () => this.value,

            setValue: (value: Value): void => {
                this.checked = value !== null;
                this.precision = value !== null ? value : defaultPrecision;

                this.updatePrecisionInput();
            },
        }));
    }

    ngAfterViewInit(): void {
        this.updatePrecisionInput();
    }

    onCheck(): void {
        this.checked = not(this.checked);

        if (this.checked) this.value.next(this.precision || 0);
        else this.value.next(null);
    }

    onInput(event: Event): void {
        const value = +valueOf(event);
        const isNaN = Number.isNaN(value);

        if (not(isNaN)) this.precision = value;

        this.value.next(isNaN ? null : value);
    }

    private updatePrecisionInput(): void {
        if (this.precisionInput)
            this.precisionInput.nativeElement.value = String(this.precision);
    }
}
