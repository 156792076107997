import { AbstractControl } from '@angular/forms';
import { not } from 'logical-not';
import {
    BehaviorSubject,
    filter,
    finalize,
    merge,
    Observable,
    Subscription,
    take,
} from 'rxjs';

export interface ValidateParams {
    control: AbstractControl;
    depends?: AbstractControl[];
    translateKey?: string;

    error(...controls: AbstractControl[]): any;
}

export function validate({
    control,
    depends,
    translateKey = '',
    error,
}: ValidateParams): Subscription {
    const controls = [control];
    if (depends) controls.push(...depends);
    let ownError = false;
    let skip = false;

    return merge(oneShot(), ...controls.map((item) => item.valueChanges))
        .pipe(
            filter(() => not(skip)),
            filter(() => control.valid || ownError),
            finalize(() => {
                control.updateValueAndValidity();
            }),
        )
        .subscribe(() => {
            const validationError = processError(control, error(...controls));

            ownError = Boolean(validationError);

            if (validationError) {
                if (validationError === true) {
                    control.setErrors({ [translateKey]: true });
                } else if (typeof validationError === 'string') {
                    control.setErrors({ [validationError]: true });
                } else {
                    control.setErrors(validationError);
                }
            } else {
                skip = true;

                control.setErrors(null);
                control.updateValueAndValidity({
                    onlySelf: true,
                    emitEvent: true,
                });

                skip = false;
            }
        });
}

function oneShot(): Observable<void> {
    return new BehaviorSubject(void 0).pipe(take(1));
}

function processError(control: AbstractControl, source: any) {
    if (not(Array.isArray(source))) return source;

    for (let i = 0, lim = source.length; i < lim; i++) {
        const validator = source[i];
        const error =
            typeof validator === 'function' ? validator(control) : null;

        if (error) return error;
    }

    return false;
}
