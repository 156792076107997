<sl-tooltip hoist>
    <div *translate="key; let tooltip" slot="content">
        {{ tooltip.title }}

        <ul>
            <li *ngFor="let line of tooltip.lines">{{ line }}</li>
        </ul>
    </div>

    <ng-content></ng-content>
</sl-tooltip>
