import { AfterViewInit, Directive, ElementRef, NgModule } from '@angular/core';
import { SlInput } from '@shoelace-style/shoelace';
import { SubscribableDirective } from 'ngx-subscribable';
import { fromEvent } from 'rxjs';

const INVALID_CHARS = ['-', 'e', '+', 'E', '.', ','];

@Directive({
    selector: 'sl-input[counterInput]',
})
export class CounterInputDirective
    extends SubscribableDirective
    implements AfterViewInit
{
    constructor(private hostRef: ElementRef<SlInput>) {
        super();
    }

    ngAfterViewInit(): void {
        const input = this.hostRef.nativeElement;

        this.subscriptions = [
            fromEvent<KeyboardEvent>(input, 'keydown').subscribe((event) => {
                if (INVALID_CHARS.includes(event.key)) event.preventDefault();
            }),
        ];
    }
}

@NgModule({
    exports: [CounterInputDirective],
    declarations: [CounterInputDirective],
})
export class CounterInputDirectiveModule {}
