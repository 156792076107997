import { NgModule } from '@angular/core';

import { TooltipifyModule } from '../../../modules/tooltipify/tooltipify.module';
import { TranslateModule } from '../../../modules/translate/translate.module';
import { CheckboxComponent } from './checkbox.component';

export * from './checkbox.component';

@NgModule({
    imports: [TooltipifyModule, TranslateModule],
    exports: [CheckboxComponent],
    declarations: [CheckboxComponent],
})
export class CheckboxModule {}
