import {
    Component,
    EventEmitter,
    Input,
    OnChanges,
    OnInit,
    Output,
    SimpleChanges,
} from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { not } from 'logical-not';
import { SubscribableComponent } from 'ngx-subscribable';
import { filter } from 'rxjs';

import { RequestArray } from '../../interfaces/rest-api';

interface Item {
    label: string;
    page: number;
}

const limit: Extract<keyof RequestArray, 'limit'> = 'limit';
const offset: Extract<keyof RequestArray, 'offset'> = 'offset';

@Component({
    selector: 'core-pagination',
    templateUrl: './pagination.component.html',
    styleUrls: ['./pagination.component.less'],
})
export class PaginationComponent
    extends SubscribableComponent
    implements OnInit, OnChanges
{
    @Input()
    total = 0;

    @Input()
    limit = 30;

    @Input()
    set page(value: number) {
        this.pageInputControlled = true;

        const { firstPage, currentPage, lastPage } = this;

        if (value !== currentPage && value >= firstPage && value <= lastPage) {
            this.currentPage = value;

            this.makeItems();
        }
    }

    @Input()
    set routing(_: '') {
        this.changeQuery = true;
    }

    @Output()
    onPageChange = new EventEmitter<number>();

    items: Item[] = [];
    currentPage = 1;

    private readonly firstPage = 1;

    private changeQuery = false;
    private pageInputControlled = false;

    get lastPage(): number {
        return Math.ceil(this.total / this.actualLimit);
    }

    private get queryLimit(): number {
        return (
            Number(this.activatedRoute.snapshot.queryParams[limit]) ||
            this.limit
        );
    }

    private get queryOffset(): number {
        return Number(this.activatedRoute.snapshot.queryParams[offset]) || 0;
    }

    private get actualLimit(): number {
        return this.changeQuery ? this.queryLimit : this.limit;
    }

    constructor(
        private activatedRoute: ActivatedRoute,
        private router: Router,
    ) {
        super();
    }

    ngOnInit(): void {
        this.subscriptions.push(
            this.activatedRoute.queryParams
                .pipe(filter(() => this.changeQuery))
                .subscribe(() => this.applyQueryParams()),
        );
    }

    ngOnChanges(changes: SimpleChanges): void {
        if ('total' in changes) {
            if (this.total >= 0) this.makeItems();

            const total = this.total || 0;
            const limit = this.limit || 30;
            const count = Math.ceil(total / limit);

            if (this.currentPage > count && this.total) {
                this.change(this.currentPage - 1);
            }
        }
    }

    change(page: number): void {
        if (page !== this.currentPage) {
            if (this.changeQuery) {
                const { queryLimit } = this;

                this.router.navigate([], {
                    queryParams: {
                        [limit]: queryLimit,
                        [offset]: (page - 1) * queryLimit,
                    },
                    queryParamsHandling: 'merge',
                });
            } else if (this.pageInputControlled) {
                this.onPageChange.emit(page);
            } else {
                this.currentPage = page;

                this.makeItems();
                this.onPageChange.emit(page);
            }
        }
    }

    private makeItems(): void {
        const { firstPage, currentPage, lastPage } = this;

        if (not(lastPage > 1)) {
            this.items = [];

            return;
        }

        const items: Item[] = [{ label: '1', page: 1 }];

        const delta = lastPage > 9 ? 2 : 1;

        // first delta current delta last
        const max = 3 + delta * 2;

        const hasEllipsis = lastPage > firstPage + max + 1;

        const hasPrevEllipsis = hasEllipsis && currentPage > max - delta;
        const hasNextEllipsis =
            hasEllipsis && currentPage < lastPage - (firstPage + delta + 1);

        const prevEllipsis = hasPrevEllipsis
            ? Math.min(currentPage - delta - firstPage, lastPage - max)
            : 0;
        const nextEllipsis = hasNextEllipsis
            ? Math.max(currentPage + delta, max) + firstPage
            : 0;

        let page = hasEllipsis
            ? Math.max(
                  prevEllipsis || currentPage - delta - firstPage,
                  firstPage + 1,
              )
            : firstPage + 1;

        for (
            const lim = hasNextEllipsis ? nextEllipsis : lastPage - 1;
            page <= lim;
            page++
        ) {
            if (
                (hasPrevEllipsis && page === prevEllipsis) ||
                (hasNextEllipsis && page === nextEllipsis)
            ) {
                items.push({
                    label: '...',
                    page,
                });
            } else {
                items.push({
                    label: String(page),
                    page,
                });
            }
        }

        items.push({
            label: String(lastPage),
            page: lastPage,
        });

        this.items = items;
    }

    private applyQueryParams(): void {
        if (this.changeQuery) {
            const { queryLimit, queryOffset } = this;

            this.currentPage = Math.floor(queryOffset / queryLimit) + 1;

            this.makeItems();
        }
    }

    // private makeItems(): void {
    //     const { delta, lastPage } = this;

    //     const items: Item[] = [{ label: '1', page: 1 }];

    //     const prevEllipsis = this.page - delta - 1;
    //     const nextEllipsis = this.page + delta + 1;

    //     for (let page = prevEllipsis; page <= nextEllipsis; page++) {
    //         if (not(page > 1 && page < lastPage)) continue;

    //         if (
    //             (page === prevEllipsis && page !== 2) ||
    //             (page === nextEllipsis && page != lastPage - 1)
    //         ) {
    //             items.push({
    //                 label: '...',
    //                 page,
    //             });
    //         } else {
    //             items.push({
    //                 label: String(page),
    //                 page,
    //             });
    //         }
    //     }

    //     items.push({
    //         label: String(lastPage),
    //         page: lastPage,
    //     });

    //     this.items = items;
    // }
}
