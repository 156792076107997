import { NgModule } from '@angular/core';
import { SortIndicatorComponent } from './sort-indicator.component';

export * from './sort-indicator.component';

@NgModule({
    exports: [SortIndicatorComponent],
    declarations: [SortIndicatorComponent],
})
export class SortIndicatorModule {}
