import { Component, Input } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { enumToArray } from 'ts-enum-to-array';

import { FormatNumberAbbreviation } from '../../enums/column.format';
import { ColumnType } from '../../enums/dataset';

@Component({
    selector: 'core-column-formatting',
    templateUrl: './column-formatting.component.html',
    styleUrls: ['./column-formatting.component.less'],
})
export class ColumnFormattingComponent {
    @Input()
    columnType!: ColumnType;

    @Input()
    control!: FormGroup;

    readonly ColumnType = ColumnType;

    readonly numberAbbrs = enumToArray(FormatNumberAbbreviation);
}
