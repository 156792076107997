<div *ngIf="label" class="label">
    {{ label }}
</div>

<div class="main">
    <sl-tooltip [content]="min">
        <sl-button
            size="small"
            [type]="type === Type.From ? 'primary' : 'default'"
            (click)="changeType(Type.From)"
        >
            min
        </sl-button>
    </sl-tooltip>

    <div #track class="track" [class]="type">
        <sl-range
            #fromThumb
            class="thumb"
            tooltip="none"
            [class.hidden]="!hasFrom"
        ></sl-range>

        <sl-range
            #toThumb
            class="thumb"
            tooltip="none"
            [class.hidden]="!hasTo"
        ></sl-range>
    </div>

    <sl-tooltip [content]="max">
        <sl-button
            size="small"
            [type]="type === Type.To ? 'primary' : 'default'"
            (click)="changeType(Type.To)"
        >
            max
        </sl-button>
    </sl-tooltip>
</div>

<div class="inputs">
    <sl-input
        #fromInput
        type="number"
        size="small"
        [value]="from.value"
        [min]="min"
        [max]="to.value"
        (sl-input)="changeValue(from, $event)"
    ></sl-input>

    <sl-input
        #toInput
        type="number"
        size="small"
        [value]="to.value"
        [min]="from.value"
        [max]="max"
        (sl-input)="changeValue(to, $event)"
    ></sl-input>
</div>

<div class="range-checkbox">
    <sl-checkbox
        size="small"
        [checked]="type === Type.Interval"
        (sl-change)="toggleFromToType()"
    >
        {{ '_$.interval.checkbox' | translate }}
    </sl-checkbox>
</div>
