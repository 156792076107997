export enum FilterUIType {
    // TODO: есть похожий FilterTemplateUIType, посмотреть можно ли объединить
    Select = 'select',
    SelectMulti = 'multi-select',
    SelectSuggest = 'suggest-select',
    Radio = 'radio',
    Search = 'search',
    Number = 'number',
    NumberRange = 'number-range',
    Date = 'date',
    DateRange = 'date-range',
    // Datetime = 'datetime',
    // DatetimeRange = 'datetime-range',
}
