<sl-dialog [label]="'_$.navigation.token.title' | translate" [(open)]="isOpen">
    <sl-input #input readonly="true" value="{{ token }}">
        <sl-icon-button
            name="subtract"
            slot="suffix"
            test-id="Copy"
            (click)="copy(input)"
        >
        </sl-icon-button>
    </sl-input>
    <p class="token-desc">
        {{ '_$.navigation.token.description' | translate }}
    </p>
    <div slot="footer">
        <sl-button
            variant="primary"
            test-id="Cancel"
            outline
            (click)="hide()"
        >
            {{ '$.close' | translate }}
        </sl-button>
    </div>
</sl-dialog>
