import { Component, EventEmitter } from '@angular/core';
import { WatchChanges } from 'ng-onpush';

@Component({
    selector: 'app-modal-leave-editor-confirm',
    templateUrl: './modal-leave-editor-confirm.component.html',
    styleUrls: ['./modal-leave-editor-confirm.component.less'],
})
export class ModalLeaveEditorConfirmComponent {
    close = new EventEmitter<boolean>();

    @WatchChanges()
    isOpen = false;

    show(): void {
        this.isOpen = true;
    }

    hide(): void {
        this.isOpen = false;
    }
}
