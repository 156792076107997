<label *ngIf="label">{{ label }}</label>

<dx-drop-down-box
    [placeholder]="'filterModal#select' | translate"
    [height]="inputHeight"
    [displayExpr]="displayExpr"
    [valueExpr]="DATA_KEY_PATH"
    [disabled]="isDisabled"
    [showClearButton]="true"
    [dataSource]="dataSource"
    [value]="selectedNodeId"
    (onValueChanged)="changeSelectedKey($event.value)"
    (onOpened)="onOpened()"
>
    <dx-tree-list
        #treeView
        height="100%"
        parentIdExpr="parentId"
        hasItemsExpr="hasItems"
        [keyExpr]="DATA_ID_PATH"
        [rootValue]="ROOT_VALUE"
        [showColumnHeaders]="false"
        [dataSource]="dataSource"
        (onSelectionChanged)="triggerTreeFilter($event)"
        (onContentReady)="setSelected()"
    >
        <dxi-column [dataField]="DATA_VIEW_PATH"></dxi-column>
        <dxo-scrolling
            mode="standart"
            rowRenderingMode="virtual"
        ></dxo-scrolling>
        <dxo-remote-operations [groupPaging]="true"></dxo-remote-operations>
        <dxo-selection mode="single"></dxo-selection>
        <dxo-search-panel [visible]="true"></dxo-search-panel>
    </dx-tree-list>
</dx-drop-down-box>
