import { HttpErrorResponse } from '@angular/common/http';
import {
    Component,
    ElementRef,
    OnInit,
    QueryList,
    ViewChildren,
} from '@angular/core';
import { FormControl, ValidatorFn, Validators } from '@angular/forms';
import { WatchChanges } from 'ng-onpush';
import { SubscribableComponent } from 'ngx-subscribable';

import {
    ChangePasswordRequest,
    UserApiService,
} from '../../../../api/user-api.service';
import { FormService } from '../../../../modules/form/form.service';
import { validate } from '../../../../tools/validate';
import { required } from '../../../../validators/required.validator';
import { stringValidator } from '../../../../validators/string.validator';
import { AlertService } from '../../../alert/alert.service';

const passwordValidators: ValidatorFn[] = [
    required,
    Validators.pattern(/^[a-zA-Z0-9!$#%@]+$/),
    stringValidator({ min: 6, max: Infinity }),
];

@Component({
    selector: 'core-change-password',
    templateUrl: './change-password.component.html',
    styleUrls: ['./change-password.component.less'],
})
export class ChangePasswordComponent
    extends SubscribableComponent
    implements OnInit
{
    @ViewChildren('password')
    passwordElements?: QueryList<ElementRef<{ isPasswordVisible: boolean }>>;

    @WatchChanges()
    isOpen = false;

    passwordForm = this.formService
        .create<ChangePasswordRequest>({
            old_password: [null, passwordValidators],
            new_password: [null, passwordValidators],
        })
        .setup({
            method: this.userApiService.changePassword,
            methodContext: this.userApiService,
            success: () => {
                this.hide();
                this.alertService.show.emit({
                    variant: 'success',
                    key: '_$.navigation.changePassword.saveSuccess',
                });
            },
            error: (response: HttpErrorResponse) => {
                this.alertService.show.emit({
                    responseError: response,
                    key: '_$.navigation.changePassword.saveDanger',
                });
            },
        });

    confirmControl = new FormControl(null, passwordValidators);

    constructor(
        private alertService: AlertService,
        private formService: FormService,
        private userApiService: UserApiService,
    ) {
        super();
    }

    ngOnInit(): void {
        const passwordControl = this.passwordForm.get('new_password')!;
        const oldPasswordControl = this.passwordForm.get('old_password')!;

        this.subscriptions = [
            validate({
                control: passwordControl,
                depends: [oldPasswordControl],
                translateKey: '_$.navigation.changePassword.passwordsEqual',
                error(passwordControl, oldPasswordControl) {
                    return passwordControl.value === oldPasswordControl.value;
                },
            }),
            validate({
                control: passwordControl,
                depends: [this.confirmControl],

                error(passwordControl, confirmControl) {
                    return passwordControl.value !== confirmControl.value;
                },
            }),
            validate({
                control: oldPasswordControl,
                depends: [passwordControl],
                error(oldPasswordControl, passwordControl) {
                    return passwordControl.value === oldPasswordControl.value;
                },
            }),

            validate({
                control: this.confirmControl,
                depends: [passwordControl],
                translateKey: '_$.navigation.changePassword.passwordsNotEqual',
                error(confirmControl, passwordControl) {
                    return passwordControl.value !== confirmControl.value;
                },
            }),
        ];
    }

    show(): void {
        this.passwordForm.reset();
        this.confirmControl.reset();

        this.passwordElements?.forEach((element) => {
            element.nativeElement.isPasswordVisible = false;
        });

        this.isOpen = true;
    }

    hide(): void {
        this.isOpen = false;
    }
}
