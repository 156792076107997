import { Observable } from 'rxjs';

import { ResponseArray } from '../../../interfaces/rest-api';
import { MultiSelectItemNext } from '../multi-select-next/multi-select-next.component';
import { SelectOption } from '../select/select.component';

export const toMultiSelectItems = parseItems<MultiSelectItemNext>;

export const toSelectItems = parseItems<SelectOption>;

function parseItems<Type, T = any>(
    mapper: (item: T) => Type,
): (source: Observable<ResponseArray<T>>) => Observable<ResponseArray<Type>> {
    return (source: Observable<ResponseArray<T>>) =>
        new Observable((subscriber) => {
            const subscription = source.subscribe(({ rows, total }) =>
                subscriber.next({
                    rows: rows.map((item) => mapper(item)),
                    total,
                }),
            );

            return () => subscription.unsubscribe();
        });
}
