import {
    ChangeDetectorRef,
    Component,
    ElementRef,
    EventEmitter,
    Input,
    OnChanges,
    OnInit,
    Output,
    SimpleChanges,
    TemplateRef,
    ViewChild,
} from '@angular/core';
import not from 'logical-not';
import { SubscribableComponent } from 'ngx-subscribable';

import { Debounce } from '../../../tools/debounce';
import { getHost } from '../../../tools/get-host';
import {
    PopupPortalService,
    PortalPopupAgent,
} from '../popup-portal/popup-portal.service';
import { CloseConfirmComponent } from './close-confirm/close-confirm.component';
import { PreventClose } from '../../../enums/close-confirm';

export type ModalType = 'dialog' | 'modal';

enum Visibility {
    Hidden = 'hidden',
    Visible = 'visible',
}

const framesPreventClose: Keyframe[] = [
    {
        transform: 'scale(1, 1)',
    },
    {
        transform: 'scale(1.01, 1.01)',
    },
    {
        transform: 'scale(1, 1)',
    },
];

const animateOptions: KeyframeAnimationOptions = {
    duration: 300,
    iterations: 1,
};

@Component({
    selector: 'core-dialog',
    templateUrl: './dialog.component.html',
    styleUrls: ['./dialog.component.less'],
})
export class DialogComponent
    extends SubscribableComponent
    implements OnInit, OnChanges
{
    @Input('open')
    externalOpen = false;

    @Input()
    width?: number;

    @Input()
    preventClose = PreventClose.None;

    @Input()
    type: ModalType = 'modal';

    @Input()
    label = '';

    @Input()
    showHeader = true;

    @Input()
    visibleOverlay = true;

    @Input()
    customStyle: Partial<CSSStyleDeclaration> = {};

    @Input()
    enableEscapeConfirm = false;

    @Output()
    openChange = new EventEmitter<boolean>();

    @Output()
    overlayChange = new EventEmitter<boolean>();

    @Output('hide')
    modalHide = new EventEmitter<void>();

    @Output()
    show = new EventEmitter<void>();

    @ViewChild('template', { static: true })
    private readonly template?: TemplateRef<{}>;

    @ViewChild('dialog')
    dialog!: ElementRef<HTMLDivElement>;

    @ViewChild('closeConfirm')
    closeConfirm!: CloseConfirmComponent;

    private readonly host = getHost();

    private internalOpen = false;

    private index = 0;

    isDownOnModal = false;

    zIndex = '';

    get openedModals(): NodeListOf<HTMLElement> {
        return document.querySelectorAll(
            'core-popup[popup-name="core-dialog-modal"]',
        ) as NodeListOf<HTMLElement>;
    }

    constructor(
        private popupPortalService: PopupPortalService,
        private changeDetectorRef: ChangeDetectorRef,
    ) {
        super();
    }

    ngOnInit(): void {
        const { modalHide, show, overlayChange, visibleOverlay } = this;

        this.index = this.openedModals.length;

        const hideAgent = new EventEmitter<void>();
        const index = new EventEmitter<number>();
        const key = new EventEmitter<string>();

        const popupAgent: PortalPopupAgent = {
            key,
            hide: hideAgent,
            index,
            visibleOverlay,
            overlayColor: 'var(--sl-overlay-background-color)',
            popupName: 'core-dialog-' + this.type,
        };

        if (this.preventClose && this.type == 'dialog') {
            popupAgent['tryHide'] = new EventEmitter<void>();
        }

        this.subscriptions = [
            show.subscribe(() => {
                if (not(this.template)) return;

                this.internalOpen = true;

                this.host.setAttribute('open', '');

                this.onChangeOpen(Visibility.Hidden);

                this.popupPortalService.append(this.template, popupAgent);
            }),

            hideAgent.subscribe(() => this.hideModal(true)),
            modalHide.subscribe(() => this.hideModal()),

            index.subscribe((value) => {
                this.zIndex = String(value);
            }),
            key.subscribe((button) => {
                if (
                    button === 'Escape' &&
                    this.enableEscapeConfirm &&
                    this.openedModals[this.index].style.visibility !== 'hidden'
                )
                    this.hideActions();
            }),

            overlayChange.subscribe((isVisibleOverlay) => {
                if (not(this.template)) return;

                popupAgent.visibleOverlay = isVisibleOverlay;

                this.popupPortalService.append(this.template, popupAgent);
            }),
        ];

        if (popupAgent.tryHide) {
            this.subscriptions.push(
                popupAgent.tryHide.subscribe(() => this.modalAnimation()),
            );
        }
    }

    ngOnChanges(changes: SimpleChanges): void {
        if ('visibleOverlay' in changes) {
            this.overlayChange.emit(this.visibleOverlay);
        }

        if ('externalOpen' in changes && !changes['externalOpen'].firstChange) {
            if (this.externalOpen && !this.internalOpen) {
                this.show.emit();

                return;
            }

            if (!this.externalOpen && this.internalOpen) {
                this.modalHide.emit();
            }
        }
    }

    overlayClick(): void {
        if (this.type === 'modal') this.hideActions();
    }

    ngOnDestroy(): void {
        this.hideModal();
    }

    private hideModal(isExternalEmmiter = false): void {
        if (this.internalOpen) {
            if (not(this.template)) return;

            this.internalOpen = false;

            this.host.removeAttribute('open');

            this.onChangeOpen(Visibility.Visible);

            if (isExternalEmmiter) this.modalHide.emit();

            if (this.externalOpen) this.openChange.emit(false);

            this.popupPortalService.remove(this.template);
        }
    }

    private hideActions(): void {
        switch (this.preventClose) {
            case PreventClose.ShowConfirm:
                this.modalAnimation();
                this.closeConfirm.show();
                break;
            case PreventClose.OnlyAnimate:
                this.modalAnimation();
                break;
            default:
                this.modalHide.emit();
        }
    }

    private modalAnimation(): void {
        this.changeDetectorRef.detectChanges();

        this.dialog.nativeElement.animate(framesPreventClose, animateOptions);
    }

    @Debounce()
    private onChangeOpen(action: Visibility): void {
        const openedCoreModalDialogs = this.openedModals;

        const parentSlDialog = this.host.closest('sl-dialog');
        const parentSlDrawer = this.host.closest('sl-drawer');
        const rearModal = parentSlDialog || parentSlDrawer;

        const { length } = openedCoreModalDialogs;

        if (this.type === 'dialog') return;

        if (length && !rearModal) {
            if (action === Visibility.Hidden) {
                if (length === 1) return;

                for (let i = 0; i <= length - 2; i++) {
                    openedCoreModalDialogs[i].style.visibility = action;
                }
            } else {
                openedCoreModalDialogs[length - 1].style.visibility = action;
            }

            return;
        }

        if (rearModal) {
            rearModal.style.visibility = action;
        }
    }
}
