import {
    Component,
    ElementRef,
    EventEmitter,
    InjectionToken,
    Input,
    Output,
    ViewChild,
    forwardRef,
} from '@angular/core';
import {
    SlBlurEvent,
    SlChangeEvent,
    SlClearEvent,
    SlFocusEvent,
    SlSelect,
} from '@shoelace-style/shoelace';
import { WatchChanges } from 'ng-onpush';

import { getHost } from '../../tools/get-host';
import { valueOf } from '../../tools/value-of';
import { Debounce } from '../../tools/debounce';

export const PLMT_SELECT_ACCESSOR = new InjectionToken('Plmt-select accessor');

type ValueCallback = (item: any) => any;

export type SelectType = 'string' | 'number' | 'boolean';

type SelectEmptyEvents = SlClearEvent | SlClearEvent | SlClearEvent;

type SelectEmptyEventsType = 'sl-clear' | 'sl-focus' | 'sl-blur';

const DEFAULT_DROPDOWN_HEIGHT = 306;

const SelectEmptyEventsMap: {
    [k in SelectEmptyEventsType]: keyof PlmtSelectComponent;
} = {
    ['sl-clear']: 'slClearEvent',
    ['sl-focus']: 'slFocusEvent',
    ['sl-blur']: 'slBlurEvent',
};

@Component({
    selector: 'plmt-select',
    templateUrl: './plmt-select.component.html',
    styleUrls: ['./plmt-select.component.less'],
    providers: [
        {
            provide: PLMT_SELECT_ACCESSOR,
            useExisting: forwardRef(() => PlmtSelectComponent),
        },
    ],
})
export class PlmtSelectComponent {
    @Input()
    set value(value: any) {
        this.#value = value;

        this.setSelectValue(value);
    }

    get value(): any {
        return this.#value;
    }

    @Input()
    multiple: boolean = false;

    @Input()
    disabled: boolean = false;

    @Input()
    filled: boolean = false;

    @Input()
    pill: boolean = false;

    @Input()
    label: string = '';

    @Input()
    placeholder: string = '';

    @Input()
    size: SlSelect['size'] = 'medium';

    @Input()
    placement: SlSelect['placement'] = 'bottom';

    @Input()
    hoist: '' | undefined = void 0;

    @Input()
    clearable: '' | undefined = void 0;

    @Input()
    maxOptionsVisible: number = 4;

    @Input()
    dropdownHeight = DEFAULT_DROPDOWN_HEIGHT;

    @Input()
    type: SelectType = 'string';

    @Input()
    showBetaBadge = false;

    @Output('sl-change')
    slChangeEvent = new EventEmitter<SlChangeEvent>();

    @Output('sl-clear')
    slClearEvent = new EventEmitter<SlClearEvent>();

    @Output('sl-focus')
    slFocusEvent = new EventEmitter<SlFocusEvent>();

    @Output('sl-blur')
    slBlurEvent = new EventEmitter<SlBlurEvent>();

    @ViewChild('select', { read: ElementRef<SlSelect>, static: true })
    selectElement!: ElementRef<SlSelect>;

    @WatchChanges()
    selectValue: any = void 0;

    host = getHost();

    #value: any = void 0;

    emitChange(event: SlChangeEvent): void {
        event.stopPropagation();

        const callback: ValueCallback = (item) => {
            switch (this.type) {
                case 'number':
                    return isNaN(Number(item))
                        ? item
                        : item === ''
                        ? undefined
                        : Number(item);

                case 'string':
                    return String(item);

                case 'boolean':
                    return Boolean(item);
            }
        };

        (event as any).target.value = arrayConvert(valueOf(event), callback);

        this.value = (event as any).target.value;

        this.slChangeEvent.emit(event);
    }

    emitUsuallyEvents(event: SelectEmptyEvents): void {
        event.stopPropagation();

        const type = event.type as SelectEmptyEventsType;

        this[SelectEmptyEventsMap[type]].emit(event);
    }

    show(): void {
        this.selectElement.nativeElement.show();
    }

    hide(): void {
        this.selectElement.nativeElement.hide();
    }

    focus(options?: FocusOptions): void {
        this.selectElement.nativeElement.focus(options);
    }

    blur(): void {
        this.selectElement.nativeElement.blur();
    }

    @Debounce()
    updateDefaultValue(): void {
        //NOTE: При динамическом добавлении новых элементов в select, дефолтное значение не обновляется
        this.selectElement.nativeElement.value = this.selectValue;
    }

    private setSelectValue(value: any) {
        this.selectValue = arrayConvert(value, (item) => String(item));
    }
}

function arrayConvert(value: any, valueCallback: ValueCallback): any {
    return Array.isArray(value)
        ? value.map((item) => valueCallback(item))
        : valueCallback(value);
}
