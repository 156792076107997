import { AbstractControl, ValidatorFn } from '@angular/forms';
import { ValidationErrorKey } from '../enums/validation-error-key';
import { TranslateService } from '../modules/translate/translate.service';

export interface SymbolValidatorError {
    [ValidationErrorKey.Symbols]: string[];
}

export function symbolsValidator(symbols: string[]): ValidatorFn {
    return (control: AbstractControl): SymbolValidatorError | null => {
        if (control.value) {
            const value: string = control.value.trim().toLowerCase();

            for (let i = 0; i < symbols.length; i++) {
                if (value.includes(symbols[i])) {
                    return {
                        [ValidationErrorKey.Symbols]: symbols,
                    };
                }
            }
        }

        return null;
    };
}
export function whitespaceValidator(): ValidatorFn {
    return (control: AbstractControl) => {
        const regExp = /\s/g;

        if (!control.value || !regExp.test(control.value.trim())) {
            return null;
        }

        return { [ValidationErrorKey.Pattern]: null };
    };
}
