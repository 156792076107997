import { CommonModule } from '@angular/common';
import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';

import {
    FormatDateUnitType,
    FormatDateUnitValue,
} from '../../enums/column.format';
import { FormModule } from '../../modules/form/form.module';
import { TranslationsKeyInterpolationMap } from '../../modules/translate/translate.interfaces';
import { TranslateModule } from '../../modules/translate/translate.module';
import { AroundsComponent } from './arounds/arounds.component';
import { ColumnFormattingComponent } from './column-formatting.component';
import { DateFormattingModule } from './date-formatting/date-formatting.module';
import { NullFormattingComponent } from './null-formatting/null-formatting.component';
import { ToFixedComponent } from './to-fixed/to-fixed.component';
import { CounterInputDirectiveModule } from '../../directives/counter-input.directive';
import { PlmtSelectModule } from '../../plmt-ui-components/plmt-select/plmt-select.module';

export * from './column-formatting.component';

export const columnFormattingInterpolationKeys: TranslationsKeyInterpolationMap =
    {
        FormatDateUnitType,
        FormatDateUnitValue,
    };

@NgModule({
    imports: [
        CommonModule,
        CounterInputDirectiveModule,
        DateFormattingModule,
        FormModule,
        TranslateModule,
        PlmtSelectModule,
    ],
    exports: [ColumnFormattingComponent],
    declarations: [
        AroundsComponent,
        ToFixedComponent,
        ColumnFormattingComponent,
        NullFormattingComponent,
    ],
    schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class ColumnFormattingModule {}
