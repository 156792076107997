import { Injectable } from '@angular/core';
import { SubscribableService } from 'ngx-subscribable';
import { BehaviorSubject, skip } from 'rxjs';

import { Lang } from '../enums/lang';
import { Mode } from '../enums/mode';

const storage = window.localStorage;

export enum StorageKey {
    AuthToken = 'at',
    Lang = 'lang',
    Mode = 'mode',
}

@Injectable({ providedIn: 'root' })
export class StorageService extends SubscribableService {
    authToken = this.create(StorageKey.AuthToken, '');
    lang = this.create(StorageKey.Lang, Lang.Ru);
    mode = this.create(StorageKey.Mode, Mode.Light);

    protected create<T>(
        storageKey: string,
        defaultValue: T,
    ): BehaviorSubject<T> {
        const subject = new BehaviorSubject(
            this.read<T>(storageKey, defaultValue),
        );

        return this.watch(subject, storageKey);
    }

    private read<T>(key: string, defaultValue: T): T {
        return (storage.getItem(key) as any) || defaultValue;
    }

    private watch<T>(
        subject: BehaviorSubject<T>,
        key: string,
    ): BehaviorSubject<T> {
        this.subscriptions.push(
            subject.pipe(skip(1)).subscribe((value: any) => {
                if (value) storage.setItem(key, value);
                else storage.removeItem(key);
            }),
        );

        return subject;
    }
}
