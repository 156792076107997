const timeoutKey = Symbol();

export function Debounce(ms = 0): MethodDecorator {
    return function (target: any, key: string | symbol): any {
        const origin = target[key] as Function;

        return {
            value(...args: any[]): void {
                clearTimeout(this[timeoutKey]);

                this[timeoutKey] = setTimeout(() => {
                    origin.apply(this, args);
                }, ms);
            },
        };
    };
}
