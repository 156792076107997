import { Component } from '@angular/core';

@Component({
    selector: 'core-list-item',
    templateUrl: './list-item.component.html',
    styleUrls: ['./list-item.component.less'],
})
export class ListItemComponent {
    static selectedClass = 'selected';
}
