import { Injectable } from '@angular/core';
import { not } from 'logical-not';
import { BehaviorSubject } from 'rxjs';
import { ModuleName } from '../enums/module';

import { PageRoleRights } from '../interfaces/page';
import { AppService } from './app.service';
import { PageDataService } from './page-data.service';

@Injectable()
export class AccessRightsService {
    modulesAccess = new BehaviorSubject<PageRoleRights[]>([]);
    projectSharingAccess = new BehaviorSubject<boolean>(true);
    access: PageRoleRights[] = [];

    constructor(
        private appService: AppService,
        pageDataService: PageDataService,
    ) {
        pageDataService.page.subscribe((page) => {
            this.modulesAccess.next(page.rights || []);
            this.projectSharingAccess.next(page.sharing_disabled);
        });

        this.modulesAccess.subscribe((modulesAccess) => {
            this.access = modulesAccess.length ? modulesAccess : [];
        });
    }

    checkAccessRights(rights: number[], moduleName?: ModuleName): boolean {
        if (this.appService.isSuperUser) return true;

        const currentModule = moduleName || this.appService.currentModule?.name;
        const currentAccess = this.access.find(
            ({ module_name }) => module_name === currentModule,
        );

        if (not(currentAccess?.module_access)) return false;

        const accessible = currentAccess!.rights || [];

        return rights.some((right) => accessible.includes(right));
    }
}
