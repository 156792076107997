import { DragDropModule } from '@angular/cdk/drag-drop';
import { CommonModule } from '@angular/common';
import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';

import { FormModule } from '../../../modules/form/form.module';
import { TranslateModule } from '../../../modules/translate/translate.module';
import { DateFormattingComponent } from './date-formatting.component';
import { PlmtSelectModule } from '../../../plmt-ui-components/plmt-select/plmt-select.module';

export * from './date-formatting.component';

@NgModule({
    imports: [
        CommonModule,
        DragDropModule,
        FormModule,
        TranslateModule,
        PlmtSelectModule,
    ],
    exports: [DateFormattingComponent],
    declarations: [DateFormattingComponent],
    schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class DateFormattingModule {}
