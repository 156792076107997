import { CommonModule } from "@angular/common";
import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from "@angular/core";
import { ShoelaceModule } from "shoelace-style-angular";
import { TranslateModule } from "../../../modules/translate/translate.module";;
import { RadioMinMaxComponent } from "./radio-min-max.component";

@NgModule({
    imports: [CommonModule, TranslateModule, ShoelaceModule],
    exports: [RadioMinMaxComponent],
    declarations: [RadioMinMaxComponent],
    schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class RadioMinMaxModule {}