<div *ngIf="isDisplayMenu" class="menu" [class.open]="isOpen">
    <div class="burger">
        <div class="menu-item menu-item--logo">
            <sl-tooltip
                hoist
                placement="right"
                [disabled]="isOpen"
                [content]="'nav.menu.burger' | translate"
            >
                <sl-icon-button [name]="burgerButton" (click)="toggleMenu()">
                </sl-icon-button>
            </sl-tooltip>

            <lib-logo class="logo"></lib-logo>
        </div>

        <sl-divider></sl-divider>
    </div>

    <div #container class="content">
        <div
            class="menu-item menu-item--notification"
            (click)="notificationComponent.isOpen = true"
        >
            <div>
                <sl-tooltip
                    hoist
                    placement="right"
                    [disabled]="isOpen"
                    [content]="'nav.menu.notification' | translate"
                >
                    <plmt-notification
                        #notificationComponent
                        (onSetUntouched)="untouched = $event"
                    ></plmt-notification>
                </sl-tooltip>

                <span>{{ 'nav.menu.notification' | translate }}</span>
            </div>

            <span *ngIf="untouched" class="untouched">{{ untouched }}</span>
        </div>

        <ng-container *ngFor="let module of modules$ | async">
            <div *ngIf="module.items.length" class="modules">
                <h3 class="module-name">
                    <sl-divider></sl-divider>
                    {{ module.label | translate }}
                </h3>

                <ng-container *ngFor="let item of module.items">
                    <sl-tooltip
                        hoist
                        placement="right"
                        [disabled]="isOpen"
                        [content]="item.label | translate"
                    >
                        <core-navigation-item
                            [href]="item.href"
                            [test-id]="item.testId"
                        >
                            <sl-icon
                                [library]="item.iconLibrary || 'default'"
                                [name]="item.iconName"
                            ></sl-icon>

                            <div class="text">
                                {{ item.label | translate }}
                            </div>
                        </core-navigation-item>
                    </sl-tooltip>
                </ng-container>
            </div>
        </ng-container>

        <div *ngIf="isShowMore && navigationMenu.length" class="navigation-menu">
            <sl-divider></sl-divider>

            <core-navigation-menu [items]="navigationMenu">
            </core-navigation-menu>
        </div>
    </div>

    <div>
        <sl-divider></sl-divider>

        <div
            class="menu-item menu-item--profile"
            (click)="profileComponent.isOpen = true"
        >
            <sl-tooltip
                hoist
                placement="right"
                [disabled]="isOpen"
                [content]="'nav.menu.profile' | translate"
            >
                <div class="profile">
                    <plmt-profile #profileComponent></plmt-profile>
                </div>
            </sl-tooltip>

            <span>{{ 'nav.menu.profile' | translate }}</span>
        </div>
    </div>
</div>
