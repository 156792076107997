export const keyInterpolationDefaultToken = Symbol();

export interface KeyInterpolationDefault {
    [keyInterpolationDefaultToken]: KeyInterpolationDefaultItem[];
}

export interface KeyInterpolationDefaultItem {
    tester: RegExp;
    value: any;
}
