import { EventEmitter, Injectable, TemplateRef } from '@angular/core';

import { PopupPortalInternalService } from './popup-portal.internal-service';

export interface PortalPopupAgent {
    hide?: EventEmitter<void>;
    tryHide?: EventEmitter<void>;
    index?: EventEmitter<number>;
    key?: EventEmitter<string>;
    visibleOverlay?: boolean;
    overlayColor?: string;
    popupName?: string;
}

@Injectable({ providedIn: 'root' })
export class PopupPortalService {
    constructor(
        private popupPortalInternalService: PopupPortalInternalService,
    ) {}

    append(template: TemplateRef<{}>, agent: PortalPopupAgent = {}): void {
        this.popupPortalInternalService.append.emit({ template, agent });
    }

    remove(template: TemplateRef<{}>): void {
        this.popupPortalInternalService.remove.emit(template);
    }
}
