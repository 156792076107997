import { CommonModule } from '@angular/common';
import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';

import { PlmtDropdownComponent } from './plmt-dropdown.component';
import { PlmtDropdownOpenDirective } from './plmt-dropdown-open.directive';
import { OutsideClickModule } from '../../directives/outside-click.directive';

export * from './plmt-dropdown.component';

@NgModule({
    imports: [CommonModule, OutsideClickModule],
    exports: [PlmtDropdownComponent, PlmtDropdownOpenDirective],
    declarations: [PlmtDropdownComponent, PlmtDropdownOpenDirective],
    schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class PlmtDropdownModule {}
