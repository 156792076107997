import { Pipe, PipeTransform } from '@angular/core';

import { PluralService } from './plural.service';

@Pipe({ name: 'plural', pure: false })
export class PluralPipe implements PipeTransform {
    constructor(private pluralService: PluralService) {}

    transform(key: string, n: number): any {
        return this.pluralService.pluralize(key, n);
    }
}
