export enum ModuleRightsDashboard {
    ProjectView = 1, // Просмотр проектов
    ProjectFullAccess = 2, // Полный доступ к проектам

    WidgetView = 4, // Просмотр виджетов
    WidgetFullAccess = 8, // Полный доступ к виджетам

    Component = 16, // Доступ к компонентам
    Themes = 32, // Доступ к темам
    SvgObjects = 64, // Доступ к svg-объектам
    Filters = 2048, // Доступ к фильтрам
}
