import { Component, Input } from '@angular/core';

@Component({
    selector: 'core-tooltip',
    templateUrl: './tooltip.component.html',
    styleUrls: ['./tooltip.component.less'],
})
export class TooltipComponent {
    @Input()
    key!: string;
}
