import { FormArray, FormControl, Validators } from '@angular/forms';
import { FilterMethod } from '../../../enums/filter';
import { FilterTemplateType } from '../../../enums/filter-template-type';
import { FilterTemplateUIType } from '../../../enums/filter-ui-type';
import { DataOptionFilter } from '../../../interfaces/data-option';
import {
    AggFnValue,
    FilterTemplateCustomData,
    FilterTemplateDataset,
} from '../../../interfaces/filter-template';
import { Form, FormService } from '../../../modules/form/form.service';
import { requiredIf } from '../../../validators/required-if.validator';
import { required } from '../../../validators/required.validator';
import { reservedValidator } from '../../../validators/reserved.validator';
import { stringValidator } from '../../../validators/string.validator';
import { TreeSelectConfig } from '../../tree-view/interfaces/tree-view.interface';
import { FilterTemplateUI } from '../interfaces/filter-template-ui.type';
import { DefaultValueAggregation } from 'src/shared/enums/radio-min-max-state.enum';

type FilterTemplateFormControl = {
    id: FormControl<number>;
    type: FormControl<FilterTemplateType>;
    subType: FormControl<FilterTemplateUIType>;
    defaultValue: FormControl<any>;
    datasetId: FormControl<number>;
    action: FormControl<FilterMethod>;
    viewColumnId: FormControl<number>;
    hasDefaultValue: FormControl<boolean>;
    defaultValueAggFn: FormControl<DefaultValueAggregation>;
    originalDefaultValue: FormControl<any>;

    filters: FormArray<Form<DataOptionFilter>>;

    datasetSelect: Form<DatasetSelect>;
    treeSelect: Form<TreeSelect>;
    customSelect: Form<FilterTemplateCustomData>;
};

type TreeSelect = Omit<TreeSelectConfig, 'filters' | 'dataset_id'>;
type DatasetSelect = Omit<FilterTemplateDataset, 'filters' | 'dataset_id'>;

export class FilterTemplateForm {
    private static instance: FilterTemplateForm;

    private filterTemplateForm!: Form<FilterTemplateUI>;

    private formControls!: FilterTemplateFormControl;

    get form(): Form<FilterTemplateUI> {
        return this.filterTemplateForm;
    }

    get controls(): FilterTemplateFormControl {
        return this.formControls;
    }

    private constructor(private formService: FormService) {
        this.filterTemplateForm = this.formService.form<FilterTemplateUI>({
            id: [],
            name: [null, [required, stringValidator({ min: 1, max: 250 })]],
            key: [, [Validators.pattern(/^[a-zA-Z0-9]+$/), reservedValidator]],
            position: [],
            type: [, [Validators.required]],
            sub_type: [, [Validators.required]],
            action: [, [Validators.required]],
            has_default_value: [],
            default_value: [],
            dataset_id: [, [requiredIf]],
            default_value_agg_fn: [],
            original_default_value: [],
            filters: new FormArray([]),
            users: new FormArray([]),
            groups: new FormArray([]),
            dataset_select: this.formService.form<DatasetSelect>({
                view_column_id: [, [requiredIf]],
                key_column_id: [],
                order_by: [],
                order_direction: [],
            }),
            custom_select: this.formService.form<FilterTemplateCustomData>({
                name_key_column: [, [requiredIf]],
                key_column_type: [],
                name_suggest_column: [, [requiredIf]],
                suggest_column_type: [],
                values: new FormArray([]),
            }),
            tree_select: this.formService.form<TreeSelect>({
                columns: new FormArray([]),
            }),
        });

        this.formControls = {
            id: this.filterTemplateForm.get('id') as FormControl<number>,
            type: this.filterTemplateForm.get(
                'type',
            ) as FormControl<FilterTemplateType>,
            subType: this.filterTemplateForm.get(
                'sub_type',
            ) as FormControl<FilterTemplateUIType>,
            defaultValue: this.filterTemplateForm.get(
                'default_value',
            ) as FormControl<any>,
            datasetId: this.filterTemplateForm.get(
                'dataset_id',
            ) as FormControl<number>,
            action: this.filterTemplateForm.get(
                'action',
            ) as FormControl<FilterMethod>,
            viewColumnId: this.filterTemplateForm.get(
                'dataset_select.view_column_id',
            ) as FormControl<number>,
            hasDefaultValue: this.filterTemplateForm.get(
                'has_default_value',
            ) as FormControl<boolean>,
            defaultValueAggFn: this.filterTemplateForm.get(
                'default_value_agg_fn',
            ) as FormControl<DefaultValueAggregation>,
            originalDefaultValue: this.filterTemplateForm.get(
                'original_default_value',
            ) as FormControl<AggFnValue>,

            filters: this.filterTemplateForm.get('filters') as FormArray<
                Form<DataOptionFilter>
            >,

            datasetSelect: this.filterTemplateForm.get(
                'dataset_select',
            ) as unknown as Form<DatasetSelect>,
            treeSelect: this.filterTemplateForm.get(
                'tree_select',
            ) as unknown as Form<TreeSelect>,
            customSelect: this.filterTemplateForm.get(
                'custom_select',
            ) as Form<FilterTemplateCustomData>,
        };
    }

    static getInstance(formService: FormService): FilterTemplateForm {
        if (!FilterTemplateForm.instance) {
            FilterTemplateForm.instance = new FilterTemplateForm(formService);
        }
        return FilterTemplateForm.instance;
    }

    static cleanForm(): void {
        this.instance.filterTemplateForm.reset();

        const { customSelect, filters, treeSelect } = this.instance.controls;

        (
            [
                customSelect.controls.values,
                filters,
                treeSelect.controls.columns,
            ] as FormArray[]
        ).forEach((item) => item.clear());
    }
}
