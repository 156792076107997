import { Directive, Input, NgModule, HostBinding } from '@angular/core';
import {
    TestIdAction,
    TestIdNav,
    TestIdNotification,
    TestIdDataset,
    TestIdFilter,
    TestIdRole,
    TestIdProject,
} from '../enums/test-id';

type ValueOf<T> = T[keyof T];

export const TestId = {
    ...TestIdAction,
    ...TestIdNav,
    ...TestIdNotification,
    ...TestIdDataset,
    ...TestIdFilter,
    ...TestIdRole,
    ...TestIdProject,
};

@Directive({ selector: '[test-id]' })
export class TestIdDirective {
    @Input('test-id')
    set testId(testId: keyof typeof TestId) {
        const hasTestId = TestId.hasOwnProperty(testId);

        this.id = hasTestId ? TestId[testId] : null;
    }

    @HostBinding('attr.test-id')
    id: ValueOf<typeof TestId> | null = null;
}

@NgModule({
    exports: [TestIdDirective],
    declarations: [TestIdDirective],
})
export class TestIdDirectiveModule {}
