import { AfterViewInit, Directive, Input, NgModule } from '@angular/core';
import { SlDropdown, SlSelect } from '@shoelace-style/shoelace';
import { SubscribableDirective } from 'ngx-subscribable';
import { fromEvent } from 'rxjs';
import not from 'logical-not';

import { getHost } from '../tools/get-host';

@Directive({
    selector: `
        sl-dropdown[availableHeight],
        sl-select[availableHeight],
    `,
})
export class AutoSizeAvailableHeightDirective
    extends SubscribableDirective
    implements AfterViewInit
{
    @Input()
    set availableHeight(height: number) {
        this.height = height;
    }

    private readonly host: SlSelect | SlDropdown = getHost();
    private height?: number;

    constructor() {
        super();
    }

    ngAfterViewInit(): void {
        const { host } = this;

        this.subscriptions = [
            fromEvent<CustomEvent>(host, 'sl-after-show').subscribe(() => {
                const { height } = this;

                const availableHeight = parseInt(
                    host.popup.style.getPropertyValue(
                        '--auto-size-available-height',
                    ),
                    10,
                );

                if (not(height)) return;

                if (host instanceof SlSelect) {
                    this.setListboxStyle(host.listbox, availableHeight);

                    return;
                }

                const menu = host.querySelector('sl-menu');

                if (not(menu)) return;

                this.setListboxStyle(menu, availableHeight);
            }),
        ];
    }

    private setListboxStyle(
        element: HTMLElement,
        availableHeight: number,
    ): void {
        const { height } = this;

        element.style.maxHeight =
            availableHeight > height!
                ? `${height}px`
                : `${availableHeight - 28}px`;

        if (element.scrollHeight > element.clientHeight) {
            const listbox = element.closest('.listbox') as HTMLElement;

            if (not(listbox)) return;

            listbox.style.paddingRight = '2px';
        }
    }
}

@NgModule({
    exports: [AutoSizeAvailableHeightDirective],
    declarations: [AutoSizeAvailableHeightDirective],
})
export class AutoSizeAvailableHeightDirectiveModule {}
