import { Inject, Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { CoreApi } from '../modules/rest/api-injectors';
import { RestService } from '../modules/rest/rest.service';
import { Role, RoleModuleRights, RolePublic } from '../interfaces/role';
import { User } from '../interfaces/user';
import {
    IdMap,
    RequestArray,
    ResponseArray,
    ResponseItem,
} from '../interfaces/rest-api';

@Injectable({ providedIn: 'root' })
export class RoleApiService {
    constructor(@Inject(CoreApi) private restService: RestService) {}

    get(id: number): Observable<RoleItemResponse> {
        return this.restService.get(`/role/${id}`);
    }

    getPublic(id: number): Observable<ResponseItem<RolePublic>> {
        return this.restService.get(`/role/public/${id}`);
    }

    list(params?: RequestArray): Observable<ResponseArray<Role>> {
        return this.restService.get('/role', params);
    }

    public(params?: RequestArray): Observable<ResponseArray<RolePublic>> {
        return this.restService.get('/role/public', params);
    }

    save(data: RoleCreateParams): Observable<ResponseItem<Role>> {
        if (data.id) {
            return this.restService.put(`/role/${data.id}`, data);
        } else {
            return this.restService.post('/role', data);
        }
    }

    delete(ids: number[]): Observable<void> {
        return this.restService.delete(`/role`, {
            ids,
        });
    }

    validate(params: RoleValidateParams): Observable<void> {
        return this.restService.post('/role/validate', params);
    }
}

export type RoleItemResponse = ResponseItem<Role> & {
    role_modules: RoleModuleRights[];
    users: IdMap<User>;
};

export type RoleCreateParams = Pick<
    Role,
    'id' | 'name' | 'description' | 'role_preset' | 'redirect_link'
> & {
    users: number[];
    module_rights: RoleModuleRights[];
};

export interface RoleValidateParams {
    id?: number;
    name: string;
}
