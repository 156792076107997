<ng-template #template>
    <div
        class="body"
        [stickTo]="button"
        [placement]="placement"
        [syncWidth]="button"
        [syncWidthEnable]="shouldSyncWidth"
        [style.zIndex]="zIndex"
    >
        <ng-content></ng-content>
    </div>
</ng-template>
