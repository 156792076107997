import { isNull, isObject, isUndefined } from 'lodash';
import { SearchParam } from '../enums/search-param';

export function deepSearchByProperty(
    object: any,
    property: string,
    search: SearchParam = SearchParam.Value,
    path: string = '',
    withValue?: any,
): any | null {
    if (isNull(object)) return null;

    const value = object[property];

    const searchParam =
        search === SearchParam.Value ? value : concatePath(path, property);

    if (value && isUndefined(withValue)) return searchParam;

    if (value && !isUndefined(withValue) && value === withValue)
        return searchParam;

    for (let item in object) {
        const value = object[item];

        if (object.hasOwnProperty(item) && isObject(value) && !isNull(value)) {
            const result = deepSearchByProperty(
                value,
                property,
                search,
                concatePath(path, item),
                withValue,
            );

            if (result) return result;
        }
    }
}

function concatePath(oldPath: string, newPath: string): string {
    return (oldPath += `${oldPath && '.'}${newPath}`);
}
