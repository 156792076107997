import { Inject, Injectable, InjectionToken } from '@angular/core';
import { not } from 'logical-not';
import { ChangeDetectionService } from 'ng-onpush';
import {
    BehaviorSubject,
    forkJoin,
    map,
    Observable,
    of,
    skip,
    tap,
} from 'rxjs';

import { Environment, ENVIRONMENT } from '../../constants/environment';
import { StorageService } from '../../services/storage.service';
import { Composer } from './composer';
import { TranslateCacheService } from './translate-cache.service';
import { TranslateType } from './translate.enums';
import { TranslationsSettings } from './translate.interfaces';

export const MainSettings = new InjectionToken<TranslationsSettings>(
    'MainSettings',
);
export const PageSettings = new InjectionToken<TranslationsSettings>(
    'PageSettings',
);

export const translationsComposer = new Composer();

export type Translations = Record<string, any>;

export const translations = new BehaviorSubject<Translations>(
    translationsComposer.get(TranslateType.Page),
);

@Injectable({ providedIn: 'root' })
export class TranslateInternalService {
    private translationsSettings: Record<TranslateType, TranslationsSettings> =
        {
            [TranslateType.Main]: {
                type: TranslateType.Main,
                sources: [],
                keyInterpolation: {},
            },
            [TranslateType.Page]: {
                type: TranslateType.Page,
                sources: [],
                keyInterpolation: {},
            },
        };

    constructor(
        @Inject(ENVIRONMENT) private environment: Environment,
        private changeDetectionService: ChangeDetectionService,
        private storageService: StorageService,
        private translateCacheService: TranslateCacheService,
    ) {
        this.storageService.lang.pipe(skip(1)).subscribe(() => {
            forkJoin([
                this.applySettings(
                    this.translationsSettings[TranslateType.Main],
                ),
                this.applySettings(
                    this.translationsSettings[TranslateType.Page],
                ),
            ]).subscribe(() => this.changeDetectionService.markForCheck());
        });
    }

    applySettings(settings: TranslationsSettings | null): Observable<boolean> {
        if (not(settings)) {
            if (this.environment !== Environment.Production)
                console.error(`you must provide translation settings`);

            return of(false);
        }

        this.translationsSettings[settings.type] = settings;

        const lang = this.storageService.lang.value;

        return this.translateCacheService
            .get(settings, lang)
            .pipe(tap(changeTranslations(settings.type)), map(toTrue));
    }
}

function changeTranslations(type: TranslateType) {
    return (value: Translations) => {
        translations.next(translationsComposer.set(type, value));
    };
}

function toTrue(): true {
    return true;
}
