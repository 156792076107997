import {
    Component,
    ElementRef,
    HostBinding,
    Input,
    OnInit,
} from '@angular/core';
import { WatchChanges } from 'ng-onpush';

@Component({
    selector: 'core-empty-state',
    templateUrl: './empty-state.component.html',
    styleUrls: ['./empty-state.component.less'],
})
export class EmptyStateComponent implements OnInit {
    @Input()
    imageSrc!: string;

    @Input()
    set title(value: string) {
        if (value) this._title = value;
    }

    @Input()
    description = '';

    @HostBinding('style.min-height')
    hostMinHeight = '';

    @WatchChanges()
    _title = '_$.tableFilter.emptyStateFilter.title';

    constructor(private host: ElementRef<HTMLElement>) {}

    ngOnInit(): void {
        const { y } = this.host.nativeElement.getBoundingClientRect();

        this.hostMinHeight = `calc(100vh - ${y * 2}px)`;
    }
}
