import { ValidatorFn } from '@angular/forms';

import { ValidationErrorKey } from '../enums/validation-error-key';
import { parseUrl } from '../tools/url';

export interface UrlValidatorError {
    [ValidationErrorKey.Url]: null;
}

export const urlValidator: ValidatorFn = (
    control,
): UrlValidatorError | null => {
    const url = parseUrl(control.value);

    return url ? null : { [ValidationErrorKey.Url]: null };
};
