<sl-checkbox size="small" [checked]="checked" (sl-change)="onCheck()">
    {{ '_$$.columnFormatting.label.toFixed' | translate }}
</sl-checkbox>

<sl-input
    #precisionInput
    type="number"
    counterInput
    [min]="0"
    [max]="6"
    [disabled]="!checked"
    (sl-input)="onInput($event)"
></sl-input>
