import { CommonModule } from '@angular/common';
import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';

import { AutoSizeAvailableHeightDirectiveModule } from '../../directives/auto-size-available-height.directive';
import { PlmtSelectComponent } from './plmt-select.component';
import { PlmtSelectStyleDirectiveModule } from './plmt-select-style.directive';
import { BetaBadgeModule } from '../../components/beta-badge/beta-badge.module';

export * from './plmt-select.component';

@NgModule({
    imports: [
        CommonModule,
        PlmtSelectStyleDirectiveModule,
        AutoSizeAvailableHeightDirectiveModule,
        BetaBadgeModule,
    ],
    exports: [PlmtSelectComponent],
    declarations: [PlmtSelectComponent],
    schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class PlmtSelectModule {}
