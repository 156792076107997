import {
    Directive,
    Input,
    NgModule,
    TemplateRef,
    ViewContainerRef,
} from '@angular/core';

import { AppService } from '../services/app.service';

@Directive({ selector: '[beta]' })
export class BetaDirective {
    @Input()
    set beta(beta: boolean) {
        if (beta !== this.appService.beta) this.viewContainerRef.clear();
        else this.viewContainerRef.createEmbeddedView(this.templateRef);
    }

    constructor(
        private appService: AppService,
        private templateRef: TemplateRef<any>,
        private viewContainerRef: ViewContainerRef,
    ) {}
}

@NgModule({
    exports: [BetaDirective],
    declarations: [BetaDirective],
})
export class BetaDirectiveModule {}
