import {
    Directive,
    ElementRef,
    Input,
    OnChanges,
    OnDestroy,
    OnInit,
    SimpleChanges,
} from '@angular/core';
import { AbstractControl, ValidatorFn, Validators } from '@angular/forms';
import { SlInput } from '@shoelace-style/shoelace';
import { not } from 'logical-not';

import {
    FormControlName,
    FormControlService,
} from '../../services/form-control.service';
import {
    Settings,
    settingsMap,
} from '../../validators/sl-input-number.internal';

const minMaxKeys: Extract<keyof SlInputNumberDirective, 'min' | 'max'>[] = [
    'min',
    'max',
];

@Directive({
    selector: 'sl-input[type=number][name]',
})
export class SlInputNumberDirective implements OnInit, OnChanges, OnDestroy {
    @Input()
    min?: SlInput['min'];

    @Input()
    max?: SlInput['max'];

    @Input()
    control?: AbstractControl;

    @Input()
    name?: FormControlName;

    private controlItem?: AbstractControl;
    private numberValidator: ValidatorFn = () => null;

    constructor(
        private formControlService: FormControlService,
        private hostRef: ElementRef<SlInput>,
    ) {}

    ngOnInit(): void {
        const input =
            this.hostRef.nativeElement.shadowRoot?.querySelector('input');

        if (input)
            this.numberValidator = () => {
                return input.validity.badInput ? { invalidValue: null } : null;
            };

        const setControl = (control: AbstractControl) => {
            this.controlItem = control;

            this.updateMinMaxValidator();
        };

        if (this.name)
            this.formControlService.getControl(this.name, setControl);
        else if (this.control) setControl(this.control);
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (minMaxKeys.some((key) => key in changes)) {
            const host = this.hostRef.nativeElement;

            if ('min' in changes) host.min = this.min!;
            if ('max' in changes) host.max = this.max!;

            this.updateMinMaxValidator();
        }
    }

    ngOnDestroy(): void {
        if (this.controlItem) settingsMap.delete(this.controlItem);
    }

    private updateMinMaxValidator(): void {
        const control = this.controlItem;

        if (not(control)) return;

        const element = this.hostRef.nativeElement;
        const settings: Settings = {
            number: this.numberValidator,
        };

        const min = getValue(element.min);

        if (min !== null) settings.min = Validators.min(min);

        const max = getValue(element.max);

        if (max !== null) settings.max = Validators.max(max);

        settingsMap.set(control, settings);

        control.updateValueAndValidity();
    }
}

function getValue(source: SlInput['min'] | SlInput['max']): number | null {
    if (typeof source === 'undefined') return null;
    if (typeof source === 'number') return source;

    source = parseFloat(source);

    return isNaN(source) ? null : source;
}
