<sl-dropdown
    #SlDropdown
    hoist
    placement="left-end"
    distance="3"
    (show)="isDisabledTooltip = true"
    (hide)="isDisabledTooltip = false"
>
    <div slot="trigger">
        <sl-tooltip
            hoist
            placement="right"
            [disabled]="isDisabledTooltip"
            [content]="'nav.menu.more' | translate"
        >
            <sl-icon-button
                name="three-dots"
                [class.activated]="hasActiveItem()"
            ></sl-icon-button>
        </sl-tooltip>
    </div>

    <div class="list">
        <ng-container *ngFor="let item of items">
            <a
                [routerLink]="item.href"
                [class.activated]="isActive(item.href)"
                (click)="SlDropdown.hide()"
            >
                <sl-icon
                    [library]="item.iconLibrary || 'default'"
                    [name]="item.iconName"
                ></sl-icon>

                {{ item.label | translate }}
            </a>
        </ng-container>
    </div>
</sl-dropdown>
