export enum ModuleRightsManager {
    DatasourceView = 1, // Источники просмотр
    DatasourceFullAccess = 2, // Источники полный доступ

    DatasetView = 4, // Датасеты просмотр
    DatasetFullAccess = 8, // Датасеты полный доступ

    Roles = 16, // Доступ к ролям
    UserAccess = 32, // Доступ к пользователям
    GroupAccess = 64, // Доступ к группам

    License = 128, // Доступ к лицензии и информации о системе
    Plugins = 256, // Доступ к подключенным плагинам
    Modules = 512, // Доступ к подключенным модулям
    Logs = 1024, // Доступ к логированию действий
    Filters = 2048, // Доступ к реестру фильтров
}
