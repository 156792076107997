import { CommonModule } from '@angular/common';
import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';

import { TranslateModule } from '../../modules/translate/translate.module';
import { RadioMinMaxModule } from '../filter-template/radio-min-max/radio-min-max.module';
import { MultiSelectModule } from '../multi-select/multi-select.module';
import { SuggestModule } from '../suggest/suggest.module';
import { TreeViewModule } from '../tree-view/tree-view.module';
import { FilterValueColumnDirective } from './column.directive';
import { FilterValueComponent } from './filter-value.component';
import { HasTimeComponent } from './has-time/has-time.component';

export * from './column.directive';
export * from './filter-value.component';
export * from './has-time/has-time.component';
export * from './interpolation-keys.helper';
export * from './null.helper';

@NgModule({
    imports: [
        CommonModule,
        MultiSelectModule,
        SuggestModule,
        TranslateModule,
        RadioMinMaxModule,
        TreeViewModule,
    ],
    exports: [FilterValueColumnDirective, FilterValueComponent],
    declarations: [
        FilterValueColumnDirective,
        FilterValueComponent,
        HasTimeComponent,
    ],
    schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class FilterValueModule {}
