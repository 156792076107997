import {
    Directive,
    Input,
    OnInit,
    TemplateRef,
    ViewContainerRef,
} from '@angular/core';
import { SubscribableDirective } from 'ngx-subscribable';

import { translations } from './translate.internal';

@Directive({ selector: '[translate]' })
export class TranslateDirective
    extends SubscribableDirective
    implements OnInit
{
    @Input()
    translate!: string;

    constructor(
        private readonly templateRef: TemplateRef<{ $implicit: any }>,
        private readonly viewContainerRef: ViewContainerRef,
    ) {
        super();
    }

    ngOnInit(): void {
        this.subscriptions = [
            translations.subscribe((translations) => {
                this.viewContainerRef.clear();

                this.viewContainerRef.createEmbeddedView(this.templateRef, {
                    $implicit: translations[this.translate],
                });
            }),
        ];
    }
}
