import { enumToArray } from 'ts-enum-to-array';

import { ColumnType } from '../enums/dataset';
import { FilterTemplateUIType } from '../enums/filter-ui-type';

const partialMap: Partial<Record<FilterTemplateUIType, ColumnType>> = {
    [FilterTemplateUIType.Number]: ColumnType.Number,
    [FilterTemplateUIType.NumberRange]: ColumnType.Number,
    [FilterTemplateUIType.DateCalendar]: ColumnType.Date,
    [FilterTemplateUIType.DatePeriod]: ColumnType.Date,
    [FilterTemplateUIType.DateSearch]: ColumnType.Date,
    [FilterTemplateUIType.DateSearchPeriod]: ColumnType.Date,
    [FilterTemplateUIType.DateTimeCalendar]: ColumnType.Date,
    [FilterTemplateUIType.DateTimePeriod]: ColumnType.Date,
    [FilterTemplateUIType.DateTimeSearch]: ColumnType.Date,
    [FilterTemplateUIType.DateTimeSearchPeriod]: ColumnType.Date,
};

export const columnTypeMap: Record<FilterTemplateUIType, ColumnType> =
    {} as any;

enumToArray(FilterTemplateUIType).forEach((type) => {
    columnTypeMap[type] = partialMap[type] || ColumnType.String;
});
