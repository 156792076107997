export enum DatePluralPeriod {
    Second = 'second',
    Minute = 'minute',
    Hour = 'hour',
    Day = 'day',
    Week = 'week',
    Month = 'month',
    Quarter = 'quarter',
    Year = 'year',
}
