import {
    Component,
    EventEmitter,
    Input,
    Output,
    TemplateRef,
} from '@angular/core';
import { TestId } from 'plmt-core-library';

export type ActionDropdownComponentType =
    | 'delete'
    | 'update'
    | 'copy'
    | 'apply'
    | 'export';

interface Action {
    type: ActionDropdownComponentType;
    hasAccess: boolean;
    template?: TemplateRef<any>;
}

@Component({
    selector: 'core-action-dropdown[actions]',
    templateUrl: './action-dropdown.component.html',
    styleUrls: ['./action-dropdown.component.less'],
})
export class ActionDropdownComponent {
    @Input()
    actions!: Action[];

    @Output()
    onCopy = new EventEmitter<void>();

    @Output()
    onApply = new EventEmitter<void>();

    @Output()
    onUpdate = new EventEmitter<void>();

    @Output()
    onDelete = new EventEmitter<void>();

    @Output()
    onExport = new EventEmitter<void>();

    get hasLeastOneAccess(): boolean {
        return this.actions.some((item) => item.hasAccess);
    }

    onChange(action: Action): void {
        if (!action.hasAccess) return;
        switch (action.type) {
            case 'apply':
                this.onApply.emit();
                break;
            case 'delete':
                this.onDelete.emit();
                break;
            case 'copy':
                this.onCopy.emit();
                break;
            case 'update':
                this.onUpdate.emit();
                break;
            case 'export':
                this.onExport.emit();
                break;
        }
    }

    getTestId(type: ActionDropdownComponentType): keyof typeof TestId {
        switch (type) {
            case 'apply':
                return 'Apply';
            case 'copy':
                return 'Copy';
            case 'update':
                return 'Update';
            case 'export':
                return 'Download';
            default:
                return 'Delete';
        }
    }
}
