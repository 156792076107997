import { NgLocalization } from '@angular/common';
import { Injectable } from '@angular/core';

const likeFew = [2, 3, 4];
const likeOther = [11, 12, 13, 14];

@Injectable()
export class PluralLocalization extends NgLocalization {
    getPluralCategory(value: any) {
        if (value === 0) return 'zero';
        if (value === 1) return 'one';
        if (likeFew.includes(value)) return 'few';
        if (
            likeOther.some((item) => value.toString().endsWith(item.toString()))
        ) {
            return 'other';
        }
        if (value > 20 && value % 10 === 1) return 'one';
        if (value > 20 && likeFew.includes(value % 10)) return 'few';
        return 'other';
    }
}
