import {
    Component,
    EventEmitter,
    Input,
    OnChanges,
    OnInit,
    Output,
    SimpleChanges,
    ViewChild,
} from '@angular/core';
import { Validators } from '@angular/forms';
import { isEqual, isNull } from 'lodash';
import { SubscribableComponent } from 'ngx-subscribable';

import { HintPopupComponent } from '../../../../hint-popup/hint-popup.component';
import { FormService } from '../../../../../modules/form/form.service';
import { showErrorsFor } from '../../../../../modules/form/internal';
import { isExistValue } from '../../../../../tools/exist-value';
import { validate } from '../../../../../tools/validate';
import {
    RangeForm,
    RangeType,
    Tupple,
    ValidateReturnEntity,
} from '../../../tools/types';

const validationMessageRange = '_$$.filters.validation.range';

@Component({
    selector: 'app-range-filter',
    templateUrl: './range-filter.component.html',
    styleUrls: ['./range-filter.component.less'],
})
export class RangeComponent
    extends SubscribableComponent
    implements OnInit, OnChanges
{
    @Input()
    value?: any;

    @Input()
    type: RangeType = 'date';

    @Output()
    changeData = new EventEmitter<Tupple | null>();

    @ViewChild(HintPopupComponent, { static: true })
    popup!: HintPopupComponent;

    form = this.formService.form<RangeForm>({
        from: [null, [Validators.required]],
        to: [null, [Validators.required]],
    });

    fromControl = this.form.get('from')!;
    toControl = this.form.get('to')!;

    constructor(private formService: FormService) {
        super();

        const isValidForm = (): boolean => this.isValidForm();

        const toControl = this.toControl;

        validate({
            control: toControl,
            error(): ValidateReturnEntity {
                if (!isValidForm())
                    return {
                        [validationMessageRange]: toControl.value,
                    };
            },
        });
    }

    ngOnChanges(changes: SimpleChanges): void {
        if ('value' in changes) {
            const { currentValue, previousValue } = changes['value'];

            const { from, to } = this.form.value;

            const conditionEqualValue = !isEqual(currentValue, previousValue);

            const conditionEqualForm = !isEqual([from, to], currentValue);

            if (conditionEqualValue && conditionEqualForm) {
                const parsedValue = changes['value'].firstChange
                    ? this.value
                    : [from, to];

                const value = this.toRangeValue(parsedValue);

                this.patchRangeForm(value);
            }
        }
    }

    ngOnInit(): void {
        let isShownErrors = false;

        this.subscriptions = [
            this.form.valueChanges.subscribe(() => {
                const { from, to } = this.form.value;

                const emitCondition =
                    isExistValue(from) &&
                    isExistValue(to) &&
                    this.isValidForm();

                this.value = emitCondition ? [from, to] : null;

                isShownErrors = false;

                this.changeData.emit(this.value);
            }),

            showErrorsFor.subscribe(() => {
                if (isNull(this.value) && !isShownErrors) {
                    isShownErrors = true;

                    this.formService.showLocalErrors(this.form);

                    if (this.toControl.hasError(validationMessageRange)) {
                        this.popup.active = true;
                    }
                }
            }),
        ];
    }

    toRangeValue(value: Tupple): Tupple {
        return (Array.isArray(value) ? value.slice(0, 2) : ['', '']) as Tupple;
    }

    isValidForm(): boolean {
        const to = this.getValue(this.toControl.value);
        const from = this.getValue(this.fromControl.value);

        return to > from;
    }

    changeFromTime(event: any): void {
        this.patchRangeForm(event);
    }

    patchRangeForm(value: any): void {
        this.form.patchValue({
            from: value[0],
            to: value[1],
        });
    }

    getValue(value: string): number {
        return this.type === 'date' ? new Date(value).getTime() : Number(value);
    }
}
