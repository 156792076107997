import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { PopupModule } from '../popup/popup.module';
import { DropdownComponent } from './dropdown.component';
import { StickToDirectiveModule } from './stick-to.directive';
import { SyncWidthDirectiveModule } from './sync-width.directive';

export * from './dropdown.component';
export * from './dropdown.interfaces';

@NgModule({
    imports: [
        CommonModule,
        PopupModule,
        StickToDirectiveModule,
        SyncWidthDirectiveModule,
    ],
    exports: [DropdownComponent],
    declarations: [DropdownComponent],
})
export class DropdownModule {}
