import { FormArray } from '@angular/forms';
import { StaticCustomType } from '../types/static.type';
import { CoreCustomForm } from './core-custom-form';

export class CoreCustomFormArray<
    T,
    U extends keyof T,
    V extends Exclude<keyof T, U>,
    S extends Exclude<keyof T, V | U> = never,
> extends FormArray<CoreCustomForm<T, U, V, S>> {
    constructor(form: StaticCustomType<T, U, V, S>[]) {
        super(
            form.map(
                (customForm) => new CoreCustomForm<T, U, V, S>(customForm),
            ),
        );
    }
}
