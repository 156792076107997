import { FormControl, FormGroup } from '@angular/forms';
import { ConstructForm, Options } from '../types/options.type';
import { CoreFormControl } from '../types/core-form-control.type';

export class CoreFormGroup<T, U extends keyof T = keyof T> extends FormGroup<
    CoreFormControl<T>
> {
    constructor(formObject: ConstructForm<T>) {
        let form: CoreFormControl<T> = {} as any;

        Object.entries<Options<T[U]>>(formObject as any).forEach(
            ([key, [value, anotherOptions]]) => {
                form[key as U] = new FormControl(value, anotherOptions);
            },
        );

        super(form);
    }
}
