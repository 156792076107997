import { CommonModule } from '@angular/common';
import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';

import { SelectSuffixComponent } from './select-suffix.component';

export * from './select-suffix.component';

@NgModule({
    imports: [CommonModule],
    exports: [SelectSuffixComponent],
    declarations: [SelectSuffixComponent],
    schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class SelectSuffixModule {}
