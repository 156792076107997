import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { SpinnerComponent } from './spinner.component';

export * from './spinner.component';

@NgModule({
    imports: [CommonModule],
    exports: [SpinnerComponent],
    declarations: [SpinnerComponent],
    schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class SpinnerModule {}
