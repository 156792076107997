import { Component, HostBinding, Input } from '@angular/core';

import { SortDirection } from '../../enums/sort';

@Component({
    selector: 'core-sort-indicator',
    templateUrl: './sort-indicator.component.html',
    styleUrls: ['./sort-indicator.component.less'],
})
export class SortIndicatorComponent {
    @Input()
    @HostBinding('class')
    direction?: SortDirection | null;
}
