export const flash = [{
  offset: 0,
  opacity: '1'
}, {
  offset: 0.25,
  opacity: '0'
}, {
  offset: 0.5,
  opacity: '1'
}, {
  offset: 0.75,
  opacity: '0'
}, {
  offset: 1,
  opacity: '1'
}];