import { Component, Input } from '@angular/core';
import { AbstractControl, UntypedFormArray } from '@angular/forms';
import { isUndefined } from 'lodash';

import { DataOptionFilter } from '../../../interfaces/data-option';
import { Form } from '../../../modules/form/form.service';
import { DropdownComponent } from '../../ui-kit-components/dropdown/dropdown.component';
import { LocalFiltersService } from '../services/local-filters.service';
import { BorderType, DataOptionFilterMapped, FilterType } from '../tools/types';

@Component({
    selector: 'core-filter-row',
    templateUrl: './filter-row.component.html',
    styleUrls: ['./filter-row.component.less'],
})
export class FilterRowComponent {
    @Input()
    localForm!: UntypedFormArray;

    isHiddenActionButtons: boolean = true;

    readonly FilterType = FilterType;

    constructor(public localFiltersService: LocalFiltersService) {}

    addRule(
        index: number,
        filter: AbstractControl,
        dropdown: DropdownComponent,
    ): void {
        const ruleForm = this.localFiltersService.defaultRuleForm();

        this.addEntity(filter, ruleForm, dropdown, index);
    }

    addGroup(
        index: number,
        filter: AbstractControl,
        dropdown: DropdownComponent,
    ): void {
        const groupForm = this.localFiltersService.defaultGroupForm();

        this.addEntity(filter, groupForm, dropdown, index);
    }

    isRule(filter: DataOptionFilter): boolean {
        return !this.isGroup(filter);
    }

    isGroup(filter: DataOptionFilter): boolean {
        return 'child' in filter;
    }

    removeControl(index: number): void {
        this.localForm.removeAt(index);
    }

    selectBorderType(index: number): BorderType | '' {
        const currentControl = this.localForm.at(index);

        if (isUndefined(currentControl)) return '';

        if (this.isRule(currentControl.value)) {
            const nextElement = this.localForm.at(index + 1)?.value;
            const isRuleNextElement = nextElement
                ? this.isRule(nextElement)
                : false;

            const previousElement = this.localForm.at(index - 1)?.value;
            const isRulePreviousElement = previousElement
                ? this.isRule(previousElement)
                : false;

            switch (true) {
                case index === 0: {
                    if (isRuleNextElement) return BorderType.First;

                    break;
                }
                case index === this.localForm.length - 1: {
                    if (isRulePreviousElement) return BorderType.Last;

                    break;
                }
                default: {
                    switch (true) {
                        case isRulePreviousElement && isRuleNextElement: {
                            return BorderType.Middle;
                        }
                        case isRulePreviousElement && !isRuleNextElement: {
                            return BorderType.NextGroup;
                        }
                        case !isRulePreviousElement && isRuleNextElement: {
                            return BorderType.First;
                        }
                        default:
                            return '';
                    }
                }
            }
        }

        return '';
    }

    private addEntity(
        filter: AbstractControl,
        form: Form<DataOptionFilterMapped>,
        dropdown: DropdownComponent,
        index: number,
    ): void {
        if (this.isGroup(filter.value)) {
            (filter.get('child')! as UntypedFormArray).push(form);
        } else {
            this.localForm.insert(index + 1, form);
        }

        dropdown.hide.emit();
    }
}
