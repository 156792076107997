import { Inject, Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { DatasetState, DatasetType } from '../enums/dataset';
import { Column, Dataset, DatasetListItem } from '../interfaces/dataset';
import { RequestArray, ResponseArray } from '../interfaces/rest-api';
import { CoreApi } from '../modules/rest/api-injectors';
import { RestService } from '../modules/rest/rest.service';

@Injectable({ providedIn: 'root' })
export class DatasetApiService {
    constructor(@Inject(CoreApi) private restService: RestService) {}

    list(
        query: RequestDatasetList = {},
    ): Observable<ResponseArray<DatasetListItem>> {
        return this.restService.get('/dataset', query);
    }

    // TODO: в менеджере лежит такой же сервис
    get(id: number, params?: RequestDatasetParams): Observable<Dataset> {
        return this.restService.get(`/dataset/${id}`, params);
    }
}

export interface RequestDatasetList extends Omit<RequestArray, 'search'> {
    name?: string;
    dataset_type?: DatasetType;
    include_ids?: number[];
    state?: DatasetState;
    load_columns?: boolean;
}

export enum RequestDatasetOrder {
    Default = 'default',
    ByType = 'base_type',
}

export interface RequestDatasetParams {
    order_columns?: RequestDatasetOrder;
}

export type ChangeColumnRequest = Pick<Column, 'id' | 'name'>;
