<div
    *ngFor="let filter of localForm.controls; index as index; last as last"
    cdkDrag
    class="filter-rows"
    [class.margin]="isGroup(filter.value) && !last"
    [cdkDragData]="filter"
    (cdkDragMoved)="localFiltersService.dragMoved($event)"
>
    <ng-container *ngIf="filter.value.child">
        <div
            class="left-border"
            [class.hidden-border]="
                !details.isShowContent || !filter.value.child.length
            "
        ></div>

        <core-filter-details #details [filter]="filter">
            <ng-container
                *ngTemplateOutlet="logicalButton"
                logical-button
            ></ng-container>

            <filter-action-buttons
                action-buttons
                [popupCondition]="true"
                [borderElement]="details.group"
                (addRule)="addRule(index, filter, $event)"
                (addGroup)="addGroup(index, filter, $event)"
                (remove)="removeControl(index)"
            ></filter-action-buttons>

            <sl-icon
                drag-button
                cdkDragHandle
                library="platform"
                name="filters/drag"
            ></sl-icon>
        </core-filter-details>
    </ng-container>

    <core-filter-rule
        #filterRule
        *ngIf="isRule(filter.value)"
        [isLast]="last"
        [filter]="filter"
        [borderType]="selectBorderType(index)"
        (deleteFilter)="removeControl(index)"
    >
        <ng-container
            *ngTemplateOutlet="logicalButton"
            logical-button
        ></ng-container>

        <filter-action-buttons
            action-buttons
            [borderElement]="filterRule.rule"
            (addRule)="addRule(index, filter, $event)"
            (addGroup)="addGroup(index, filter, $event)"
            (remove)="removeControl(index)"
        ></filter-action-buttons>

        <sl-icon
            drag-button
            cdkDragHandle
            library="platform"
            name="filters/drag"
        ></sl-icon>
    </core-filter-rule>

    <ng-template #logicalButton>
        <core-logical-button
            [class.last]="last"
            [isLast]="last"
            [logicalControl]="filter.get('logical')!"
        ></core-logical-button>
    </ng-template>
</div>
