import {
    Component,
    EventEmitter,
    Injectable,
    Input,
    Output,
    forwardRef,
} from '@angular/core';
import {
    ControlComponent,
    ControlComponentRef,
} from '../../../modules/form/control-component';

@Component({
    selector: 'core-checkbox',
    templateUrl: './checkbox.component.html',
    styleUrls: ['./checkbox.component.less'],
    providers: [
        {
            provide: ControlComponentRef,
            useValue: forwardRef(() => CheckboxComponent),
        },
    ],
})
@Injectable()
export class CheckboxComponent implements ControlComponent {
    @Input()
    label = '';

    @Input()
    checked = false;

    @Input()
    disabled = false;

    @Output()
    changeValue = new EventEmitter<boolean>();

    setValue(next: any): void {
        this.checked = Boolean(next);
    }
}
