import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { ShoelaceModule } from 'shoelace-style-angular';

import { TestIdDirectiveModule } from '../../directives/test-id.directive';

import { ActionComponent } from './action.component';

export * from './action.component';

@NgModule({
    imports: [ShoelaceModule, TestIdDirectiveModule],
    exports: [ActionComponent],
    declarations: [ActionComponent],
    schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class ActionModule {}
