<core-form [data]="control" [ngSwitch]="columnType">
    <div *ngSwitchCase="ColumnType.String" class="content">
        <plmt-arounds></plmt-arounds>

        <plmt-null-formatting></plmt-null-formatting>
    </div>

    <div *ngSwitchCase="ColumnType.Number" class="content">
        <core-validate
            [serverErrorsMap]="{
                gte: '_$$.columnFormatting.validation.toFixed',
                lte: '_$$.columnFormatting.validation.toFixed'
            }"
        >
            <plmt-to-fixed></plmt-to-fixed>
        </core-validate>

        <plmt-select
            type="number"
            name="digits_separate_type"
            [label]="'_$$.columnFormatting.label.abbr' | translate"
            [value]="0"
        >
            <sl-option [value]="0">
                {{ '_$$.columnFormatting.abbr.no' | translate }}
            </sl-option>

            <sl-option *ngFor="let abbr of numberAbbrs" [value]="abbr">
                {{ '$.format.numberAbbr.' + abbr | translate }}
            </sl-option>
        </plmt-select>

        <plmt-arounds></plmt-arounds>

        <sl-checkbox size="small" name="separate_digits">
            {{ '_$$.columnFormatting.label.grouping' | translate }}
        </sl-checkbox>

        <plmt-null-formatting></plmt-null-formatting>
    </div>

    <div *ngSwitchCase="ColumnType.Date" class="content">
        <plmt-arounds></plmt-arounds>

        <label>
            {{ '_$$.columnFormatting.label.dateFormatting' | translate }}
        </label>

        <plmt-date-formatting></plmt-date-formatting>

        <plmt-null-formatting></plmt-null-formatting>
    </div>

    <div *ngSwitchCase="ColumnType.Boolean" class="content">
        <sl-input
            name="bool_true_format"
            [label]="'_$$.columnFormatting.label.true' | translate"
            [placeholder]="
                '_$$.columnFormatting.label.true.placeholder' | translate
            "
        >
        </sl-input>

        <sl-input
            name="bool_false_format"
            [label]="'_$$.columnFormatting.label.false' | translate"
            [placeholder]="
                '_$$.columnFormatting.label.false.placeholder' | translate
            "
        >
        </sl-input>

        <plmt-null-formatting></plmt-null-formatting>
    </div>
</core-form>
