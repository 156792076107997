import { NgModule } from '@angular/core';
import { TranslateModule } from '../../modules/translate/translate.module';

import { EmptyStateComponent } from './empty-state.component';

export * from './empty-state.component';

@NgModule({
    declarations: [EmptyStateComponent],
    exports: [EmptyStateComponent],
    imports: [TranslateModule],
})
export class EmptyStateModule {}
