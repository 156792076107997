import { Component, NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';

import { AuthTokenPageGuard } from './auth-token-page.guard';

@Component({
    selector: 'core-auth-token-page',
    template: '',
})
export class AuthTokenPageComponent {}

@NgModule({
    imports: [
        RouterModule.forChild([
            {
                path: '',
                canActivate: [AuthTokenPageGuard],
                component: AuthTokenPageComponent,
            },
        ]),
    ],
    declarations: [AuthTokenPageComponent],
})
export class AuthTokenPageModule {}
