import {
    Component,
    ElementRef,
    EventEmitter,
    Output,
    ViewChild,
} from '@angular/core';
import { SlDialog } from '@shoelace-style/shoelace';

@Component({
    selector: 'app-close-confirm',
    templateUrl: './close-confirm.component.html',
    styleUrls: ['./close-confirm.component.less'],
})
export class CloseConfirmComponent {
    @Output()
    onClose = new EventEmitter<void>();

    @ViewChild('dialog', { static: true })
    private dialogRef!: ElementRef<SlDialog>;

    show(): void {
        this.dialogRef.nativeElement.show();
    }
}
