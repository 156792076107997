import { Component, Input } from '@angular/core';

import { getHost } from '../../tools/get-host';

@Component({
    selector: 'core-error-state',
    templateUrl: './error-state.component.html',
    styleUrls: ['./error-state.component.less'],
})
export class ErrorStateComponent {
    @Input()
    set code(code: number) {
        switch (code) {
            case 403:
            case 404:
            case 500:
            case 503:
                this.image = `assets/core-library/images/${code}.svg`;
                this.title = `_$.errorState.${code}.title`;
                this.description = `_$.errorState.${code}.description`;

                this.host.style.display = '';
                break;
            default:
                this.host.style.display = 'none';
        }
    }

    image = '';
    title = '';
    description = '';

    private readonly host = getHost();
}
