import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { TagComponent } from './tag.component';
import { OverflowModule } from '../../overflow/overflow.module';

export * from './tag.component';

@NgModule({
    imports: [CommonModule, OverflowModule],
    exports: [TagComponent],
    declarations: [TagComponent],
})
export class TagModule {}
