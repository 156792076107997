import { Component } from '@angular/core';
import { PageService } from '../../services/page.service';
import { Router } from '@angular/router';

@Component({
    selector: 'core-page-error',
    templateUrl: './page-error.component.html',
    styleUrls: ['./page-error.component.less'],
})
export class PageErrorComponent {
    status: number;

    constructor(private pageService: PageService, private router: Router) {
        const currentNavigation = this.router.getCurrentNavigation();

        this.pageService.loading = false;
        this.status = currentNavigation?.extras.state!.status;
    }
}
