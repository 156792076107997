import { Component, Input } from '@angular/core';

import { Column } from '../../interfaces/dataset';

@Component({
    selector: 'core-column-name',
    templateUrl: './column-name.component.html',
    styleUrls: ['./column-name.component.less'],
})
export class ColumnNameComponent {
    @Input()
    column!: Column;

    @Input()
    showErrorTooltip = false;
}
