import {
    AfterViewInit,
    Component,
    ElementRef,
    EventEmitter,
    Output,
    ViewChild,
} from '@angular/core';
import { SlAlert, SlButton, SlCheckbox } from '@shoelace-style/shoelace';
import { SubscribableComponent } from 'ngx-subscribable';
import { catchError, finalize, merge, of, switchMap, take, tap } from 'rxjs';

import { AlertService } from '../../../../alert/alert.service';
import { FormService } from '../../../../../modules/form/form.service';
import { ConfirmComponent } from '../../../../confirm/confirm.component';
import { NotificationsApiService } from '../../api/notifications-api.service';

@Component({
    selector: 'lib-settings',
    templateUrl: './settings.component.html',
    styleUrls: ['./settings.component.less'],
})
export class SettingsComponent
    extends SubscribableComponent
    implements AfterViewInit
{
    @Output()
    onClear = new EventEmitter<void>();

    @Output()
    onBack = new EventEmitter<void>();

    form = this.formService
        .create<DisableNotificationForm>({
            disable: [],
        })
        .setup({
            method: ({ disable }) =>
                this.notificationsApiService.disableNotifications(disable),
            success: () => {
                const key =
                    '_$.navigation.notification.settings.disabled.' +
                    this.form.get('disable')?.value;
                this.alertService.show.emit({
                    variant: 'success',
                    key,
                }),
                    this.onBack.emit();
            },
        });

    @ViewChild('clearConfirmation', { static: true })
    private clearConfirmation!: ConfirmComponent;

    @ViewChild('disableCheckbox', { static: true })
    private disableCheckboxRef!: ElementRef<SlCheckbox>;

    constructor(
        private alertService: AlertService,
        private formService: FormService,
        private notificationsApiService: NotificationsApiService,
    ) {
        super();
    }

    ngAfterViewInit(): void {
        const button = this.disableCheckboxRef.nativeElement;

        button.disabled = true;

        this.notificationsApiService
            .isNotificationDisabled()
            .subscribe((disable) => {
                button.disabled = false;

                this.form.patchValue({ disable });
            });
    }

    touch(button: SlButton): void {
        button.disabled = true;

        this.notificationsApiService
            .touch()
            .pipe(
                finalize(() => {
                    button.disabled = false;
                }),
            )
            .subscribe({
                next: () =>
                    this.alertService.show.emit({
                        variant: 'success',
                        key: '_$.navigation.notification.settings.touch.success',
                    }),
                error: () =>
                    this.alertService.show.emit({
                        key: '_$.navigation.notification.settings.touch.error',
                    }),
            });
    }

    clear(button: SlButton): void {
        button.disabled = true;

        const { ok, cancel } = this.clearConfirmation;

        const clearing = ok.pipe(
            switchMap(() =>
                this.notificationsApiService.clear().pipe(
                    tap(() => {
                        this.alertService.show.emit({
                            variant: 'success',
                            key: '_$.navigation.notification.settings.clear.success',
                        });

                        this.onClear.emit();
                    }),
                    catchError(() => {
                        this.alertService.show.emit({
                            key: '_$.navigation.notification.settings.clear.error',
                        });

                        return of(0);
                    }),
                ),
            ),
        );

        merge(clearing, cancel)
            .pipe(take(1))
            .subscribe(() => {
                button.disabled = false;
            });

        this.clearConfirmation.show();
    }
}

interface DisableNotificationForm {
    disable: boolean;
}

interface Notify {
    variant: SlAlert['variant'];
    content: string;
}
