import { divider_styles_default } from "./chunk.SUSCR7CI.js";
import { watch } from "./chunk.2FB5TK5H.js";
import { component_styles_default } from "./chunk.TUVJKY7S.js";
import { ShoelaceElement } from "./chunk.SFSTXCXC.js";
import { __decorateClass } from "./chunk.IFDWM6P4.js";

// src/components/divider/divider.component.ts
import { property } from "lit/decorators.js";
var SlDivider = class extends ShoelaceElement {
  constructor() {
    super(...arguments);
    this.vertical = false;
  }
  connectedCallback() {
    super.connectedCallback();
    this.setAttribute("role", "separator");
  }
  handleVerticalChange() {
    this.setAttribute("aria-orientation", this.vertical ? "vertical" : "horizontal");
  }
};
SlDivider.styles = [component_styles_default, divider_styles_default];
__decorateClass([property({
  type: Boolean,
  reflect: true
})], SlDivider.prototype, "vertical", 2);
__decorateClass([watch("vertical")], SlDivider.prototype, "handleVerticalChange", 1);
export { SlDivider };