import { Component, HostBinding, Input, OnInit } from '@angular/core';
import { AbstractControl } from '@angular/forms';
import { not } from 'logical-not';
import { WatchChanges } from 'ng-onpush';
import { FromChild, ToParent } from 'ng-to-parent';
import { SubscribableComponent } from 'ngx-subscribable';
import { distinctUntilChanged, filter, merge } from 'rxjs';
import { enumToArray } from 'ts-enum-to-array';

import { ValidationErrorKey } from '../../../enums/validation-error-key';
import { ServerValidationError } from '../../../interfaces/rest-api';
import { getFormControl } from '../../../tools/internal.get-form-control';
import { onDestroy } from '../../../tools/on-destroy';
import { PluralService } from '../../translate/plural.service';
import { TranslateService } from '../../translate/translate.service';
import { maybeExtraControl, serverErrorKey, showErrorsFor } from '../internal';
import { validatable } from '../provide-control';

export interface ServerErrorHandler {
    (serverError: ServerValidationError): { key: string; params?: any } | null;
}

@Component({
    selector: 'core-validate',
    templateUrl: './validate.component.html',
    styleUrls: ['./validate.component.less'],
    providers: [FromChild, ToParent],
})
export class ValidateComponent extends SubscribableComponent implements OnInit {
    @Input()
    serverErrorsHandler?: ServerErrorHandler;

    @Input()
    serverErrorsMap?: Record<string, string>;

    @Input()
    noMessage: boolean | '' = false;

    @HostBinding('class.errors')
    @WatchChanges()
    hasErrors = false;

    readonly coreValidationKeys = enumToArray(ValidationErrorKey);

    message = '';
    params: any;

    private form!: AbstractControl;
    private control!: AbstractControl;

    get isNoMessage(): boolean {
        return this.noMessage === true || this.noMessage === '';
    }

    constructor(
        private fromChild: FromChild,
        private pluralService: PluralService,
        private toParent: ToParent,
        private translateService: TranslateService,
    ) {
        super();
    }

    ngOnInit(): void {
        this.subscriptions = [
            this.fromChild.listen(validatable).subscribe((control) => {
                if (not(this.control)) this.linkWithControl(control);
            }),
        ];
    }

    private linkWithControl(control: AbstractControl): void {
        this.form = getFormControl(control);
        this.control = control;

        this.toParent.send(maybeExtraControl, {
            form: this.form,
            control: this.control,

            onTrue: (dispose) => this.subscriptions.push(onDestroy(dispose)),
        });

        this.subscriptions.push(
            merge(
                control.statusChanges.pipe(distinctUntilChanged()),
                control.valueChanges,
            ).subscribe(() => {
                if (this.hasErrors) this.hasErrors = false;
            }),

            showErrorsFor
                .pipe(
                    filter(({ form, currentControl }) => {
                        return form === this.form || currentControl;
                    }),
                    filter(() => control.invalid),
                )
                .subscribe(() => {
                    if (this.isNoMessage) this.hasErrors = true;
                    else this.showMessage();
                }),
        );
    }

    private showMessage(): void {
        const errors = this.control!.errors!;

        if (serverErrorKey in errors) {
            const [message, paramsSource] = this.processServerError(
                errors[serverErrorKey],
            );

            this.message = message;
            this.params = this.getParams(paramsSource);
        } else {
            const key = this.coreValidationKeys.find((key) => key in errors);

            if (key) {
                this.message = `_$.validate.${key}`;

                switch (key) {
                    case ValidationErrorKey.Required:
                        this.params = errors[key];
                        break;
                    case ValidationErrorKey.Min:
                        this.params = {
                            condition: errors[key].min,
                        };
                        break;
                    case ValidationErrorKey.Max:
                        this.params = {
                            condition: errors[key].max,
                        };
                        break;
                    case ValidationErrorKey.Symbols:
                        const condition = errors[key] as string[];

                        this.params = {
                            condition: condition
                                .map((item) => {
                                    const regExp = /\s/;

                                    return `'${
                                        regExp.test(item)
                                            ? this.translateService.getTranslation(
                                                  '_$.symbol.space',
                                              )
                                            : item
                                    }'`;
                                })
                                .join(', '),
                        };
                        break;
                    case ValidationErrorKey.MinDate:
                    case ValidationErrorKey.MaxDate:
                        this.params = {
                            condition: errors[key].requiredValue,
                        };
                        break;
                    case ValidationErrorKey.MaxLength:
                    case ValidationErrorKey.MinLength:
                        this.params = {
                            condition: this.pluralService.pluralize(
                                `$.symbol(s)`,
                                errors[key].requiredLength,
                            ),
                        };
                        break;
                }
            } else {
                const [[key, paramsSource]] = Object.entries(errors);

                this.message = key;
                this.params = this.getParams(paramsSource);
            }
        }

        this.hasErrors = true;
    }

    private processServerError(error: ServerValidationError): [string, any] {
        if (this.serverErrorsHandler) {
            const handled = this.serverErrorsHandler(error);

            if (handled) return [handled.key, handled.params];
        }

        const { key, condition } = error;

        return [
            this.serverErrorsMap?.[key] || `_$.validate.server.${key}`,
            condition,
        ];
    }

    private getParams(source: any): any {
        if (not(source)) return {};

        return typeof source === 'object' ? source : {};
    }
}
