import { AbstractControl, ValidatorFn } from '@angular/forms';

export interface StringValidatorParams {
    min?: number;
    max?: number;
}

export interface StringValidatorMinLengthError {
    minlength: {
        requiredLength: number;
    };
}

export interface StringValidatorMaxLengthError {
    maxlength: {
        requiredLength: number;
    };
}

export type StringValidatorError =
    | StringValidatorMinLengthError
    | StringValidatorMaxLengthError;

export function stringValidator(params?: StringValidatorParams): ValidatorFn {
    const min = params?.min || 2;
    const max = params?.max || 50;

    return (control: AbstractControl): StringValidatorError | null => {
        if (control.value) {
            const value = control.value.trim();

            if (value.length < min) {
                return {
                    minlength: {
                        requiredLength: min,
                    },
                };
            } else if (value.length > max) {
                return {
                    maxlength: {
                        requiredLength: max,
                    },
                };
            }
        }

        return null;
    };
}
