import {
    Component,
    ElementRef,
    EventEmitter,
    Input,
    Output,
    ViewChild,
} from '@angular/core';
import { SlSelect } from '@shoelace-style/shoelace';
import { isNull } from 'lodash';
import { UpdateImmediately } from 'ng-onpush';
import { SubscribableComponent } from 'ngx-subscribable';
import { enumToArray } from 'ts-enum-to-array';

import { MaxDateValue } from '../../../constants/max-date-value';
import { DatePluralPeriod } from '../../../modules/date-plural/enums/date-plural-period';
import { FilterDateOffsetOperator } from '../enums/filter-date-offset';
import { getDate } from '../tools/filter-date-offset';
import { DateFormat } from '../../../enums/global-filter';
import { FilterDateOffset } from '../../../interfaces/filter-template';
import { FormService } from '../../../modules/form/form.service';
import { valueOf } from '../../../tools/value-of';

@Component({
    selector: 'plmt-filter-date-value-default',
    templateUrl: './filter-date-value-default.component.html',
    styleUrls: ['./filter-date-value-default.component.less'],
})
export class FilterDateValueDefaultComponent extends SubscribableComponent {
    @Input()
    label = '';

    @Input()
    dateFormat = DateFormat.Date;

    @Input()
    value: any;

    @Input()
    validationError = false;

    @Output()
    change = new EventEmitter<any>();

    @Output()
    changeOffset = new EventEmitter<FilterDateOffset | null | string>();

    @ViewChild('periodSelect')
    periodSelectRef!: ElementRef<SlSelect>;

    @UpdateImmediately()
    dateValue = '';

    hasOffset = false;

    operators: FilterDateOffsetOperator[] = enumToArray(
        FilterDateOffsetOperator,
    );
    periods: DatePluralPeriod[] = enumToArray(DatePluralPeriod);

    form = this.formService.form<FilterDateOffset>({
        operator: [],
        offset: [],
        period: [],
    });

    readonly maxDateValue = MaxDateValue;
    readonly DateFormat = DateFormat;

    constructor(private formService: FormService) {
        super();
    }

    ngOnInit(): void {
        if (this.dateFormat === DateFormat.Date) {
            this.periods.splice(0, 3);
        }
        if (typeof this.value === 'number') {
            this.patchFormDefaultValue();
            this.toggleOffsetChangeHandler();
        }

        if (typeof this.value === 'string' || isNull(this.value)) {
            this.patchFormDefaultValue();
        } else {
            this.hasOffset = true;

            if (!this.periods.includes(this.value.period)) {
                this.value.period = DatePluralPeriod.Day;
            }

            this.form.reset(this.value);
        }

        this.subscriptions = [
            this.form.valueChanges.subscribe((value) => {
                value.offset = Number(value.offset);
                this.dateValue = getDate(
                    value as FilterDateOffset,
                    this.dateFormat,
                );

                this.changeOffset.emit(this.form.getRawValue());
            }),
        ];
    }

    changeOffsetValue(event: Event): void {
        const { period } = this.form.getRawValue();
        const value = Number(valueOf(event));

        if (value < 0) {
            this.form.controls['offset'].patchValue(0);
        }

        if (value > 0 && !Number.isInteger(value)) {
            this.form.controls['offset'].patchValue(Math.trunc(value));
        }

        this.form.controls['period'].reset();

        setTimeout(() => {
            this.form.controls['period'].patchValue(period);
        }, 0);
    }

    toggleOffset(): void {
        this.hasOffset = !this.hasOffset;
        this.validationError = false;

        if (!this.hasOffset) {
            const value = typeof this.value === 'string' ? this.value : null;

            this.changeOffset.emit(value);
        }
    }

    private patchFormDefaultValue(): void {
        this.form.patchValue({
            operator: FilterDateOffsetOperator.Subtraction,
            offset: 1,
            period: DatePluralPeriod.Day,
        });
    }

    private toggleOffsetChangeHandler(): void {
        this.changeOffset.emit(this.value);
    }
}
