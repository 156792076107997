import {
    Component,
    ElementRef,
    Output,
    EventEmitter,
    ViewChild,
    OnDestroy,
    Input,
    HostListener,
} from '@angular/core';
import { SlCheckbox } from '@shoelace-style/shoelace';

@Component({
    selector: 'core-table-checkbox',
    templateUrl: './table-checkbox.component.html',
    styleUrls: ['./table-checkbox.component.less'],
})
export class TableCheckboxComponent implements OnDestroy {
    @Input()
    value: any;

    @Output()
    change = new EventEmitter<any | null>();

    @Output()
    destroy = new EventEmitter<void>();

    @ViewChild('checkbox')
    element!: ElementRef<SlCheckbox>;

    @HostListener('click', ['$event'])
    stopPropagation(event: Event): void {
        event.stopPropagation();
    }

    get checked(): boolean {
        return this.element.nativeElement.checked;
    }

    set checked(value: boolean) {
        this.element.nativeElement.checked = value;
    }

    set indeterminate(value: boolean) {
        this.element.nativeElement.indeterminate = value;
    }

    ngOnDestroy(): void {
        this.destroy.emit();
    }

    onChange(): void {
        this.change.emit(this.change ? this.value : null);
    }
}
