import {
    Directive,
    HostListener,
    Output,
    EventEmitter,
    NgModule,
} from '@angular/core';

@Directive({
    selector: '[outsideClick]',
})
export class OutsideClickDirective {
    @Output() clickOutside = new EventEmitter<void>();

    @HostListener('document:click', ['$event'])
    onClick(event: MouseEvent) {
        if (!(event.target as HTMLElement).closest('[outsideClick]')) {
            this.clickOutside.emit();
        }
    }
}

@NgModule({
    exports: [OutsideClickDirective],
    declarations: [OutsideClickDirective],
})
export class OutsideClickModule {}
