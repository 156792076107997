import { Component, Input, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { SubscribableComponent } from 'ngx-subscribable';

import { SortDirection } from '../../../enums/sort';
import { RequestArray } from '../../../interfaces/rest-api';

const orderDirectionKey: Extract<keyof RequestArray, 'order_direction'> =
    'order_direction';

const orderByKey: Extract<keyof RequestArray, 'order_by'> = 'order_by';

@Component({
    selector: 'core-table-sort[routing]',
    templateUrl: './table-sort-routing.component.html',
    styleUrls: ['./table-sort-routing.component.less'],
})
export class TableSortRoutingComponent
    extends SubscribableComponent
    implements OnInit
{
    @Input()
    by: string | null = null;

    direction: SortDirection | null = null;

    constructor(
        private activatedRoute: ActivatedRoute,
        private router: Router,
    ) {
        super();
    }

    ngOnInit(): void {
        this.subscriptions = [
            this.activatedRoute.queryParams.subscribe((params) => {
                if (params[orderByKey] === this.by) {
                    this.direction = params[orderDirectionKey] || null;
                } else {
                    this.direction = null;
                }
            }),
        ];
    }

    setQueryState(direction: SortDirection | null): void {
        this.router.navigate([], {
            queryParams: {
                order_direction: direction,
                order_by: direction ? this.by : null,
            },
            queryParamsHandling: 'merge',
        });
    }
}
