import {
    AfterViewInit,
    Component,
    EventEmitter,
    Input,
    Output,
} from '@angular/core';
import { not } from 'logical-not';
import { SubscribableComponent } from 'ngx-subscribable';
import { fromEvent, Observable } from 'rxjs';

import { StorageApiService } from '../../../../../api/storage-api.service';
import { ModuleName } from '../../../../../enums/module';
import { ResponseArray } from '../../../../../interfaces/rest-api';
import { ModulesRouterService } from '../../../../../services/modules-router.service';
import { Debounce } from '../../../../../tools/debounce';
import { getHost } from '../../../../../tools/get-host';
import { resizeObserver } from '../../../../../tools/resize-observer';
import { NotificationsApiService } from '../../api/notifications-api.service';
import { EventLevel } from '../../enums/event-level';
import { EventType } from '../../enums/event-type';
import {
    Notification,
    NotificationDownloadable,
    NotificationEventDataVariant,
    NotificationFor,
} from '../../interfaces/notification';
import { DownloadedFilesService } from '../../../../../services/downloaded-files.service';

@Component({
    selector: 'plmt-messages',
    templateUrl: './messages.component.html',
    styleUrls: ['./messages.component.less'],
})
export class MessagesComponent
    extends SubscribableComponent
    implements AfterViewInit
{
    @Input()
    items!: ResponseArray<Notification>;

    @Input()
    loadMore!: () => Observable<any>;

    @Output()
    touched = new EventEmitter<number>();

    @Output()
    delete = new EventEmitter<number>();

    readonly EventType = EventType;
    readonly EventLevel = EventLevel;
    readonly ModuleName = ModuleName;

    readonly is = {
        [EventType.DatasetLoaded]: (
            notification: Notification,
        ): notification is NotificationFor<EventType.DatasetUpdated> =>
            notification.event_type === EventType.DatasetLoaded,

        [EventType.DatasetLocalGc]: (
            notification: Notification,
        ): notification is NotificationFor<EventType.DatasetLocalGc> =>
            notification.event_type === EventType.DatasetLocalGc,

        [EventType.DatasetUpdated]: (
            notification: Notification,
        ): notification is NotificationFor<EventType.DatasetUpdated> =>
            notification.event_type === EventType.DatasetUpdated,

        [EventType.LogsExported]: (
            notification: Notification,
        ): notification is NotificationFor<EventType.LogsExported> =>
            notification.event_type === EventType.LogsExported,

        [EventType.ProjectExported]: (
            notification: Notification,
        ): notification is NotificationFor<EventType.ProjectExported> =>
            notification.event_type === EventType.ProjectExported,

        [EventType.ReportReady]: (
            notification: Notification,
        ): notification is NotificationFor<EventType.ReportReady> =>
            notification.event_type === EventType.ReportReady,

        [EventType.WidgetExported]: (
            notification: Notification,
        ): notification is NotificationFor<EventType.WidgetExported> =>
            notification.event_type === EventType.WidgetExported,

        [EventType.EntityExported]: (
            notification: Notification,
        ): notification is NotificationFor<EventType.EntityExported> =>
            notification.event_type === EventType.EntityExported,

        [EventType.ImportFinished]: (
            notification: Notification,
        ): notification is NotificationFor<EventType.ImportFinished> =>
            notification.event_type === EventType.ImportFinished,
    } as const;

    private readonly host = getHost();

    private scrolledTo = 0;
    private marked = new Set<number>();

    constructor(
        private notificationsApiService: NotificationsApiService,
        private modulesRouterService: ModulesRouterService,
        private storageApiService: StorageApiService,
        private downloadedFilesService: DownloadedFilesService,
    ) {
        super();
    }

    ngAfterViewInit(): void {
        const { host } = this;

        this.subscriptions = [
            fromEvent(host, 'scroll').subscribe(() =>
                this.processViewedMessages(),
            ),

            resizeObserver(host).subscribe(({ height }) => {
                if (height > 0) this.processViewedMessages();
            }),
        ];
    }

    getTitle({ event_level, event_type }: Notification): string {
        if (event_level === EventLevel.Error)
            return '_$.navigation.notification.error.' + event_type;

        return '_$.navigation.notification.' + event_type;
    }

    goTo(module: ModuleName, path: string): void {
        this.modulesRouterService.navigate(module, path);
    }

    download({ storage_path, filename }: NotificationDownloadable): void {
        this.storageApiService
            .download(storage_path, filename)
            .subscribe(() => {
                this.downloadedFilesService.fileDownloaded(storage_path);
            });
    }

    getFileNameForExport(
        event_data: NotificationEventDataVariant[EventType.EntityExported],
    ): string {
        return `export_${event_data.entity_type}.zip`;
    }

    private processViewedMessages(): void {
        const { clientHeight, scrollTop } = this.host;

        this.scrolledTo = Math.max(scrollTop + clientHeight, this.scrolledTo);

        this.markAsViewed();
    }

    @Debounce(300)
    private markAsViewed(): void {
        const {
            items: { rows: items },
            host,
            host: { children },
            marked,
            scrolledTo,
        } = this;

        const markAsViewedIds: number[] = [];

        const hostOffset = host.offsetTop;

        for (let i = 0, lim = children.length; i < lim; i++) {
            const element = children[i] as HTMLElement;

            if (not(element.classList.contains('item'))) break;

            const { id, is_viewed } = items[i];

            if (is_viewed) continue;

            if (marked.has(id)) continue;

            const offset =
                element.offsetTop + element.clientHeight - hostOffset;

            if (offset > scrolledTo) break;

            markAsViewedIds.push(id);
        }

        if (markAsViewedIds.length === 0) return;

        for (let id of markAsViewedIds) marked.add(id);

        this.touched.emit(markAsViewedIds.length);

        this.notificationsApiService.touch(markAsViewedIds).subscribe();
    }
}
