import { Component, Input, SimpleChanges } from '@angular/core';
import { SlAvatar } from '@shoelace-style/shoelace';
import { not } from 'logical-not';

import { User, UserPublic } from '../../interfaces/user';
import { StorageApiService } from '../../api/storage-api.service';

export type UserAvatar = Pick<
    User,
    'first_name' | 'last_name' | 'middle_name' | 'image_path' | 'username'
>;

@Component({
    selector: 'core-avatar',
    templateUrl: './avatar.component.html',
    styleUrls: ['./avatar.component.less'],
})
export class AvatarComponent {
    @Input()
    user?: User | UserPublic | UserAvatar;

    @Input()
    path = '';

    @Input()
    size = 28;

    @Input()
    shape: SlAvatar['shape'] = 'circle';

    img = '';

    constructor(private storageApiService: StorageApiService) {}

    ngOnChanges(simpleChanges: SimpleChanges) {
        if ('user' in simpleChanges) {
            const path = this.user?.image_path;

            this.img = path ? this.storageApiService.resolve(path) : '';
        }

        if ('path' in simpleChanges) {
            this.img = this.path
                ? this.storageApiService.resolve(this.path)
                : '';
        }
    }

    get initials(): SlAvatar['initials'] {
        const result: string[] = [];

        if (not(this.user)) return '';

        if (this.user.last_name) {
            result.push(this.user.last_name);
        }

        if (this.user.first_name) {
            result.push(this.user.first_name);
        }

        if (result.length) {
            if (result.length > 1) {
                return result.map((item) => item[0]).join('');
            }
            return result[0].slice(0, 2);
        }

        if ('username' in this.user) {
            return this.user.username.slice(0, 2);
        }

        return '';
    }
}
