export interface Assert {
    (condition: () => unknown): void;
}

const PROD = typeof (window as any).ng === 'undefined';

export const assert: Assert = PROD
    ? () => {}
    : (condition) => {
          if (condition()) return;

          throw 'неправильное поведение';
      };
