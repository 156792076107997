<sl-tooltip
    hoist
    placement="top"
    [content]="'_$$.filters.tooltip.hasTime' | translate"
>
    <core-button
        icon
        variant="outline"
        [active]="hasTime"
        (click)="hasTime = !hasTime"
    >
        <material-icon>access_time</material-icon>
    </core-button>
</sl-tooltip>
