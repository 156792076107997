import { ElementRef, Injectable, Optional } from '@angular/core';
import { AbstractControl, Validators } from '@angular/forms';
import { not } from 'logical-not';
import { ToParent } from 'ng-to-parent';
import { SubscribableService } from 'ngx-subscribable';
import { finalize, Observable, Subject, Subscription } from 'rxjs';

import {
    provideControlByName,
    validatable,
} from '../modules/form/provide-control';
import { requiredIf } from '../validators/required-if.validator';
import { required } from '../validators/required.validator';

export type FormControlName = string | (string | number)[];

export interface FormControlAdapter {
    createValueStream(): Observable<any>;
    setValue(value: any): void;

    requiredCallback?(): void;
}

const inited = Symbol();
const requiredValidators = [required, requiredIf, Validators.required];

@Injectable({ providedIn: 'root' })
export class FormControlService extends SubscribableService {
    constructor(
        @Optional() hostRef: ElementRef<HTMLElement>,
        @Optional() private toParent: ToParent,
    ) {
        super();

        if (not(hostRef))
            throw new Error(`FormControlService must be provided in component`);

        if (not(toParent)) throw new Error(`ToParent not provided`);
    }

    getControl(
        name: FormControlName,
        callback: (control: AbstractControl) => void,
    ): void {
        this.toParent.send(provideControlByName, {
            path: Array.isArray(name) ? name.join('.') : name,
            provider: callback,
        });
    }

    provide(
        control: AbstractControl | FormControlName,
        createAdapter: () => FormControlAdapter,
    ): void {
        if (control instanceof AbstractControl)
            this.connect(control, createAdapter());
        else
            this.getControl(control, (control) =>
                this.connect(control, createAdapter()),
            );
    }

    private connect(
        control: AbstractControl,
        adapter: FormControlAdapter,
    ): void {
        this.verify(control);

        const required = requiredValidators.some((validator) =>
            control.hasValidator(validator),
        );

        if (required) adapter.requiredCallback?.();

        adapter.setValue(control.value);

        let ignore = false;

        this.subscriptions = [
            control.valueChanges.subscribe((value) => {
                if (ignore) ignore = false;
                else adapter.setValue(value);
            }),

            adapter.createValueStream().subscribe((value) => {
                if (value !== control.value) {
                    ignore = true;

                    control.patchValue(value);
                }
            }),

            onDestroy(() => {
                delete (control as any)[inited];
            }),
        ];

        this.toParent.send(validatable, control);
    }

    private verify(control: any): void | never {
        if (control[inited])
            throw new Error('use [name] or [control], not both');

        control[inited] = true;
    }
}

function onDestroy(callback: () => void): Subscription {
    return new Subject().pipe(finalize(callback)).subscribe();
}
