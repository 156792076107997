import { FormArray, FormControl, FormGroup } from '@angular/forms';
import { CustomFormType } from '../types/custom.type';
import { StaticCustomType } from '../types/static.type';
import { CoreFormControl } from '../types/core-form-control.type';
import { CoreFormGroup } from './core-form-group';
import { CoreFormArray } from './core-form-array';
import { isNull } from 'lodash';
import { ConstructForm, Options } from '../types/options.type';

export class CoreCustomForm<
    T,
    U extends keyof T,
    V extends Exclude<keyof T, U>,
    S extends Exclude<keyof T, V | U> = never,
> extends FormGroup<CustomFormType<T, U, V, S>> {
    constructor(formObject: StaticCustomType<T, U, V, S>) {
        let formControls: CoreFormControl<Pick<T, U>> = {} as any;
        let formGroups: { [key: string]: CoreFormGroup<T[V]> } = {} as any;
        let formArrays: { [key: string]: CoreFormArray<T[S]> } = {} as any;

        Object.entries(formObject).forEach(([key, value]) => {
            if (Array.isArray(value)) {
                const isFormArrayValue = () =>
                    'value' in value[0] ? 'value' in value[1] : true;

                const isSuccessType =
                    typeof value[0] === 'object' && !isNull(value[0]);

                if (
                    value.length === 0 ||
                    (isSuccessType && isFormArrayValue())
                ) {
                    formArrays[key] = new FormArray(
                        value.map(
                            (formGroup) => new CoreFormGroup<T[S]>(formGroup),
                        ),
                    );

                    return;
                } else {
                    const [parsedValue, anotherOptions] = value as Options<
                        T[U]
                    >;

                    formControls[key as U] = new FormControl(
                        parsedValue,
                        anotherOptions,
                    );

                    return;
                }
            }

            if (!Array.isArray(value)) {
                const parsedValue = value as ConstructForm<T[V]>;

                formGroups[key] = new CoreFormGroup<T[V]>(parsedValue);
            }
        });

        const form = { ...formControls, ...formGroups, ...formArrays } as any;

        super(form);
    }
}
