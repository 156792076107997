<core-form [data]="form">
    <div class="main">
        <sl-checkbox #disableCheckbox name="disable" size="small">
            {{ '_$.navigation.notification.settings.disable' | translate }}
        </sl-checkbox>

        <p class="description">
            {{
                '_$.navigation.notification.settings.disable.description'
                    | translate
            }}
        </p>

        <sl-button
            variant="text"
            test-id="MarkAsRead"
            (click)="touch($any($event.target))"
        >
            {{ '_$.navigation.notification.settings.touch' | translate }}
        </sl-button>

        <sl-button
            variant="text"
            class="clear"
            test-id="Reset"
            (click)="clear($any($event.target))"
        >
            {{ '_$.navigation.notification.settings.clear' | translate }}
        </sl-button>
    </div>

    <footer>
        <sl-button
            variant="primary"
            test-id="Cancel"
            outline
            (click)="onBack.emit()"
        >
            {{ '$.cancel' | translate }}
        </sl-button>

        <sl-button type="submit" variant="primary" test-id="Save">
            {{ '$.save' | translate }}
        </sl-button>
    </footer>
</core-form>

<core-confirm
    #clearConfirmation
    buttonOkText="_$.navigation.notification.settings.clear.confirm.ok"
    [title]="
        '_$.navigation.notification.settings.clear.confirm.title' | translate
    "
    [description]="
        '_$.navigation.notification.settings.clear.confirm.content' | translate
    "
    (mousedown)="$event.stopPropagation()"
>
</core-confirm>
