<core-hint-popup
    #popup
    *ngIf="popupCondition; else ruleDelete"
    placement="bottom-start"
    width="340px"
    [label]="'_$$.filters.label.deleteMessageWarning' | translate"
    [description]="'_$$.filters.label.deleteMessage' | translate"
    [buttonCancelText]="'$.cancel' | translate"
    [buttonApplyText]="'$.delete' | translate"
    (onApply)="remove.emit()"
>
    <sl-icon-button
        trigger
        slot="anchor"
        library="platform"
        name="filters/trash"
        (click)="popup.active = true"
    ></sl-icon-button>
</core-hint-popup>

<ng-template #ruleDelete>
    <sl-icon-button
        library="platform"
        name="filters/trash"
        (click)="remove.emit()"
    ></sl-icon-button>
</ng-template>

<sl-icon-button #iconButton name="plus"></sl-icon-button>

<core-dropdown
    #dropdown
    [button]="$any(iconButton)"
    [shouldSyncWidth]="false"
    [visibleOverlay]="false"
    (hide)="dropdownOpened.emit(false)"
    (show)="dropdownOpened.emit(true)"
>
    <div class="menu">
        <span>{{ '_$$.filters.label.add' | translate }}</span>

        <span class="action" (click)="addRule.emit(dropdown)">
            {{ '_$$.filters.button.add.rule' | translate }}
        </span>
        <span class="action" (click)="addGroup.emit(dropdown)">
            {{ '_$$.filters.button.add.group' | translate }}
        </span>
    </div>
</core-dropdown>
