import { I18nPluralPipe } from '@angular/common';
import { Pipe, PipeTransform } from '@angular/core';

import { StorageService } from '../../../services/storage.service';
import { PluralLocalization } from '../../../tools/plural-localization';
import { datePlural } from '../constants/date-plural';
import { DatePluralPeriod } from '../enums/date-plural-period';

@Pipe({ name: 'i18DatePipe' })
export class DatePluralPipe implements PipeTransform {
    constructor(
        private localization: PluralLocalization,
        private storageService: StorageService,
    ) {}

    datePluralPipe: I18nPluralPipe = new I18nPluralPipe(this.localization);
    locale = this.storageService.lang.value;

    public transform(value: number, key: DatePluralPeriod): any {
        return this.datePluralPipe.transform(value, datePlural[key][this.locale]);
    }
}
