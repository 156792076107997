import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { TooltipifyModule } from '../../../modules/tooltipify/tooltipify.module';
import { TranslateModule } from '../../../modules/translate/translate.module';
import { LazyLoadingModule } from '../../lazy-loading/lazy-loading.module';
import { CheckboxModule } from '../checkbox/checkbox.module';
import { DropdownModule } from '../dropdown/dropdown.module';
import { InputModule } from '../input/input.module';
import { ListModule } from '../list/list.module';
import { MaterialIconComponent } from '../material-icon/material-icon.component';
import { TagModule } from '../tag/tag.module';
import { MultiSelectNextComponent } from './multi-select-next.component';

export * from './multi-select-next.component';

@NgModule({
    imports: [
        CheckboxModule,
        CommonModule,
        ListModule,
        DropdownModule,
        InputModule,
        LazyLoadingModule,
        TagModule,
        TooltipifyModule,
        TranslateModule,
        MaterialIconComponent,
    ],
    exports: [MultiSelectNextComponent],
    declarations: [MultiSelectNextComponent],
})
export class MultiSelectNextModule {}
