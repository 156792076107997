import { CommonModule } from '@angular/common';
import { Component } from '@angular/core';

@Component({
    selector: 'material-icon',
    standalone: true,
    imports: [CommonModule],
    template: '<i><ng-content></ng-content></i>',
    styleUrls: ['./material-icon.component.less'],
})
export class MaterialIconComponent {}
