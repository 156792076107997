import {
    Component,
    EventEmitter,
    Input,
    Output,
    TemplateRef,
} from '@angular/core';
import { SubscribableComponent } from 'ngx-subscribable';

@Component({
    selector: 'core-file-source',
    templateUrl: './file-source.component.html',
    styleUrls: ['./file-source.component.less'],
})
export class FileSourceComponent extends SubscribableComponent {
    @Input()
    dropZoneTitle = '_$.file.uploadFile';

    @Input()
    dropZoneDescription = '_$.file.dropFileForLoad';

    @Input()
    accept = '';

    @Input()
    uploadItem: TemplateRef<any> | null = null;

    @Output()
    pick = new EventEmitter<File>();

    onDrop(event: DragEvent): void {
        const file = event.dataTransfer?.files[0];

        if (file) this.pick.next(file);
    }
}
