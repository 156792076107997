import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { HttpStatusCode } from '@angular/common/http';

@Injectable({ providedIn: 'root' })
export class PageErrorService {
    constructor(private router: Router) {}

    navigate(
        responseServerError: HttpStatusCode | null,
        routePath: string,
    ): void {
        this.router.navigate([routePath, 'error-page'], {
            skipLocationChange: true,
            state: {
                status: responseServerError,
            },
        });
    }
}
