import { isEqual } from 'lodash';
import { OperatorFunction, map, pairwise, pipe, startWith } from 'rxjs';

export function pairwiseWithFirst<T>(
    startValue: T,
): OperatorFunction<T, Partial<T>> {
    return pipe(
        startWith(startValue),
        pairwise(),
        map(([oldState, newState]) => {
            const changedFields: Partial<T> = {};

            for (const key in newState) {
                if (!isEqual(oldState[key], newState[key])) {
                    changedFields[key] = newState[key];
                }
            }

            return changedFields;
        }),
    );
}
