import { NgModule } from '@angular/core';

import { PluralPipe } from './plural.pipe';
import { TranslateDirective } from './translate.directive';
import { TranslatePipe } from './translate.pipe';

export * from './plural.enum';
export * from './plural.service';
export * from './plural.pipe';
export * from './translate.directive';
export * from './translate.enums';
export * from './translate.interfaces';
export * from './translate.pipe';
export * from './translate.provider';
export * from './translate.service';
export * from './translations-main.guard';
export * from './translations-page.guard';

@NgModule({
    exports: [PluralPipe, TranslateDirective, TranslatePipe],
    declarations: [PluralPipe, TranslateDirective, TranslatePipe],
})
export class TranslateModule {}
