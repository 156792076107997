import { CommonModule } from '@angular/common';
import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { ShoelaceModule } from 'shoelace-style-angular';

import { PaginationComponent } from './pagination.component';

export * from './pagination.component';

@NgModule({
    imports: [CommonModule, ShoelaceModule],
    exports: [PaginationComponent],
    declarations: [PaginationComponent],
    schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class PaginationModule {}
