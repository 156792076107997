import {
    Component,
    ElementRef,
    EventEmitter,
    HostBinding,
    Input,
    OnInit,
    Output,
} from '@angular/core';

import { DropdownComponent } from '../../../../components/ui-kit-components/dropdown/dropdown.component';

@Component({
    selector: 'filter-action-buttons',
    templateUrl: './filter-action-buttons.component.html',
    styleUrls: ['./filter-action-buttons.component.less'],
})
export class FilterActionButtonsComponent implements OnInit {
    @Input()
    borderElement!: ElementRef<HTMLDivElement>;

    @Input()
    @HostBinding('attr.group-buttons')
    popupCondition = false;

    @Output()
    addRule = new EventEmitter<DropdownComponent>();

    @Output()
    addGroup = new EventEmitter<DropdownComponent>();

    @Output()
    dropdownOpened = new EventEmitter<boolean>();

    @Output()
    remove = new EventEmitter<void>();

    ngOnInit(): void {
        this.dropdownOpened.subscribe((isOpen) => {
            const element = this.borderElement?.nativeElement;

            const action = isOpen ? 'add' : 'remove';

            if (element) {
                element.classList[action]('border-inside');
                element.parentElement?.classList[action]('border-after');
            }
        });
    }
}
