import { DataItem } from '../../../api/data-api.service';
import { SortDirection } from '../../../enums/sort';
import { Formatter } from '../../../services/format.service';
import { DataOptionFilter } from '../../../interfaces/data-option';
import { ColumnWithKey } from '../../../interfaces/dataset';
import { FilterTemplate } from '../../../interfaces/filter-template';
import { ResponseArray } from '../../../interfaces/rest-api';
import { EventEmitter } from '@angular/core';
import { KeyView } from '../../../interfaces/filter-template';
import { LayerBinding } from 'src/shared/interfaces/layer-binding.interface';

export const DATA_ID_PATH = 'id';
export const TREE_DELIMITER = '/';
export const ROOT_VALUE = null;

export type AvailableColumnDataType = any;

export type TreeFilterData = { [key: number]: TreeDataRow };

export type TreeResponseArray = ResponseArray<TreeDataRow>;

export type TreeNode = {
    children: TreeNode[];
    disabled: boolean;
    data: TreeDataRow;
    parent: TreeNode;
    selected: boolean;
    text: ColumnWithKey['key'];
} & TreeDataRow;

export type TreeData = { rows: TreeDataRow[]; formatter: Formatter };

export interface TreeSelectConfig {
    dataset_id: number;
    filters: DataOptionFilter[];
    columns: TreeDataColumn[];
}

export interface TreeDataColumn {
    view_id: number;
    key_id?: number;
    sort?: TreeSort;
}

export interface TreeSort {
    order_by: number;
    order_direction: SortDirection;
}

export interface TreeDataRow extends KeyView {
    id: string;
    originalView: AvailableColumnDataType;
    parentId: string | null;
    hasItems?: boolean;
}

export interface BoundWidgetData {
    widgetBindings: LayerBinding[];
    value: KeyView[];
    isUrlFilter: boolean;
}

export interface BoundWidgetValue {
    columnId: number;
    value: KeyView[];
}

export interface TreeViewState {
    value: KeyView[] | undefined;
}
