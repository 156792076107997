import { ValidatorFn } from '@angular/forms';

import { ReservedNameList } from '../enums/reserved-names';
import { ValidationErrorKey } from '../enums/validation-error-key';

export interface ReservedValidatorError {
    [ValidationErrorKey.Reserved]: null;
}

export const reservedValidator: ValidatorFn = (
    control,
): ReservedValidatorError | null => {
    const condition = ReservedNameList.includes(control.value);

    return condition ? { [ValidationErrorKey.Reserved]: null } : null;
};
