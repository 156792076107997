import {
    Directive,
    InjectionToken,
    Injector,
    OnInit,
    Type,
    inject,
} from '@angular/core';
import { AbstractControl } from '@angular/forms';

import {
    FormControlAdapter,
    FormControlName,
    FormControlService,
} from '../../../services/form-control.service';
import { ControlComponent } from './control-component.interfaces';
import { SelectComponent } from '../../../components/ui-kit-components/select/select.component';
import { isNull } from 'lodash';
import { InputComponent } from '../../../components/ui-kit-components/input/input.component';

export const ControlComponentRef = new InjectionToken<Type<ControlComponent>>(
    'ControlComponentRef',
);

export abstract class ControlComponentInjectors {
    private injector = inject(Injector);
    private formControlService = inject(FormControlService);
    private component =
        this.injector.get<ControlComponent>(ControlComponentRef);

    protected provide(control: AbstractControl | FormControlName) {
        this.formControlService.provide(control, () =>
            createAdapter(this.component),
        );
    }
}

@Directive()
export abstract class ControlComponentDirective
    extends ControlComponentInjectors
    implements OnInit
{
    abstract control: AbstractControl;

    ngOnInit(): void {
        this.provide(this.control);
    }
}

@Directive()
export abstract class ControlComponentNameDirective
    extends ControlComponentInjectors
    implements OnInit
{
    abstract name: FormControlName;

    ngOnInit(): void {
        this.provide(this.name);
    }
}

function createAdapter(component: ControlComponent): FormControlAdapter {
    const adapter: FormControlAdapter = {
        createValueStream: () => component.changeValue,

        setValue: (value) => setValue(component, value),
    };

    if (typeof component.setValue === 'function')
        adapter.setValue = (value) => component.setValue!(value);

    if (typeof component.setRequired === 'function')
        adapter.requiredCallback = () => component.setRequired!();

    return adapter;
}

function setValue(component: any, value: any): void {
    switch (true) {
        case isSelectComponent(component):
            component.value = isNull(value) ? undefined : value;

            if (isNull(value)) component.selectedLabel = '';

            break;
        case isInputComponent(component):
            component.value = isNull(value) ? undefined : value;
    }
}

function isSelectComponent(
    component: ControlComponent,
): component is SelectComponent {
    return 'selectedOptionIndex' in component;
}

function isInputComponent(
    component: ControlComponent,
): component is InputComponent {
    return 'inputRef' in component;
}
