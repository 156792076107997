import { Component, Input } from '@angular/core';
import { BaseUrlService } from 'plmt-core-library';

import { NavigationItem } from '../navigation-config';

@Component({
    selector: 'core-navigation-menu',
    templateUrl: './navigation-menu.component.html',
    styleUrls: ['./navigation-menu.component.less'],
})
export class NavigationMenuComponent {
    @Input()
    items: NavigationItem[] = [];

    isDisabledTooltip = false;

    constructor(private baseUrlService: BaseUrlService) {}

    isActive(routerLink: string): boolean {
        let current = this.baseUrlService.resolve(location.href)!;

        return current === routerLink;
    }

    hasActiveItem(): boolean {
        return this.items.some((item) => this.isActive(item.href));
    }
}
