import { EventEmitter, Injectable, TemplateRef } from '@angular/core';

import { PortalPopupAgent } from './popup-portal.service';

export interface PopupSettings {
    template: TemplateRef<{}>;
    agent: PortalPopupAgent;
}

@Injectable({ providedIn: 'root' })
export class PopupPortalInternalService {
    readonly append = new EventEmitter<PopupSettings>();
    readonly remove = new EventEmitter<TemplateRef<{}>>();
}
