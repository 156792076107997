import { Injectable } from '@angular/core';

@Injectable({ providedIn: 'root' })
export class BaseUrlService {
    readonly baseUrl = location.origin + '/';

    resolve(url: string): string | null {
        const domain = location.origin;

        if (url.startsWith(domain)) {
            return url.slice(domain.length);
        }

        return null;
    }
}
