import { Observable, of } from 'rxjs';

export function elementOnView(element: Element | null, root = document.body): Observable<boolean> {
    if (!element) return of(false);

    return new Observable((subscriber) => {
        const observer = new IntersectionObserver(
            ([{ isIntersecting }]) => {
                subscriber.next(isIntersecting);
                subscriber.complete();
            },
            { root },
        );
        observer.observe(element);

        return () => observer.unobserve(element);
    });
}
