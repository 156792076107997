import { Component, Input } from '@angular/core';

import { Column } from '../../interfaces/dataset';

@Component({
    selector: 'core-column-type-icon',
    templateUrl: './column-type-icon.component.html',
    styleUrls: ['./column-type-icon.component.less'],
})
export class ColumnTypeIconComponent {
    @Input()
    column!: Column;
}
