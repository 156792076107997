import { DragDropModule } from '@angular/cdk/drag-drop';
import { CommonModule } from '@angular/common';
import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { TrackByIdModule } from 'ng-track-by';
import { ShoelaceModule } from 'shoelace-style-angular';

import { TestIdDirectiveModule } from '../../directives/test-id.directive';
import { FormModule } from '../../modules/form/form.module';
import { TooltipifyModule } from '../../modules/tooltipify/tooltipify.module';
import { TranslateModule } from '../../modules/translate/translate.module';
import { ColumnTypeIconModule } from '../column-type-icon/column-type-icon.module';
import { HintPopupModule } from '../hint-popup/hint-popup.module';
import { FilterValueModule } from '../filter-value/filter-value.module';
import { SuggestModule } from '../suggest/suggest.module';
import { ButtonModule } from '../ui-kit-components/button/button.module';
import { DialogModule } from '../ui-kit-components/dialog/dialog.module';
import { DropdownModule } from '../ui-kit-components/dropdown/dropdown.module';
import { InputModule } from '../ui-kit-components/input/input.module';
import { MaterialIconComponent } from '../ui-kit-components/material-icon/material-icon.component';
import { MultiSelectNextModule } from '../ui-kit-components/multi-select-next/multi-select-next.module';
import { SelectModule } from '../ui-kit-components/select/select.module';
import { FilterActionButtonsComponent } from './components/filter-action-buttons/filter-action-buttons.component';
import { FilterClearModalComponent } from './components/filter-clear-modal/filter-clear-modal.component';
import { LogicalButtonComponent } from './components/logical-button/logical-button.component';
import { LocalFilterValueColumnDirective } from './directives/column.directive';
import { FilterDetailsComponent } from './filter-details/filter-details.component';
import { FilterModalComponent } from './filter-modal/filter-modal.component';
import { FilterRowComponent } from './filter-row/filter-row.component';
import { FilterRuleComponent } from './filter-rule/filter-rule.component';
import { HasTimeFilterComponent } from './local-filter-value/components/has-time/has-time.component';
import { RangeComponent } from './local-filter-value/components/range/range-filter.component';
import { LocalFilterValueComponent } from './local-filter-value/local-filter-value.component';
import { LocalFiltersComponent } from './local-filters.component';
import { LocalFiltersService } from './services/local-filters.service';

export * from './components/logical-button/logical-button.component';
export * from './components/filter-clear-modal/filter-clear-modal.component';
export * from './components/filter-action-buttons/filter-action-buttons.component';
export * from './directives/column.directive';
export * from './filter-details/filter-details.component';
export * from './filter-modal/filter-modal.component';
export * from './filter-row/filter-row.component';
export * from './filter-rule/filter-rule.component';
export * from './local-filter-value/local-filter-value.component';
export * from './services/filter-type.service';
export * from './services/local-filters.service';
export * from './tools/consts';
export * from './tools/icon.tools';
export * from './tools/types';

@NgModule({
    imports: [
        ButtonModule,
        ColumnTypeIconModule,
        CommonModule,
        DialogModule,
        DragDropModule,
        DropdownModule,
        HintPopupModule,
        FilterValueModule,
        FormModule,
        InputModule,
        MaterialIconComponent,
        MultiSelectNextModule,
        SelectModule,
        ShoelaceModule,
        SuggestModule,
        TooltipifyModule,
        TestIdDirectiveModule,
        TrackByIdModule,
        TranslateModule,
    ],
    exports: [LocalFiltersComponent],
    declarations: [
        FilterActionButtonsComponent,
        FilterClearModalComponent,
        FilterDetailsComponent,
        FilterModalComponent,
        FilterRowComponent,
        FilterRuleComponent,
        HasTimeFilterComponent,
        LocalFiltersComponent,
        LocalFilterValueColumnDirective,
        LocalFilterValueComponent,
        LogicalButtonComponent,
        RangeComponent,
    ],
    schemas: [CUSTOM_ELEMENTS_SCHEMA],
    providers: [LocalFiltersService],
})
export class LocalFiltersModule {}
