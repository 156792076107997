import { Component } from '@angular/core';
import { SlInput } from '@shoelace-style/shoelace';
import { WatchChanges } from 'ng-onpush';

import { ClipboardService } from '../../../../services/clipboard.service';

@Component({
    selector: 'core-token',
    templateUrl: './token.component.html',
    styleUrls: ['./token.component.less'],
})
export class TokenComponent {
    token = '';

    @WatchChanges()
    isOpen = false;

    constructor(private clipboardService: ClipboardService) {}

    show(token: string): void {
        this.token = token;
        this.isOpen = true;
    }

    copy(input: SlInput): void {
        input.focus();
        input.select();
        this.clipboardService.write(input.value);
    }

    hide(): void {
        this.isOpen = false;
    }
}
