import { NgModule, Pipe, PipeTransform } from '@angular/core';

@Pipe({ name: 'stringArrayToString' })
export class StringArrayToStringPipe implements PipeTransform {
    transform(data: string[], separator = ', '): string {
        return data.map((i) => `«${i}»`).join(separator);
    }
}

@NgModule({
    exports: [StringArrayToStringPipe],
    declarations: [StringArrayToStringPipe],
})
export class StringArrayToStringPipeModule {}
