import { CommonModule } from '@angular/common';
import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';

import { TooltipifyModule } from '../../../modules/tooltipify/tooltipify.module';
import { TranslateModule } from '../../../modules/translate/translate.module';
import { LazyLoadingModule } from '../../lazy-loading/lazy-loading.module';
import { DropdownModule } from '../dropdown/dropdown.module';
import { InputModule } from '../input/input.module';
import { SelectComponent } from './select.component';

export * from './select.component';

@NgModule({
    imports: [
        CommonModule,
        DropdownModule,
        TooltipifyModule,
        TranslateModule,
        InputModule,
        LazyLoadingModule,
    ],
    schemas: [CUSTOM_ELEMENTS_SCHEMA],
    exports: [SelectComponent],
    declarations: [SelectComponent],
})
export class SelectModule {}
