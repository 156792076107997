import { FilterLogical, FilterMethod } from '../../../enums/filter';
import { FilterDataOptionKey } from '../../../constants/filter-data-option-key';
import {
    DataOptionAggregateFunction,
    DataOptionMethod,
} from '../../../enums/data-option';
import {
    DataOption,
    DataOptionDataBlock,
    DataOptionFilter,
    DataOptionSort,
} from '../../../interfaces/data-option';
import {
    BoundWidgetValue,
    TreeDataColumn,
    TreeNode,
    TreeSelectConfig,
} from '../interfaces/tree-view.interface';
import { getColumnByNodeLevel, getNodeLevel } from './tree-view.helper';
import {
    DATA_KEY_PATH,
    DATA_VIEW_PATH,
} from '../../../constants/filter-default-value-keys';

export function makeTreeViewFilterDataOption(
    treeSelectConfig: TreeSelectConfig,
    parent?: TreeNode,
    additionalFilters?: BoundWidgetValue[],
    localfilters: DataOptionFilter[] = [],
): DataOption | null {
    const level = getNodeLevel(parent);
    const currentLevelColumn = getColumnByNodeLevel(
        treeSelectConfig,
        level + 1,
    );

    const blocks: DataOptionDataBlock[] = [];

    const keyId = currentLevelColumn?.key_id;
    if (keyId) {
        blocks.push({
            key: DATA_KEY_PATH,
            columns: [
                {
                    column_id: keyId,
                    agg_fn: DataOptionAggregateFunction.Min,
                },
            ],
        });
    }

    const columnSort = currentLevelColumn?.sort;
    const sortWithDirection = columnSort && buildSortColumn([columnSort]);

    return {
        key: FilterDataOptionKey,
        dataset_id: treeSelectConfig.dataset_id,
        offset: 0,
        method: DataOptionMethod.Aggregate,
        sort: sortWithDirection,
        filters: [...(treeSelectConfig.filters || [])].concat([
            ...buildFilters(
                treeSelectConfig,
                parent,
                additionalFilters,
                currentLevelColumn?.view_id,
            ),
            ...localfilters,
        ]),
        blocks: [
            {
                key: DATA_VIEW_PATH,
                columns: [
                    {
                        column_id: currentLevelColumn?.view_id,
                        agg_fn: DataOptionAggregateFunction.Group,
                    },
                ],
            },
            ...blocks,
        ],
    };
}

export function makeSearchTreeViewFilterDataOption(
    columnsToSearch: TreeDataColumn[],
    datasetId: number,
    dxFilters?: string[],
    localFilters: DataOptionFilter[] = [],
): DataOption | null {
    const sortColumns = columnsToSearch
        .filter((column) => column.sort?.order_by)
        ?.map((col) => col.sort);

    const sortWithDirection = buildSortColumn(sortColumns);
    const keysArray = columnsToSearch.filter((column) => column.key_id);

    const blocks: DataOptionDataBlock[] = [
        {
            key: DATA_VIEW_PATH,
            columns: columnsToSearch.map((column) => {
                return {
                    column_id: column!.view_id,
                    agg_fn: DataOptionAggregateFunction.Group,
                };
            }),
        },
    ];

    keysArray.forEach((keyColumn) => {
        blocks.push({
            key: keyColumn.view_id.toString(),
            columns: [
                {
                    column_id: keyColumn.key_id!,
                    agg_fn: DataOptionAggregateFunction.Group,
                },
            ],
        });
    });

    return {
        key: FilterDataOptionKey,
        dataset_id: datasetId,
        offset: 0,
        method: DataOptionMethod.Aggregate,
        sort: sortWithDirection,
        // самое оптимальное решение получить отфильтрованные данные по нескольким колонкам:
        // кидать запросы за данными, начиная с полного списка колонок и заканчивая одной последней колонкой,
        // результаты пересекаем (merge) и таким образом не получаем лишних данных
        filters: [
            ...buildSearchFilters(
                columnsToSearch[columnsToSearch.length - 1],
                dxFilters,
            ),
            ...localFilters,
        ],
        blocks,
    };
}

function buildFilters(
    treeSelectConfig: TreeSelectConfig,
    node?: TreeNode,
    additionalFilters?: BoundWidgetValue[],
    columnIdToGet?: number,
): DataOptionFilter[] {
    const filters: DataOptionFilter[] = [];

    let dummy = node;
    while (dummy && dummy.data) {
        const level = getNodeLevel(dummy);
        const nodeColumn = getColumnByNodeLevel(treeSelectConfig, level);

        filters.push({
            value: dummy.data.originalView,
            column_id: nodeColumn.view_id,
            action: FilterMethod.Equal,
            logical: FilterLogical.And,
        });

        dummy = dummy.parent;
    }

    addAdditionalFilterIdNeeded(additionalFilters, columnIdToGet, filters);

    return filters;
}

function buildSortColumn(
    columnsSort: TreeDataColumn['sort'][],
): DataOptionSort[] {
    if (!columnsSort || !columnsSort.length) return [];

    return columnsSort.map((column) => {
        return {
            column_id: column!.order_by,
            direction: column!.order_direction,
            agg_fn: DataOptionAggregateFunction.Min,
        };
    });
}

function buildSearchFilters(
    column: TreeDataColumn,
    dxFilters?: string[],
): DataOptionFilter[] {
    if (!dxFilters) return [];

    const value = dxFilters[2];

    return [
        {
            value: value,
            column_id: column.view_id,
            logical: FilterLogical.And,
            action: FilterMethod.Like$CaseIgnore,
        },
    ];
}

function addAdditionalFilterIdNeeded(
    additionalFilters?: BoundWidgetValue[],
    columnIdToGet?: number,
    filters?: DataOptionFilter[],
): void {
    const filter = additionalFilters?.find(
        (filter) => filter.columnId === columnIdToGet,
    );

    if (filter && columnIdToGet && filter.value.length) {
        filters?.push({
            value: filter.value,
            column_id: columnIdToGet,
            action: FilterMethod.InList,
            logical: FilterLogical.And,
        });
    }
}
