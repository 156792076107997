import { Component, EventEmitter, Input, Output } from '@angular/core';
import { ComponentSize } from '../../../interfaces/core-library-components';

export type ButtonVariant =
    | 'primary'
    | 'secondary'
    | 'neutral'
    | 'outline'
    | 'clear'
    | 'link'
    | 'negative';

@Component({
    selector: 'core-button',
    templateUrl: './button.component.html',
    styleUrls: ['./button.component.less'],
})
export class ButtonComponent {
    @Input()
    variant: ButtonVariant = 'secondary';

    @Input()
    size: ComponentSize = 'medium';

    @Input()
    disabled: boolean = false;

    @Input()
    active: boolean = false;

    @Input()
    submit?: '';

    @Input()
    icon?: '';

    @Input()
    loading = false;

    @Output()
    buttonClick = new EventEmitter<void>();
}
