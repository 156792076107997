/**
 * @license
 * Copyright 2017 Google LLC
 * SPDX-License-Identifier: BSD-3-Clause
 */
function t(t) {
  return (n, o) => {
    const c = "function" == typeof n ? n : n[o];
    Object.assign(c, t);
  };
}
export { t as eventOptions };
