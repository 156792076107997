<ng-template #template>
    <div
        class="wrapper"
        [ngClass]="{
            'modal-wrapper': type === 'modal',
            'dialog-wrapper': type === 'dialog'
        }"
        [style]="customStyle"
        [style.zIndex]="zIndex"
        (mouseup)="isDownOnModal ? (isDownOnModal = false) : overlayClick()"
    >
        <div
            #dialog
            class="dialog"
            [style.width]="width"
            (mouseup)="$event.stopPropagation(); isDownOnModal = false"
            (mousedown)="isDownOnModal = true"
        >
            <header *ngIf="showHeader">
                <h2>{{ label }}</h2>
                <material-icon (click)="modalHide.emit()">
                    close
                </material-icon>
            </header>

            <ng-content></ng-content>
        </div>
    </div>
</ng-template>

<app-close-confirm
    #closeConfirm
    (onClose)="modalHide.emit()"
></app-close-confirm>
