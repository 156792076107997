<div cdkDropList (cdkDropListDropped)="onDrop($event)">
    <div *ngFor="let unit of units; let i = index" cdkDrag>
        <ng-container
            *ngTemplateOutlet="line; context: getUnitContext(unit, i)"
        ></ng-container>

        <ng-template cdkDragPreview>
            <ng-container
                *ngTemplateOutlet="line; context: getUnitContext(unit, i, true)"
            ></ng-container>
        </ng-template>

        <div *cdkDragPlaceholder class="placeholder"></div>
    </div>
</div>

<ng-template
    #line
    let-unit
    let-values="values"
    let-isPreview="isPreview"
    let-checkboxDisabled="checkboxDisabled"
    let-index="index"
>
    <div class="line">
        <sl-icon class="drag-icon" name="grip-vertical"></sl-icon>

        <sl-checkbox
            *ngIf="!isPreview"
            size="small"
            [checked]="!unit.disabled"
            [disabled]="checkboxDisabled"
            (sl-change)="onChangeDisabled(unit, $event)"
        >
            {{ '_$$.columnFormatting.dateFormatting.' + unit.type | translate }}
        </sl-checkbox>

        <sl-checkbox
            *ngIf="isPreview"
            size="small"
            [checked]="!unit.disabled"
            [disabled]="checkboxDisabled"
        >
            {{ '_$$.columnFormatting.dateFormatting.' + unit.type | translate }}
        </sl-checkbox>

        <plmt-select
            size="small"
            [value]="unit.value"
            [disabled]="unit.disabled"
            (sl-change)="onChangeValue(unit, $event)"
        >
            <sl-option *ngFor="let value of values" [value]="value">
                {{
                    '_$$.columnFormatting.dateFormatting.value.' +
                        unit.type +
                        value | translate
                }}
            </sl-option>
        </plmt-select>

        <sl-input
            size="small"
            maxlength="2"
            [value]="unit.suffix"
            [disabled]="unit.disabled || index === getLastEnableIndex()"
            (sl-input)="onChangeSuffix(unit, $event)"
        ></sl-input>
    </div>
</ng-template>
