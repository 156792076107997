import { ColumnType } from '../../../enums/dataset';
import { FilterTemplateCustomData } from '../../../interfaces/filter-template';

export function getTypesForCustomSelect(
    data: FilterTemplateCustomData | null,
): FilterTemplateCustomData | null {
    if (data) {
        const { values } = data;

        if (values && values.length) {
            data.key_column_type = getColumnType(values[0].key);
            data.suggest_column_type = getColumnType(values[0].label);
        }
    }

    return data;
}

function getColumnType(item: string | number | undefined): ColumnType {
    return item
        ? ColumnType[item.constructor.name as keyof typeof ColumnType]
        : ColumnType.Number;
}
