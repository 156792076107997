<core-form [data]="form">
    <div class="range-blocks" [class.number-range]="type === 'number'">
        <div class="inputs">
            <core-validate>
                <core-input
                    #from
                    [placeholder]="'_$$.filters.placeholder.from' | translate"
                    [type]="type"
                    [control]="fromControl"
                ></core-input>
            </core-validate>

            <core-hint-popup
                placement="top"
                variant="negative"
                icon="exclamation-circle"
                arrowPlacement="anchor"
                [label]="'_$$.filters.validation.range' | translate"
            >
                <core-validate trigger slot="anchor">
                    <core-input
                        #to
                        [placeholder]="'_$$.filters.placeholder.to' | translate"
                        [type]="type"
                        [control]="toControl"
                    ></core-input>
                </core-validate>
            </core-hint-popup>
        </div>

        <app-has-time-filter
            *ngIf="type === 'date'"
            [inputs]="[from, to]"
            (change)="changeFromTime($event)"
        ></app-has-time-filter>
    </div>
</core-form>
