import { Component, OnInit, ViewChild } from '@angular/core';
import { SubscribableComponent } from 'ngx-subscribable';
import { BehaviorSubject } from 'rxjs';

import { Lang } from '../../enums/lang';
import { showNotification } from '../../services/license.internal';
import { StorageService } from '../../services/storage.service';
import { NotifyComponent } from '../notify/notify.component';

const translations: Record<Lang, string> = {
    [Lang.En]: 'License file not found',
    [Lang.Ru]: 'Лицензия не установлена',
};

@Component({
    selector: 'core-license-notification',
    templateUrl: './license-notification.component.html',
    styleUrls: ['./license-notification.component.less'],
})
export class LicenseNotificationComponent
    extends SubscribableComponent
    implements OnInit
{
    readonly message = new BehaviorSubject<string>('');

    @ViewChild(NotifyComponent, { static: true })
    notifyComponent!: NotifyComponent;

    constructor(private storageService: StorageService) {
        super();
    }

    ngOnInit(): void {
        this.subscriptions = [
            showNotification.subscribe(() => {
                this.notifyComponent.toast();
            }),

            this.storageService.lang.subscribe((lang) =>
                this.message.next(translations[lang]),
            ),
        ];
    }
}
