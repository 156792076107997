import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';

import { ModalCloseConfirmComponent } from './modal-close-confirm.component';

@Injectable({ providedIn: 'root' })
export class ModalCloseConfirmService {
    modalCloseConfirmComponent?: ModalCloseConfirmComponent;

    setModalCloseConfirmComponent(component: ModalCloseConfirmComponent): void {
        this.modalCloseConfirmComponent = component;
    }

    confirm(): Observable<boolean> {
        return this.modalCloseConfirmComponent?.confirm() ?? of(true);
    }
}
