export enum EventType {
    DatasetLoaded = 'dataset_loaded',
    DatasetUpdated = 'dataset_updated',
    LogsExported = 'logs_exported',
    ProjectExported = 'project_exported',
    ReportReady = 'report_ready',
    WidgetExported = 'widget_exported',
    EntityExported = 'entity_exported',
    ImportFinished = 'import_finished',
    DatasetLocalGc = 'dataset_local_gc',
}
