import { Directive, NgModule, OnInit } from '@angular/core';
import { SlDialog } from '@shoelace-style/shoelace';
import { not } from 'logical-not';
import { SubscribableDirective } from 'ngx-subscribable';
import {
    filter,
    fromEvent,
    map,
    merge,
    Observable,
    switchMap,
    tap,
} from 'rxjs';

import { ModalCloseConfirmService } from '../components/modal-close-confirm/modal-close-confirm.service';
import { getHost } from '../tools/get-host';

@Directive({
    selector: 'sl-dialog[closeConfirm]',
})
export class ModalCloseConfirmDirective
    extends SubscribableDirective
    implements OnInit
{
    private host: SlDialog = getHost();

    constructor(private modalCloseConfirmService: ModalCloseConfirmService) {
        super();
    }

    ngOnInit(): void {
        const { host, modalCloseConfirmService } = this;

        let hasChanges = false;

        this.subscriptions = [
            fromEvent(host, 'sl-after-show')
                .pipe(
                    filter((event) => event.target === host),
                    tap(() => {
                        hasChanges = false;
                    }),
                    switchMap(() =>
                        this.getChangeEvents().pipe(
                            tap(() => {
                                hasChanges = true;
                            }),
                        ),
                    ),
                )
                .subscribe(),

            fromEvent<
                CustomEvent<{
                    source: 'close-button' | 'keyboard' | 'overlay';
                }>
            >(host, 'sl-request-close').subscribe((event) => {
                if (event.target !== host) return;
                if (event.detail.source !== 'overlay') return;

                if (not(hasChanges)) return;

                event.preventDefault();

                modalCloseConfirmService.confirm().subscribe((close) => {
                    if (close) host.hide();
                });
            }),
        ];
    }

    private getChangeEvents(): Observable<void> {
        const { host } = this;

        return merge(
            fromEvent(host, 'sl-change'),
            fromEvent(host, 'sl-input'),
            fromEvent(host, 'change'),
            fromEvent(host, 'input'),
        ).pipe(map(() => {}));
    }
}

@NgModule({
    exports: [ModalCloseConfirmDirective],
    declarations: [ModalCloseConfirmDirective],
})
export class ModalCloseConfirmDirectiveModule {}
