<ng-container [ngSwitch]="uiType">
    <ng-container *ngSwitchCase="FilterUIType.SelectSuggest">
        <core-suggest
            *ngIf="subType !== FilterTemplateUIType.TreeView"
            #suggest
            clearable
            [selected]="value"
            [label]="'_$$.filters.label.value' | translate"
            [getLabelFn]="getLabelFn"
            [compareFn]="compareFn"
            [suggestionsProvider]="nullMappingProvider"
            [cache]="false"
            [disabled]="shouldDisable"
        ></core-suggest>

        <app-tree-view
            *ngIf="subType === FilterTemplateUIType.TreeView"
            inputHeight="40px"
            [defaultValue]="value"
            [config]="treeSelectConfig!"
            [label]="'_$$.filters.label.value' | translate"
            (changeData)="onTreeSelectValueChange($event)"
        ></app-tree-view>
    </ng-container>

    <core-multi-select
        *ngSwitchCase="FilterUIType.SelectMulti"
        [selected]="toArray(value)"
        [label]="'_$$.filters.label.value' | translate"
        [getLabelFn]="getLabelFn"
        [compareFn]="compareFn"
        [suggestionsProvider]="nullMappingProvider"
        [cache]="false"
        (onChange)="emitChange($event)"
    ></core-multi-select>

    <sl-input
        *ngSwitchCase="FilterUIType.Search"
        [label]="'_$$.filters.label.value' | translate"
        [value]="value"
        (sl-input)="emitFromEvent($event)"
    ></sl-input>

    <sl-input
        *ngSwitchCase="FilterUIType.Number"
        type="number"
        [label]="'_$$.filters.label.value' | translate"
        [value]="value"
        (sl-input)="emitChangeNumber($event)"
    ></sl-input>

    <ng-container *ngSwitchCase="FilterUIType.Date">
        <sl-input
            #input
            [label]="'_$$.filters.label.value' | translate"
            [value]="value"
            (sl-change)="emitFromEvent($event)"
        ></sl-input>

        <plmt-has-time
            [inputs]="[input]"
            (change)="emitChange($event)"
        ></plmt-has-time>
    </ng-container>

    <div *ngSwitchCase="FilterUIType.DateRange">
        <sl-input
            #from
            [label]="'_$$.filters.label.value.dateRange.from' | translate"
            [value]="value?.[0]"
            (sl-input)="emitChangeDateRange($event, 0)"
        ></sl-input>

        <sl-input
            #to
            [label]="'_$$.filters.label.value.dateRange.to' | translate"
            [value]="value?.[1]"
            (sl-change)="emitChangeDateRange($event, 1)"
        ></sl-input>

        <plmt-has-time
            [inputs]="[from, to]"
            (change)="emitChange($event)"
        ></plmt-has-time>
    </div>
</ng-container>

<plmt-radio-min-max
    *ngIf="showMinMax"
    [state]="aggFn"
    (radioMinMaxChange)="onRadioMinMaxChange($event)"
></plmt-radio-min-max>
