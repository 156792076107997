import { CommonModule } from '@angular/common';
import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { ShoelaceModule } from 'shoelace-style-angular';

import { TestIdDirectiveModule } from '../../../directives/test-id.directive';
import { FormModule } from '../../../modules/form/form.module';
import { TooltipifyModule } from '../../../modules/tooltipify/tooltipify.module';
import { TranslateModule } from '../../../modules/translate/translate.module';
import { ActionModule } from '../../action/action.module';
import { ConfirmModule } from '../../confirm/confirm.module';
import { LazyLoadingModule } from '../../lazy-loading/lazy-loading.module';
import { RelativeTimeModule } from '../../relative-time/relative-time.module';
import { EmptyStateComponent } from './components/empty-state/empty-state.component';
import { MessagesComponent } from './components/messages/messages.component';
import { SettingsComponent } from './components/settings/settings.component';
import { SpinnerComponent } from './components/spinner/spinner.component';
import { NotificationComponent } from './notification.component';
import { StringArrayToStringPipeModule } from '../../../pipes/string-array-to-string.pipe';
import { MessageIconComponent } from './components/message-icon/message-icon.component';
import { ImportedEntitiesNamesPipeModule } from '../../../pipes/imported-entitties-names.pipe';

export * from './notification.component';
export * from './enums/event-type';
export * from './enums/event-level';
export * from './interfaces/notification';

@NgModule({
    imports: [
        ActionModule,
        CommonModule,
        ConfirmModule,
        FormModule,
        LazyLoadingModule,
        RelativeTimeModule,
        ShoelaceModule,
        TooltipifyModule,
        TranslateModule,
        TestIdDirectiveModule,
        StringArrayToStringPipeModule,
        ImportedEntitiesNamesPipeModule,
    ],
    exports: [NotificationComponent],
    declarations: [
        EmptyStateComponent,
        MessagesComponent,
        NotificationComponent,
        SettingsComponent,
        SpinnerComponent,
        MessageIconComponent,
    ],
    schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class NotificationModule {}
