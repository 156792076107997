export function parseUrl(source: string): URL | null {
    try {
        return new URL(fixUrlSource(source), location.href);
    } catch (_) {
        return null;
    }
}

function fixUrlSource(source: string): string {
    const parts = source.split('/');

    if (parts.length > 0) {
        const [item] = parts;

        if (item.includes('.')) return 'http://' + source;
    }

    return source;
}
