import {
    Component,
    Input,
    OnChanges,
    OnInit,
    SimpleChanges,
} from '@angular/core';
import { Dataset } from '../../../../interfaces/dataset';
import { FormArray, FormControl, FormGroup, Validators } from '@angular/forms';
import { Form, FormService } from '../../../../modules/form/form.service';
import { SortDirection } from '../../../../enums/sort';
import { valueOf } from '../../../../tools/value-of';
import { isNull, isUndefined } from 'lodash';
import { FilterTemplateForm } from '../../form/filter-template-form';
import {
    TreeDataColumn,
    TreeSort,
} from '../../../../components/tree-view/interfaces/tree-view.interface';
import { TreeViewService } from 'src/shared/components/tree-view/tree-view.module';

const SORT_UP_ICON_NAME = 'sort-up';
const SORT_DOWN_ICON_NAME = 'sort-down-alt';

enum TreeFilterColumnType {
    View = 'view_id',
    Key = 'key_id',
    Sort = 'sort',
}

@Component({
    selector: 'plmt-tree-filter',
    templateUrl: './tree-filter.component.html',
    styleUrls: [
        './tree-filter.component.less',
        '../../filter-template.component.less',
    ],
})
export class TreeFilterComponent implements OnInit, OnChanges {
    @Input()
    dataset?: Dataset;

    readonly SortDirection = SortDirection;
    readonly TreeFilterColumnType = TreeFilterColumnType;

    get columnsControl(): FormArray<Form<TreeDataColumn>> {
        if (!this.formInstance) {
            this.formInstance = FilterTemplateForm.getInstance(
                this.formService,
            );
        }

        return this.formInstance.controls.treeSelect.controls
            .columns as FormArray<Form<TreeDataColumn>>;
    }

    formInstance!: FilterTemplateForm;

    constructor(
        private formService: FormService,
        private treeViewService: TreeViewService,
    ) {}

    ngOnInit(): void {
        this.formInstance = FilterTemplateForm.getInstance(this.formService);
    }

    ngOnChanges(changes: SimpleChanges): void {
        if ('dataset' in changes) {
            if (!changes['dataset'].previousValue) return;

            this.columnsControl.clear();
            this.addNewTreeColumn();
        }
    }

    addNewTreeColumn(): void {
        const column = this.createColumnControl();

        this.columnsControl.push(column);
    }

    removeTreeColumn(index: number): void {
        this.columnsControl.removeAt(index);
        this.onColumnChange();
    }

    getSortIconName(index: number): string {
        const orderDirectionControl =
            this.getOrderDirectionControlByIndex(index);

        switch (orderDirectionControl?.value) {
            case SortDirection.Asc:
                return SORT_UP_ICON_NAME;
            case SortDirection.Desc:
                return SORT_DOWN_ICON_NAME;
            default:
                return SORT_UP_ICON_NAME;
        }
    }

    changeSortDirection(event: MouseEvent, index: number): void {
        event.preventDefault();
        event.stopPropagation();

        const orderDirectionControl =
            this.getOrderDirectionControlByIndex(index);

        if (!orderDirectionControl) return;

        const currentSortDirection =
            orderDirectionControl?.value as SortDirection;
        const newSortDirection =
            currentSortDirection === SortDirection.Asc
                ? SortDirection.Desc
                : SortDirection.Asc;

        orderDirectionControl.patchValue(newSortDirection);
        this.onColumnChange(index);
    }

    onColumnChange(
        index?: number,
        event?: CustomEvent,
        columnType = TreeFilterColumnType.View,
    ): void {
        if (!isUndefined(index) && !isNull(index) && event) {
            const column = this.columnsControl.controls[index]
                .value as TreeDataColumn;

            const value = Number(valueOf(event));

            switch (columnType) {
                case TreeFilterColumnType.Sort:
                    column[columnType]!.order_by = value;
                    break;
                case TreeFilterColumnType.View:
                    column[columnType] = value;
                    break;
                case TreeFilterColumnType.Key:
                    column[columnType] = !Boolean(value) ? undefined : value;
            }

            this.columnsControl.controls[index].patchValue(column);
        }

        if (columnType === TreeFilterColumnType.View) {
            const currentColumns: TreeDataColumn[] =
                this.columnsControl.controls.map((control) =>
                    control.getRawValue(),
                );

            this.treeViewService.treeColumns$.next(currentColumns);
        }
    }

    private createColumnControl(
        treeColumn?: TreeDataColumn,
    ): Form<TreeDataColumn> {
        const column = this.formService.form<TreeDataColumn>({
            view_id: [treeColumn?.view_id, [Validators.required]],
            key_id: [treeColumn?.key_id],
            sort: this.formService.form<TreeSort>({
                order_by: [treeColumn?.sort?.order_by],
                order_direction: [
                    treeColumn?.sort?.order_direction || SortDirection.Asc,
                ],
            }),
        }) as Form<TreeDataColumn>;

        return column;
    }

    private getOrderDirectionControlByIndex(
        index: number,
    ): FormControl | undefined {
        const control = this.columnsControl.controls[index] as FormGroup;
        const orderDirectionControl = control?.get(
            'sort.order_direction',
        ) as FormControl;

        return orderDirectionControl;
    }
}
