import { Injectable } from '@angular/core';
import { Router } from '@angular/router';

import { authPath } from '../constants/route-path';
import { StorageService } from './storage.service';

@Injectable({ providedIn: 'root' })
export class LogoutService {
    constructor(
        private router: Router,
        private storageService: StorageService,
    ) {}

    logout(
        params: { withBackwardRedirect: boolean } = {
            withBackwardRedirect: true,
        },
    ): void {
        const { withBackwardRedirect } = params;

        this.storageService.authToken.next('');

        if (withBackwardRedirect) {
            const redirect = encodeURIComponent(location.href);

            this.router.navigate(['/' + authPath], {
                queryParams: { redirect: redirect },
            });
        } else {
            this.router.navigate(['/' + authPath]);
        }
    }
}
