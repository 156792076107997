import {
    AfterViewInit,
    Component,
    ElementRef,
    EventEmitter,
    Injectable,
    Input,
    Output,
    ViewChild,
    forwardRef,
} from '@angular/core';

import { SubscribableComponent } from 'ngx-subscribable';
import { ComponentSize } from '../../../interfaces/core-library-components';
import {
    ControlComponent,
    ControlComponentRef,
} from '../../../modules/form/control-component';
import { resizeObserver } from '../../../tools/resize-observer';
import { valueOf } from '../../../tools/value-of';

@Component({
    selector: 'core-input',
    templateUrl: './input.component.html',
    styleUrls: ['./input.component.less'],
    providers: [
        {
            provide: ControlComponentRef,
            useExisting: forwardRef(() => InputComponent),
        },
    ],
    host: {
        '(mouseup)': '$event.stopPropagation()',
    },
})
@Injectable()
export class InputComponent
    extends SubscribableComponent
    implements AfterViewInit, ControlComponent
{
    @Input()
    size: ComponentSize = 'medium';

    @Input()
    type = 'text';

    @Input()
    label = '';

    @Input()
    icon = '';

    @Input()
    clearable?: '';

    @Input()
    placeholder?: string;

    @Input()
    disabled: boolean = false;

    @Input()
    autofocus?: '';

    @Input()
    value: any;

    @Output()
    changeValue = new EventEmitter<any>();

    @ViewChild('input', { static: true })
    private inputRef!: ElementRef<HTMLInputElement>;

    ngAfterViewInit(): void {
        if (this.autofocus !== '') return;

        const input = this.inputRef.nativeElement;

        this.subscriptions.push(
            resizeObserver(input).subscribe(({ height }) => {
                if (height) input.focus();
            }),
        );
    }

    emitChange(event: Event): void {
        event.stopPropagation();

        if (this.type === 'date' || this.type === 'datetime-local') {
            this.emit(event);
        }
    }

    emitInput(event: Event): void {
        event.stopPropagation();

        if (this.type !== 'date' && this.type !== 'datetime-local') {
            this.emit(event);
        }
    }

    emit(event: Event): void {
        this.value = valueOf(event);

        this.changeValue.emit(this.value);
    }
}
