import { Directive, Input } from '@angular/core';
import { ToParent } from 'ng-to-parent';

import { AbstractControl } from '@angular/forms';
import {
    FormControlName,
    FormControlService,
} from '../../../services/form-control.service';
import {
    ControlComponentDirective,
    ControlComponentNameDirective,
} from './control-component.directive';

@Directive({
    selector: `
        core-input[control],
        core-checkbox[control],
        core-select[control]
    `,
    providers: [FormControlService, ToParent],
})
export class CoreControlComponents extends ControlComponentDirective {
    @Input()
    control!: AbstractControl;
}

@Directive({
    selector: `
        core-input[name],
        core-checkbox[name],
        core-select[name]
    `,
    providers: [FormControlService, ToParent],
})
export class CoreControlByNameComponents extends ControlComponentNameDirective {
    @Input()
    name!: FormControlName;
}
