<sl-radio-group
    no-fieldset
    size="small"
    [value]="state"
    (sl-change)="onChange($event)"
>
    <sl-radio [value]="DefaultValueAggregation.Exact">
        {{ '_$$.filters.radio.exact' | translate }}
    </sl-radio>
    <sl-radio [value]="DefaultValueAggregation.Max">
        {{ '_$$.filters.radio.max' | translate }}
    </sl-radio>
    <sl-radio [value]="DefaultValueAggregation.Min">
        {{ '_$$.filters.radio.min' | translate }}
    </sl-radio>
</sl-radio-group>
