import { NgModule } from '@angular/core';
import {
    ActivationStart,
    NavigationEnd,
    Router,
    RouterModule,
} from '@angular/router';
import { not } from 'logical-not';

import { PageService } from '../../services/page.service';
import { PageLoadingService } from './page-loading.service';
import { dashboardRoutePath } from '../../constants/route-path';

@NgModule({
    imports: [RouterModule],
    providers: [PageLoadingService],
})
export class PageLoadingModule {
    constructor(
        pageLoadingService: PageLoadingService,
        pageService: PageService,
        router: Router,
    ) {
        router.events.subscribe((event) => {
            if (event instanceof ActivationStart) {
                pageLoadingService.showAppLoading();
            }

            if (
                event instanceof NavigationEnd &&
                !event.urlAfterRedirects.includes(dashboardRoutePath)
            ) {
                pageLoadingService.removeAppLoading();
            }
        });

        pageService.loading$.subscribe((loading) => {
            if (not(loading) && not(pageLoadingService.isHidden())) {
                pageLoadingService.hideAppLoading();
            }
        });
    }
}
