<core-dialog
    type="dialog"
    [label]="'_$$.filters.label.dialog.clear.header' | translate"
    [(open)]="isOpen"
>
    <span class="content">
        {{ '_$$.filters.label.clear' | translate }}
    </span>

    <div class="footer">
        <div class="buttons">
            <core-button variant="outline" (buttonClick)="isOpen = false">
                {{ '$.cancel' | translate }}
            </core-button>
            <core-button variant="negative" (buttonClick)="clearFilters()">
                {{ '_$$.filters.button.clear' | translate }}
            </core-button>
        </div>
    </div>
</core-dialog>
