import { Inject, Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import {
    DependenceParams,
    RelatedEntityResponse,
} from '../interfaces/related-info-interface';
import { EntityCacheApi } from '../../rest/api-injectors';
import { RestService } from '../../rest/rest.service';

@Injectable()
export class DependenceApiService {
    constructor(@Inject(EntityCacheApi) private restService: RestService) {}

    getDependencies(
        params: DependenceParams,
    ): Observable<RelatedEntityResponse> {
        const { id, entityType, actionType } = params;
        return this.restService.get(
            `/entity/${entityType}/${actionType}/${id}/dependencies`,
        );
    }
}
