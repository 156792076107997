import { cloneDeep } from 'lodash';
import {
    FilterTemplate,
    FilterTemplateApi,
    FilterTemplateCustomData,
    FilterTemplateCustomRow,
} from '../../../interfaces/filter-template';
import { Form } from '../../../modules/form/form.module';
import { FilterTemplateUI } from '../interfaces/filter-template-ui.type';
import { DefaultValueAggregation } from '../../../enums/radio-min-max-state.enum';
import { FilterTemplateUIType } from '../../../enums/filter-ui-type';
import { FilterLabelPosition } from '../../../enums/filter-label-position';
import { FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import {
    TreeDataColumn,
    TreeSelectConfig,
} from '../../tree-view/tree-view.module';
import { SortDirection } from '../../../enums/sort';
import { ColumnType } from '../../../enums/dataset';
import { realNumbersValidator } from '../../../validators/real-numbers.validator';
import { isNullOrUndefined } from '../../../helpers/is-null-or-undefined';
import { FilterTemplateType } from 'plmt-core-library';

// хелпер для манипуляций с формой

// патчи

export function patchForm(
    filterRow: FilterTemplateApi,
    form: Form<FilterTemplateUI>,
): void {
    const { groups, users, filter } = filterRow;

    const template = cloneDeep(filter);

    const aggFn = hasAggFn(filter.default_value?.view)
        ? filter.default_value.view.agg_fn
        : DefaultValueAggregation.Exact;

    form.patchValue(template);

    form.controls.original_default_value.patchValue(template.default_value);
    form.controls.default_value_agg_fn.patchValue(aggFn, {
        emitEvent: false,
    });

    form.controls.groups.patchValue(groups);
    form.controls.users.patchValue(users);

    patchDatasetId(template, form);
    patchTreeSelect(template, form);
    patchLocalFilters(template, form);
    patchCustomSelect(form, template);
}

export function patchNewFilterForm(form: Form<FilterTemplateUI>): void {
    form.patchValue({
        position: FilterLabelPosition.None,
        default_value_agg_fn: DefaultValueAggregation.Exact,
    });
    patchTreeSelect(
        { tree_select: {} as TreeSelectConfig } as FilterTemplate,
        form,
    );
    patchCustomSelect(form);
}

export function patchDefaultValue(
    value: any,
    form: Form<FilterTemplateUI>,
): void {
    if (hasAggFn(form.controls.original_default_value.value?.view)) {
        form.controls.original_default_value.patchValue(value);
    }

    if (isNullOrUndefined(value)) {
        form.controls.default_value_agg_fn.patchValue(
            DefaultValueAggregation.Exact,
            { emitEvent: false },
        );
        form.controls.default_value.reset();
        form.controls.original_default_value.reset();
        form.controls.has_default_value.reset(false);
    } else {
        form.controls.default_value.patchValue(value);
    }
}

function patchDatasetId(
    filterTemplate: FilterTemplate,
    form: Form<FilterTemplateUI>,
): void {
    const datasetId =
        filterTemplate.sub_type === FilterTemplateUIType.TreeView
            ? filterTemplate.tree_select?.dataset_id
            : filterTemplate.dataset_select?.dataset_id;

    if (!datasetId) return;

    form.controls.dataset_id.patchValue(datasetId);
}

function patchTreeSelect(
    filterTemplate: FilterTemplate,
    form: Form<FilterTemplateUI>,
): void {
    const treeColumns = filterTemplate.tree_select?.columns || [];
    const columnsControl = form.controls.tree_select.get(
        'columns',
    ) as FormArray<Form<TreeDataColumn>>;

    form.controls.tree_select.patchValue(filterTemplate.tree_select);

    const createControl = (treeColumn?: TreeDataColumn) => {
        const control: Form<TreeDataColumn> = new FormBuilder().group({
            view_id: [treeColumn?.view_id, [Validators.required]],
            key_id: [treeColumn?.key_id],
            sort: new FormBuilder().group({
                order_by: [treeColumn?.sort?.order_by],
                order_direction: [
                    treeColumn?.sort?.order_direction || SortDirection.Asc,
                ],
            }),
        }) as any;

        return control;
    };

    if (!treeColumns.length) {
        const control = createControl();

        columnsControl.push(control);
        return;
    }

    treeColumns.forEach((treeColumn) => {
        const control = createControl(treeColumn);

        columnsControl.push(control);
    });
}

function patchCustomSelect(
    form: Form<FilterTemplateUI>,
    filterTemplate?: FilterTemplate,
): void {
    const values = filterTemplate?.custom_select?.values || [];
    const valuesControl = form.controls.custom_select.get(
        'values',
    ) as FormArray;

    if (!values.length) {
        const control = createCustomSelectControl(
            form.controls.custom_select as any,
        );

        valuesControl.push(control);

        return;
    }

    values.forEach((value) => {
        const control = createCustomSelectControl(
            form.controls.custom_select as any,
            value,
        );

        valuesControl.push(control);
    });
}

function patchLocalFilters(
    filterTemplate: FilterTemplate,
    form: Form<FilterTemplateUI>,
): void {
    const filters =
        filterTemplate.sub_type === FilterTemplateUIType.TreeView
            ? filterTemplate.tree_select?.filters || []
            : filterTemplate.dataset_select?.filters || [];

    filters.forEach((filter) => {
        const control = new FormBuilder().group({
            logical: [filter.logical],
            column_id: [filter.column_id],
            action: [filter.action],
            value: [filter.value],
            child: [filter.child],
        });

        (form.get('filters') as FormArray).push(control);
    });
}

// общие методы

export function hasAggFn(value: any): boolean {
    return value?.hasOwnProperty('agg_fn');
}

export function removeExtraFields(
    form: Form<FilterTemplateUI>,
): Form<FilterTemplateUI> {
    const removeControlComparator = () => {
        return (
            isTreeView ||
            isCustomSelectMode ||
            hasNoDataset ||
            isSearch ||
            isDate ||
            isNumber
        );
    };

    const isTreeView =
        form.controls.sub_type.value === FilterTemplateUIType.TreeView;

    const isCustomSelectMode = Boolean(
        form.controls.custom_select.value?.name_suggest_column,
    );

    const isSearch = form.controls.type.value === FilterTemplateType.Search;

    const isDate = form.controls.type.value === FilterTemplateType.Date;

    const isNumber = form.controls.type.value === FilterTemplateType.Number;

    const hasNoDataset = Boolean(!form.controls.dataset_id.value);

    const newForm = cloneDeep(form);

    if (isCustomSelectMode) {
        newForm.removeControl('dataset_id');
    }

    if (removeControlComparator()) {
        newForm.removeControl('dataset_select');
    }

    if (!isCustomSelectMode) {
        newForm.removeControl('custom_select');
    }

    if (!isTreeView) {
        newForm.removeControl('tree_select');
    }

    return newForm;
}

export function createCustomSelectControl(
    customSelectForm: Form<FilterTemplateCustomData>,
    customRow?: FilterTemplateCustomRow,
): FormGroup {
    const { suggest_column_type, key_column_type, values } =
        customSelectForm.controls;

    const item = new FormBuilder().group({
        label: [, [Validators.required]] as any,
        key: [] as any,
    });

    if (suggest_column_type?.value === ColumnType.Number) {
        item.controls.label?.addValidators(realNumbersValidator);
    }

    if (key_column_type?.value === ColumnType.Number) {
        item.controls.key?.addValidators(realNumbersValidator);
    }

    if (customRow) item.patchValue(customRow);

    return item;
}
