import { Inject, Injectable } from '@angular/core';
import { not } from 'logical-not';

import { Environment, ENVIRONMENT } from '../../constants/environment';
import { AccessRight } from '../../enums/access';
import { FormatNumberAbbreviation } from '../../enums/column.format';
import { Lang } from '../../enums/lang';
import { ValidationErrorKey } from '../../enums/validation-error-key';
import { FormState } from '../form/form.service';
import { Composer } from './composer';
import { PluralRule } from './plural.enum';
import {
    TranslationsKeyInterpolationMap,
    TranslationsSettings,
} from './translate.interfaces';
import { Translations } from './translate.internal';
import {
    KeyInterpolationDefault,
    keyInterpolationDefaultToken,
} from './translate.internal.key-interpolation-default';

const interpolation = /\{\{(.+?)\}\}/g;

enum HttpStatusCode {
    Ok = 200,
    BadRequest = 400,
    Unauthorized = 401,
    PaymentRequired = 402,
    Forbidden = 403,
    NotFound = 404,
    InternalServerError = 500,
    NotImplemented = 501,
    BadGateway = 502,
    ServiceUnavailable = 503,
    GatewayTimeout = 504,
}

const baseMap = {
    AccessRight,
    FormatNumberAbbreviation,
    FormState,
    HttpStatusCode,
    Lang,
    PluralRule,
    ServerValidationErrorKey: {
        Exists: 'exists',
    },
    ValidationErrorKey,
};

Object.setPrototypeOf(baseMap, null);

@Injectable({ providedIn: 'root' })
export class TranslatePreparationService {
    map = new Composer(baseMap);

    constructor(
        @Inject(ENVIRONMENT)
        private environment: Environment,
    ) {}

    create(settings: TranslationsSettings, sourceItems: any[]): Translations {
        const translations: Translations = Object.create(null);

        const keyInterpolations = createKeyInterpolations(
            this.map.set(settings.type, settings.keyInterpolation),
            this.environment,
        );

        sourceItems.forEach((source) =>
            Object.entries(source || {}).forEach(([key, value]) => {
                const isDefault = addKeyInterpolationDefault(
                    translations,
                    key,
                    value,
                );

                if (not(isDefault))
                    translations[keyInterpolations(key)] = value;
            }),
        );

        return translations;
    }
}

function createKeyInterpolations(
    map: TranslationsKeyInterpolationMap,
    environment: Environment,
) {
    return (source: string): string => {
        return source.replace(
            interpolation,
            (_, interpolationSource: string) => {
                const parts = interpolationSource.split('.');

                if (parts.length < 2) return interpolationSource;

                const [key, property] = parts;

                if (not(key in map) || not(property in map[key])) {
                    if (environment !== Environment.Production) {
                        console.error(
                            `ошибка интерполяции "${interpolationSource}"`,
                        );

                        if (not(key in map))
                            console.error(`ошибка в ключе "${source}"`);
                        else
                            console.error(
                                `значение ключа "${property}" не найдено`,
                            );
                    }

                    return interpolationSource;
                }

                return String(map[key][property]);
            },
        );
    };
}

const keyInterpolationDefault = /\{\{\[default\]\}\}/;

function addKeyInterpolationDefault(
    translations: Translations,
    key: string,
    value: any,
): boolean {
    if (not(keyInterpolationDefault.test(key))) return false;

    const target: KeyInterpolationDefault = translations as any;

    if (not(keyInterpolationDefaultToken in target))
        target[keyInterpolationDefaultToken] = [];

    const pattern = key.replace(keyInterpolationDefault, '.*?');

    target[keyInterpolationDefaultToken].push({
        tester: new RegExp(`^${pattern}$`),
        value,
    });

    return true;
}
