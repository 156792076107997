import { Component, Input, OnInit } from '@angular/core';
import { SubscribableComponent } from 'ngx-subscribable';
import { Observable, throttle } from 'rxjs';

import { getHost } from '../../tools/get-host';

@Component({
    selector: 'core-lazy-loading',
    templateUrl: './lazy-loading.component.html',
    styleUrls: ['./lazy-loading.component.less'],
})
export class LazyLoadingComponent
    extends SubscribableComponent
    implements OnInit
{
    @Input()
    load!: () => Observable<unknown>;

    private readonly host = getHost();

    ngOnInit(): void {
        this.subscriptions = [
            onView(this.host).pipe(throttle(this.load)).subscribe(),
        ];
    }
}

function onView(element: HTMLElement): Observable<void> {
    return new Observable((subscriber) => {
        const observer = new IntersectionObserver(([{ isIntersecting }]) => {
            if (isIntersecting) subscriber.next();
        });

        observer.observe(element);

        return () => observer.unobserve(element);
    });
}
