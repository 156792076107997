import { ValueProvider } from '@angular/core';

import { TranslateType } from './translate.enums';
import {
    TranslationsKeyInterpolationMap,
    TranslationsSettings,
} from './translate.interfaces';
import { MainSettings, PageSettings } from './translate.internal';

export interface TranslationsProviderSettings {
    sources: string[];
    keyInterpolation?: TranslationsKeyInterpolationMap;
}

export function provideMainTranslations(
    settings: TranslationsProviderSettings,
): ValueProvider {
    const useValue: TranslationsSettings = {
        type: TranslateType.Main,
        sources: settings.sources,
        keyInterpolation: settings.keyInterpolation || {},
    };

    return { provide: MainSettings, useValue };
}

export function providePageTranslations(
    settings: TranslationsProviderSettings,
): ValueProvider {
    const useValue: TranslationsSettings = {
        type: TranslateType.Page,
        sources: settings.sources,
        keyInterpolation: settings.keyInterpolation || {},
    };

    return { provide: PageSettings, useValue };
}
