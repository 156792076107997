import { Injectable } from '@angular/core';
import not from 'logical-not';

import { BooleanFormatSettings } from '../formatter-settings/boolean.format-settings';
import { Formatter } from './formatter';

@Injectable({ providedIn: 'root' })
export class BooleanFormatter implements Formatter<BooleanFormatSettings> {
    format(formatSettings: BooleanFormatSettings, source: any): string {
        const { nullText, trueText, falseText } = formatSettings;

        if (source === null) {
            if (not(nullText)) return '';
            else return nullText;
        }

        return source ? trueText : falseText;
    }
}
