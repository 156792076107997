import { NgModule } from '@angular/core';

import { PopupComponent } from './popup.component';

export * from './popup.component';

@NgModule({
    exports: [PopupComponent],
    declarations: [PopupComponent],
})
export class PopupModule {}
