import { HttpStatusCode } from '@angular/common/http';
import { Component, OnInit, ViewChild, TemplateRef } from '@angular/core';
import { SlAlert } from '@shoelace-style/shoelace';
import { UpdateImmediately, WatchChanges } from 'ng-onpush';
import { SubscribableComponent } from 'ngx-subscribable';

import { TranslateService } from '../../modules/translate/translate.service';
import { NotifyComponent } from '../notify/notify.component';
import { AlertService } from './alert.service';

@Component({
    selector: 'core-alert',
    templateUrl: './alert.component.html',
    styleUrls: ['./alert.component.less'],
})
export class AlertComponent extends SubscribableComponent implements OnInit {
    @ViewChild(NotifyComponent, { static: true })
    notifyComponent!: NotifyComponent;

    @UpdateImmediately()
    text = '';

    @UpdateImmediately()
    variant: SlAlert['variant'] = 'danger';

    @UpdateImmediately()
    templateRef?: TemplateRef<HTMLElement | { value: HTMLElement }>;

    @WatchChanges()
    multiValue = false;

    @UpdateImmediately()
    context: any;

    constructor(
        private alertService: AlertService,
        private translateService: TranslateService,
    ) {
        super();
    }

    ngOnInit() {
        this.subscriptions = [
            this.alertService.show.subscribe((response) => {
                const { variant, responseError, params, multiValue } = response;

                this.multiValue = Boolean(multiValue);

                if (variant) {
                    this.variant = variant;
                }

                if ('template' in response) {
                    this.templateRef = response.template;
                    this.context = response.context;
                } else {
                    this.templateRef = this.context = undefined;
                }

                if (!responseError) {
                    if ('key' in response) {
                        this.text = multiValue
                            ? response.key
                            : this.translateService.getTranslation(
                                  response.key,
                                  params,
                              );
                    } else this.text = '';

                    setTimeout(() => {
                        this.notifyComponent.toast();
                    }, 0);

                    return;
                }

                const { status } = responseError;

                const message =
                    responseError.error.message || responseError.message;

                if (status === HttpStatusCode.BadRequest) return;
                if (status === HttpStatusCode.Unauthorized) return;

                const isInternalServerError =
                    status === HttpStatusCode.InternalServerError && message;

                this.variant = 'danger';

                if ('key' in response) {
                    if (isInternalServerError) {
                        this.text = this.translateService.getTranslation(
                            response.key,
                            {
                                error: message,
                            },
                        );
                    } else {
                        this.text = this.translateService.getTranslation(
                            response.key,
                            {
                                error: this.translateService.getTranslation(
                                    `_$.responseError.${
                                        status >
                                        HttpStatusCode.InternalServerError
                                            ? HttpStatusCode.InternalServerError
                                            : status
                                    }`,
                                ),
                            },
                        );
                    }
                }

                setTimeout(() => {
                    this.notifyComponent.toast();
                }, 0);
            }),
        ];
    }
}
