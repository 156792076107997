import {
    Component,
    ContentChildren,
    EventEmitter,
    Input,
    OnInit,
    Output,
    QueryList,
    ViewContainerRef,
} from '@angular/core';
import { not } from 'logical-not';
import { SubscribableComponent } from 'ngx-subscribable';
import { Observable, fromEvent, switchMap, takeUntil, tap } from 'rxjs';

import { getHost } from '../../../tools/get-host';
import { ListItemComponent } from './list-item/list-item.component';

const { selectedClass } = ListItemComponent;

const scrollLockKeys = [
    'Space',
    'ArrowUp',
    'ArrowDown',
    'ArrowLeft',
    'ArrowRight',
];

export interface ListController {
    show: Observable<void>;
    hide: Observable<void>;
    key: Observable<string>;
}

@Component({
    selector: 'core-list',
    templateUrl: './list.component.html',
    styleUrls: ['./list.component.less'],
})
export class ListComponent extends SubscribableComponent implements OnInit {
    @Input()
    controller!: ListController;

    @Output()
    select = new EventEmitter<number>();

    @ContentChildren(ListItemComponent, {
        read: ViewContainerRef,
        descendants: false,
    })
    private items!: QueryList<ViewContainerRef>;

    private readonly host = getHost();

    private index = -1;

    ngOnInit(): void {
        const {
            controller: { show, hide, key },
            select,
        } = this;

        const scrollLock = show.pipe(
            switchMap(() =>
                fromEvent<KeyboardEvent>(window, 'keydown').pipe(
                    takeUntil(hide),
                    tap((event) => {
                        if (scrollLockKeys.includes(event.key))
                            event.preventDefault();
                    }),
                ),
            ),
        );

        this.subscriptions = [
            show.subscribe(() => {
                this.index = -1;
            }),

            key.subscribe((key) => {
                switch (key) {
                    case 'Enter':
                        if (this.index !== -1) select.emit(this.index);
                        break;
                    case 'ArrowUp':
                        this.changeIndex(-1);
                        break;
                    case 'ArrowDown':
                        this.changeIndex(+1);
                        break;
                }
            }),

            scrollLock.subscribe(),
        ];
    }

    private changeIndex(delta: number): void {
        this.getCurrent()?.classList.remove(selectedClass);

        const prev = this.index;

        if (prev === -1) this.index = 0;
        else this.index += delta;

        const last = this.items.length - 1;

        if (this.index < 0) this.index = last;
        else if (this.index > last) this.index = 0;

        const item = this.getCurrent();

        if (not(item)) return;

        item.classList.add(selectedClass);

        const itemRect = item.getBoundingClientRect();
        const hostRect = this.host.getBoundingClientRect();

        const block: ScrollLogicalPosition =
            this.index > prev ? 'end' : 'start';

        const scrollRequired =
            block === 'end'
                ? itemRect.bottom > hostRect.bottom
                : itemRect.top < hostRect.top;

        if (scrollRequired) item.scrollIntoView({ block });
    }

    private getCurrent(): HTMLElement | undefined {
        return this.items.get(this.index)?.element.nativeElement;
    }
}
