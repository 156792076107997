import { mutation_observer_styles_default } from "./chunk.2W6X55FG.js";
import { watch } from "./chunk.2FB5TK5H.js";
import { component_styles_default } from "./chunk.TUVJKY7S.js";
import { ShoelaceElement } from "./chunk.SFSTXCXC.js";
import { __decorateClass } from "./chunk.IFDWM6P4.js";

// src/components/mutation-observer/mutation-observer.component.ts
import { html } from "lit";
import { property } from "lit/decorators.js";
var SlMutationObserver = class extends ShoelaceElement {
  constructor() {
    super(...arguments);
    this.attrOldValue = false;
    this.charData = false;
    this.charDataOldValue = false;
    this.childList = false;
    this.disabled = false;
    this.handleMutation = mutationList => {
      this.emit("sl-mutation", {
        detail: {
          mutationList
        }
      });
    };
  }
  connectedCallback() {
    super.connectedCallback();
    this.mutationObserver = new MutationObserver(this.handleMutation);
    if (!this.disabled) {
      this.startObserver();
    }
  }
  disconnectedCallback() {
    super.disconnectedCallback();
    this.stopObserver();
  }
  startObserver() {
    const observeAttributes = typeof this.attr === "string" && this.attr.length > 0;
    const attributeFilter = observeAttributes && this.attr !== "*" ? this.attr.split(" ") : void 0;
    try {
      this.mutationObserver.observe(this, {
        subtree: true,
        childList: this.childList,
        attributes: observeAttributes,
        attributeFilter,
        attributeOldValue: this.attrOldValue,
        characterData: this.charData,
        characterDataOldValue: this.charDataOldValue
      });
    } catch (e) {}
  }
  stopObserver() {
    this.mutationObserver.disconnect();
  }
  handleDisabledChange() {
    if (this.disabled) {
      this.stopObserver();
    } else {
      this.startObserver();
    }
  }
  handleChange() {
    this.stopObserver();
    this.startObserver();
  }
  render() {
    return html` <slot></slot> `;
  }
};
SlMutationObserver.styles = [component_styles_default, mutation_observer_styles_default];
__decorateClass([property({
  reflect: true
})], SlMutationObserver.prototype, "attr", 2);
__decorateClass([property({
  attribute: "attr-old-value",
  type: Boolean,
  reflect: true
})], SlMutationObserver.prototype, "attrOldValue", 2);
__decorateClass([property({
  attribute: "char-data",
  type: Boolean,
  reflect: true
})], SlMutationObserver.prototype, "charData", 2);
__decorateClass([property({
  attribute: "char-data-old-value",
  type: Boolean,
  reflect: true
})], SlMutationObserver.prototype, "charDataOldValue", 2);
__decorateClass([property({
  attribute: "child-list",
  type: Boolean,
  reflect: true
})], SlMutationObserver.prototype, "childList", 2);
__decorateClass([property({
  type: Boolean,
  reflect: true
})], SlMutationObserver.prototype, "disabled", 2);
__decorateClass([watch("disabled")], SlMutationObserver.prototype, "handleDisabledChange", 1);
__decorateClass([watch("attr", {
  waitUntilFirstUpdate: true
}), watch("attr-old-value", {
  waitUntilFirstUpdate: true
}), watch("char-data", {
  waitUntilFirstUpdate: true
}), watch("char-data-old-value", {
  waitUntilFirstUpdate: true
}), watch("childList", {
  waitUntilFirstUpdate: true
})], SlMutationObserver.prototype, "handleChange", 1);
export { SlMutationObserver };