import {
    Component,
    ElementRef,
    EventEmitter,
    Input,
    Output,
    ViewChild,
} from '@angular/core';

import {
    SlDropdown,
    SlHideEvent,
    SlMenuItem,
    SlPopup,
    SlShowEvent,
} from '@shoelace-style/shoelace';

import { SubscribableComponent } from 'ngx-subscribable';

import { getHost } from '../../tools/get-host';

const topLevelSelector = 'sl-dropdown > sl-menu > sl-menu-item';
const internalSelector = 'sl-menu > sl-menu-item';

const placementAtrr = 'data-current-placement';

@Component({
    selector: 'plmt-dropdown',
    templateUrl: './plmt-dropdown.component.html',
    styleUrls: ['./plmt-dropdown.component.less'],
})
export class PlmtDropdownComponent extends SubscribableComponent {
    @Input()
    placement: SlDropdown['placement'] = 'bottom';

    @Input()
    distance: SlDropdown['distance'] = 0;

    @Input()
    skidding: SlDropdown['skidding'] = 0;

    @Input()
    containingElement: SlDropdown['containingElement'] = void 0;

    @Input()
    hoist: '' | undefined = '';

    @Output('sl-show')
    slShowEvent = new EventEmitter<SlShowEvent>();

    @Output('sl-hide')
    slHideEvent = new EventEmitter<SlHideEvent>();

    @Output('sl-after-show')
    slAfterShowEvent = new EventEmitter<SlShowEvent>();

    @Output('sl-after-hide')
    slAfterHideEvent = new EventEmitter<SlHideEvent>();

    @ViewChild('dropdown', { read: ElementRef<SlDropdown>, static: true })
    dropdownElement!: ElementRef<SlDropdown>;

    host = getHost();

    constructor() {
        super();

        this.subscriptions = [
            this.slShowEvent.subscribe(() => {
                const topLevelMenuItems =
                    this.host.querySelectorAll<SlMenuItem>(topLevelSelector);

                this.createHandlers(topLevelMenuItems);
            }),

            this.slAfterHideEvent.subscribe(() =>
                this.host.removeAttribute('open'),
            ),

            this.slAfterShowEvent.subscribe(() =>
                this.host.setAttribute('open', ''),
            ),
        ];
    }

    private subMenuStylization(item: SlMenuItem): void {
        const slPopup = item.shadowRoot?.querySelector<SlPopup>('sl-popup');

        if (!slPopup) return;

        this.setDefaultPopupStyles(slPopup);

        const internalItems =
            item.querySelectorAll<SlMenuItem>(internalSelector);

        this.createHandlers(internalItems);
    }

    private createHandlers(items: NodeListOf<SlMenuItem>): void {
        items.forEach((item) => {
            if (item.isSubmenu()) this.subMenuStylization(item);
        });
    }

    private setDefaultPopupStyles(slPopup: SlPopup): void {
        slPopup.skidding = 0;
        slPopup.popup.style.borderRadius = '8px';

        this.setPopupPlacement(slPopup);
    }

    private setPopupPlacement(popup: SlPopup): void {
        const callback: MutationCallback = (mutationsList) => {
            let isDistanceChanged = false;

            mutationsList.forEach((mutation) => {
                if (isDistanceChanged) return;

                if (mutation.attributeName !== placementAtrr) return;

                const placement = popup.getAttribute(placementAtrr);

                popup.distance = placement?.includes('right') ? 8 : 4;

                isDistanceChanged = true;
            });
        };

        const mutationObserver = new MutationObserver(callback);

        mutationObserver.observe(popup, { attributes: true });
    }

    show(): void {
        this.dropdownElement.nativeElement.show();
    }

    hide(): void {
        this.dropdownElement.nativeElement.hide();
    }
}
