<core-notify [variant]="variant">
    <ng-container *ngIf="multiValue; else simpleKey">
        <ng-container *translate="text; let key">
            <div *ngIf="key?.title" class="block-save-title">
                {{ key.title }}
            </div>
            <div *ngFor="let line of key?.lines" class="block-save-description">
                {{ line }}
            </div>
        </ng-container>
    </ng-container>
    <ng-template #simpleKey>
        {{ text }}
        <ng-container
            *ngIf="templateRef"
            [ngTemplateOutlet]="templateRef"
            [ngTemplateOutletContext]="{ value: context }"
        ></ng-container
    ></ng-template>
</core-notify>
