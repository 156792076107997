import { isNull, isUndefined } from 'lodash';

export function valueToNumber(value: any): '' | number {
    if (isUndefined(value) || isNull(value)) return '';

    if (typeof value === 'number') return value;

    if (typeof value === 'string') {
        if (value.includes(',')) value = value.replace(',', '.');

        if (value.trim() === '' || isNaN(Number(value))) return '';

        return Number(value);
    }

    return '';
}
