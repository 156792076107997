import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { DataApiService, DataResponse } from '../../../api/data-api.service';
import { DataOption } from '../../../interfaces/data-option';

@Injectable()
export class TreeViewApiService {
    constructor(private dataApiService: DataApiService) {}

    load(dataOption: DataOption): Observable<DataResponse> {
        return this.dataApiService.getData({
            data_options: [dataOption],
            get_columns: true,
        });
    }
}
