import { AbstractControl, ValidatorFn } from '@angular/forms';

import { ValidationErrorKey } from '../enums/validation-error-key';

export interface IntegerValidatorParams {
    possibleNegative?: boolean;
}

export interface IntegerValidatorError {
    [ValidationErrorKey.Integer]: null;
}

export function integerValidator(params?: IntegerValidatorParams): ValidatorFn {
    const regExp = params?.possibleNegative ? /^-?\d+$/ : /^\d+$/;

    return ({ value }: AbstractControl): IntegerValidatorError | null => {
        if (regExp.test(value)) return null;

        return { [ValidationErrorKey.Integer]: null };
    };
}
