import { Injectable } from '@angular/core';

const STORE_KEY = 'downloadedFilesPaths';

@Injectable({
    providedIn: 'root',
})
export class DownloadedFilesService {
    constructor() {
        localStorage.setItem(STORE_KEY, JSON.stringify([]));
    }

    fileDownloaded(path: string): void {
        const data = JSON.parse(localStorage.getItem(STORE_KEY) || '[]');
        localStorage.setItem(STORE_KEY, JSON.stringify([...data, path]));
    }

    checkFile(path: string): boolean {
        const data = JSON.parse(
            localStorage.getItem(STORE_KEY) || '[]',
        ) as string[];
        return data.includes(path);
    }
}
