import {
    Component,
    ElementRef,
    EventEmitter,
    Input,
    OnChanges,
    OnDestroy,
    OnInit,
    Output,
    SimpleChanges,
    ViewChild,
} from '@angular/core';
import { DxTreeListComponent } from 'devextreme-angular';
import CustomStore from 'devextreme/data/custom_store';
import DataSource from 'devextreme/data/data_source';
import DxTreeList, { SelectionChangedEvent } from 'devextreme/ui/tree_list';
import { cloneDeep, isEqual, isUndefined } from 'lodash';
import { SubscribableComponent } from 'ngx-subscribable';
import { forkJoin, lastValueFrom, map, Observable, of, tap } from 'rxjs';

import { WidgetDependence } from 'dashboard/src/app/modules/binding-settings/services/binding-settings-query.service';
import { DataResponse } from '../../api/data-api.service';
import {
    DATA_KEY_PATH,
    DATA_VIEW_PATH,
} from '../../constants/filter-default-value-keys';
import { KeyView } from '../../interfaces/filter-template';
import { AppService } from '../../services/app.service';
import { FormatService } from '../../services/format.service/format.service';
import {
    makeSearchTreeViewFilterDataOption,
    makeTreeViewFilterDataOption,
} from './helpers/get-tree-view-data-option';
import {
    ParentInfo,
    buildFormatter,
    convertToFormattedData,
    convertToTreeData,
    injectTreeNodeAdditionalInfo,
    mapFilteredDataToTreeDataSource,
} from './helpers/tree-data-mapper';
import {
    childNodeHasItems,
    createMockTreeData,
    getIdByViewFromTreeNodes,
    getNodesAsKeyValueArray,
} from './helpers/tree-view.helper';
import {
    AvailableColumnDataType,
    DATA_ID_PATH,
    TreeDataRow,
    TREE_DELIMITER,
    TreeDataColumn,
    TreeNode,
    TreeResponseArray,
    BoundWidgetValue,
    BoundWidgetData,
    TreeSelectConfig,
} from './interfaces/tree-view.interface';
import { TreeViewApiService } from './services/tree-view-api.service';
import { TreeViewService } from './services/tree-view.service';

import { isKeyViewValue } from '../filter-value/null.helper';

@Component({
    selector: 'app-tree-view',
    templateUrl: './tree-view.component.html',
    styleUrls: ['./tree-view.component.less'],
})
export class TreeViewComponent
    extends SubscribableComponent
    implements OnInit, OnChanges, OnDestroy
{
    @Input()
    config!: TreeSelectConfig;

    @Input()
    defaultValue: KeyView[] = [];

    @Input()
    useLastSelectedValue = false;

    @Input()
    dependence?: WidgetDependence;

    @Input()
    isDisabled: boolean = false;

    @Input()
    inputHeight?: string;

    @Input()
    label?: string;

    @Output()
    changeData = new EventEmitter<AvailableColumnDataType[]>();

    @ViewChild(DxTreeListComponent)
    treeView?: DxTreeListComponent;

    @ViewChild('filterRow')
    filterRow?: ElementRef;

    selectedNodeId?: AvailableColumnDataType = null;
    dataSource!: DataSource;

    previewValueFilter?: KeyView[];

    readonly DATA_KEY_PATH = DATA_KEY_PATH;
    readonly DATA_VIEW_PATH = DATA_VIEW_PATH;
    readonly DATA_ID_PATH = DATA_ID_PATH;
    readonly ROOT_VALUE = null;

    private shouldNavigate = true;
    private mutableDefaultValue: KeyView[] = [];
    private boundWidgetsValues: BoundWidgetValue[] = [];

    private isLoading = false;
    private waitForDependenceDataIsSet = true;
    private datasetId?: number;
    private needToLoadData = false;
    private stopLoadingAfterRefresh = false;

    get treeInstance(): DxTreeList | undefined {
        return this.treeView?.instance;
    }

    get isTreeOnLayer(): boolean {
        return Boolean(this.dependence);
    }

    constructor(
        private treeViewApiService: TreeViewApiService,
        private formatService: FormatService,
        private treeViewService: TreeViewService,
        private appService: AppService,
    ) {
        super();
    }

    ngOnInit(): void {
        if (!this.isTreeOnLayer) {
            this.waitForDependenceDataIsSet = false;

            this.init();
        } else if (this.dependence?.data) {
            this.handleDependenceChange();
        }

        this.datasetId = this.config.dataset_id;

        this.subscriptions = [
            this.treeViewService.treeColumns$.subscribe((columns) => {
                this.handleTreeColumnsChange(columns);
            }),

            this.treeViewService.boundWidgetData$.subscribe((data) => {
                if (!data) return;

                this.handleBoundWidgetChange(data);
            }),

            this.treeViewService.updateValueOnLayer.subscribe((data) => {
                if (
                    !isEqual(
                        this.treeViewService.currentState$.value.value,
                        this.previewValueFilter,
                    )
                ) {
                    this.handleUpdateValueOnLayer(data.shouldReset);
                }
            }),
        ];

        if (this.dependence?.onChange) {
            this.subscriptions.push(
                this.dependence.onChange.subscribe(() => {
                    this.handleDependenceChange();
                }),
            );
        }
    }

    ngOnChanges(changes: SimpleChanges): void {
        const configKey = 'config';

        if (configKey in changes) {
            const { currentValue, previousValue } = changes[configKey];

            if (isEqual(currentValue?.dataset_id, previousValue?.dataset_id))
                return;

            this.handleDatasetChange(currentValue.dataset_id);
        }
    }

    displayExpr(item: TreeNode): string {
        if (!item || !item.data) return '';

        const getDisplayValue = (node: TreeNode) => {
            const result: string[] = [];

            while (node.parent) {
                result.unshift(node.data.view);
                node = node.parent;
            }

            return result.join(' - ');
        };

        return getDisplayValue(item);
    }

    triggerTreeFilter(event: SelectionChangedEvent): void {
        if (!event.selectedRowKeys || !event.selectedRowKeys.length) return;

        this.selectedNodeId =
            event.selectedRowKeys[event.selectedRowKeys.length - 1];

        const selectedNode = this.getNodeByKey(this.selectedNodeId);

        if (!selectedNode) return;

        const views = getNodesAsKeyValueArray(selectedNode).reverse();

        if (!this.defaultValue || !this.defaultValue.length) {
            this.changeData.emit(views);
        }

        if (views.length === this.defaultValue.length) {
            this.changeData.emit(views);
            this.defaultValue = [];
        }
    }

    changeSelectedKey(key: string): void {
        this.selectedNodeId = key;

        const selectedNode = this.getNodeByKey(key);
        const selectedValue = selectedNode
            ? getNodesAsKeyValueArray(selectedNode).reverse()
            : undefined;
        this.treeViewService.currentState$.next({ value: selectedValue });

        this.previewValueFilter =
            this.treeViewService.currentState$.value.value;

        if (key) return;

        this.cleanTreeFilter();

        this.changeData.emit(undefined);
    }

    setSelected(): void {
        if (!this.mutableDefaultValue?.length) {
            this.navigateToSelectedRow();
            return;
        }

        if (this.mutableDefaultValue.length) {
            const cuttedInitialArr = this.mutableDefaultValue.shift();
            const parentNode = this.getNodeByKey(this.selectedNodeId);
            const parentChildren = parentNode?.children || [];

            const id = getIdByViewFromTreeNodes(
                parentChildren,
                cuttedInitialArr?.view,
            );

            this.selectedNodeId = id;

            this.selectRow();
            this.expandRow();
        }
    }

    onOpened(): void {
        if (isUndefined(this.dataSource)) {
            this.setCustomStore();
            return;
        }

        this.treeInstance?.refresh();
        this.needToLoadData = false;
    }

    private init(): void {
        if (this.isLoading) return;

        const isExport = !this.appService.user.value.id;

        if (isExport) {
            this.createMockTreeData();

            return;
        }

        if (!this.config.columns.length) {
            this.config = this.treeViewService.getDefaultTreeSelect();
        }

        const lastSelectedValue =
            this.treeViewService.currentState$.value.value;

        const defaultValue =
            !Array.isArray(this.defaultValue) &&
            isKeyViewValue(this.defaultValue)
                ? this.defaultValue[DATA_VIEW_PATH]
                : this.defaultValue;

        const priorityValue = this.useLastSelectedValue
            ? lastSelectedValue || defaultValue
            : defaultValue;

        this.resetMutableValue(priorityValue);
        this.cleanTreeFilter();

        if (priorityValue) {
            this.setCustomStore();
        }
    }

    private setCustomStore(): void {
        if (this.dataSource) return;

        this.dataSource = new DataSource({
            store: new CustomStore<TreeNode>({
                load: (loadOptions) => {
                    if (
                        this.waitForDependenceDataIsSet ||
                        this.stopLoadingAfterRefresh
                    ) {
                        this.waitForDependenceDataIsSet = false;
                        this.stopLoadingAfterRefresh = false;

                        return this.dataSource.items();
                    }

                    const parentIds: any[] | undefined = loadOptions?.parentIds;
                    const fieldToFilter = loadOptions.filter[0];

                    if (fieldToFilter === DATA_VIEW_PATH) {
                        return lastValueFrom(
                            this.filterData(loadOptions.filter),
                        );
                    }

                    if (!parentIds) {
                        return this.dataSource.items();
                    }

                    const lastValue = parentIds[parentIds.length - 1];
                    const parentId =
                        lastValue === this.ROOT_VALUE ? undefined : lastValue;

                    return lastValueFrom(this.loadData(parentId));
                },
                byKey: (key: string) => {
                    const item = this.getNodeByKey(key);

                    return new Promise((resolve) => {
                        resolve(item || ({} as TreeNode));
                    });
                },
            }),
        });
    }

    private filterData(
        filter: string[],
    ): Observable<TreeResponseArray['rows']> {
        const observables: Observable<DataResponse>[] = [];

        const columns = this.config.columns;

        columns.forEach((_, index) => {
            const columnsToSearch =
                index === 0 ? columns : columns.slice(0, -index);

            const dataOption = makeSearchTreeViewFilterDataOption(
                columnsToSearch,
                this.datasetId!,
                filter,
                this.config.filters,
            )!;

            observables.push(this.treeViewApiService.load(dataOption));
        });

        return forkJoin(observables).pipe(
            map((data) =>
                mapFilteredDataToTreeDataSource(data, this.formatService),
            ),
        );
    }

    private loadData(parentId?: number): Observable<TreeResponseArray['rows']> {
        const columnsAreEmpty = this.config?.columns?.every(
            (column) => !column.view_id,
        );

        if (!this.config.columns?.length || columnsAreEmpty) {
            return of([]);
        }

        if (!this.datasetId) {
            return of([]);
        }

        const parentNode = this.getNodeByKey(parentId);

        if (!parentNode && this.dataSource.items()?.length) {
            this.dataSource.reload();
            return of([]);
        }

        const hasItems = childNodeHasItems(this.config, parentNode);

        const dataOption = makeTreeViewFilterDataOption(
            this.config,
            parentNode,
            this.boundWidgetsValues,
            this.config.filters,
        )!;

        this.shouldNavigate = true;
        this.isLoading = true;
        return this.treeViewApiService.load(dataOption).pipe(
            convertToFormattedData(this.formatService),
            injectTreeNodeAdditionalInfo(hasItems, parentNode),
            map((data) => {
                return data.map((item) => {
                    if (item.view === 'undefined') {
                        return {
                            ...item,
                            view: 'null',
                            originalView: null,
                        };
                    }

                    return item;
                });
            }),
            tap((data) => {
                this.handleEmptyData(data);
                this.isLoading = false;
            }),
        );
    }

    private expandRow(): void {
        const node = this.getNodeByKey(this.selectedNodeId);

        if (node && this.mutableDefaultValue.length) {
            this.treeInstance?.expandRow(this.selectedNodeId);
        }
    }

    private selectRow(): void {
        this.treeInstance?.selectRows([this.selectedNodeId], true);
    }

    private cleanTreeFilter(): void {
        if (!this.dataSource) return;

        this.selectedNodeId = null;
        this.collapseAllNodes();
    }

    private navigateToSelectedRow(): void {
        if (!this.shouldNavigate) return;

        const firstOfSelected = this.selectedNodeId
            ?.toString()
            .split(TREE_DELIMITER)[0];
        const node = this.getNodeByKey(firstOfSelected);

        if (node) {
            this.treeInstance?.navigateToRow(firstOfSelected);
        }

        this.shouldNavigate = false;
    }

    private getNodeByKey(key?: string | number): TreeNode | undefined {
        return this.treeInstance?.getNodeByKey(key) as TreeNode | undefined;
    }

    private resetMutableValue(value: KeyView[]): void {
        this.mutableDefaultValue = cloneDeep(value);
        this.defaultValue = cloneDeep(value);
    }

    private collapseAllNodes(): void {
        const hasFirstNode = this.treeInstance?.getNodeByKey('0');

        if (!hasFirstNode) return;

        this.stopLoadingAfterRefresh = true;
        this.needToLoadData = true;
        this.treeInstance?.option('autoExpandAll', false);
        this.treeInstance?.refresh();
    }

    private createMockTreeData(): void {
        const mockData = createMockTreeData(this.defaultValue);

        this.dataSource = new DataSource(mockData);
        this.selectedNodeId = Array.from(
            Array(this.defaultValue.length).keys(),
        ).join('/');
    }

    private extractDataFromDependence(): void {
        if (!this.dependence?.data) return;

        const formatter = buildFormatter(
            this.dependence.data.data.columns,
            this.formatService,
        );
        const formattedData = {
            rows: convertToTreeData(
                cloneDeep(this.dependence.data.data.columns!),
                cloneDeep(this.dependence.data.data.rows),
            ),
            formatter,
        };

        const treeRows: TreeDataRow[] = [];

        formattedData.rows.forEach((row, index) => {
            const parentInfo: ParentInfo = {
                id: index.toString(),
                parentId: this.ROOT_VALUE,
            };

            treeRows.push({
                id: parentInfo.id,
                view: formatter(row.view),
                originalView: row.view,
                parentId: parentInfo.parentId,
                key: row.key,
                hasItems: this.config.columns.length > 1,
            });
        });

        this.setCustomStore();
        this.dataSource.items().push(...treeRows);
        this.datasetId = this.dependence.data.data.dataset?.id;
        this.init();
    }

    private handleDependenceChange(): void {
        if (!this.dependence?.data) return;

        // NOTE: данные первого уровня всегда пытаемся взять из dependence.data,
        // т.к. они уже были получены в project.service
        if (!this.dataSource?.items()?.length) {
            this.extractDataFromDependence();

            return;
        }

        this.init();
    }

    private handleTreeColumnsChange(actualColumns: TreeDataColumn[]): void {
        const columnsAreEmpty = actualColumns.every(
            (column) => !column.view_id,
        );
        const firstColumnIsEqual = isEqual(
            actualColumns[0],
            this.config.columns[0],
        );

        this.cleanTreeFilter();

        if (firstColumnIsEqual || columnsAreEmpty) {
            return;
        }

        this.needToLoadData = true;
        this.config.columns = cloneDeep(actualColumns);
    }

    private handleDatasetChange(datasetId: number): void {
        this.datasetId = datasetId;
        this.cleanTreeFilter();
    }

    private handleEmptyData(data: TreeDataRow[]): void {
        if (!data || !data.length) {
            this.mutableDefaultValue = [];
        }
    }

    private handleBoundWidgetChange({
        widgetBindings,
        value,
        isUrlFilter,
    }: BoundWidgetData): void {
        if (isUrlFilter) {
            if (isEqual(value, this.defaultValue)) return;

            this.resetMutableValue(value);

            return;
        }

        const bindingWithCurrentTreeComponent = widgetBindings.find(
            (b: any) => b.bind_with_uid === this.dependence?.uid,
        );

        if (
            !bindingWithCurrentTreeComponent ||
            !bindingWithCurrentTreeComponent.column_id
        )
            return;

        const existedColumn = this.boundWidgetsValues.find(
            (value) =>
                value.columnId === bindingWithCurrentTreeComponent.column_id,
        );

        if (value) {
            this.cleanTreeFilter();

            if (existedColumn) {
                existedColumn.value = value;
            } else {
                this.boundWidgetsValues.push({
                    value,
                    columnId: bindingWithCurrentTreeComponent.column_id,
                });
            }
        }

        const widgetsValuesAreEmpty = this.boundWidgetsValues.every(
            (widgetValue) => !widgetValue.value.length,
        );

        if (widgetsValuesAreEmpty) {
            this.resetMutableValue(this.defaultValue);
            this.needToLoadData = true;

            return;
        }

        const upperTreeLevelId = this.config.columns[0].view_id;

        if (bindingWithCurrentTreeComponent.column_id === upperTreeLevelId) {
            this.needToLoadData = true;
        }

        this.collapseAllNodes();
    }

    private handleUpdateValueOnLayer(shouldReset?: boolean): void {
        const lastSelected = shouldReset
            ? this.defaultValue
            : this.treeViewService.currentState$.value.value;

        const firstLevel = (lastSelected || [])[0];
        const shiftedArray = lastSelected?.slice(1);

        const data = lastSelected?.length
            ? getIdByViewFromTreeNodes(this.dataSource.items(), firstLevel.view)
            : null;

        this.resetMutableValue(shiftedArray || []);

        this.selectedNodeId = data;

        if (lastSelected && lastSelected.length > 1) {
            this.expandRow();
        }
    }

    ngOnDestroy(): void {
        this.treeViewService.boundWidgetData$.next(null);

        if (!this.useLastSelectedValue) {
            this.treeViewService.currentState$.next({ value: undefined });
        }
    }
}
