<label (click)="input.focus()">{{ label }}</label>

<div class="{{ size }}" [class.icon]="icon">
    <material-icon>{{ icon }}</material-icon>

    <input
        #input
        [type]="type"
        [value]="value || ''"
        [placeholder]="placeholder ?? ('_$.input.search' | translate)"
        [disabled]="disabled"
        (input)="emitInput($event)"
        (change)="emitChange($event)"
    />

    <material-icon
        *ngIf="clearable === ''"
        class="clear"
        (click)="changeValue.emit((value = ''))"
    >
        close
    </material-icon>
</div>
