<sl-popup
    class="popup"
    strategy="fixed"
    [active]="active"
    [placement]="placement"
    [attr.flip]="flip"
    [attr.arrow]="arrow"
    [attr.distance]="distance"
    [attr.arrow-placement]="arrowPlacement"
>
    <ng-content select="[trigger]"></ng-content>

    <div class="hint-popup" [style.width]="width">
        <div class="content {{ variant }}">
            <sl-icon *ngIf="icon" [name]="icon"></sl-icon>

            <div class="text">
                <p class="header">{{ label }}</p>
                <p *ngIf="description" class="description">{{ description }}</p>
            </div>

            <sl-icon-button name="x" (click)="active = false"></sl-icon-button>
        </div>

        <div *ngIf="buttonCancelText || buttonApplyText" class="actions">
            <core-button
                *ngIf="buttonCancelText"
                size="small"
                variant="outline"
                (buttonClick)="cancel()"
            >
                {{ buttonCancelText }}
            </core-button>

            <core-button
                *ngIf="buttonApplyText"
                size="small"
                variant="neutral"
                (buttonClick)="apply()"
            >
                {{ buttonApplyText }}
            </core-button>
        </div>
    </div>
</sl-popup>
