import { setCorrectLocalFilterValues } from '../components/filter-value/null.helper';
import { makeTreeViewFilterDataOption } from '../components/tree-view/helpers/get-tree-view-data-option';
import { FilterDataOptionKey } from '../constants/filter-data-option-key';
import {
    DATA_KEY_PATH,
    DATA_VIEW_PATH,
} from '../constants/filter-default-value-keys';
import {
    DataOptionAggregateFunction,
    DataOptionMethod,
} from '../enums/data-option';
import { FilterTemplateUIType } from '../enums/filter-ui-type';
import { DefaultValueAggregation } from '../enums/radio-min-max-state.enum';
import { SortDirection } from '../enums/sort';
import {
    DataOption,
    DataOptionDataBlockColumn,
} from '../interfaces/data-option';
import { FilterTemplate } from '../interfaces/filter-template';

export function createFilterDataOption(
    template: FilterTemplate,
    offset: number,
    aggFn?: DefaultValueAggregation,
): DataOption | null {
    switch (template.sub_type) {
        case FilterTemplateUIType.TreeView:
            return makeTreeViewFilterDataOption(template.tree_select!);
        default:
            return createCommonFilterDataOption(template, offset, aggFn);
    }
}

function createCommonFilterDataOption(
    template: FilterTemplate,
    offset: number,
    aggFn?: DefaultValueAggregation,
): DataOption | null {
    if (template?.dataset_select?.dataset_id) {
        const sort: DataOptionDataBlockColumn[] = [];

        if (template.dataset_select) {
            if (
                template.dataset_select?.order_by &&
                template.dataset_select.order_by !==
                    template.dataset_select.view_column_id
            ) {
                sort.push({
                    column_id: template?.dataset_select?.order_by,
                    agg_fn:
                        template?.dataset_select?.order_direction ===
                        SortDirection.Asc
                            ? DataOptionAggregateFunction.Min
                            : DataOptionAggregateFunction.Max,
                });
            }
        }

        const blocks = [];

        if (template.dataset_select.key_column_id) {
            blocks.push({
                key: DATA_KEY_PATH,
                columns: [
                    {
                        column_id: template.dataset_select.key_column_id,
                        agg_fn: DataOptionAggregateFunction.Min,
                    },
                ],
            });
        }

        if (template.dataset_select.filters?.length) {
            setCorrectLocalFilterValues(template.dataset_select.filters);
        }

        return {
            key: FilterDataOptionKey,
            dataset_id: template.dataset_select.dataset_id,
            offset,
            method: DataOptionMethod.Aggregate,
            sort: [
                {
                    column_id: +template.dataset_select.order_by,
                    direction: template.dataset_select.order_direction,
                },
            ],
            filters: [...(template.dataset_select.filters || [])],
            blocks: [
                {
                    key: DATA_VIEW_PATH,
                    columns: [
                        {
                            column_id: template.dataset_select.view_column_id,
                            agg_fn:
                                aggFn === DefaultValueAggregation.Max
                                    ? DataOptionAggregateFunction.Max
                                    : aggFn === DefaultValueAggregation.Min
                                    ? DataOptionAggregateFunction.Min
                                    : DataOptionAggregateFunction.Group,
                        },
                        ...sort,
                    ],
                },
                ...blocks,
            ],
        };
    }

    return null;
}
