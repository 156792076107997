import { CommonModule } from '@angular/common';
import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { ShoelaceModule } from 'shoelace-style-angular';

import { ContentComponent } from './content.component';

export * from './content.component';

@NgModule({
    imports: [CommonModule, ShoelaceModule],
    exports: [ContentComponent],
    declarations: [ContentComponent],
    schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class ContentModule {}
