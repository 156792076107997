import {
    AfterContentChecked,
    ContentChildren,
    Directive,
    EventEmitter,
    Input,
    Output,
    QueryList,
} from '@angular/core';
import { not } from 'logical-not';
import { SubscribableDirective } from 'ngx-subscribable';
import { merge } from 'rxjs';

import { SortState, TableSortComponent } from './table-sort.component';

@Directive({ selector: 'core-table[sort]' })
export class TableSortDirective
    extends SubscribableDirective
    implements AfterContentChecked
{
    @Input()
    sort!: SortState;

    @Output()
    sortChange = new EventEmitter<SortState>();

    @ContentChildren(TableSortComponent, { descendants: true })
    tableSortComponents!: QueryList<TableSortComponent>;

    ngAfterContentChecked(): void {
        const eventEmitters = this.tableSortComponents.map(
            (component) => component.change,
        );

        if (eventEmitters.length > 0)
            this.subscriptions = [
                merge(...eventEmitters).subscribe((state) => {
                    const prevBy = this.sort.order_by;

                    if (prevBy && prevBy !== state.order_by) {
                        const sortComponent = this.tableSortComponents.find(
                            ({ by }) => by === prevBy,
                        );

                        if (sortComponent) sortComponent.direction = null;
                    }

                    if (not(state.order_direction))
                        state = {
                            order_by: null,
                            order_direction: null,
                        };

                    this.sortChange.emit(state);
                }),
            ];
    }
}
