<label>
    <input
        #checkbox
        type="checkbox"
        [checked]="checked"
        [disabled]="disabled"
        (change)="changeValue.emit(checkbox.checked)"
    />

    <span tooltipify>{{ label }}</span>
</label>
