<plmt-dropdown hoist placement="bottom-start">
    <sl-button slot="trigger" variant="text">
        {{ '_$.access.right.' + value | translate }}
    </sl-button>

    <sl-menu>
        <sl-menu-item
            *ngFor="let item of rights"
            type="checkbox"
            tooltipify
            [checked]="item === value"
            (click)="valueChange.emit(item)"
        >
            {{ '_$.access.right.' + item | translate }}
        </sl-menu-item>
    </sl-menu>
</plmt-dropdown>
