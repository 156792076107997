import { CommonModule } from '@angular/common';
import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { ShoelaceModule } from 'shoelace-style-angular';

import { ButtonModule } from '../ui-kit-components/button/button.module';
import { HintPopupComponent } from './hint-popup.component';

export * from './hint-popup.component';

@NgModule({
    imports: [CommonModule, ShoelaceModule, ButtonModule],
    exports: [HintPopupComponent],
    declarations: [HintPopupComponent],
    schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class HintPopupModule {}
