import { Directive, Input } from '@angular/core';
import { AbstractControl } from '@angular/forms';
import { ToParent } from 'ng-to-parent';

import {
    FormControlAdapter,
    FormControlName,
    FormControlService,
} from '../../services/form-control.service';
import { MultiSelectComponent } from './multi-select.component';

@Directive({
    selector: 'core-multi-select[control]',
    providers: [FormControlService, ToParent],
})
export class MultiSelectControlDirective {
    @Input()
    control!: AbstractControl;

    constructor(
        private formControlService: FormControlService,
        private multiSelectComponent: MultiSelectComponent,
    ) {}

    ngOnInit(): void {
        this.formControlService.provide(this.control, () =>
            createAdapter(this.multiSelectComponent),
        );
    }
}

@Directive({
    selector: 'core-multi-select[name]',
    providers: [FormControlService, ToParent],
})
export class MultiSelectNameDirective {
    @Input()
    name!: FormControlName;

    constructor(
        private formControlService: FormControlService,
        private multiSelectComponent: MultiSelectComponent,
    ) {}

    ngOnInit(): void {
        this.formControlService.provide(this.name, () =>
            createAdapter(this.multiSelectComponent),
        );
    }
}

function createAdapter(
    multiSelectComponent: MultiSelectComponent,
): FormControlAdapter {
    return {
        createValueStream: () => multiSelectComponent.onChange,

        setValue(value) {
            multiSelectComponent.selected = value;
        },
    };
}
