import { Inject, Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import {
    RequestArray,
    ResponseItem,
    ResponseArray,
} from '../interfaces/rest-api';
import { User, UserPublic } from '../interfaces/user';
import { CoreApi } from '../modules/rest/api-injectors';
import { RestService } from '../modules/rest/rest.service';

@Injectable({ providedIn: 'root' })
export class UserApiService {
    constructor(@Inject(CoreApi) private restService: RestService) {}

    list(query: RequestUserList = {}): Observable<ResponseArray<User>> {
        return this.restService.get('/user', query);
    }

    get(id: number): Observable<ResponseItem<User>> {
        return this.restService.get(`/user/${id}`);
    }

    me(): Observable<ResponseItem<User>> {
        return this.restService.get(`/user/me`);
    }

    public(query: RequestUserList = {}): Observable<ResponseArray<UserPublic>> {
        return this.restService.get(`/user/public`, query);
    }

    save(user: UserSaveRequest): Observable<ResponseItem<User>> {
        if (user.id) {
            return this.restService.put(`/user/${user.id}`, user);
        } else {
            return this.restService.post('/user', user);
        }
    }

    edit(user: UserSaveRequest): Observable<ResponseItem<User>> {
        return this.restService.put(`/user/me`, user);
    }

    delete(id: number): Observable<any> {
        return this.restService.delete(`/user/${id}`);
    }

    deleteList(ids: number[]): Observable<any> {
        return this.restService.delete(`/user`, {
            ids,
        });
    }

    changePassword(request: ChangePasswordRequest): Observable<void> {
        return this.restService.post(`/user/change-password`, request);
    }

    updateToken(): Observable<Token> {
        return this.restService.post(`/user/update-token`);
    }

    uploadAvatar(file: File): Observable<any> {
        const formData = new FormData();
        formData.append('file', file);

        return this.restService.put('/user/upload-avatar', formData);
    }
}

export interface RequestUserList extends RequestArray {
    role?: any; // TODO: add type
    exclude_remote?: boolean;
    include_ids?: number[];
    exclude_ids?: number[];
}

export type UserSaveRequest = Pick<
    User,
    | 'id'
    | 'username'
    | 'email'
    | 'image_path'
    | 'first_name'
    | 'last_name'
    | 'middle_name'
    | 'role_id'
    | 'super_user'
> & {
    password: string;
    groups: number[];
};

export interface ChangePasswordRequest {
    old_password: string;
    new_password: string;
}

export interface Token {
    token: string;
}
