import { inject } from '@angular/core';
import { CanActivateFn } from '@angular/router';

import { PageSettings, TranslateInternalService } from './translate.internal';

export const PageTranslationsGuard: CanActivateFn = () => {
    const settings = inject(PageSettings, { optional: true });
    const translateInternalService = inject(TranslateInternalService);

    return translateInternalService.applySettings(settings);
};
