<ng-container *ngIf="dateValue">
    <ng-container *ngIf="isRelative; else dateTemplate">
        <sl-tooltip [content]="dateValue | date" hoist>
            <sl-relative-time
                [date]="dateValue"
                [lang]="locale | async"
            ></sl-relative-time>
        </sl-tooltip>
    </ng-container>

    <ng-template #dateTemplate>
        <sl-format-date
            month="numeric"
            day="numeric"
            year="2-digit"
            hour="numeric"
            minute="numeric"
            second="numeric"
            hour-format="24"
            [date]="dateValue"
            [lang]="locale | async"
        ></sl-format-date>
    </ng-template>
</ng-container>
