export enum TestIdAction {
    Add = 'add',
    Delete = 'delete',
    Copy = 'copy',
    More = 'more',
    Select = 'select',
    Edit = 'edit',
    Next = 'next',
    Back = 'back',
    Apply = 'apply',
    Login = 'login',
    Exit = 'exit',
    Update = 'update',
    UpdateToken = 'update-token',
    Download = 'download', //export
    Upload = 'upload', //import
    Reset = 'reset',
    Cancel = 'cancel',
    ChooseColumns = 'choose-columns',
    Setup = 'setup',
    ShowAccessRights = 'access',
    New = 'new',
    Create = 'create',
    Save = 'save'
}

export enum TestIdNav {
    Datasets = 'nav-datasets',
    Sources = 'nav-sources',
    Filters = 'nav-filters',
    Settings = 'nav-settings',
    Projects = 'nav-projects',
    Widgets = 'nav-widgets',
    Components = 'nav-components',
    SvgObjects = 'nav-svgObjects',
    Themes = 'nav-themes',
    Users = 'nav-users',
    Roles = 'nav-roles',
    Groups = 'nav-groups',
    Appearance = 'nav-appearance',
    Modules = 'nav-modules',
    Plugins = 'nav-plugins',
    Logs = 'nav-logs',
    Notifications = 'nav-notifications',
    Profile = 'nav-profile',
}

export enum TestIdNotification {
    ToDataset = 'notifications-to-dataset',
    MarkAsRead = 'notifications-mark-as-read',
}

export enum TestIdDataset {
    Formatting = 'dataset-formatting',
    Lock = 'dataset-lock',
    Unlock = 'dataset-unlock',
    SqlRequest = 'dataset-sql-request',
    Replace = 'dataset-replace',
}

export enum TestIdFilter {
    Dataset = 'filter-dataset',
    Custom = 'filter-custom',
}

export enum TestIdRole {
    Analyst = 'role-analyst',
    Administrator = 'role-administrator',
    User = 'role-user',
}

export enum TestIdProject {
    OpenAccess = 'project-open-access',
    CloseAccess = 'project-close-access',
    Sharing = 'project-sharing',
    Fullscreen = 'project-fullscreen',
    MoveUp = 'project-move-up',
    MoveLeft = 'project-move-left',
}
