import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

import { User } from '../interfaces/user';

@Injectable({
    providedIn: 'root',
})
export class AppUserService {
    user = new BehaviorSubject<User>({} as any);
    clientUuid = new BehaviorSubject<string | undefined>(undefined);
}
