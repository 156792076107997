import {
    Component,
    ElementRef,
    EventEmitter,
    HostListener,
    Input,
    Output,
    ViewChild,
} from '@angular/core';
import { not } from 'logical-not';
import { AlertService } from '../../alert/alert.service';

@Component({
    selector: 'core-file-picker',
    templateUrl: './file-picker.component.html',
    styleUrls: ['./file-picker.component.less'],
})
export class FilePickerComponent {
    @Input()
    accept = '';

    @Output()
    pick = new EventEmitter<File>();

    @ViewChild('label')
    label!: ElementRef<HTMLElement>;

    @ViewChild('inputFile')
    private inputFile!: ElementRef<HTMLInputElement>;

    @HostListener('click', ['$event'])
    onClick(event: PointerEvent): void {
        const isUploadButton = (event.target as any).hasAttribute('upload');
        const isUploadInput =
            (event.target as any) === this.inputFile.nativeElement;

        if (isUploadButton || isUploadInput) {
            this.inputFile.nativeElement.click();
            return;
        }
        event.preventDefault();
    }

    private generatedId?: string;

    get id(): string {
        if (not(this.generatedId)) {
            this.generatedId = Math.random().toString().slice(2);
        }

        return this.generatedId;
    }

    filePicked(files: File[]): void {
        const [file] = files;

        if (file) {
            // if (not(this.isValidFile(file)))
            //     this.alertService.show.emit({
            //         key: '_$.filePicker.acceptError',
            //         variant: 'danger',
            //         params: { accept: this.accept },
            //     });
            // else
            this.pick.emit(file);
        }

        this.inputFile.nativeElement.value = '';
    }

    // private isValidFile(file: File): boolean {
    //     if (not(this.accept)) return true;

    //     return this.accept
    //         .trim()
    //         .split(/\s*,\s*/)
    //         .some((type) => this.isValidFileType(file, type));
    // }

    // private isValidFileType(file: File, type: string): boolean {
    //     if (type.startsWith('.')) return file.name.endsWith(type);
    //     if (type.endsWith('*')) return file.type.startsWith(type.slice(0, -1));

    //     return file.type === type;
    // }
}
