<sl-icon
    *ngIf="showClearIcon"
    name="x-circle-fill"
    class="clear-icon"
    (click)="onClear.emit()"
></sl-icon>

<sl-icon
    #slInputIcon
    *ngIf="icon"
    [class.toggler]="icon === DropdownToggleIcon"
    [class.dropped]="dropped"
    [name]="icon"
    [library]="library"
    (click)="onClick()"
></sl-icon>
