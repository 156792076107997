import {
    AfterViewInit,
    Component,
    ElementRef,
    EventEmitter,
    Input,
    Output,
    TemplateRef,
    ViewChild,
} from '@angular/core';
import { SlButton, SlDialog } from '@shoelace-style/shoelace';
import { SubscribableComponent } from 'ngx-subscribable';
import { first, fromEvent, merge, tap, throttle } from 'rxjs';

import { TestId } from '../../directives/test-id.directive';

@Component({
    selector: 'core-confirm',
    templateUrl: './confirm.component.html',
    styleUrls: ['./confirm.component.less'],
})
export class ConfirmComponent
    extends SubscribableComponent
    implements AfterViewInit
{
    @Input()
    title = '';

    @Input()
    description = '';

    @Input()
    buttonOkText = '$.apply';

    @Input()
    buttonCancelText = '$.cancel';

    @Input()
    buttonOk?: TemplateRef<void>;

    @Input()
    buttonCancel?: TemplateRef<void>;

    @Output()
    ok = new EventEmitter<any>();

    @Output()
    cancel = new EventEmitter<any>();

    @ViewChild('dialog')
    private dialogRef!: ElementRef<SlDialog>;

    @ViewChild('apply')
    private applyRef!: ElementRef<SlButton>;

    private emitter = new EventEmitter<any>();

    get testId(): keyof typeof TestId {
        return this.buttonOkText === '$.delete' ? 'Delete' : 'Apply';
    }

    ngAfterViewInit(): void {
        this.subscriptions = [
            this.emitter
                .pipe(throttle((payload?: any) => this.confirm(payload)))
                .subscribe(),
        ];
    }

    show(payload?: any): void {
        this.emitter.emit(payload);
    }

    hide(): void {
        this.dialogRef.nativeElement.hide();
    }

    private confirm(payload?: any) {
        const dialog = this.dialogRef.nativeElement;
        const apply = this.applyRef.nativeElement;

        dialog.show();

        return merge(
            fromEvent(apply, 'click').pipe(
                tap(() => this.ok.emit(payload)),
                tap(() => dialog.hide()),
            ),
            fromEvent(dialog, 'sl-hide').pipe(
                tap(() => this.cancel.emit(payload)),
            ),
        ).pipe(first());
    }
}
