<label #label [for]="id">
    <input
        #inputFile
        type="file"
        [id]="id"
        [accept]="accept"
        (change)="filePicked($any($event).target.files)"
    />
    <ng-content></ng-content>
</label>
