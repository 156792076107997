import { Directive } from '@angular/core';

import { DataApiService } from '../../../api/data-api.service';
import { FilterValueColumnDirective } from '../../filter-value/column.directive';
import { LocalFilterValueComponent } from '../local-filter-value/local-filter-value.component';

@Directive({
    selector: 'local-filter-value[datasetId][column]',
})
export class LocalFilterValueColumnDirective extends FilterValueColumnDirective {
    constructor(
        dataApiService: DataApiService,
        localFilterValueComponent: LocalFilterValueComponent,
    ) {
        super(dataApiService, localFilterValueComponent);
    }
}
