import { Injectable } from '@angular/core';
import { ReplaySubject } from 'rxjs';

import { Notification } from '../navigation/notification/interfaces/notification';
import { NotifyInternalService } from './notify.internal';

@Injectable({ providedIn: 'root' })
export class NotifyService {
    constructor(private notifyInternalService: NotifyInternalService) {}

    messages = new ReplaySubject<Notification>();

    toastByName(name: string): void {
        const component = this.notifyInternalService.getBy(name);

        if (component) component.toast();
    }
}
