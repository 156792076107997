import { HttpClient } from '@angular/common/http';
import { InjectionToken } from '@angular/core';

import { StorageService } from '../../services/storage.service';
import { ApiBaseUrls } from './interfaces';

interface Scope {
    API_BASE_URLS: ApiBaseUrls;
    httpClient: HttpClient;
    storageService: StorageService;
}

export const scope: Scope = {} as any;

export const API_SOURCE = new InjectionToken<keyof ApiBaseUrls>('API_SOURCE');
