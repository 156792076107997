<core-avatar
    test-id="Profile"
    [user]="user"
    (click)="slDrawer.show()"
></core-avatar>

<sl-drawer #slDrawer [(open)]="isOpen" (show)="onShow()">
    <div class="header">
        <h4 class="title">
            <sl-icon library="core" name="user"></sl-icon>
            <span>{{ '_$.navigation.profile.title' | translate }}</span>
        </h4>

        <div class="user">
            <div
                class="user-avatar"
                [class.open-id-avatar]="isOpenIdAuthMethod"
                (click)="!isOpenIdAuthMethod && cropModal.show(user)"
            >
                <core-avatar [user]="user" [size]="60"></core-avatar>

                <sl-icon *ngIf="!isOpenIdAuthMethod" name="pencil"></sl-icon>
            </div>

            <p class="user-info">
                <span class="username" tooltipify>{{ user.username }}</span>

                <span
                    *ngIf="!isOpenIdAuthMethod"
                    class="role"
                    multiLineTooltipify
                >
                    {{ displayRole }}
                </span>
            </p>
        </div>
    </div>

    <core-form [data]="userForm" class="form">
        <div class="form-wrap">
            <div class="fields">
                <!-- Темная тема и выбор языка: временно скрыть, после возвращения обратить внимание что в .ts также нужно вернуть функционал -->
                <!-- <div class="form-block">
                        <h5>{{ '_$.navigation.profile.interface' | translate }}</h5>

                        <div class="content interface">
                            <label>
                                {{ '_$.navigation.profile.language' | translate }}
                            </label>
                            <sl-select
                                #langSelect
                                class="select-lang"
                                placeholder="
                                {{ '_$.navigation.profile.selectLang' | translate }}"
                                size="small"
                                (sl-change)="changeLang($any($event).target.value)"
                            >
                                <sl-menu-item *ngFor="let lang of langs" [value]="lang">
                                    {{ '$.lang.' + lang | translate }}
                                </sl-menu-item>
                            </sl-select>

                            <div>
                                <label>
                                    {{ '_$.navigation.profile.theme' | translate }}
                                </label>
                                <sl-switch
                                    [checked]="isDarkMode"
                                    (sl-change)="changeMode($any($event).target.checked)"
                                ></sl-switch>
                            </div>
                        </div>
                    </div> -->

                <div>
                    <core-validate>
                        <sl-input
                            name="last_name"
                            [label]="'_$.navigation.profile.family' | translate"
                            [maxlength]="MAX_LENGTH_NAME"
                            [disabled]="isOpenIdAuthMethod"
                        ></sl-input>
                    </core-validate>
                </div>
                <div>
                    <core-validate>
                        <sl-input
                            name="first_name"
                            [label]="'_$.navigation.profile.name' | translate"
                            [maxlength]="MAX_LENGTH_NAME"
                            [disabled]="isOpenIdAuthMethod"
                        ></sl-input>
                    </core-validate>
                </div>
                <div>
                    <core-validate>
                        <sl-input
                            name="middle_name"
                            [label]="
                                '_$.navigation.profile.midName' | translate
                            "
                            [maxlength]="MAX_LENGTH_NAME"
                            [disabled]="isOpenIdAuthMethod"
                        ></sl-input>
                    </core-validate>
                </div>
                <div>
                    <core-validate
                        [serverErrorsMap]="{
                            exists: '_$.navigation.profile.emailExists',
                            email: '_$.navigation.profile.errorAddress'
                        }"
                    >
                        <sl-input
                            name="email"
                            [label]="'_$.navigation.profile.email' | translate"
                            [disabled]="isOpenIdAuthMethod"
                        ></sl-input>
                    </core-validate>
                </div>
                <div>
                    <core-validate
                        [serverErrorsMap]="{
                            exists: '_$.navigation.profile.loginExists'
                        }"
                    >
                        <sl-input
                            class="login"
                            name="username"
                            [disabled]="!isUserAccess"
                            [label]="'_$.navigation.profile.login' | translate"
                            [disabled]="isOpenIdAuthMethod"
                        >
                            <span class="login-label" slot="label">
                                <span>
                                    {{
                                        '_$.navigation.profile.login'
                                            | translate
                                    }}
                                </span>

                                <core-tooltip
                                    *ngIf="isUserAccess"
                                    key="_$.navigation.profile.nameTooltip"
                                >
                                    <sl-icon name="question-circle"></sl-icon>
                                </core-tooltip>
                            </span>
                        </sl-input>
                    </core-validate>
                </div>
            </div>

            <sl-button
                *ngIf="!isOpenIdAuthMethod"
                class="btn-pass"
                variant="text"
                test-id="Edit"
                outline
                (click)="changePassword.show()"
            >
                {{ '_$.navigation.profile.editPassw' | translate }}
            </sl-button>

            <div [class.hidden]="hideActionButtons" class="btns-container">
                <sl-button
                    variant="primary"
                    size="small"
                    test-id="Cancel"
                    outline
                    (click)="cancelChanges()"
                >
                    {{ '$.cancel' | translate }}
                </sl-button>
                <sl-button
                    type="submit"
                    variant="primary"
                    size="small"
                    test-id="Save"
                >
                    {{ '$.save' | translate }}
                </sl-button>
            </div>
        </div>

        <sl-button
            *ngIf="!isOpenIdAuthMethod"
            variant="text"
            class="token-btn"
            test-id="UpdateToken"
            (click)="updateToken()"
        >
            <span class="token-desc">
                {{ '_$.navigation.profile.updateToken' | translate }}
            </span>
            <sl-tooltip
                [content]="
                    '_$.navigation.profile.updateTokenTooltip' | translate
                "
            >
                <sl-icon name="question-circle"></sl-icon>
            </sl-tooltip>
        </sl-button>

        <div class="groups-wrap">
            <h5 class="title title--sub">
                <sl-icon name="people"></sl-icon>
                <ng-container *ngIf="groups.length; else noGroups">
                    <span>
                        {{ '_$.navigation.profile.groupUser' | translate }}
                    </span>
                </ng-container>
                <ng-template #noGroups>
                    <span>
                        {{ '_$.navigation.profile.noGroups' | translate }}
                    </span>
                </ng-template>
            </h5>

            <div class="groups">
                <sl-tag *ngFor="let group of groups" trackById size="small">
                    <div tooltipify>{{ group.name }}</div>
                </sl-tag>
            </div>
        </div>
    </core-form>

    <div class="footer">
        <sl-button
            class="logout"
            type="text"
            variant="primary"
            size="small"
            test-id="Exit"
            outline
            (click)="logout()"
        >
            {{ '_$.navigation.profile.logout' | translate }}
            <sl-icon slot="prefix" name="box-arrow-right"></sl-icon>
        </sl-button>
    </div>
</sl-drawer>

<core-change-password #changePassword></core-change-password>

<core-crop-modal #cropModal (image)="setAvatar($event)"></core-crop-modal>

<core-token #tokenModal></core-token>
