<sl-dialog
    #dialog
    class="center"
    [label]="'_$.modalCloseConfirm.title' | translate"
    (hide)="close.emit(false)"
>
    {{ '_$.modalCloseConfirm.description' | translate }}

    <sl-button slot="footer" variant="primary" test-id="Cancel" (click)="dialog.hide()">
        {{ '$.cancel' | translate }}
    </sl-button>

    <sl-button
        slot="footer"
        variant="danger"
        test-id="Exit"
        outline
        (click)="close.emit(true); dialog.hide()"
    >
        {{ '_$.modalCloseConfirm.ok' | translate }}
    </sl-button>
</sl-dialog>
