import { Inject, Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { CustomTheme } from '../interfaces/custom-theme';
import { CoreApi } from '../modules/rest/api-injectors';
import { RestService } from '../modules/rest/rest.service';

@Injectable({ providedIn: 'root' })
export class InternalThemeService {
    readonly themeProvider: Observable<CustomTheme | null>;

    constructor(@Inject(CoreApi) private restService: RestService) {
        let theme: CustomTheme | null = null;
        let inited = false;
        let loading = false;

        this.themeProvider = new Observable((subscriber) => {
            if (inited) {
                subscriber.next(theme);
                subscriber.complete();
            } else {
                if (loading) return;
                else loading = true;

                this.restService
                    .get<CustomTheme | null>('/themes/current')
                    .subscribe((response) => {
                        theme = response;
                        inited = true;

                        subscriber.next(theme);
                        subscriber.complete();
                    });
            }
        });
    }
}
