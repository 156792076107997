export enum SourceType {
    XLS,
    XML,
    CSV,
    JSON,
    Mongo,
    ClickHouse,
    Postgres,
    Sqlite,
    MySql,
    SqlServer,
    BigQuery,
}
