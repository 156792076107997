import { AfterViewInit, Directive, NgModule } from '@angular/core';
import {
    SlOption,
    SlBlurEvent,
    SlFocusEvent,
    SlSelect,
} from '@shoelace-style/shoelace';
import { SubscribableDirective } from 'ngx-subscribable';
import { fromEvent, merge } from 'rxjs';

import { getHost } from '../../tools/get-host';

const listboxStyle =
    'margin: var(--sl-spacing-3x-small) 0;' +
    'border-radius: var(--sl-border-radius-large);' +
    'box-shadow: 0 11px 20px 0 #0510371a;' +
    'padding: 4px;' +
    'background: var(--sl-panel-background-color);' +
    'border: solid var(--sl-panel-border-width) var(--sl-panel-border-color);';

const toggleSelected = (condition: boolean, option: SlOption) => {
    option.classList.toggle('selected', condition);
};

@Directive({
    selector: 'sl-select[optionSelectedStyle]',
})
export class PlmtSelectStyleDirective
    extends SubscribableDirective
    implements AfterViewInit
{
    private readonly select: SlSelect = getHost();

    constructor() {
        super();
    }

    ngAfterViewInit(): void {
        const { select } = this;

        let options: NodeListOf<SlOption>;

        this.subscriptions = [
            fromEvent<CustomEvent>(select, 'sl-show').subscribe(() => {
                this.appendParentNode(select.listbox);

                options = select.querySelectorAll('sl-option');

                options.forEach((option) => {
                    if (option.current) option.classList.add('current');
                    if (option.disabled) option.classList.add('disabled');

                    toggleSelected(option.selected, option);

                    this.subscribeToFocusChanges(option);
                });
            }),

            fromEvent<CustomEvent>(select, 'sl-change').subscribe(() => {
                options.forEach((option) => {
                    toggleSelected(option.selected, option);
                });
            }),
        ];
    }

    private subscribeToFocusChanges(option: SlOption): void {
        const focusEvent$ = fromEvent<SlFocusEvent>(option, 'focus');
        const blurEvent$ = fromEvent<SlBlurEvent>(option, 'blur');

        this.subscriptions.push(
            merge(focusEvent$, blurEvent$).subscribe(() =>
                toggleSelected(option.current, option),
            ),
        );
    }

    private appendParentNode(listbox: HTMLSlotElement): void {
        if (listbox.closest('.listbox')) return;

        const parent = listbox.parentNode!;
        const wrapper = document.createElement('div');

        wrapper.classList.add('listbox');
        wrapper.setAttribute('style', listboxStyle);

        parent.replaceChild(wrapper, listbox);
        wrapper.appendChild(listbox);
    }
}

@NgModule({
    exports: [PlmtSelectStyleDirective],
    declarations: [PlmtSelectStyleDirective],
})
export class PlmtSelectStyleDirectiveModule {}
