import { __spreadProps, __spreadValues } from "./chunk.IFDWM6P4.js";

// src/internal/animate.ts
function animateTo(el, keyframes, options) {
  return new Promise(resolve => {
    if ((options == null ? void 0 : options.duration) === Infinity) {
      throw new Error("Promise-based animations must be finite.");
    }
    const animation = el.animate(keyframes, __spreadProps(__spreadValues({}, options), {
      duration: prefersReducedMotion() ? 0 : options.duration
    }));
    animation.addEventListener("cancel", resolve, {
      once: true
    });
    animation.addEventListener("finish", resolve, {
      once: true
    });
  });
}
function parseDuration(delay) {
  delay = delay.toString().toLowerCase();
  if (delay.indexOf("ms") > -1) {
    return parseFloat(delay);
  }
  if (delay.indexOf("s") > -1) {
    return parseFloat(delay) * 1e3;
  }
  return parseFloat(delay);
}
function prefersReducedMotion() {
  const query = window.matchMedia("(prefers-reduced-motion: reduce)");
  return query.matches;
}
function stopAnimations(el) {
  return Promise.all(el.getAnimations().map(animation => {
    return new Promise(resolve => {
      animation.cancel();
      requestAnimationFrame(resolve);
    });
  }));
}
function shimKeyframesHeightAuto(keyframes, calculatedHeight) {
  return keyframes.map(keyframe => __spreadProps(__spreadValues({}, keyframe), {
    height: keyframe.height === "auto" ? `${calculatedHeight}px` : keyframe.height
  }));
}
export { animateTo, parseDuration, prefersReducedMotion, stopAnimations, shimKeyframesHeightAuto };