import {
    AfterViewInit,
    Component,
    EventEmitter,
    Input,
    OnInit,
    ViewChild,
} from '@angular/core';
import { isNull } from 'lodash';
import { not } from 'logical-not';
import { WatchChanges } from 'ng-onpush';
import { SubscribableComponent } from 'ngx-subscribable';

import {
    dateFilterMethodsMap,
    multiSelectFilterMethods,
    numberFilterMethodsMap,
    searchFilterMethodsMap,
    selectFilterMethods,
} from '../../constants/filter-methods-provider';
import { filterTemplateTypes } from '../../constants/filter-template-types';
import { filterUITypeOptionsMap } from '../../constants/filter-ui-type-options-map';
import { positions } from '../../constants/positions';
import { FilterMethod, FilterState } from '../../enums/filter';
import { FilterTemplateType } from '../../enums/filter-template-type';
import { FilterTemplateUIType } from '../../enums/filter-ui-type';
import {
    FilterTemplate,
    FilterTemplateApi,
} from '../../interfaces/filter-template';
import { Form, FormService } from '../../modules/form/form.service';
import { NotifyComponent } from '../notify/notify.component';
import { ExtendedSettingsComponent } from './extended-settings/extended-settings.component';
import { filterDateValidator } from './tools/filter-date-validator';
import { FilterTemplateService } from '../../services/filter-template.service';
import { ResponseErrorPayload } from '../../interfaces/rest-api';
import { distinctUntilChanged, filter } from 'rxjs/operators';
import { FilterTemplateUI } from './interfaces/filter-template-ui.type';
import { FilterTemplateForm } from './form/filter-template-form';
import { getTypesForCustomSelect } from './tools/get-types-for-custom-select';
import { DefaultValueAggregation } from '../../enums/radio-min-max-state.enum';
import {
    patchNewFilterForm,
    patchForm,
    removeExtraFields,
    patchDefaultValue,
} from './form/form-helper';

const typesWithExtendedSettings = [
    FilterTemplateType.Select,
    FilterTemplateType.MultiSelect,
];

type FilterMethodsMap = typeof searchFilterMethodsMap;

@Component({
    selector: 'core-filter-template',
    templateUrl: './filter-template.component.html',
    styleUrls: ['./filter-template.component.less'],
    providers: [FilterTemplateService],
})
export class FilterTemplateComponent
    extends SubscribableComponent
    implements OnInit, AfterViewInit
{
    @Input()
    onFilterIsLoaded = new EventEmitter<FilterTemplateApi>();

    @Input()
    onModalClose = new EventEmitter();

    @ViewChild('notify', { static: true })
    notify!: NotifyComponent;

    @ViewChild('extendedSettings')
    extendedSettingsComponent?: ExtendedSettingsComponent;

    readonly FilterState = FilterState;
    readonly FilterTemplateType = FilterTemplateType;

    readonly filterTemplateTypes = filterTemplateTypes;
    readonly filterUITypeOptionsMap = filterUITypeOptionsMap;
    readonly positions = positions;

    @WatchChanges()
    state: FilterState = FilterState.CommonType;

    @WatchChanges()
    filterTemplate: FilterTemplate | undefined;
    filterMethods: FilterMethod[] = [];

    form!: Form<FilterTemplateUI>;
    formInstance!: FilterTemplateForm;

    get type(): FilterTemplateType {
        return this.formInstance.controls.type.value;
    }

    get subType(): FilterTemplateUIType {
        return this.formInstance.controls.subType.value;
    }

    get formValue(): FilterTemplateUI {
        return this.form.getRawValue() as FilterTemplateUI;
    }

    get canShowExtendedSettings(): boolean {
        return this.state === FilterState.ExtendedSettings;
    }

    get canShowFilterValueDefault(): boolean {
        return Boolean(this.subType);
    }

    constructor(
        private formService: FormService,
        private filterTemplateService: FilterTemplateService,
    ) {
        super();
    }

    ngOnInit(): void {
        this.formInstance = FilterTemplateForm.getInstance(this.formService);
        this.form = this.formInstance.form;
        this.init();
    }

    ngAfterViewInit(): void {
        this.subscriptions = [
            this.onFilterIsLoaded.subscribe((filterRow) =>
                this.init(filterRow),
            ),

            this.formInstance.controls.type.valueChanges.subscribe(
                (type: FilterTemplateType | null) => {
                    this.filterMethods = this.getFilterMethods(
                        type,
                        this.subType,
                    );

                    if (type && this.filterUITypeOptionsMap[type].length === 1)
                        this.formInstance.controls.subType.patchValue(
                            this.filterUITypeOptionsMap[type][0].value,
                        );
                    else this.formInstance.controls.subType.reset();

                    this.changeState();
                },
            ),

            this.formInstance.controls.subType.valueChanges
                .pipe(distinctUntilChanged(), filter(Boolean))
                .subscribe((subType: FilterTemplateUIType | null) => {
                    this.filterMethods = this.getFilterMethods(
                        this.type,
                        subType,
                    );

                    const { action } = this.formValue;

                    if (!isNull(action)) {
                        if (not(this.filterMethods.includes(action))) {
                            this.formInstance.controls.action.reset();

                            this.form.patchValue({
                                default_value: null,
                                has_default_value: false,
                            });
                        }
                    }

                    if (this.filterTemplate) {
                        this.filterTemplate.sub_type = subType!;
                    }

                    this.changeState();
                }),

            filterDateValidator(
                this.formInstance.controls.defaultValue,
                this.formInstance.controls.type,
                this.formInstance.controls.subType,
            ),

            this.filterTemplateService.onChangeDefaultValue.subscribe(
                (value) => {
                    patchDefaultValue(value, this.formInstance.form);
                },
            ),

            this.formInstance.controls.defaultValueAggFn.valueChanges.subscribe(
                (value) => {
                    if (!value && value !== 0) return;

                    if (value === DefaultValueAggregation.Exact) {
                        this.filterTemplateService.onChangeDefaultValue.next(
                            this.formInstance.controls.originalDefaultValue
                                .value,
                        );

                        return;
                    }

                    const filter = this.getMappedForm(false);

                    if (!filter) return;

                    if (filter.dataset_select) {
                        filter.dataset_select = {
                            ...filter.dataset_select,
                        };
                    }

                    this.filterTemplateService.onLoadMinMaxDefaultValue.emit(
                        filter,
                    );
                },
            ),
        ];
    }

    getMappedForm(shouldCheckValidity = true): FilterTemplate | null {
        const form = removeExtraFields(this.form);

        if (shouldCheckValidity && form.invalid) {
            this.formService.showLocalErrors(this.form, true);

            return null;
        }

        return this.filterTemplateService.mapFormDataToFilterParams(
            form.value,
            this.extendedSettingsComponent?.column?.base_type,
        );
    }

    showNotification(): void {
        this.notify.toast();
    }

    showServerErrors(error: ResponseErrorPayload | null): void {
        this.formService.showServerErrors(this.form, error);
    }

    private init(filterRow?: FilterTemplateApi): void {
        this.filterTemplate = undefined;

        FilterTemplateForm.cleanForm();

        this.state = FilterState.CommonType;

        if (filterRow) {
            const { filter } = filterRow;

            filter.custom_select = getTypesForCustomSelect(
                filter.custom_select,
            );

            if (filter.type === 'number' && filter.sub_type === 'range') {
                //NOTE: для миграции фильтров где вместо диапозона указывалось множество
                filter.action =
                    filter.action === FilterMethod.NotInList ||
                    filter.action === FilterMethod.Range$Not
                        ? FilterMethod.Range$Not
                        : FilterMethod.Range;
            }

            this.filterTemplate = filter;

            patchForm(filterRow, this.form);

            if (
                this.filterTemplate.dataset_select ||
                this.filterTemplate.custom_select ||
                this.filterTemplate.tree_select
            ) {
                this.state = FilterState.ExtendedSettings;

                this.filterTemplateService.onLoadMinMaxDefaultValue.emit(
                    filter,
                );
            }
        } else {
            patchNewFilterForm(this.form);
        }
    }

    private changeState(): void {
        const prev = this.state;

        if (not(this.type)) {
            this.state = FilterState.CommonType;
            return;
        }

        this.state = typesWithExtendedSettings.includes(this.type)
            ? FilterState.ExtendedSettings
            : FilterState.CommonType;

        if (
            prev === FilterState.ExtendedSettings &&
            this.state === FilterState.CommonType
        ) {
            this.formInstance.controls.datasetSelect?.reset();
            this.formInstance.controls.customSelect.reset();
        }
    }

    private getFilterMethods(
        type: FilterTemplateType | null,
        uiType: FilterTemplateUIType | null,
    ): FilterMethod[] {
        switch (type) {
            case FilterTemplateType.Select:
                return selectFilterMethods;
            case FilterTemplateType.MultiSelect:
                return multiSelectFilterMethods;
            case FilterTemplateType.Search:
                return getFrom(searchFilterMethodsMap, uiType);
            case FilterTemplateType.Number:
                return getFrom(numberFilterMethodsMap, uiType);
            case FilterTemplateType.Date:
                return getFrom(dateFilterMethodsMap, uiType);
            default:
                return [];
        }
    }
}

function getFrom(
    map: FilterMethodsMap,
    uiType: FilterTemplateUIType | null,
): FilterMethod[] {
    if (not(uiType)) return [];

    return map[uiType] || [];
}
