import { Injectable } from '@angular/core';
import { not } from 'logical-not';

import {
    FormatDateUnitType,
    FormatDateUnitValue,
} from '../../../enums/column.format';
import { Lang } from '../../../enums/lang';
import { StorageService } from '../../storage.service';
import { DateFormatSettings } from '../formatter-settings/date.format-settings';
import { Formatter } from './formatter';

const { Year, Month, Day, Hour, Minute, Second, Millisecond } =
    FormatDateUnitType;

const { XL, L, M } = FormatDateUnitValue;

const months: Record<Lang, string[]> = {
    [Lang.En]: [],

    [Lang.Ru]: [
        'Январь',
        'Февраль',
        'Март',
        'Апрель',
        'Май',
        'Июнь',
        'Июль',
        'Август',
        'Сентябрь',
        'Октябрь',
        'Ноябрь',
        'Декабрь',
    ],
};

@Injectable({ providedIn: 'root' })
export class DateFormatter implements Formatter<DateFormatSettings> {
    constructor(private storageService: StorageService) {}

    format(formatSettings: DateFormatSettings, source: any): string {
        const { nullText, units, arounds } = formatSettings;

        if (source === null) {
            if (not(nullText)) return '';
            else return nullText;
        }

        if (not(source)) return '';

        const date = new Date(source);

        if (isNaN(date.valueOf())) return '';

        const parts: string[] = [];
        const commonFormat = commonFormatSource.bind(parts);

        units.forEach(({ type, value, suffix }) => {
            switch (type) {
                case Year: {
                    const year = date.getFullYear();

                    switch (value) {
                        case XL:
                            parts.push(String(year));
                            break;
                        case L:
                            parts.push(String(year % 1000).padStart(3, '0'));
                            break;
                        default:
                            commonFormat(year % 100, value);
                    }
                    break;
                }
                case Month: {
                    const month = date.getMonth();
                    const lang = this.storageService.lang.value;

                    switch (value) {
                        case XL:
                            parts.push(months[lang][month]);
                            break;
                        case L:
                            parts.push(months[lang][month].slice(0, 3));
                            break;
                        default:
                            commonFormat(month + 1, value);
                    }
                    break;
                }
                case Day:
                    commonFormat(date.getDate(), value);
                    break;
                case Hour:
                    commonFormat(date.getHours(), value);
                    break;
                case Minute:
                    commonFormat(date.getMinutes(), value);
                    break;
                case Second:
                    commonFormat(date.getSeconds(), value);
                    break;
                case Millisecond:
                    parts.push(String(date.getMilliseconds()));
                    break;
            }

            if (suffix) parts.push(suffix);
        });

        return arounds.format(parts.join(''));
    }
}

function commonFormatSource(
    this: string[],
    source: number,
    unitValue: FormatDateUnitValue,
): void {
    let value = String(source);

    if (unitValue === M) value = value.padStart(2, '0');

    this.push(value);
}
