import { inject } from '@angular/core';
import { CanActivateFn } from '@angular/router';

import { MainSettings, TranslateInternalService } from './translate.internal';

export const MainTranslationsGuard: CanActivateFn = () => {
    const settings = inject(MainSettings, { optional: true });
    const translateInternalService = inject(TranslateInternalService);

    return translateInternalService.applySettings(settings);
};
