import { FilterTemplateType } from '../enums/filter-template-type';
import { FilterTemplateUIType } from '../enums/filter-ui-type';
import { SelectOption } from '../interfaces/select-option';

export const filterUITypeOptionsMap: Record<
    FilterTemplateType,
    SelectOption<FilterTemplateUIType>[]
> = {
    [FilterTemplateType.Select]: selectOptions([
        FilterTemplateUIType.Radio,
        FilterTemplateUIType.Select,
        FilterTemplateUIType.TreeView
    ]),
    [FilterTemplateType.MultiSelect]: selectOptions([
        FilterTemplateUIType.Checkbox,
        FilterTemplateUIType.Select,
    ]),
    [FilterTemplateType.Search]: selectOptions([FilterTemplateUIType.Search]),
    [FilterTemplateType.Number]: selectOptions([
        FilterTemplateUIType.Number,
        FilterTemplateUIType.NumberRange,
    ]),
    [FilterTemplateType.Date]: selectOptions([
        FilterTemplateUIType.DateSearch,
        FilterTemplateUIType.DateTimeSearch,
        FilterTemplateUIType.DateCalendar,
        // FilterTemplateUIType.DateTimeCalendar,
        FilterTemplateUIType.DateSearchPeriod,
        FilterTemplateUIType.DateTimeSearchPeriod,
        FilterTemplateUIType.DatePeriod,
        // FilterTemplateUIType.DateTimePeriod,
    ]),
};

function selectOptions(
    items: FilterTemplateUIType[],
): SelectOption<FilterTemplateUIType>[] {
    return items.map((value) => ({
        value,
        label: `$$.filterTemplate.uiType.${value}`,
    }));
}
