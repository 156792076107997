<sl-alert
    #alert
    closable
    duration="5000"
    [variant]="variant"
>
    <ng-container [ngSwitch]="variant">
        <sl-icon
            *ngSwitchCase="'primary'"
            slot="icon"
            name="info-circle"
        ></sl-icon>

        <sl-icon
            *ngSwitchCase="'success'"
            slot="icon"
            name="check-circle"
        ></sl-icon>

        <sl-icon
            *ngSwitchCase="'warning'"
            slot="icon"
            name="exclamation-circle"
        ></sl-icon>

        <sl-icon *ngSwitchCase="'danger'" slot="icon" name="x-circle"></sl-icon>
    </ng-container>

    <ng-content></ng-content>
</sl-alert>
