import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import { Component, EventEmitter, Input, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import { SlCheckbox } from '@shoelace-style/shoelace';
import { cloneDeep } from 'lodash';
import { not } from 'logical-not';
import { WatchChanges } from 'ng-onpush';
import { ToParent } from 'ng-to-parent';
import { SubscribableComponent } from 'ngx-subscribable';
import { enumToArray } from 'ts-enum-to-array';

import {
    FormatDateUnitType,
    FormatDateUnitValue,
} from '../../../enums/column.format';
import { FormatDateUnit } from '../../../interfaces/column.format';
import {
    FormControlName,
    FormControlService,
} from '../../../services/form-control.service';
import { valueOf } from '../../../tools/value-of';

const valueOptions: Record<FormatDateUnitType, FormatDateUnitValue[]> =
    {} as any;

const unitTypes = enumToArray(FormatDateUnitType);

unitTypes.forEach((type) => {
    switch (type) {
        case FormatDateUnitType.Year:
        case FormatDateUnitType.Month:
            valueOptions[type] = enumToArray(FormatDateUnitValue);
            break;
        case FormatDateUnitType.Millisecond:
            valueOptions[type] = [FormatDateUnitValue.S];
            break;
        default:
            valueOptions[type] = [FormatDateUnitValue.M, FormatDateUnitValue.S];
    }
});

@Component({
    selector: 'plmt-date-formatting',
    templateUrl: './date-formatting.component.html',
    styleUrls: ['./date-formatting.component.less'],
    providers: [FormControlService, ToParent],
})
export class DateFormattingComponent
    extends SubscribableComponent
    implements OnInit
{
    @Input()
    name: FormControlName = 'date_format';

    @Input()
    set disableCheckboxes(types: FormatDateUnitType[]) {
        unitTypes.forEach((type) => {
            this.disabledCheckboxesMap[type] = types.includes(type);
        });
    }

    readonly value = new EventEmitter<FormatDateUnit[]>();

    @WatchChanges()
    units: FormatDateUnit[] = [];

    private disabledCheckboxesMap: Record<FormatDateUnitType, boolean> =
        {} as any;

    constructor(private formControlService: FormControlService) {
        super();
    }

    ngOnInit(): void {
        unitTypes.forEach((type) => {
            const control = new FormControl<boolean>(false);

            this.subscriptions.push(
                control.valueChanges.subscribe((checked) => {
                    const unit = this.units.find((unit) => unit.type === type);

                    if (unit) {
                        unit.disabled = not(checked);

                        this.emitChanges();
                    }
                }),
            );
        });

        this.formControlService.provide(this.name, () => ({
            createValueStream: () => this.value,

            setValue: (units: FormatDateUnit[] | null) => {
                if (not(units)) return;

                this.units = units;
            },
        }));
    }

    onDrop({ previousIndex, currentIndex }: CdkDragDrop<string[]>): void {
        moveItemInArray(this.units, previousIndex, currentIndex);

        this.emitChanges();
    }

    getUnitContext(
        unit: FormatDateUnit,
        index: number,
        isPreview = false,
    ): Context {
        const { type } = unit;

        return {
            $implicit: unit,
            values: valueOptions[type],
            index,
            checkboxDisabled: this.disabledCheckboxesMap[type],
            isPreview,
        };
    }

    onChangeDisabled(unit: FormatDateUnit, { target }: Event): void {
        if (target instanceof SlCheckbox) unit.disabled = !target.checked;

        this.emitChanges();
    }

    onChangeValue(unit: FormatDateUnit, event: Event): void {
        unit.value = valueOf(event);

        this.emitChanges();
    }

    onChangeSuffix(unit: FormatDateUnit, event: Event): void {
        unit.suffix = valueOf(event);

        this.emitChanges();
    }

    getLastEnableIndex(): number {
        let lastEnableIndex = 0;
        this.units.forEach((unit, i) => {
            if (!unit.disabled) {
                lastEnableIndex = i;
            }
        });

        return lastEnableIndex;
    }

    private emitChanges(): void {
        const units = cloneDeep(this.units);
        units[this.getLastEnableIndex()].suffix = '';

        this.value.emit([...units]);
    }
}

interface Context {
    $implicit: FormatDateUnit;
    values: FormatDateUnitValue[];
    index: number;
    checkboxDisabled: boolean;
    isPreview: boolean;
}
