<sl-dialog
    #dialog
    class="center"
    [class.hasRelatedEntities]="hasRelatedEntities()"
>
    <div slot="label">
        <ng-container
            *ngIf="actionType === EntityActionType.Deleted; else affectedTitle"
        >
            {{
                isMultiple()
                    ? ('$$.dependenciesConfirm.plural.title' | translate)
                    : ('$$.dependenciesConfirm.title.' + entityType | translate)
            }}
        </ng-container>
        <ng-template #affectedTitle>
            {{ '$$.dependenciesConfirm.title.affected' | translate }}
        </ng-template>
        <span
            *ngIf="hasRelatedEntities() || isMultiple() || hasError"
            class="attention"
        >
            {{ '$$.dependenciesConfirm.attention' | translate }}
        </span>
    </div>
    <core-spinner [isLoading]="isLoading" [hideContentWhileLoading]="true">
        <ng-container *ngIf="!hasError">
            <ng-container *ngIf="isMultiple()">
                <div
                    *translate="
                        '$$.dependenciesConfirm.plural.confirm.' + entityType;
                        let key
                    "
                    class="mb-large"
                >
                    <p *ngFor="let line of key.lines">
                        {{ line }}
                    </p>
                </div>
            </ng-container>

            <div
                [class.entity-multiple-container]="isMultiple()"
                [class.project-name]="entityType === RelatedEntityType.Project"
            >
                <ng-container *ngIf="affectedEntity?.name; else deletedEntity">
                    <p class="bold" tooltipify>{{ affectedEntity?.name }}</p>
                </ng-container>
                <ng-template #deletedEntity>
                    <core-related-entity
                        *ngFor="let item of data"
                        class="bold"
                        [item]="item"
                        [uiType]="entityType"
                    ></core-related-entity>
                </ng-template>
            </div>

            <ng-container
                *ngIf="
                    !isMultiple() && entityType === RelatedEntityType.Project
                "
            >
                <p class="multi-delete-description">
                    {{
                        '$$.dependenciesConfirm.confirm.description.' +
                            entityType +
                            actionType | translate
                    }}
                </p>
                <ng-container
                    *translate="
                        '$$.dependenciesConfirm.project.entities';
                        let key
                    "
                >
                    <p class="bold" *ngFor="let line of key.lines">
                        {{ line }}
                    </p>
                </ng-container>
            </ng-container>

            <div *ngIf="isShowPasswordConfirm" class="password-confirm">
                <ng-content select=".password"> </ng-content>
            </div>

            <ng-container *ngIf="hasRelatedEntities()">
                <p>
                    {{
                        '$$.dependenciesConfirm.confirm.description.' +
                            entityType +
                            actionType | translate
                    }}
                </p>

                <div class="entities">
                    <ng-container *ngFor="let row of dependencies">
                        <sl-details *ngIf="row.value.length" [open]="true">
                            <p slot="summary" class="summary">
                                <sl-icon name="caret-down-fill"></sl-icon>
                                {{
                                    '$$.dependenciesConfirm.label.' + row.key
                                        | translate: { count: row.value.length }
                                }}
                            </p>
                            <ng-container *ngFor="let item of row.value">
                                <core-related-entity
                                    [item]="item"
                                    [uiType]="row.key"
                                ></core-related-entity>
                            </ng-container>
                        </sl-details>
                    </ng-container>
                </div>
            </ng-container>

            <p
                *ngIf="!isMultiple()"
                [class.extra-confirm-spacing]="
                    entityType === RelatedEntityType.Project
                "
            >
                {{
                    (hasRelatedEntities()
                        ? '$$.dependenciesConfirm.confirm.hasDependence.'
                        : '$$.dependenciesConfirm.confirm.noDependence.') +
                        entityType +
                        actionType | translate
                }}
            </p>
        </ng-container>

        <p *ngIf="hasError" class="delete-error">
            {{ '$$.dependenciesConfirm.error' | translate }}
        </p>
    </core-spinner>

    <sl-button
        class="cancel-button"
        slot="footer"
        variant="primary"
        test-id="Cancel"
        [outline]="actionType === EntityActionType.Affected"
        (click)="close()"
    >
        {{ '$.cancel' | translate }}
    </sl-button>

    <sl-button
        slot="footer"
        [outline]="actionType === EntityActionType.Deleted"
        [variant]="
            actionType === EntityActionType.Deleted ? 'danger' : 'primary'
        "
        [disabled]="isLoading"
        [test-id]="actionType === EntityActionType.Deleted ? 'Delete' : 'Apply'"
        (click)="submit()"
    >
        {{
            (actionType === EntityActionType.Deleted ? '$.delete' : '$.save')
                | translate
        }}
    </sl-button>
</sl-dialog>
