import {
    AfterContentInit,
    Directive,
    ElementRef,
    Renderer2,
} from '@angular/core';
import { SlTooltip } from '@shoelace-style/shoelace';
import { SubscribableDirective } from 'ngx-subscribable';
import {
    distinctUntilChanged,
    filter,
    fromEvent,
    map,
    switchMap,
    takeUntil,
    tap,
} from 'rxjs';

@Directive({
    selector: 'core-table',
})
export class TableTextOverflow
    extends SubscribableDirective
    implements AfterContentInit
{
    constructor(
        private host: ElementRef<HTMLElement>,
        private renderer: Renderer2,
    ) {
        super();
    }

    ngAfterContentInit(): void {
        const table = this.host.nativeElement;

        const tooltip: SlTooltip = this.renderer.createElement('sl-tooltip');
        tooltip.setAttribute('hoist', 'true');
        tooltip.setAttribute('placement', 'top-start');
        tooltip.style.wordBreak = 'break-word';

        const positioner: HTMLElement = this.renderer.createElement('div');
        positioner.style.position = 'fixed';

        this.renderer.appendChild(tooltip, positioner);
        this.renderer.insertBefore(table.parentElement, tooltip, table);

        this.subscriptions = [
            fromEvent<{ target?: HTMLElement }>(table, 'mouseover')
                .pipe(
                    filter(({ target }) => target?.tagName === 'TD'),
                    map(({ target }) => target!),
                    filter((td) => td.offsetWidth < td.scrollWidth),
                    switchMap((td) => {
                        let { left, right, top, bottom } =
                            td.getBoundingClientRect();

                        const {
                            paddingLeft,
                            paddingRight,
                            paddingTop,
                            paddingBottom,
                        } = getComputedStyle(td);

                        left += parseFloat(paddingLeft);
                        right -= parseFloat(paddingRight);
                        top += parseFloat(paddingTop);
                        bottom -= parseFloat(paddingBottom);

                        return fromEvent(td, 'mousemove').pipe(
                            takeUntil<any>(
                                fromEvent(td, 'mouseleave').pipe(
                                    tap(() => tooltip.hide()),
                                ),
                            ),
                            map<MouseEvent, boolean>(({ x, y }) => {
                                return (
                                    x >= left &&
                                    x <= right &&
                                    y >= top &&
                                    y <= bottom
                                );
                            }),
                            distinctUntilChanged(),
                            tap((show) => {
                                if (show) {
                                    positioner.style.left = left + 'px';
                                    positioner.style.top = top + 'px';
                                    positioner.style.height =
                                        bottom - top + 'px';

                                    tooltip.content = td.textContent || '';
                                    tooltip.show();
                                } else {
                                    tooltip.hide();
                                }
                            }),
                        );
                    }),
                )
                .subscribe(),
        ];
    }
}
