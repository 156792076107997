import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { SubscribableComponent } from 'ngx-subscribable';

import { DefaultValueAggregation } from '../../../enums/radio-min-max-state.enum';
import { valueOf } from '../../../tools/value-of';

@Component({
    selector: 'plmt-radio-min-max',
    templateUrl: './radio-min-max.component.html',
    styleUrls: ['./radio-min-max.component.less'],
})
export class RadioMinMaxComponent extends SubscribableComponent {
    @Input()
    state = DefaultValueAggregation.Exact;

    @Output()
    radioMinMaxChange = new EventEmitter();

    readonly DefaultValueAggregation = DefaultValueAggregation;

    constructor() {
        super();
    }

    onChange(event: Event): void {
        this.state = valueOf(event);
        this.radioMinMaxChange.emit(this.state);
    }
}
