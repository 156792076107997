import { CommonModule } from '@angular/common';
import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { TrackByIdModule } from 'ng-track-by';
import { ShoelaceModule } from 'shoelace-style-angular';

import { FilterLabelPosition } from '../../enums/filter-label-position';
import { FilterTemplateType } from '../../enums/filter-template-type';
import { FilterTemplateUIType } from '../../enums/filter-ui-type';
import { FormModule } from '../../modules/form/form.module';
import { TooltipifyModule } from '../../modules/tooltipify/tooltipify.module';
import { TranslationsKeyInterpolationMap } from '../../modules/translate/translate.interfaces';
import { TranslateModule } from '../../modules/translate/translate.module';
import { PluralLocalization } from '../../tools/plural-localization';
import { FilterValueModule } from '../filter-value/filter-value.module';
import { NotifyModule } from '../notify/notify.module';
import { SuggestModule } from '../suggest/suggest.module';
import { FilterDateOffsetOperator } from './enums/filter-date-offset';
import { ExtendedSettingsModule } from './extended-settings/extended-settings.module';

import { PlmtSelectModule } from '../../plmt-ui-components/plmt-select/plmt-select.module';
import { FilterTemplateComponent } from './filter-template.component';
import { FilterValueDefaultModule } from './filter-value-default/filter-value-default.module';
import { RadioMinMaxModule } from './radio-min-max/radio-min-max.module';

export * from './filter-template.component';

export const filterTemplateInterpolationKeys: TranslationsKeyInterpolationMap =
    {
        FilterLabelPosition,
        FilterTemplateType,
        FilterTemplateUIType,
        FilterDateOffsetOperator,
    };

@NgModule({
    imports: [
        CommonModule,
        ExtendedSettingsModule,
        FilterValueDefaultModule,
        FilterValueModule,
        FormModule,
        NotifyModule,
        PlmtSelectModule,
        ShoelaceModule,
        SuggestModule,
        TrackByIdModule,
        TranslateModule,
        TooltipifyModule,
        RadioMinMaxModule,
    ],
    exports: [FilterTemplateComponent],
    declarations: [FilterTemplateComponent],
    schemas: [CUSTOM_ELEMENTS_SCHEMA],
    providers: [PluralLocalization],
})
export class FilterTemplateModule {}
