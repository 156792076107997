import { CommonModule } from '@angular/common';
import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { TrackByModule } from 'ng-track-by';

import { CounterInputDirectiveModule } from '../../../directives/counter-input.directive';
import { DatePluralModule } from '../../../modules/date-plural/date-plural.module';
import { FormModule } from '../../../modules/form/form.module';
import { TranslateModule } from '../../../modules/translate/translate.module';
import { DatePipeModule } from '../../../pipes/date.pipe';
import { FilterDateValueDefaultComponent } from './filter-date-value-default.component';
import { PlmtSelectModule } from '../../../plmt-ui-components/plmt-select/plmt-select.module';

@NgModule({
    imports: [
        CommonModule,
        CounterInputDirectiveModule,
        DatePipeModule,
        DatePluralModule,
        FormModule,
        PlmtSelectModule,
        TrackByModule,
        TranslateModule,
    ],
    exports: [FilterDateValueDefaultComponent],
    declarations: [FilterDateValueDefaultComponent],
    schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class FilterDateValueDefaultModule {}
