import { Component, Input } from '@angular/core';
import { Column } from '../../interfaces/dataset';

@Component({
    selector: 'app-calculate-column-icon',
    templateUrl: './calculate-column-icon.component.html',
    styleUrls: ['./calculate-column-icon.component.less'],
})
export class CalculateColumnIconComponent {
    @Input()
    column!: Column;

    @Input()
    showErrorTooltip = false;
}
