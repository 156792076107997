<sl-tooltip
    #tooltip
    hoist
    [content]="tooltipText"
    [disabled]="disable"
    [placement]="params.placement || 'top'"
>
    <div>
        <sl-resize-observer (sl-resize)="reflow($event)">
            <ng-container *ngTemplateOutlet="content"></ng-container>
        </sl-resize-observer>
    </div>
</sl-tooltip>
