import { isEqual, isNull, isUndefined } from 'lodash';
import {
    DATA_KEY_PATH,
    DATA_VIEW_PATH,
} from '../constants/filter-default-value-keys';
import { BindingType } from '../enums/binding-type';
import { KeyView } from '../interfaces/filter-template';
import { NULL_VALUE } from '../components/filter-value/null.helper';

const VIEW_INDEX = 1;

export function getCorrectValue<T = any>(
    value: any,
    bindingType: BindingType,
    shouldUseView = false,
): T | T[] | undefined {
    if (Array.isArray(value)) {
        return value.map((item) =>
            getFilterValue(item, bindingType, shouldUseView),
        );
    } else {
        return getFilterValue(value, bindingType, shouldUseView);
    }
}

export function getFilterValue(
    value: any,
    bindingType: BindingType,
    shouldUseView = false,
): any {
    if (isUndefined(value) || isNull(value)) return value;

    if (
        !isUndefined(value[DATA_VIEW_PATH]) &&
        bindingType === BindingType.Suggest
    ) {
        if (shouldUseView) return value[DATA_VIEW_PATH];

        //value[DATA_VIEW_PATH] это отформатированное значение, в фильтр требуется исходное
        const isEqualViewAndValue = () =>
            isEqual(value[VIEW_INDEX], value[DATA_VIEW_PATH]);

        if (VIEW_INDEX in value && !isEqualViewAndValue()) {
            const filterValue = value[VIEW_INDEX];

            return filterValue === NULL_VALUE ? null : filterValue;
        }

        return value[DATA_VIEW_PATH];
    }

    if (!isUndefined(value[DATA_KEY_PATH])) {
        return value[DATA_KEY_PATH];
    }

    return value;
}

//NOTE: Для миграции старых дефолтных настроек
export function getMigrateDefaultValue(
    value: any,
    toArray = false,
): KeyView | KeyView[] | undefined {
    if (isUndefined(value)) return value;

    const newValue = (oldValue: any): KeyView => {
        const key = oldValue[DATA_KEY_PATH];
        const view = oldValue[DATA_VIEW_PATH] ?? oldValue;

        return { key, view };
    };

    if (Array.isArray(value)) {
        return value.map((oldValue) => newValue(oldValue));
    }

    return toArray ? [newValue(value)] : newValue(value);
}
