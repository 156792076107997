import { ChangeDetectorRef, Component, Input } from '@angular/core';
import { UntypedFormArray } from '@angular/forms';

import { DataOptionFilter } from '../../interfaces/data-option';
import { Dataset } from '../../interfaces/dataset';
import { LocalFiltersService } from './services/local-filters.service';
import { WatchChanges } from 'ng-onpush';

@Component({
    selector: 'core-local-filters',
    templateUrl: './local-filters.component.html',
})
export class LocalFiltersComponent {
    @Input()
    set dataset(dataset: Dataset) {
        this.localFiltersService.datasetId$.next(dataset.id);
        this.localFiltersService.columns$.next(dataset.columns);
    }

    @Input()
    set filters(filters: DataOptionFilter[]) {
        const parsedFilters = this.parseFilters(filters);

        this.form.clear();

        if (parsedFilters && parsedFilters.length) {
            this.localFiltersService
                .addFilters(parsedFilters)
                .forEach((control) => this.form.push(control));

            this.cdr.detectChanges();
        }

        this.filterCounter = this.form.length;
    }

    @WatchChanges()
    filterCounter: number = 0;

    get form(): UntypedFormArray {
        return this.localFiltersService.form;
    }

    constructor(
        private localFiltersService: LocalFiltersService,
        private cdr: ChangeDetectorRef,
    ) {}

    private parseFilters(filters: DataOptionFilter[]): DataOptionFilter[] {
        if (filters.length === 1 && 'child' in filters[0]) {
            return filters[0].child!;
        } else {
            return filters;
        }
    }
}
