import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CalculateColumnIconComponent } from './calculate-column-icon.component';
import { ShoelaceModule } from 'shoelace-style-angular';

@NgModule({
    declarations: [CalculateColumnIconComponent],
    imports: [CommonModule, ShoelaceModule],
    exports: [CalculateColumnIconComponent],
    schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class CalculateColumnIconModule {}
