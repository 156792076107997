<div class="pagination" *ngIf="items.length">
    <div
        *ngFor="let item of items"
        class="item"
        [class.current]="item.page === currentPage"
        (click)="change(item.page)"
    >
        {{ item.label }}
    </div>
</div>
