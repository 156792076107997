import { Directive, Input, NgModule, OnInit } from '@angular/core';
import { SubscribableDirective } from 'ngx-subscribable';

import { fromEvent, merge } from 'rxjs';
import { getHost } from '../../../tools/get-host';
import { resizeObserver } from '../../../tools/resize-observer';

export type Placement =
    | 'top'
    | 'top-start'
    | 'top-end'
    | 'bottom'
    | 'bottom-start'
    | 'bottom-end'
    | 'right'
    | 'right-start'
    | 'right-end'
    | 'left'
    | 'left-start'
    | 'left-end';

@Directive({ selector: '[stickTo]' })
export class StickToDirective extends SubscribableDirective implements OnInit {
    @Input()
    stickTo!: HTMLElement;

    //необходимо доработать, пока что работает только bottom-start
    @Input()
    placement: Placement = 'bottom-start';

    private host = getHost();

    ngOnInit(): void {
        const { host, stickTo } = this;

        const resize = merge(
            resizeObserver(host),
            resizeObserver(stickTo),
            fromEvent(window, 'resize'),
        );

        this.subscriptions = [
            resize.subscribe(() => {
                const { left, bottom } = stickTo.getBoundingClientRect();

                host.style.top = `${bottom}px`;
                host.style.left = `${left}px`;
            }),
        ];
    }
}

@NgModule({
    exports: [StickToDirective],
    declarations: [StickToDirective],
})
export class StickToDirectiveModule {}
