import { HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import {
    AbstractControl,
    AbstractControlOptions,
    ValidatorFn,
    FormBuilder,
    FormGroup,
} from '@angular/forms';
import { BehaviorSubject, Observable } from 'rxjs';

import { ResponseErrorPayload } from '../../interfaces/rest-api';
import { sendOptionsKey, serverErrorKey, showErrorsFor } from './internal';
import { SearchParam } from '../../enums/search-param';
import { deepSearchByProperty } from '../../tools/deep-search-by-property';

export enum FormState {
    Create = 'create',
    Update = 'update',
}

type O = { [key: string]: any };

export interface Form<T>
    extends FormGroup<{ [K in keyof T]: AbstractControl<T[K]> }> {
    state: FormState;
}

export type FormConfig<T extends O> = {
    [property in keyof T]: NonNullable<T[property]> extends any[]
        ? AbstractControl
        : NonNullable<T[property]> extends O
        ? AbstractControl
        : FormControlConfig;
};

export type FormControlConfig = [] | [any] | [any, ValidatorFn[]];

@Injectable()
export class FormService {
    create<T extends O>(
        config: FormConfig<T>,
        options?: AbstractControlOptions,
    ): {
        setup<U>(options: {
            method: (form: T, ...args: any[]) => Observable<U>;
            methodContext?: any;
            methodArguments?: () => any[];

            success(response: U): void;
            error?(response: HttpErrorResponse): void;
        }): Form<T>;
    } {
        const form = create(config, options);

        return {
            setup(options) {
                return Object.assign(form, {
                    [sendOptionsKey]: options,
                });
            },
        };
    }

    form<T extends O>(
        config: FormConfig<T>,
        options?: AbstractControlOptions,
    ): Form<T> {
        return create(config, options);
    }

    showLocalErrors(form: AbstractControl, currentControl = false): void {
        showErrorsFor.emit({ form, currentControl });
    }

    showServerErrors(
        form: AbstractControl,
        errors: ResponseErrorPayload | null,
        currentControl = false,
    ): void {
        Object.entries(errors?.errors || {}).forEach(([path, error]) => {
            const formPath = deepSearchByProperty(
                form.value,
                path,
                SearchParam.Path,
            );

            const control = form.get(formPath) ?? form.get(path);

            control?.setErrors({ [serverErrorKey]: error });
        });

        if (form.invalid) showErrorsFor.emit({ form, currentControl });
    }
}

function create<T extends O>(
    config: FormConfig<T>,
    options?: AbstractControlOptions,
): Form<T> {
    const form: Form<T> = new FormBuilder().group(config, options!) as any;

    Object.defineProperty(form, 'state', {
        get(): FormState {
            return form.value.id ? FormState.Update : FormState.Create;
        },
        configurable: true,
    });

    return form;
}
