<a [routerLink]="href" [class.activated]="isActive(href)">
    <div class="link">
        <div class="icon">
            <ng-content select="sl-icon"></ng-content>
        </div>

        <ng-content></ng-content>
    </div>

    <span *ngIf="count" class="count">{{ count }}</span>
</a>
