import { enumToArray } from 'ts-enum-to-array';

import { FilterTemplateType } from '../enums/filter-template-type';
import { SelectOption } from '../interfaces/select-option';

export const filterTemplateTypes: SelectOption<FilterTemplateType>[] =
    enumToArray(FilterTemplateType).map((value) => ({
        label: `$$.filterTemplate.type.${value}`,
        value,
    }));
