import { EventEmitter, Injectable } from '@angular/core';
import {
    BoundWidgetData,
    TreeDataColumn,
    TreeSelectConfig,
    TreeViewState,
} from '../interfaces/tree-view.interface';
import { BehaviorSubject, Subject } from 'rxjs';
import { DataOptionFilter } from '../../../../shared/interfaces/data-option';

@Injectable({
    providedIn: 'root',
})
export class TreeViewService {
    boundWidgetData$ = new BehaviorSubject<BoundWidgetData | null>(null);

    currentState$ = new BehaviorSubject<TreeViewState>({ value: undefined });

    localFilters = new BehaviorSubject<DataOptionFilter[]>([]);

    treeColumns$ = new Subject<TreeDataColumn[]>();

    updateValueOnLayer = new EventEmitter<{ shouldReset: boolean }>();

    getDefaultTreeSelect(): TreeSelectConfig {
        return {
            columns: [] as TreeDataColumn[],
        } as TreeSelectConfig;
    }
}
