<div class="label">
    <span>{{ '_$.access.title' | translate }}</span>

    <div>
        <sl-button
            size="small"
            circle
            [class.active]="state === State.Groups"
            (click)="state = State.Groups"
        >
            <sl-icon name="people"></sl-icon>
        </sl-button>

        <sl-button
            size="small"
            circle
            [class.active]="state === State.Users"
            (click)="state = State.Users"
        >
            <sl-icon name="person"></sl-icon>
        </sl-button>
    </div>
</div>

<div class="values" [style.maxHeight]="maxHeight">
    <ng-container *ngIf="state === State.Groups">
        <ng-container
            *ngTemplateOutlet="
                valueTemplate;
                context: {
                    $implicit: 'groups',
                    valueTemplate: groupTemplate
                }
            "
        ></ng-container>
    </ng-container>

    <ng-container *ngIf="state === State.Users">
        <ng-container
            *ngTemplateOutlet="
                valueTemplate;
                context: {
                    $implicit: 'users',
                    valueTemplate: userTemplate
                }
            "
        ></ng-container>
    </ng-container>
</div>

<sl-button
    variant="text"
    class="btn-add"
    test-id="Add"
    (click)="dialogOpened = true"
>
    {{ '_$.access.add' | translate }}
</sl-button>

<ng-template #valueTemplate let-target let-valueTemplate="valueTemplate">
    <sl-tag
        *ngFor="let access of getAccess(target); let i = index"
        pill
        removable
        (sl-remove)="onRemove(target, i)"
    >
        <div tooltipify>
            <ng-container
                *ngTemplateOutlet="
                    valueTemplate;
                    context: { $implicit: access.id }
                "
            ></ng-container>
        </div>

        <core-access-right-select
            [value]="access.access_right"
            (valueChange)="changeAccess(target, i, $event)"
        ></core-access-right-select>
    </sl-tag>
</ng-template>

<ng-template #groupTemplate let-accessId>
    {{ groupsMap.get(accessId)?.name }}
</ng-template>

<ng-template #userTemplate let-accessId>
    {{ usersMap.get(accessId)! | username }}
</ng-template>

<sl-dialog
    #dialog
    closeParentDialog
    [(open)]="dialogOpened"
    [label]="'_$.access.title' | translate"
>
    <div class="label viewAccess">
        <div>
            <sl-button
                size="small"
                circle
                [class.active]="state === State.Groups"
                (click)="state = State.Groups"
            >
                <sl-icon name="people"></sl-icon>
            </sl-button>

            <sl-button
                size="small"
                circle
                [class.active]="state === State.Users"
                (click)="state = State.Users"
            >
                <sl-icon name="person"></sl-icon>
            </sl-button>
        </div>

        <sl-input
            #searchInput
            clearable
            class="search"
            [placeholder]="'_$.access.search' | translate"
        >
            <sl-icon slot="prefix" library="core" name="search-bold"></sl-icon>
        </sl-input>
    </div>

    <core-entities
        [show]="state === State.Groups"
        [selectedEntitiesProvider]="selectedGroupsProvider"
        [entitiesProvider]="groupsProvider"
        [entityTemplate]="groupEntityTemplate"
        [searchInput]="searchInput"
        [dialog]="dialog"
    >
        <ng-template #groupEntityTemplate let-group>
            <div tooltipify>
                {{ group.name }}
            </div>
        </ng-template>

        <span class="empty-message">
            {{ '_$.access.empty.groups' | translate }}
        </span>
        <span class="error-message">
            {{ '_$.access.error.groups' | translate }}
        </span>
    </core-entities>

    <core-entities
        [show]="state === State.Users"
        [selectedEntitiesProvider]="selectedUsersProvider"
        [entitiesProvider]="usersProvider"
        [entityTemplate]="userEntityTemplate"
        [searchInput]="searchInput"
        [dialog]="dialog"
    >
        <ng-template #userEntityTemplate let-user>
            <core-avatar [user]="user" [size]="32"></core-avatar>

            <div tooltipify>
                {{ user | username }}
            </div>
        </ng-template>

        <span class="empty-message">
            {{ '_$.access.empty.users' | translate }}
        </span>
        <span class="error-message">
            {{ '_$.access.error.users' | translate }}
        </span>
    </core-entities>

    <div slot="footer">
        <sl-button
            variant="primary"
            test-id="Cancel"
            outline
            (click)="dialogOpened = false"
        >
            {{ '$.cancel' | translate }}
        </sl-button>

        <sl-button
            class="apply-button"
            test-id="Apply"
            variant="primary"
            (click)="onApply()"
        >
            {{ '$.apply' | translate }}
        </sl-button>
    </div>
</sl-dialog>
