import { CommonModule } from '@angular/common';
import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';

import { MultiSelectModule } from '../../../components/multi-select/multi-select.module';
import { FormModule } from '../../../modules/form/form.module';
import { TranslateModule } from '../../../modules/translate/translate.module';
import { FilterDateValueDefaultModule } from '../filter-date-value-default/filter-date-value-default.module';
import { FilterValueDefaultComponent } from './filter-value-default.component';
import { PlmtSelectModule } from '../../../plmt-ui-components/plmt-select/plmt-select.module';

@NgModule({
    imports: [
        CommonModule,
        TranslateModule,
        MultiSelectModule,
        PlmtSelectModule,
        FilterDateValueDefaultModule,
        FormModule,
    ],
    exports: [FilterValueDefaultComponent],
    declarations: [FilterValueDefaultComponent],
    schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class FilterValueDefaultModule {}
