import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';

import { CommonModule } from '@angular/common';
import { ButtonComponent } from './button.component';

export * from './button.component';

@NgModule({
    imports: [CommonModule],
    exports: [ButtonComponent],
    declarations: [ButtonComponent],
    schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class ButtonModule {}
