import { Component } from '@angular/core';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import { not } from 'logical-not';
import { WatchChanges } from 'ng-onpush';

import { StorageApiService } from '../../../api/storage-api.service';
import { AppService } from '../../../services/app.service';

const DEFAULT_LOGO = 'assets/core-library/icons/polymatica-logo-medium.svg';

@Component({
    selector: 'lib-logo',
    templateUrl: './logo.component.html',
    styleUrls: ['./logo.component.less'],
})
export class LogoComponent {
    @WatchChanges()
    image = '';

    @WatchChanges()
    svg: SafeHtml = '';

    constructor(
        appService: AppService,
        private domSanitizer: DomSanitizer,
        private storageApiService: StorageApiService,
    ) {
        appService.theme.subscribe((theme) => {
            if (theme?.logo?.file_path) {
                const path = theme.logo.file_path;

                if (path.endsWith('.svg')) this.loadSvg(path);
                else this.image = this.toStaticPath(path);
            } else this.image = DEFAULT_LOGO;
        });
    }

    private loadSvg(path: string): void {
        this.storageApiService.read(path).subscribe((svg) => {
            this.svg = this.domSanitizer.bypassSecurityTrustHtml(svg);
        });
    }

    private toStaticPath(path: string): string {
        if (not(path.startsWith('/'))) path = '/' + path;

        return this.storageApiService.resolve(path);
    }
}
