export enum OptionsBlock {
    NA,
    Column,
    Number,
    Boolean,
    Date,
}

export enum DataType {
    Period = 1,
    DateAndTime,
    Date,
    Year,
    Month,
    Day,
    Hour,
    Minute,
    Second,
    MiliSec,
}

export enum NumberType {
    Diaposone = 1,
    Equal,
    NotEqual,
    LessThan,
    GreaterThan,
    LessThanOrEqualTo,
    GreaterThanOrEqualTo,
}

export enum DateFormat {
    Date = 'date',
    DateTime = 'datetime-local',
}
