import {
    Component,
    EventEmitter,
    HostBinding,
    Input,
    OnChanges,
    Output,
    SimpleChanges,
} from '@angular/core';
import { not } from 'logical-not';
import { SubscribableComponent } from 'ngx-subscribable';
import {
    Subscription,
    distinctUntilChanged,
    filter,
    fromEvent,
    switchMap,
    takeUntil,
    tap,
} from 'rxjs';

import { assert } from '../../../tools/assert';
import { getHost } from '../../../tools/get-host';

const popupStack: PopupComponent[] = [];

@Component({
    selector: 'core-popup',
    templateUrl: './popup.component.html',
    styleUrls: ['./popup.component.less'],
})
export class PopupComponent extends SubscribableComponent implements OnChanges {
    @Input()
    open = false;

    @Input()
    visibleOverlay = false;

    @Input()
    overlayColor?: string;

    @Output()
    openChange = new EventEmitter<boolean>();

    @Output()
    index = new EventEmitter<number>();

    @Output()
    key = new EventEmitter<string>();

    overlayZIndex = 0;

    private host = getHost();
    private visible = new EventEmitter<boolean>();

    @HostBinding('class.visible-overlay')
    private activeOverlay = false;

    constructor() {
        super();

        const {
            host: { classList },
            visible,
            key,
        } = this;

        const show = visible.pipe(
            filter((visible) => visible),
            distinctUntilChanged(),
        );

        const hide = visible.pipe(
            filter((visible) => not(visible)),
            distinctUntilChanged(),
        );

        const keyStream = show.pipe(
            switchMap(() =>
                fromEvent<KeyboardEvent>(document, 'keydown').pipe(
                    takeUntil(hide),
                    tap((event) => key.emit(event.key)),
                ),
            ),
        );

        this.subscriptions = [
            visible.subscribe((visible) => {
                if (visible) classList.remove('hidden');
                else classList.add('hidden');
            }),

            show.subscribe(() => {
                assert(() => not(popupStack.includes(this)));

                popupStack.unshift(this);

                this.overlayZIndex = 800 + popupStack.length * 2;

                this.index.emit(this.overlayZIndex + 1);

                if (not(this.visibleOverlay)) return;

                for (let popup of popupStack) {
                    if (popup.activeOverlay) {
                        popup.activeOverlay = false;

                        break;
                    }
                }

                this.activeOverlay = true;
            }),

            hide.subscribe(() => {
                assert(() => Object.is(popupStack[0], this));

                if (not(this.visibleOverlay)) return;

                this.activeOverlay = true;

                popupStack.shift();

                for (let popup of popupStack) {
                    if (popup.visibleOverlay) {
                        popup.activeOverlay = true;

                        break;
                    }
                }
            }),

            keyStream.subscribe(),

            new Subscription(() => {
                if (this === popupStack[0]) popupStack.shift();
            }),
        ];
    }

    ngOnChanges(changes: SimpleChanges): void {
        if ('open' in changes) this.visible.emit(this.open);
    }

    onOverlayClick(event: Event): void {
        event.stopPropagation();

        this.openChange.emit(false);
    }
}
