export enum DatasetSourceType {
    Datasource = 'datasource',
    File = 'file',
    Api = 'api',
    CSV = 'csv',
    XLS = 'xls',
    Google = 'google',
    Yandex = 'yandex',
}

export enum DatasetType {
    Joined = 'joined',
    Local = 'local',
}

export enum DatasetState {
    Wait,
    Done,
    Reject,
}

export enum DatasetPeriodType {
    EveryDay = 'every_day',
    EveryWeek = 'every_week',
    EveryMonth = 'every_month',
}

export enum FileTypes {
    CSV = 'csv',
    Excel = 'excel',
    JSON = 'json',
    XML = 'xml',
}

export enum DatasetPeriodDayWeek {
    Monday = '0',
    Tuesday = '1',
    Wednesday = '2',
    Thursday = '3',
    Friday = '4',
    Saturday = '5',
    Sunday = '6',
}

export enum ColumnType {
    String,
    Number,
    Date,
    Boolean,
}

export enum ColumnFormulaType {
    Id,
    Token,
}

export enum UpdateAction {
    Reject = 'reject-update',
    Update = 'update-data',
}
