import { Directive, HostBinding, Input } from '@angular/core';

@Directive({
    selector: 'td[width], th[width]',
})
export class WidthDirective {
    @Input()
    @HostBinding('style.width')
    width = '';
}

@Directive({
    selector: 'td[maxWidth], th[maxWidth]',
})
export class MaxWidthDirective {
    @Input()
    @HostBinding('style.max-width')
    maxWidth = '';
}
