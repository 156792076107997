import { not } from 'logical-not';

import { ResponseError } from '../interfaces/rest-api';

export function isResponseError(source: any): source is ResponseError {
    if (not(source)) return false;

    return (
        typeof source.code === 'string' && typeof source.message === 'string'
    );
}
