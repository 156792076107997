import {
    HttpEvent,
    HttpInterceptor,
    HttpHandler,
    HttpRequest,
    HttpErrorResponse,
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, tap } from 'rxjs';

import { LogoutService } from '../../services/logout.service';
import { Debounce } from '../../tools/debounce';
import { ActivatedRoute } from '@angular/router';

const PATH_AUTH = '/auth';
const PARAMETER_NAME = 'redirect';

@Injectable()
export class Interceptor implements HttpInterceptor {
    constructor(
        private logoutService: LogoutService,
        private route: ActivatedRoute,
    ) {}

    intercept(
        request: HttpRequest<any>,
        next: HttpHandler,
    ): Observable<HttpEvent<any>> {
        return next.handle(request).pipe(
            tap({
                error: (event) => {
                    if (event instanceof HttpErrorResponse)
                        switch (event.status) {
                            case 401:
                                this.toAuthPage();
                                break;
                        }
                },
            }),
        );
    }

    @Debounce()
    private toAuthPage(): void {
        const queryParams = this.route.snapshot.queryParams[PARAMETER_NAME];
        const params =
            !queryParams && location.pathname === PATH_AUTH
                ? { withBackwardRedirect: false }
                : undefined;

        this.logoutService.logout(params);
    }
}
