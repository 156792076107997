// src/components/card/card.styles.ts
import { css } from "lit";
var card_styles_default = css`
  :host {
    --border-color: var(--sl-color-neutral-200);
    --border-radius: var(--sl-border-radius-medium);
    --border-width: 1px;
    --padding: var(--sl-spacing-large);

    display: inline-block;
  }

  .card {
    display: flex;
    flex-direction: column;
    background-color: var(--sl-panel-background-color);
    box-shadow: var(--sl-shadow-x-small);
    border: solid var(--border-width) var(--border-color);
    border-radius: var(--border-radius);
  }

  .card__image {
    display: flex;
    border-top-left-radius: var(--border-radius);
    border-top-right-radius: var(--border-radius);
    margin: calc(-1 * var(--border-width));
    overflow: hidden;
  }

  .card__image::slotted(img) {
    display: block;
    width: 100%;
  }

  .card:not(.card--has-image) .card__image {
    display: none;
  }

  .card__header {
    display: block;
    border-bottom: solid var(--border-width) var(--border-color);
    padding: calc(var(--padding) / 2) var(--padding);
  }

  .card:not(.card--has-header) .card__header {
    display: none;
  }

  .card:not(.card--has-image) .card__header {
    border-top-left-radius: var(--border-radius);
    border-top-right-radius: var(--border-radius);
  }

  .card__body {
    display: block;
    padding: var(--padding);
  }

  .card--has-footer .card__footer {
    display: block;
    border-top: solid var(--border-width) var(--border-color);
    padding: var(--padding);
  }

  .card:not(.card--has-footer) .card__footer {
    display: none;
}    
        .card__body::-webkit-scrollbar {
            width: 4px;
            height: 4px;
            transition: width 0.2s;
            background: transparent;
        }
        
        .card__body::-webkit-scrollbar:hover {
            height: 6px;
            width: 6px;
        }
        
        .card__body::-webkit-scrollbar-thumb {
            border-radius: 2px;
            transition: background-color 0.2s;
            background-color: rgb(217, 221, 234);
        }
        
        .card__body::-webkit-scrollbar-thumb:hover {
            border-radius: 4px;
            background-color: rgb(0, 174, 213);
        }
    `;
export { card_styles_default };