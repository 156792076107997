export enum ValidationErrorKey {
    // ng keys
    Required = 'required',
    Min = 'min',
    MinDate = 'minDate',
    Max = 'max',
    MinLength = 'minlength',
    MaxDate = 'maxDate',
    MaxLength = 'maxlength',
    Pattern = 'pattern',
    Email = 'email',
    Symbols = 'symbol',

    // core-library keys
    Integer = 'integer',
    Reserved = 'reserved',
    Repeat = 'repeat',
    Url = 'url',
    InvalidValue = 'invalidValue',
}
