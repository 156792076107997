import { isNull, isUndefined } from 'lodash';

import { DATA_VIEW_PATH } from '../../constants/filter-default-value-keys';
import { DataOptionFilter } from 'src/shared/interfaces/data-option';
import { KeyView } from 'src/shared/interfaces/filter-template';

export const NULL_VALUE = 'null\u00AD\u00AD';

export function isKeyViewValue(value: any): value is KeyView {
    return isNull(value)
        ? false
        : typeof value == 'object' && DATA_VIEW_PATH in value;
}

export function checkIfHasDefaultValue(value: any): boolean {
    const isArray = Array.isArray(value);

    if (isArray && value.length === 0) return false;

    return value !== '' && !isNull(value) && !isUndefined(value);
}

export function convertMarkerValuesToNull(data: any): any {
    const condition = (value: any) => (value === NULL_VALUE ? null : value);

    return parsedByArrayFilterValue(data, condition);
}

export function convertNullValuesToMarker(data: any): any {
    const condition = (value: any) => (isNull(value) ? NULL_VALUE : value);

    return parsedByArrayFilterValue(data, condition);
}

export function updateValueByCondition(
    value: any,
    condition: (value: any) => any,
    parsedValue?: any,
): any {
    const valueToCondition = (value: any) =>
        condition(isUndefined(parsedValue) ? value : parsedValue);

    if (isKeyViewValue(value)) {
        value[DATA_VIEW_PATH] = valueToCondition(value[DATA_VIEW_PATH]);
    } else {
        value = valueToCondition(value);
    }

    return value;
}

export function parsedByArrayFilterValue(
    data: any,
    condition: (value: any) => any,
): any {
    if (Array.isArray(data)) {
        return data.map((item) => updateValueByCondition(item, condition));
    } else {
        return updateValueByCondition(data, condition);
    }
}

export function everyArrayCheck(
    data: any,
    callback: (value: any) => any,
): boolean {
    return Array.isArray(data)
        ? data.every((item) => callback(item))
        : callback(data);
}

export function setCorrectLocalFilterValues(
    filters: DataOptionFilter[],
): DataOptionFilter[] {
    filters.map((item) => {
        if ('child' in item) {
            setCorrectLocalFilterValues(item.child!);
        }

        item.value = convertMarkerValuesToNull(item.value);

        if (
            Array.isArray(item.value) &&
            item.value.every((value: any) => isNull(value))
        ) {
            item.value = null;
        }
    });

    return filters;
}
