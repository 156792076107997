import { Injectable } from '@angular/core';
import { not } from 'logical-not';
import { enumToArray } from 'ts-enum-to-array';

import { ColumnType } from '../../../enums/dataset';
import { FilterMethod } from '../../../enums/filter';
import { FilterUIType } from '../../../enums/types';
import {
    Cache,
    FiltersViewService,
} from '../../../services/filters-view.service';

const cache: Cache<
    LocalFiltersTypeService,
    | 'filterUIType'
    | 'filterUIsByColumnType'
    | 'filterMethods'
    | 'filterMethodsByColumnType'
> = {};

@Injectable({ providedIn: 'root' })
export class LocalFiltersTypeService extends FiltersViewService {
    readonly filterMethodsByColumnType: Record<ColumnType, FilterMethod[]>;

    constructor() {
        super();

        if (not(cache.filterUIType)) {
            cache.filterMethodsByColumnType = {} as any;

            const filterMethods = enumToArray(FilterMethod);

            enumToArray(ColumnType).forEach((columnType) =>
                filterMethods.forEach((filterMethod) => {
                    const filterUIType = this.getFilterUIType(
                        columnType,
                        filterMethod,
                    );

                    setFilterMethodsByColumnType(
                        columnType,
                        filterUIType,
                        filterMethod,
                    );
                }),
            );
        }

        this.filterMethodsByColumnType = cache.filterMethodsByColumnType!;
    }

    getFilterUIType(
        columnType: ColumnType,
        filterMethod: FilterMethod,
    ): FilterUIType | null {
        switch (columnType) {
            case ColumnType.String:
                switch (filterMethod) {
                    case FilterMethod.Equal:
                    case FilterMethod.NotEqual:
                        return FilterUIType.SelectSuggest;
                    case FilterMethod.InList:
                    case FilterMethod.NotInList:
                        return FilterUIType.SelectMulti;
                    case FilterMethod.Like:
                    case FilterMethod.Like$CaseIgnore:
                    case FilterMethod.Like$Not:
                    case FilterMethod.Like$Not$CaseIgnore:
                    case FilterMethod.StartsLike:
                    case FilterMethod.StartsLike$CaseIgnore:
                    case FilterMethod.StartsLike$Not:
                    case FilterMethod.StartsLike$Not$CaseIgnore:
                    case FilterMethod.EndsLike:
                    case FilterMethod.EndsLike$CaseIgnore:
                    case FilterMethod.EndsLike$Not:
                    case FilterMethod.EndsLike$Not$CaseIgnore:
                        return FilterUIType.Search;
                    default:
                        return null;
                }
            case ColumnType.Number:
                switch (filterMethod) {
                    case FilterMethod.Range:
                    case FilterMethod.Range$Not:
                        return FilterUIType.NumberRange;
                    case FilterMethod.InList:
                    case FilterMethod.NotInList:
                        return FilterUIType.SelectMulti;
                    case FilterMethod.Equal:
                    case FilterMethod.NotEqual:
                    case FilterMethod.GreaterThen:
                    case FilterMethod.LessThen:
                    case FilterMethod.GreaterThenOrEqual:
                    case FilterMethod.LessThenOrEqual:
                        return FilterUIType.Number;

                    default:
                        return null;
                }
            case ColumnType.Date:
                switch (filterMethod) {
                    case FilterMethod.InList:
                    case FilterMethod.NotInList:
                        return FilterUIType.SelectMulti;
                    case FilterMethod.Equal:
                    case FilterMethod.NotEqual:
                    case FilterMethod.GreaterThen:
                    case FilterMethod.LessThen:
                    case FilterMethod.GreaterThenOrEqual:
                    case FilterMethod.LessThenOrEqual:
                        return FilterUIType.Date;
                    case FilterMethod.DateRange:
                    case FilterMethod.DateRange$Not:
                        return FilterUIType.DateRange;
                    default:
                        return null;
                }
            case ColumnType.Boolean:
                switch (filterMethod) {
                    case FilterMethod.Equal:
                    case FilterMethod.NotEqual:
                        return FilterUIType.SelectSuggest;
                    case FilterMethod.InList:
                    case FilterMethod.NotInList:
                        return FilterUIType.SelectMulti;
                    default:
                        return null;
                }
            default:
                return null;
        }
    }
}

function setFilterMethodsByColumnType(
    columnType: ColumnType,
    filterUIType: FilterUIType | null,
    filterMethod: FilterMethod,
) {
    const map = cache.filterMethodsByColumnType!;

    if (not(map[columnType])) map[columnType] = [];

    if (not(filterUIType)) return;

    if (not(map[columnType].includes(filterMethod)))
        map[columnType].push(filterMethod);
}
