import {
    Directive,
    Input,
    NgModule,
    TemplateRef,
    ViewContainerRef,
} from '@angular/core';

import { AccessRightsService } from '../services/access-rights.service';

@Directive({ selector: '[accessRights]' })
export class AccessRightsDirective {
    constructor(
        private accessRightsService: AccessRightsService,
        private templateRef: TemplateRef<void>,
        private viewContainer: ViewContainerRef,
    ) {}

    @Input()
    set accessRights(rights: number | number[]) {
        if (typeof rights === 'number') rights = [rights];

        if (this.accessRightsService.checkAccessRights(rights))
            this.viewContainer.createEmbeddedView(this.templateRef);
        else this.viewContainer.clear();
    }
}

@NgModule({
    exports: [AccessRightsDirective],
    declarations: [AccessRightsDirective],
})
export class AccessRightsDirectiveModule {}
