import { Component, Input, OnChanges } from '@angular/core';
import { AbstractControl } from '@angular/forms';
import { isNull } from 'lodash';

import { FilterLogical } from '../../../../enums/filter';

@Component({
    selector: 'core-logical-button',
    templateUrl: './logical-button.component.html',
    styleUrls: ['./logical-button.component.less'],
})
export class LogicalButtonComponent implements OnChanges {
    @Input()
    logicalControl!: AbstractControl;

    @Input()
    isLast!: boolean;

    ngOnChanges(): void {
        if (this.isLast && this.logicalControl.value) {
            this.logicalControl.patchValue(null);
        }

        if (!this.isLast && isNull(this.logicalControl.value)) {
            this.logicalControl.patchValue(FilterLogical.And);
        }
    }

    updateValue(): void {
        const { logicalControl } = this;

        const condition = logicalControl.value === FilterLogical.Or;

        logicalControl.patchValue(
            condition ? FilterLogical.And : FilterLogical.Or,
        );
    }
}
