import { inject } from '@angular/core';
import { CanDeactivateFn } from '@angular/router';
import { map, take, tap } from 'rxjs';
import { PageService } from '../services/page.service';
import { StorageService } from '../services/storage.service';
import { ThemesEditService } from '../services/themes-edit.service';

export const ThemeEditLeaveGuard: CanDeactivateFn<unknown> = (
    _component,
    _currentRoute,
    _currentState,
    nextState,
) => {
    const themesEditService = inject(ThemesEditService);
    const pageService = inject(PageService);
    const storageService = inject(StorageService);

    const { showModal$ } = themesEditService;

    if (!showModal$.value || !storageService.authToken.value) {
        return true;
    }

    pageService.loading = false;

    themesEditService.leaveThemesModal?.show();

    return themesEditService.leaveThemesModal.close.pipe(
        tap((value) => {
            if (value) {
                themesEditService.nextUrlRedirect$.next(nextState?.url);
            }

            themesEditService.saveOnexit$.next(value);
            themesEditService.leaveThemesModal.hide();
        }),
        map((value) => !value),
        take(1),
    );
};
