<div
    class="container"
    [class.error-border]="loadStatus === LoadingStatus.Reject"
    [class.loading-container]="loadStatus === LoadingStatus.Loading"
>
    <sl-icon library="core" name="upload"></sl-icon>

    <div>
        <core-file-source
            [accept]="accept"
            [uploadItem]="customLoadButton || uploadButton"
            (pick)="pick($event)"
        >
            <ng-container [ngSwitch]="loadStatus">
                <ng-container *ngSwitchCase="LoadingStatus.Default">
                    <ng-container
                        *ngTemplateOutlet="
                            defaultDescription;
                            context: {
                                $implicit: description
                            }
                        "
                    ></ng-container>
                </ng-container>
                <ng-container *ngSwitchCase="LoadingStatus.Reject">
                    <div class="text-center" [class.hidden]="hideDescription">
                        <div
                            *ngIf="errorDescription.hasDescription"
                            class="error-description-additional"
                        >
                            <ng-container
                                *ngTemplateOutlet="
                                    defaultDescription;
                                    context: {
                                        $implicit: description
                                    }
                                "
                            ></ng-container>
                        </div>

                        <div class="description error-description">
                            {{ errorDescription.error | translate }}
                        </div>
                    </div>
                </ng-container>
                <ng-container *ngSwitchCase="LoadingStatus.Success">
                    <div class="file-name success-name text-center">
                        {{ fileName || '' }}
                    </div>

                    <div
                        class="description success-description"
                        [class.hidden]="hideDescription"
                    >
                        {{ '_$.filePicker.success' | translate }}
                    </div>
                </ng-container>
                <ng-container *ngSwitchCase="LoadingStatus.Loading">
                    <div class="file-name text-center">
                        {{ fileName }}
                    </div>
                    <div class="description" [class.hidden]="hideDescription">
                        {{ '_$.filePicker.loading' | translate }}
                    </div>
                    <sl-progress-bar indeterminate></sl-progress-bar>
                </ng-container>
            </ng-container>
            <div *ngIf="extraButton" extraButton>
                <ng-container *ngTemplateOutlet="extraButton"></ng-container>
            </div>
        </core-file-source>
    </div>
</div>
<div class="drop-zone" [class.hidden]="!showDropZone">
    <div
        *ngIf="item"
        class="drop-zone-item"
        [class.active]="activeDropZoneItem === item"
        (drop)="item.onDrop($event)"
        (dragenter)="activeDropZoneItem = item"
        (dragleave)="activeDropZoneItem = undefined"
    >
        <div class="drop-zone-title">
            {{ item.dropZoneTitle | translate }}
        </div>

        <div class="drop-zone-description">
            {{ item.dropZoneDescription | translate }}
        </div>
    </div>
</div>

<ng-template #defaultDescription let-description>
    <div
        *ngFor="let key of description"
        class="description"
        [class.hidden]="hideDescription"
    >
        {{ key | translate: { accept: getParseAcception() } }}
    </div>
</ng-template>

<ng-template #uploadButton>
    <sl-button
        *ngIf="loadStatus !== LoadingStatus.Loading"
        upload
        variant="primary"
        outline
        test-id="Upload"
    >
        {{
            (loadStatus === LoadingStatus.Default
                ? '$.upload'
                : '$.uploadOther'
            ) | translate
        }}
    </sl-button>
</ng-template>
