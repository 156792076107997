import { Format_Server } from '../../../interfaces/column.format';
import { GetEmptyFormatSettings } from '.';

export class BooleanFormatSettings {
    static getEmpty: GetEmptyFormatSettings = () => {
        return {
            bool_true_format: '',
            bool_false_format: '',
        };
    };

    readonly trueText: string;
    readonly falseText: string;
    readonly nullText: string;

    constructor(source: Format_Server) {
        this.trueText = source.bool_true_format;
        this.falseText = source.bool_false_format;
        this.nullText = source.null_format;
    }
}
