import { Component, ElementRef, TemplateRef, ViewChild } from '@angular/core';
import { SlTooltip } from '@shoelace-style/shoelace';

import { OverflowParams } from '../overflow.directive';

@Component({
    selector: 'core-overflow-internal',
    templateUrl: './overflow-internal.component.html',
    styleUrls: ['./overflow-internal.component.less'],
})
export class OverflowInternalComponent {
    @ViewChild('tooltip', { static: true })
    tooltip?: ElementRef<SlTooltip>;

    content: TemplateRef<void> | null = null;
    params: OverflowParams = {};

    disable = true;

    get tooltipText(): string {
        return (
            this.params.content || this.tooltip?.nativeElement.textContent || ''
        );
    }

    reflow(event: CustomEvent<{ entries: ResizeObserverEntry[] }>): void {
        const [{ target: element }] = event.detail.entries;

        this.disable = element.scrollWidth <= element.clientWidth;
    }
}
