import { NgModule, Pipe, PipeTransform } from '@angular/core';
import { EventEntityType, ProjectImportData } from 'plmt-core-library';
import { enumToArray } from 'ts-enum-to-array';

@Pipe({ name: 'importedEntitiesNames' })
export class ImportedEntitiesNamesPipe implements PipeTransform {
    transform(
        data: ProjectImportData[],
        separator = ', ',
        entityTypes: EventEntityType[] = [],
    ): string {
        const filterEntities =
            entityTypes.length > 0 ? entityTypes : enumToArray(EventEntityType);

        return data
            .filter((i) => filterEntities.includes(i.entity))
            .map((i) => `${i.name}`)
            .join(separator);
    }
}

@NgModule({
    exports: [ImportedEntitiesNamesPipe],
    declarations: [ImportedEntitiesNamesPipe],
})
export class ImportedEntitiesNamesPipeModule {}
