import { ColumnWithKey, Dataset } from '../interfaces/dataset';
import { DataItem } from '../api/data-api.service';
import { WidgetDataset } from 'polymatica-widget-component/esm/interfaces/data';

export function setDataItem(
    dataset: Dataset,
    widgetDataset: WidgetDataset,
): DataItem {
    return {
        rows: widgetDataset.rows,
        total: widgetDataset.rows.length,
        columns: getColumnsWithKey(dataset, widgetDataset),
        dataset: dataset,
    };
}

function getColumnsWithKey(
    dataset: Dataset,
    widgetDataset: WidgetDataset,
): ColumnWithKey[] {
    const columnWithKey: ColumnWithKey[] = [];
    const { columns, columnsByBlock } = widgetDataset;

    Object.entries(columnsByBlock).forEach(([key, value]) => {
        value.forEach((item) => {
            columnWithKey.push({
                key: columns.find((column) => column.id === item)!.path,
                block_key: key,
                column: dataset.columns.find(({ id }) => id === item)!,
            });
        });
    });

    return columnWithKey;
}
