<div *ngIf="breadcrumbs as breadcrumbs" class="center">
    <ng-container *ngFor="let item of breadcrumbs.items; last as last">
        <span class="crumb link" [routerLink]="item.link">
            {{ item.label | async }}
        </span>

        <sl-icon
            *ngIf="!(last && !breadcrumbs.target)"
            library="core"
            name="breadcrumbs-splitter"
        ></sl-icon>
    </ng-container>

    <ng-container *ngIf="breadcrumbs.target as target">
        <span class="crumb">
            {{ target | async }}
        </span>
    </ng-container>
</div>

<div class="center">
    <div class="content center">
        <h1>
            {{ title }}
            <sl-badge pill *ngIf="counter">{{ counter }}</sl-badge>
        </h1>

        <div *ngIf="extra" class="extra center">
            <ng-container *ngTemplateOutlet="extra"></ng-container>
        </div>
    </div>

    <div class="controls center">
        <ng-content></ng-content>
    </div>
</div>
