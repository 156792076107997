import { Lang } from '../../../enums/lang';
import { DatePluralPeriod } from '../enums/date-plural-period';

export const datePlural: Record<
    DatePluralPeriod,
    Record<Lang, { [count: string]: string }>
> = {
    [DatePluralPeriod.Second]: {
        ru: {
            '=0': 'Секунд',
            one: 'Секунда',
            few: 'Секунды',
            other: 'Секунд',
        },
        en: {
            '=0': 'Seconds',
            one: 'Second',
            other: 'Seconds',
        },
    },
    [DatePluralPeriod.Minute]: {
        ru: {
            '=0': 'Минут',
            one: 'Минута',
            few: 'Минуты',
            other: 'Минут',
        },
        en: {
            '=0': 'Minutes',
            one: 'Minute',
            other: 'Minutes',
        },
    },
    [DatePluralPeriod.Hour]: {
        ru: {
            '=0': 'Часов',
            one: 'Час',
            few: 'Часа',
            other: 'Часов',
        },
        en: {
            '=0': 'Hours',
            one: 'Hour',
            other: 'Hours',
        },
    },
    [DatePluralPeriod.Day]: {
        ru: {
            '=0': 'Дней',
            one: 'День',
            few: 'Дня',
            other: 'Дней',
        },
        en: {
            '=0': 'Days',
            one: 'Day',
            other: 'Days',
        },
    },
    [DatePluralPeriod.Week]: {
        ru: {
            '=0': 'Недель',
            one: 'Неделя',
            few: 'Недели',
            other: 'Недель',
        },
        en: {
            '=0': 'Weeks',
            one: 'Week',
            other: 'Weeks',
        },
    },
    [DatePluralPeriod.Month]: {
        ru: {
            '=0': 'Месяцев',
            one: 'Месяц',
            few: 'Месяца',
            other: 'Месяцев',
        },
        en: {
            '=0': 'Months',
            one: 'Month',
            other: 'Months',
        },
    },
    [DatePluralPeriod.Quarter]: {
        ru: {
            '=0': 'Кварталов',
            one: 'Квартал',
            few: 'Квартала',
            other: 'Кварталов',
        },
        en: {
            '=0': 'Quarters',
            one: 'Quarter',
            other: 'Quarters',
        },
    },
    [DatePluralPeriod.Year]: {
        ru: {
            '=0': 'Лет',
            one: 'Год',
            few: 'Года',
            other: 'Лет',
        },
        en: {
            '=0': 'Years',
            one: 'Year',
            other: 'Years',
        },
    },
};
