import { Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';
import { AbstractControl, UntypedFormArray } from '@angular/forms';
import not from 'logical-not';
import { SubscribableComponent } from 'ngx-subscribable';
import { validate } from '../../../tools/validate';

import { showErrorsFor } from '../../../modules/form/internal';
import { HintPopupComponent } from '../../hint-popup/hint-popup.component';
import { LocalFiltersService } from '../services/local-filters.service';
import { ValidateReturnEntity } from '../tools/types';

@Component({
    selector: 'core-filter-details',
    templateUrl: './filter-details.component.html',
    styleUrls: ['./filter-details.component.less'],
})
export class FilterDetailsComponent
    extends SubscribableComponent
    implements OnInit
{
    @Input()
    filter!: AbstractControl;

    @ViewChild(HintPopupComponent, { static: true })
    popup!: HintPopupComponent;

    @ViewChild('group')
    readonly group!: ElementRef<HTMLDivElement>;

    isShowContent = true;

    constructor(public localFiltersService: LocalFiltersService) {
        super();
    }

    ngOnInit(): void {
        const child = this.filter.get('child')!;

        this.subscriptions = [
            validate({
                control: child,
                error(): ValidateReturnEntity {
                    if (not(child.value.length)) {
                        return {
                            ['_$$.filters.validation.emptyGroup']: child.value,
                        };
                    }
                },
            }),

            showErrorsFor.subscribe(() => {
                this.popup.active = child.hasError(
                    '_$$.filters.validation.emptyGroup',
                );
            }),

            this.filter
                .get('child')!
                .valueChanges.subscribe(() => (this.popup.active = false)),
        ];
    }

    toFormArray(control: AbstractControl): UntypedFormArray {
        return control as UntypedFormArray;
    }
}
