import {
    HttpClient,
    HttpParams,
    HttpResponse,
    HttpUrlEncodingCodec,
} from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { not } from 'logical-not';
import { map, Observable, tap } from 'rxjs';

import { StorageService } from '../../services/storage.service';
import { download } from '../../tools/download';
import { API_SOURCE } from './internal';
import { ApiBaseUrls } from './interfaces';
import { API_BASE_URLS } from './tokens';

export enum ResponseType {
    ArrayBuffer = 'arraybuffer',
    Blob = 'blob',
    Json = 'json',
    Text = 'text',
}

export enum RequestMethod {
    GET = 'GET',
    POST = 'POST',
}

@Injectable({
    providedIn: 'root',
})
export class RestService {
    readonly headers: Record<string, string> = {};

    get baseUrl(): string {
        return this.apiUrl[this.source] || '';
    }

    constructor(
        @Inject(API_BASE_URLS) private apiUrl: ApiBaseUrls,
        @Inject(API_SOURCE) private readonly source: keyof ApiBaseUrls,
        private httpClient: HttpClient,
        private storageService: StorageService,
    ) {
        this.storageService.authToken.subscribe((token) => {
            if (token) this.headers.Authorization = `Bearer ${token}`;
            else delete this.headers.Authorization;
        });
    }

    request<T>(
        method: RequestMethod,
        url: string,
        config?: {
            params?: any;
            body?: any;
            responseType?: ResponseType;
            version?: number;
        },
    ): Observable<HttpResponse<T>> {
        const v = `/v${config?.version || 1}`;

        return this.httpClient.request(method, this.baseUrl + v + url, {
            observe: 'response',
            responseType: config?.responseType,
            headers: this.headers,
            params: createParams(config?.params),
            body: config?.body,
        }) as any;
    }

    get<T>(
        url: string,
        params: any = {},
        config?: { responseType?: ResponseType; version?: number },
    ): Observable<T> {
        const options = {
            params: createParams(params),
            headers: this.headers,
        } as Exclude<Parameters<HttpClient['get']>[1], undefined>;

        const v = `/v${config?.version || 1}`;

        if (config?.responseType)
            options.responseType = config.responseType as any;

        return this.httpClient.get(
            this.baseUrl + v + url,
            options,
        ) as Observable<T>;
    }

    post<T>(
        url: string,
        body: any = {},
        config?: { responseType?: ResponseType; version?: number },
    ): Observable<T> {
        const v = `/v${config?.version || 1}`;

        const options = { headers: this.headers } as Exclude<
            Parameters<HttpClient['post']>[2],
            undefined
        >;

        if (config?.responseType)
            options.responseType = config.responseType as any;

        return this.httpClient.post(
            this.baseUrl + v + url,
            body,
            options,
        ) as Observable<T>;
    }

    put<T>(
        url: string,
        body: any = {},
        config?: { version?: number },
    ): Observable<T> {
        const options = { headers: this.headers };
        const v = `/v${config?.version || 1}`;

        return this.httpClient.put(
            this.baseUrl + v + url,
            body,
            options,
        ) as Observable<T>;
    }

    patch<T>(
        url: string,
        body: any = {},
        config?: { version?: number },
    ): Observable<T> {
        const options = { headers: this.headers };
        const v = `/v${config?.version || 1}`;

        return this.httpClient.patch(
            this.baseUrl + v + url,
            body,
            options,
        ) as Observable<T>;
    }

    delete<T>(
        url: string,
        params: any = {},
        config?: { version?: number },
    ): Observable<T> {
        const options = { params: createParams(params), headers: this.headers };
        const v = `/v${config?.version || 1}`;

        return this.httpClient.delete(
            this.baseUrl + v + url,
            options,
        ) as Observable<T>;
    }

    download(
        path: string,
        filename?: string,
        config?: { version?: number },
    ): Observable<void> {
        return this.request<Blob>(RequestMethod.GET, path, {
            responseType: ResponseType.Blob,
            version: config?.version,
        }).pipe(
            tap((response) =>
                download(filename || getFileName(response), response.body!),
            ),
            map(() => {}),
        );
    }
}

class Encoder extends HttpUrlEncodingCodec {
    override encodeValue(value: string): string {
        return encodeURIComponent(value);
    }
}

function createParams(source: any): HttpParams | undefined {
    if (not(source)) return undefined;

    return new HttpParams({
        fromObject: source,
        encoder: new Encoder(),
    });
}

function getFileName(response: HttpResponse<unknown>): string {
    const disposition =
        response.headers.get('content-disposition') || '="file"';
    const [, filename] = disposition.split('=');

    return JSON.parse(filename);
}
