import { isArray, isNull, isUndefined } from 'lodash';
import { DataItem } from '../api/data-api.service';
import { searchFilterMethodsMap } from '../constants/filter-methods-provider';
import { ColumnType } from '../enums/dataset';
import { FilterMethod } from '../enums/filter';
import { FilterTemplateUIType } from '../enums/filter-ui-type';
import { Column } from '../interfaces/dataset';
import { LayerBinding } from '../interfaces/layer-binding.interface';

export function convertBindingColumnsToNumber(
    filterAction: FilterMethod,
    filterValue: number | string,
    binding: LayerBinding,
    dependenceData?: { [key: string]: DataItem },
    dependenceColumns?: Column[],
): number | string | any[] {
    const searchMethods = searchFilterMethodsMap[FilterTemplateUIType.Search];

    const columnBinding = dependenceColumns?.find(
        (column) =>
            column.id === binding.source_column_id ||
            column.id === binding.column_id,
    );

    const type = columnBinding?.base_type;

    if (
        typeof filterValue !== 'string' ||
        !searchMethods!.includes(filterAction) ||
        !dependenceData
    ) {
        return castToCorrectType(filterValue, type);
    }

    if (!type || type !== ColumnType.Number) {
        return filterValue;
    }

    const replaced = (filterValue as string).replace(/,/g, '.');
    return isNaN(+replaced) ? filterValue : +replaced;
}

function castToCorrectType(
    value: any,
    columnType: ColumnType | undefined,
): string | number | Array<any> {
    if (isUndefined(columnType)) return value;

    const checkType = (value: any) => {
        if (isNull(value)) return value;

        switch (columnType) {
            case ColumnType.Number:
                return parseFloat(value);
            default:
                return String(value);
        }
    };

    if (isArray(value)) {
        value = value.map((element) => checkType(element));
    } else {
        value = checkType(value);
    }

    return value;
}
