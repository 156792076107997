import { AbstractControl, ValidatorFn } from "@angular/forms";

import { ValidationErrorKey } from '../enums/validation-error-key';

export interface EmailValidatorError {
    [ValidationErrorKey.Email]: null;
}

export const emailValidator: ValidatorFn = (control: AbstractControl) => {
    if (!control.value) {
        return null;
    }

    const regExp = /^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+\.[a-zA-Z0-9-.]+$/;
    if (regExp.test(control.value)) return null;

    return { [ValidationErrorKey.Email]: null };
}
