<div class="head" [class.margin]="filter.value.child.length && isShowContent">
    <ng-content select="[action-buttons]"></ng-content>

    <core-hint-popup
        variant="negative"
        icon="exclamation-circle"
        [label]="'_$$.filters.validation.emptyGroup' | translate"
    >
        <div
            #group
            trigger
            class="summary-block"
            slot="anchor"
            [class.validate-error]="popup.active"
            [attr.data-id]="filter.value.uid"
            [attr.open]="isShowContent ? '' : null"
        >
            <sl-icon
                library="platform"
                name="filters/chevron"
                (click)="isShowContent = !isShowContent"
            ></sl-icon>

            <div class="group-title">
                <sl-icon library="platform" name="filters/group"></sl-icon>

                <span>{{ '_$$.filters.label.group' | translate }}</span>
            </div>

            <ng-content select="[logical-button]"></ng-content>

            <ng-content select="[drag-button]"></ng-content>
        </div>
    </core-hint-popup>
</div>

<core-filter-row
    *ngIf="filter.value.child.length"
    cdkDropList
    [id]="filter.value.uid"
    [cdkDropListData]="filter.get('child')"
    [cdkDropListConnectedTo]="localFiltersService.dropTargetIds"
    [cdkDropListSortingDisabled]="true"
    [hidden]="!isShowContent"
    [localForm]="toFormArray(filter.get('child')!)"
    (cdkDropListDropped)="localFiltersService.drop($event)"
></core-filter-row>
