import {
    Component,
    ElementRef,
    Input,
    OnChanges,
    OnInit,
    QueryList,
    SimpleChanges,
    ViewChildren,
} from '@angular/core';
import { AbstractControl, FormArray } from '@angular/forms';
import { SlDialog } from '@shoelace-style/shoelace';
import { not } from 'logical-not';
import { WatchChanges } from 'ng-onpush';
import { ToParent } from 'ng-to-parent';
import { SubscribableComponent } from 'ngx-subscribable';
import { fromEvent } from 'rxjs';

import { GroupApiService } from '../../api/group-api.service';
import { UserApiService } from '../../api/user-api.service';
import { AccessRight } from '../../enums/access';
import { SortDirection } from '../../enums/sort';
import {
    Access,
    AccessGroup,
    AccessSettings,
    AccessUser,
} from '../../interfaces/access';
import { GroupPublic } from '../../interfaces/group';
import { UserPublic } from '../../interfaces/user';
import { FormService } from '../../modules/form/form.service';
import { provideControlByName } from '../../modules/form/provide-control';
import { UsernamePipe } from '../../pipes/username.pipe';
import { AppService } from '../../services/app.service';
import {
    EntitiesComponent,
    EntitiesProvider,
    Entity,
    SelectedEntities,
    SelectedEntitiesProvider,
} from './entities/entities.component';

enum State {
    Users,
    Groups,
}

type FormKey = keyof AccessSettings;

type Serializer = (id: Entity['id']) => string;

const DEFAULT_MAX_HEIGHT = '289px';

@Component({
    selector: 'core-access',
    templateUrl: './access.component.html',
    styleUrls: ['./access.component.less'],
    providers: [ToParent],
})
export class AccessComponent
    extends SubscribableComponent
    implements OnInit, OnChanges
{
    @Input()
    set settings(value: any) {
        value.groups?.forEach((item: AccessGroup) => (item.id = item.group.id));
        value.users?.forEach((item: AccessUser) => (item.id = item.user.id));

        this._settings = value;
    }

    @Input()
    maxHeight = DEFAULT_MAX_HEIGHT;

    readonly State = State;

    readonly groupsProvider: EntitiesProvider = (params) =>
        this.groupApiService.public({
            order_by: 'name',
            order_direction: SortDirection.Asc,
            ...params,
        });
    readonly usersProvider: EntitiesProvider = (params) =>
        this.userApiService.public({
            order_by: 'last_name',
            order_direction: SortDirection.Asc,
            exclude_ids: [this.currentUserId],

            ...params,
        });

    readonly selectedGroupsProvider: SelectedEntitiesProvider = () =>
        this.selectedEntetiesProvider('groups', this.groupsMap);
    readonly selectedUsersProvider: SelectedEntitiesProvider = () =>
        this.selectedEntetiesProvider('users', this.usersMap);

    @WatchChanges()
    state = State.Groups;

    @WatchChanges()
    dialogOpened = false;

    @WatchChanges()
    groupsMap = new Map<GroupPublic['id'], GroupPublic>();

    @WatchChanges()
    usersMap = new Map<UserPublic['id'], UserPublic>();

    @ViewChildren(EntitiesComponent)
    entitiesComponentList!: QueryList<EntitiesComponent>;

    private _settings!: AccessSettings;

    private controls: Record<FormKey, FormArray | null> = {
        groups: null,
        users: null,
    };

    private serializerFor: Record<FormKey, Serializer> = {
        groups: (id) => {
            return this.groupsMap.get(id)?.name || '';
        },
        users: (id) => {
            const user = this.usersMap.get(id);

            return user ? this.usernamePipe.transform(user) : '';
        },
    };

    get currentUserId(): number {
        return this.appService.user.value.id;
    }

    constructor(
        private appService: AppService,
        private formService: FormService,
        private groupApiService: GroupApiService,
        private hostRef: ElementRef<HTMLElement>,
        private toParent: ToParent,
        private userApiService: UserApiService,
        private usernamePipe: UsernamePipe,
    ) {
        super();
    }

    ngOnInit(): void {
        const dialog = this.hostRef.nativeElement.closest('sl-dialog');

        if (dialog)
            this.subscriptions.push(
                fromEvent(dialog, 'sl-show').subscribe(({ target }) => {
                    if (target === dialog) this.reset();
                }),
            );

        this.provideControl('groups');
        this.provideControl('users');
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (!this._settings.groups || !this._settings.users) return;

        if ('settings' in changes) {
            this.groupsMap.clear();

            this._settings.groups?.forEach(({ id, group }) =>
                this.groupsMap.set(id, group),
            );

            this.appendToForm('groups', this._settings.groups);

            this.usersMap.clear();

            this._settings.users?.forEach(({ id, user }) =>
                this.usersMap.set(id, user),
            );

            this.appendToForm('users', this._settings.users);
        }
    }

    getAccess(key: FormKey): Access[] {
        const control = this.controls[key];

        return control?.value || [];
    }

    changeAccess(key: FormKey, i: number, value: AccessRight): void {
        const control = this.controls[key]?.get(`${i}`)?.get('access_right');

        if (control) control.patchValue(value);
    }

    onRemove(key: FormKey, i: number): void {
        const formArray = this.controls[key];

        formArray?.removeAt(i);
    }

    onApply(): void {
        this.entitiesComponentList.forEach((component) => {
            if (component.entitiesProvider === this.groupsProvider)
                this.apply('groups', this.groupsMap, component.selected);
            else this.apply('users', this.usersMap, component.selected);
        });

        this.dialogOpened = false;
    }

    onShow(dialog: SlDialog): void {
        const parentDialog = dialog.parentElement?.closest('sl-dialog');

        if (parentDialog) parentDialog.style.visibility = 'hidden';
    }

    onHide(dialog: SlDialog): void {
        const parentDialog = dialog.parentElement?.closest('sl-dialog');

        if (parentDialog) parentDialog.style.visibility = '';
    }

    private provideControl(key: FormKey): void {
        this.toParent.send(provideControlByName, {
            path: key,
            provider: (control) => {
                if (isFormArray(control, key)) {
                    this.controls[key] = control;

                    this.appendToForm(key, this._settings[key]);
                }
            },
        });
    }

    private reset(): void {
        this.state = State.Groups;
    }

    private selectedEntetiesProvider<T extends Entity>(
        key: FormKey,
        map: Map<T['id'], T>,
    ): ReturnType<SelectedEntitiesProvider> {
        const selected: SelectedEntities = new Map();

        this.getAccess(key).forEach(({ id, access_right }) => {
            selected.set(id, {
                access: { id, access_right },
                entity: map.get(id)!,
            });
        });

        return selected;
    }

    private apply<T extends Entity>(
        key: FormKey,
        map: Map<T['id'], T>,
        selected?: SelectedEntities,
    ): void {
        map.clear();

        const value: Access[] = [];

        selected?.forEach(({ entity, access }) => {
            map.set(entity.id, entity as any);
            value.push(access);
        });

        this.appendToForm(key, value);
    }

    private appendToForm(key: FormKey, value: Access[]): void {
        const map: Record<string, Access[]> = {};
        const serializedArray: string[] = [];

        value?.forEach((item) => {
            const serialized = this.serializerFor[key](item.id);

            if (not(map[serialized])) {
                serializedArray.push(serialized);
                map[serialized] = [item];
            } else map[serialized].push(item);
        });

        const control = this.controls[key];

        if (control) {
            control.clear();

            serializedArray.sort().forEach((serialized) => {
                map[serialized].forEach(({ id, access_right }) => {
                    control.push(
                        this.formService.form<Access>({
                            id: [id],
                            access_right: [access_right],
                        }),
                    );
                });
            });
        }
    }
}

function isFormArray(
    control: AbstractControl,
    path: string,
): control is FormArray {
    if (control instanceof FormArray) return true;

    const type = control.constructor.name;

    throw new Error(`"${path}" control must be a FormArray, not a ${type}`);
}
