import {
    AfterContentInit,
    Component,
    EventEmitter,
    Input,
    Output,
} from '@angular/core';
import { SlInput } from '@shoelace-style/shoelace';
import { InputComponent } from '../../ui-kit-components/input/input.component';

@Component({
    selector: 'plmt-has-time',
    templateUrl: './has-time.component.html',
    styleUrls: ['./has-time.component.less'],
})
export class HasTimeComponent implements AfterContentInit {
    @Input()
    inputs!: SlInput[] | InputComponent[];

    @Output()
    change = new EventEmitter<any>();

    get hasTime(): boolean {
        return this._hasTime;
    }

    set hasTime(hasTime: boolean) {
        this._hasTime = hasTime;

        const type: SlInput['type'] = hasTime ? 'datetime-local' : 'date';

        const value = this.inputs.map((input) => {
            let value = '';

            if (input.value) {
                if (hasTime) {
                    if (!input.value.includes('T')) {
                        value = input.value + 'T00:00';
                    }
                } else {
                    const indexOf = input.value.indexOf('T');
                    const sliceIndex = indexOf === -1 ? undefined : indexOf;

                    value = input.value.slice(0, sliceIndex);
                }
            }

            input.type = type;

            if (value === '') value = input.value;
            else input.value = value;

            return value;
        });

        this.change.emit(value.length === 1 ? value[0] : value);
    }

    private _hasTime = false;

    ngAfterContentInit(): void {
        const items = this.inputs.map((input) => input.value);

        this.hasTime = items.some((item) => item?.includes('T'));
    }
}
