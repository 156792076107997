import {
    AfterViewInit,
    Component,
    ElementRef,
    EventEmitter,
    OnInit,
    ViewChild,
} from '@angular/core';
import { SlInput } from '@shoelace-style/shoelace';
import { not } from 'logical-not';
import { ToParent } from 'ng-to-parent';

import { Format_Server } from '../../../interfaces/column.format';
import { FormControlService } from '../../../services/form-control.service';
import { valueOf } from '../../../tools/value-of';

type Value = Format_Server['null_format'];

@Component({
    selector: 'plmt-null-formatting',
    templateUrl: './null-formatting.component.html',
    styleUrls: ['./null-formatting.component.less'],
    providers: [FormControlService, ToParent],
})
export class NullFormattingComponent implements OnInit, AfterViewInit {
    value = new EventEmitter<Value>();

    checked = false;
    nullFormat = '';

    @ViewChild('nullInput', { static: true })
    private nullInput?: ElementRef<SlInput>;

    constructor(private formControlService: FormControlService) {}

    ngOnInit(): void {
        this.formControlService.provide('null_format', () => ({
            createValueStream: () => this.value,

            setValue: (value: Value): void => {
                this.checked = !!value;

                this.nullFormat = value;

                this.updateNullInput();
            },
        }));
    }

    ngAfterViewInit(): void {
        this.updateNullInput();
    }

    onCheck(): void {
        this.checked = not(this.checked);

        if (this.checked) this.value.next(this.nullFormat);
        else this.value.next('');
    }

    onInput(event: Event): void {
        const value = valueOf(event);
        this.nullFormat = value;

        this.value.next(value);
    }

    private updateNullInput(): void {
        if (this.nullInput)
            this.nullInput.nativeElement.value = this.nullFormat;
    }
}
