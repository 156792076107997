import { Component, Input } from '@angular/core';

import { getHost } from '../../../../../tools/get-host';

@Component({
    selector: 'lib-spinner',
    templateUrl: './spinner.component.html',
    styleUrls: ['./spinner.component.less'],
})
export class SpinnerComponent {
    @Input()
    show!: boolean;

    readonly host = getHost();
}
