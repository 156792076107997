import { Observable, observable, of } from 'rxjs';
import { map, tap } from 'rxjs/operators';
import {
    DEFAULT_FORMATTER,
    FormatService,
    Formatter,
} from '../../../services/format.service';
import { DataResponse } from '../../../api/data-api.service';
import {
    TreeDataRow,
    ROOT_VALUE,
    TREE_DELIMITER,
    TreeData,
    TreeNode,
    TreeFilterData,
} from '../interfaces/tree-view.interface';
import { FilterDataOptionKey } from '../../../constants/filter-data-option-key';
import { isResponseError } from '../../../tools/is-response-error';
import { ColumnWithKey } from '../../../interfaces/dataset';
import { isEqual } from 'lodash';
import {
    DATA_KEY_PATH,
    DATA_VIEW_PATH,
} from '../../../constants/filter-default-value-keys';

export type ParentInfo = Pick<TreeDataRow, 'parentId' | 'id'>;

export function convertToFormattedData(
    formatService: FormatService,
): (observable: Observable<DataResponse>) => Observable<TreeData> {
    return (observable) => {
        return observable.pipe(
            map((response) => {
                const data = response[FilterDataOptionKey];

                if (isResponseError(data)) {
                    return getDefaultResponse();
                }

                const formatter = buildFormatter(data?.columns, formatService);

                return {
                    rows: convertToTreeData(data.columns!, data.rows),
                    formatter,
                };
            }),
        );
    };
}

export function injectTreeNodeAdditionalInfo(
    hasItems: boolean,
    parent?: TreeNode,
): (observable: Observable<TreeData>) => Observable<TreeDataRow[]> {
    return (observable) => {
        return observable.pipe(
            map(({ rows, formatter }) =>
                rows.map((row, index) => {
                    let parentInfo: ParentInfo = {
                        id: index.toString(),
                        parentId: ROOT_VALUE,
                    };

                    if (parent) {
                        parentInfo = getRowInfoIfHasParent(index, parent);
                    }

                    return {
                        id: parentInfo.id,
                        view: formatter(row.view),
                        originalView: row.view,
                        parentId: parentInfo.parentId,
                        key: row.key,
                        hasItems,
                    } as TreeDataRow;
                }),
            ),
        );
    };
}

export function mapFilteredDataToTreeDataSource(
    filteredData: DataResponse[],
    formatService: FormatService,
): TreeDataRow[] {
    const mergedFilterData: TreeFilterData[] = [];
    filteredData.forEach((response) => {
        const data = response[FilterDataOptionKey];
        if (!isResponseError(data)) {
            data.rows.forEach((row) => {
                Object.keys(row).forEach((key, index) => {
                    const isKey =
                        data.columns?.find((column) => column.key === key)
                            ?.block_key !== DATA_VIEW_PATH;

                    if (isKey) {
                        delete row[key];
                        return;
                    }

                    const formatter = buildFormatter(
                        [data.columns![index]],
                        formatService,
                    );

                    const viewColumnId = data.columns?.find(
                        (column) => column.key === key,
                    )?.column.id;

                    const keyValueKey = data.columns?.find(
                        (column) =>
                            column.block_key === viewColumnId?.toString(),
                    )?.key;

                    row[key] = {
                        view: formatter(row[key]),
                        originalView: row[key],
                        key: keyValueKey ? row[keyValueKey] : undefined,
                    };
                });
            });
            merge(mergedFilterData, data.rows);
        }
    });

    const rows: TreeDataRow[] = [];

    mergedFilterData.forEach((filterData) => {
        const keys = Object.keys(filterData).map(Number);
        const values = Object.values(filterData).map((data) => data.view);
        keys.forEach((key, index) => {
            const id = values.slice(0, index + 1).join(TREE_DELIMITER);
            const parentId =
                index === 0
                    ? ROOT_VALUE
                    : values.slice(0, index).join(TREE_DELIMITER);

            const row = {
                id,
                parentId,
                view: filterData[key].view,
                originalView: filterData[key].originalView,
                key: filterData[key].key,
            } as TreeDataRow;

            if (!rows.some((r) => isEqual(r, row))) {
                rows.push(row);
            }
        });
    });

    return rows;
}

export function buildFormatter(
    columns: ColumnWithKey[] = [],
    formatService: FormatService,
): Formatter {
    const column = columns?.find(
        (column) => column.block_key === DATA_VIEW_PATH,
    );
    if (!column?.column) {
        return DEFAULT_FORMATTER;
    }
    return formatService.createFormatter(column.column);
}

export function convertToTreeData(
    columns: ColumnWithKey[],
    rows: Record<ColumnWithKey['key'], any>[],
): TreeDataRow[] {
    const viewColumn = columns.find(
        (column) => column.block_key === DATA_VIEW_PATH,
    )!;
    // Колонка view есть всегда
    const viewPath = viewColumn.key;

    replaceKeyToAliasInPlace(rows, viewPath, DATA_VIEW_PATH);

    const keyColumn = columns.find(
        (column) => column.block_key === DATA_KEY_PATH,
    );

    // Колонка key_id в настройках дерева опциональное поле
    // поэтому колонки с DATA_KEY_PATH может и не быть
    const keyPath = keyColumn?.key;
    if (keyPath) {
        replaceKeyToAliasInPlace(rows, keyPath, DATA_KEY_PATH);
    }

    return rows as TreeDataRow[];
}

function getDefaultResponse(): TreeData {
    return { rows: [], formatter: DEFAULT_FORMATTER } as unknown as TreeData;
}

function replaceKeyToAliasInPlace(
    rows: Record<ColumnWithKey['key'], any>[],
    source: ColumnWithKey['key'],
    target: keyof TreeDataRow,
): void {
    rows.forEach((row) => {
        if (row[source]) {
            row[target] = row[source];
        }
        delete row[source];
    });
}

function getRowInfoIfHasParent(index: number, parent: TreeNode): ParentInfo {
    return {
        parentId: parent.data.id,
        id: [parent.data.id, index].join(TREE_DELIMITER),
    };
}

function merge(toArray: TreeFilterData[], fromArray: TreeFilterData[]): void {
    fromArray.forEach((fromElement) => {
        const keys = Object.keys(fromElement).map(Number);

        for (let i = 0; i < keys.length; i++) {
            const hasValue = toArray.filter(
                (toElement) =>
                    toElement[keys[i]].originalView ===
                    fromElement[keys[i]].originalView,
            ).length;

            if (!hasValue) {
                toArray.push(fromElement);
                break;
            }
        }
    });
}
