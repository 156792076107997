export function download(filename: string, data: Blob): void {
    const a = document.createElement('a');

    a.href = URL.createObjectURL(data);
    a.download = filename;

    document.body.append(a);

    a.click();
    a.remove();
}
