import { HttpErrorResponse } from '@angular/common/http';
import {
    Component,
    ElementRef,
    EventEmitter,
    Input,
    OnInit,
    Output,
} from '@angular/core';
import { AbstractControl, FormArray, FormGroup } from '@angular/forms';
import { SlButton } from '@shoelace-style/shoelace';
import { FromChild } from 'ng-to-parent';
import { SubscribableComponent } from 'ngx-subscribable';

import { maybeExtraControl, sendOptionsKey, showErrorsFor } from '../internal';
import { FormService } from '../form.service';
import { provideControlByName } from '../provide-control';
import { ButtonComponent } from '../../../components/ui-kit-components/button/button.component';

@Component({
    selector: 'core-form',
    templateUrl: './form.component.html',
    styleUrls: ['./form.component.less'],
    providers: [FromChild],
})
export class FormComponent extends SubscribableComponent implements OnInit {
    @Input('data')
    form!: AbstractControl;

    @Output()
    submit = new EventEmitter<any>();

    private submitButton?: SlButton | ButtonComponent;
    private extraControls: AbstractControl[] = [];

    constructor(
        private formService: FormService,
        private fromChild: FromChild,
        private hostRef: ElementRef<HTMLElement>,
    ) {
        super();
    }

    ngOnInit(): void {
        const host = this.hostRef.nativeElement;

        this.submitButton = Array.from(
            host.querySelectorAll<SlButton>(
                'sl-button[type=submit], core-button[submit]',
            ),
        ).find((item) => item.closest('core-form') === host);

        this.subscriptions = [
            this.fromChild
                .listen(provideControlByName)
                .subscribe(({ path, provider }) => {
                    const control = this.form.get(path);

                    if (control) provider(control);
                }),

            this.fromChild
                .listen(maybeExtraControl)
                .subscribe(({ form, control, onTrue }) => {
                    if (form !== this.form) {
                        this.extraControls.push(control);

                        onTrue(() => {
                            const i = this.extraControls.indexOf(control);

                            if (i !== -1) this.extraControls.splice(i, 1);
                        });
                    }
                }),
        ];
    }

    onSubmit(event: Event): void {
        event.preventDefault();
        event.stopPropagation();

        let valid = true;

        [this.form].concat(this.extraControls).forEach((form) => {
            if (form.invalid) {
                valid = false;

                showErrorsFor.emit({ form, currentControl: false });
            }
        });

        if (valid) this.send();
    }

    private send(): void {
        if (sendOptionsKey in this.form) this.sendHelper();

        this.submit.emit(this.form.value);
    }

    private sendHelper(): void {
        if (this.submitButton) this.submitButton.loading = true;

        const options: FormOptions = (this.form as any)[sendOptionsKey];

        const { method, methodContext, methodArguments, success, error } =
            options;

        const args = [this.form.value, ...(methodArguments?.() || [])] as any;

        method.apply(methodContext, args).subscribe({
            next: (response) => {
                if (this.submitButton) this.submitButton.loading = false;

                success(response);
            },
            error: (response: HttpErrorResponse) => {
                if (this.submitButton) this.submitButton.loading = false;

                if (response.status === 400)
                    this.formService.showServerErrors(
                        this.form,
                        response.error,
                    );
                else error?.(response);
            },
        });
    }
}

type FormOptions = Parameters<ReturnType<FormService['create']>['setup']>[0];
