import { Inject, Injectable } from '@angular/core';
import { not } from 'logical-not';

import { Environment, ENVIRONMENT } from '../constants/environment';
import { Page } from '../interfaces/page';
import { showNotification } from './license.internal';

@Injectable({
    providedIn: 'root',
})
export class LicenseService {
    private hasComponent = false;

    constructor(@Inject(ENVIRONMENT) private environment: Environment) {}

    verify(page: Page): boolean {
        if (not(this.notificationProvided())) return false;
        if (not(page.license.license_error)) return true;

        showNotification.emit();

        return false;
    }

    private notificationProvided(): boolean {
        if (this.hasComponent) return true;

        this.hasComponent = Boolean(
            document.querySelector('core-license-notification'),
        );

        if (this.hasComponent) return true;
        else if (this.environment !== Environment.Production)
            console.error(
                `В корне проекта должен быть <core-license-notification>`,
            );

        return false;
    }
}
