import { Directive, Input, NgModule, OnInit } from '@angular/core';
import { not } from 'logical-not';
import { SubscribableDirective } from 'ngx-subscribable';

import { getHost } from '../../../tools/get-host';
import { resizeObserver } from '../../../tools/resize-observer';

@Directive({ selector: '[syncWidth]' })
export class SyncWidthDirective
    extends SubscribableDirective
    implements OnInit
{
    @Input()
    syncWidth!: HTMLElement;

    @Input()
    syncWidthEnable = true;

    private readonly host = getHost();

    ngOnInit(): void {
        if (not(this.syncWidthEnable)) return;

        const { host } = this;

        this.subscriptions = [
            resizeObserver(this.syncWidth).subscribe(({ width }) => {
                host.style.width = `${width}px`;
            }),
        ];
    }
}

@NgModule({
    exports: [SyncWidthDirective],
    declarations: [SyncWidthDirective],
})
export class SyncWidthDirectiveModule {}
