<ng-container *ngIf="column.calculate">
    <ng-container *ngIf="column.calculate.error; else calcColumnSuccesIcon">
        <sl-tooltip
            hoist
            [disabled]="!showErrorTooltip"
            [content]="column.calculate.error"
        >
            <sl-icon name="exclamation-triangle"></sl-icon>
        </sl-tooltip>
    </ng-container>

    <ng-template #calcColumnSuccesIcon>
        <sl-icon
            *ngIf="!column.calculate.save_result"
            library="platform"
            name="func"
        ></sl-icon>
        <sl-icon
            *ngIf="column.calculate.save_result"
            name="calculator"
        ></sl-icon>
    </ng-template>
</ng-container>
