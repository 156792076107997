import { CommonModule } from '@angular/common';
import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { ShoelaceModule } from 'shoelace-style-angular';

import { TooltipifyModule } from '../../modules/tooltipify/tooltipify.module';
import { CalculateColumnIconModule } from '../calculate-column-icon/calculate-column-icon.module';
import { ColumnTypeIconModule } from '../column-type-icon/column-type-icon.module';
import { ColumnNameComponent } from './column-name.component';

export * from './column-name.component';

@NgModule({
    imports: [
        CalculateColumnIconModule,
        CommonModule,
        ColumnTypeIconModule,
        ShoelaceModule,
        TooltipifyModule,
    ],
    exports: [ColumnNameComponent],
    declarations: [ColumnNameComponent],
    schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class ColumnNameModule {}
