import { Component, ElementRef, EventEmitter, ViewChild } from '@angular/core';
import { SlDialog } from '@shoelace-style/shoelace';
import { first, Observable } from 'rxjs';

import { ModalCloseConfirmService } from './modal-close-confirm.service';

@Component({
    selector: 'core-modal-close-confirm',
    templateUrl: './modal-close-confirm.component.html',
    styleUrls: ['./modal-close-confirm.component.less'],
})
export class ModalCloseConfirmComponent {
    readonly close = new EventEmitter<boolean>();

    @ViewChild('dialog', { static: true })
    private dialogRef!: ElementRef<SlDialog>;

    constructor(modalCloseConfirmService: ModalCloseConfirmService) {
        modalCloseConfirmService.setModalCloseConfirmComponent(this);
    }

    confirm(): Observable<boolean> {
        this.dialogRef.nativeElement.show();

        return this.close.pipe(first());
    }
}
