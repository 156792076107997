import {
    AfterViewInit,
    Component,
    EventEmitter,
    HostListener,
    Output,
    ViewChild,
} from '@angular/core';

import { TableCheckboxComponent } from '../table-checkbox/table-checkbox.component';

@Component({
    selector: 'core-table-checkbox-controller',
    templateUrl: './table-checkbox-controller.component.html',
    styleUrls: ['./table-checkbox-controller.component.less'],
})
export class TableCheckboxControllerComponent implements AfterViewInit {
    @Output()
    change = new EventEmitter<any>();

    @Output()
    ready = new EventEmitter<void>();

    @ViewChild('checkbox')
    checkbox!: TableCheckboxComponent;

    @HostListener('click', ['$event'])
    stopPropagation(event: Event): void {
        event.stopPropagation();
    }

    ngAfterViewInit(): void {
        this.ready.emit();
    }
}
