import { Injectable } from '@angular/core';
import not from 'logical-not';

import { StringFormatSettings } from '../formatter-settings/string.formatter-settings';
import { Formatter } from './formatter';

@Injectable({ providedIn: 'root' })
export class StringFormatter implements Formatter<StringFormatSettings> {
    format(formatSettings: StringFormatSettings, source: any): string {
        const { arounds, nullText } = formatSettings;

        if (source === null) {
            if (not(nullText)) return '';
            else return nullText;
        }

        if (typeof source !== 'string') return String(source);

        return arounds.format(source);
    }
}
