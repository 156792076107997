import { resize_observer_styles_default } from "./chunk.5VKIB4HA.js";
import { watch } from "./chunk.2FB5TK5H.js";
import { component_styles_default } from "./chunk.TUVJKY7S.js";
import { ShoelaceElement } from "./chunk.SFSTXCXC.js";
import { __decorateClass } from "./chunk.IFDWM6P4.js";

// src/components/resize-observer/resize-observer.component.ts
import { html } from "lit";
import { property } from "lit/decorators.js";
var SlResizeObserver = class extends ShoelaceElement {
  constructor() {
    super(...arguments);
    this.observedElements = [];
    this.disabled = false;
  }
  connectedCallback() {
    super.connectedCallback();
    this.resizeObserver = new ResizeObserver(entries => {
      this.emit("sl-resize", {
        detail: {
          entries
        }
      });
    });
    if (!this.disabled) {
      this.startObserver();
    }
  }
  disconnectedCallback() {
    super.disconnectedCallback();
    this.stopObserver();
  }
  handleSlotChange() {
    if (!this.disabled) {
      this.startObserver();
    }
  }
  startObserver() {
    const slot = this.shadowRoot.querySelector("slot");
    if (slot !== null) {
      const elements = slot.assignedElements({
        flatten: true
      });
      this.observedElements.forEach(el => this.resizeObserver.unobserve(el));
      this.observedElements = [];
      elements.forEach(el => {
        this.resizeObserver.observe(el);
        this.observedElements.push(el);
      });
    }
  }
  stopObserver() {
    this.resizeObserver.disconnect();
  }
  handleDisabledChange() {
    if (this.disabled) {
      this.stopObserver();
    } else {
      this.startObserver();
    }
  }
  render() {
    return html` <slot @slotchange=${this.handleSlotChange}></slot> `;
  }
};
SlResizeObserver.styles = [component_styles_default, resize_observer_styles_default];
__decorateClass([property({
  type: Boolean,
  reflect: true
})], SlResizeObserver.prototype, "disabled", 2);
__decorateClass([watch("disabled", {
  waitUntilFirstUpdate: true
})], SlResizeObserver.prototype, "handleDisabledChange", 1);
export { SlResizeObserver };