import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
    selector: 'core-tag',
    templateUrl: './tag.component.html',
    styleUrls: ['./tag.component.less'],
})
export class TagComponent {
    @Input()
    value = '';

    @Input()
    removable?: '';

    @Output()
    remove = new EventEmitter<void>();

    removeTag(event: Event): void {
        event.stopPropagation();

        this.remove.emit();
    }
}
