import { Injectable } from '@angular/core';
import { not } from 'logical-not';
import { forkJoin, from, map, Observable, of, tap } from 'rxjs';

import { Lang } from '../../enums/lang';
import { TranslatePreparationService } from './translate-preparation.service';
import { TranslationsSettings } from './translate.interfaces';
import { Translations } from './translate.internal';

class Store {
    private store: {
        [key: string]: Map<TranslationsSettings, Observable<Translations>>;
    } = Object.create(null);

    for(lang: Lang): Map<TranslationsSettings, Observable<Translations>> {
        if (not(lang in this.store)) {
            this.store[lang] = new Map<
                TranslationsSettings,
                Observable<Translations>
            >();
        }

        return this.store[lang];
    }
}

@Injectable({ providedIn: 'root' })
export class TranslateCacheService {
    private readonly cache = new Store();
    private readonly pending = new Store();

    constructor(
        private translatePreparationService: TranslatePreparationService,
    ) {}

    get(settings: TranslationsSettings, lang: Lang): Observable<Translations> {
        const cache = this.cache.for(lang);

        if (cache.has(settings)) return cache.get(settings)!;

        if (not(this.pending.for(lang).has(settings))) {
            if (settings.sources.length === 0) return of({});

            const requests: Observable<any>[] = settings.sources.map(
                (baseUrl) =>
                    from(
                        fetch(`${baseUrl}${lang}.json`).then((response) =>
                            response.json(),
                        ),
                    ),
            );

            this.pending.for(lang).set(
                settings,
                forkJoin(requests).pipe(
                    map((sourceItems) =>
                        this.translatePreparationService.create(
                            settings,
                            sourceItems,
                        ),
                    ),
                    tap((translations) => {
                        this.cache.for(lang).set(settings, of(translations));
                    }),
                ),
            );
        }

        return this.pending.for(lang).get(settings)!;
    }
}
