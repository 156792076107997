import { Component, Input } from '@angular/core';

import { TestId } from '../../directives/test-id.directive';

export type ActionComponentType = 'delete' | 'setup' | 'update' | 'copy';

@Component({
    selector: 'core-action',
    templateUrl: './action.component.html',
    styleUrls: ['./action.component.less'],
})
export class ActionComponent {
    @Input()
    disabled = false;

    @Input()
    type!: ActionComponentType;

    get icon(): string {
        return `actions/${this.type}`;
    }

    get testId(): keyof typeof TestId {
        switch (this.type) {
            case 'setup':
                return 'Edit';
            case 'copy':
                return 'Copy';
            default:
                return 'Delete';
        }
    }
}
