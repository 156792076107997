<sl-dialog
    [label]="'dashboard#leaveProjectConfirmTitle' | translate"
    [(open)]="isOpen"
>
    {{ 'dashboard#leaveProjectConfirmtext' | translate }}
    <div slot="footer" class="footer">
        <sl-button
            variant="primary"
            test-id="Exit"
            outline
            (click)="close.emit(false)"
        >
            {{ 'dashboard#leaveProjectConfirmButton' | translate }}
        </sl-button>
        <sl-button
            variant="primary"
            test-id="Save"
            (click)="close.emit(true)"
        >
            {{ '$.save' | translate }}
        </sl-button>
    </div>
</sl-dialog>
