export enum FilterLogical {
    And = 'AND',
    Or = 'OR',
}

export enum FilterMethod {
    Equal = 'EQ', // Равно
    NotEqual = 'NEQ', // Не равно
    GreaterThen = 'GT', // Больше
    LessThen = 'LT', // Меньше
    GreaterThenOrEqual = 'GTE', // Больше или равно
    LessThenOrEqual = 'LTE', // Меньше или равно
    InList = 'IN', // Принадлежит множеству
    NotInList = 'NIN', // Не принадлежит множеству
    Like = 'LIKE', // Содержит (с учетом регистра)
    Like$CaseIgnore = 'LIKE_I', // Содержит (без учета регистра)
    Like$Not = 'NLIKE', // Не содержит (с учетом регистра)
    Like$Not$CaseIgnore = 'NLIKE_I', // Не содержит (без учета регистра)
    StartsLike = 'START_LIKE', // Начинается на (с учетом регистра)
    StartsLike$CaseIgnore = 'START_LIKE_I', // Начинается на (без учета регистра)
    StartsLike$Not = 'NSTART_LIKE', // Не начинается на (с учетом регистра)
    StartsLike$Not$CaseIgnore = 'NSTART_LIKE_I', // Не начинается на (без учета регистра)
    EndsLike = 'END_LIKE', // Заканчивается на (с учетом регистра)
    EndsLike$CaseIgnore = 'END_LIKE_I', // Заканчивается на (без учета регистра)
    EndsLike$Not = 'NEND_LIKE', // Не заканчивается на (с учетом регистра)
    EndsLike$Not$CaseIgnore = 'NEND_LIKE_I', // Не заканчивается (без учета регистра)
    DateRange = 'DATE_RANGE', // Входит в период
    DateRange$Not = 'NDATE_RANGE', // Не входит в период
    Range = 'RANGE', //Входит в диапазон
    Range$Not = 'NRANGE', //Не входит в диапазон
}

export enum FilterState {
    CommonType,
    ExtendedSettings,
}

export enum SettingsBlock {
    Dataset = 1,
    Custom,
}
