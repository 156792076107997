import {
    AfterViewInit,
    ChangeDetectorRef,
    Component,
    EventEmitter,
    Input,
    OnChanges,
    Output,
    QueryList,
    SimpleChanges,
    ViewChildren,
} from '@angular/core';
import { isEqual, isNull } from 'lodash';
import { not } from 'logical-not';
import { WatchChanges } from 'ng-onpush';
import { ToParent } from 'ng-to-parent';
import { SubscribableComponent } from 'ngx-subscribable';
import { of, Subscription, tap } from 'rxjs';

import { FormControlService } from '../../services/form-control.service';
import { DefaultValueAggregation } from '../../enums/radio-min-max-state.enum';
import { ColumnType } from '../../enums/dataset';
import { FilterMethod } from '../../enums/filter';
import { FilterTemplateType } from '../../enums/filter-template-type';
import { FilterUIType } from '../../enums/types';
import { CompareFn, GetLabel } from '../../interfaces/items-operations';
import { DataSearchProvider } from '../../interfaces/rest-api';
import { FiltersViewService } from '../../services/filters-view.service';
import { skipAfter } from '../../tools/skip-after';
import { valueOf } from '../../tools/value-of';
import { SuggestComponent } from '../suggest/suggest.component';
import {
    checkIfHasDefaultValue,
    convertNullValuesToMarker,
    isKeyViewValue,
    NULL_VALUE,
} from './null.helper';
import { FilterTemplateUIType } from '../../enums/filter-ui-type';
import {
    DATA_KEY_PATH,
    DATA_VIEW_PATH,
} from '../../constants/filter-default-value-keys';
import { TreeSelectConfig } from '../tree-view/tree-view.module';
import { Tupple } from '../local-filters/tools/types';

export interface FilterValueState {
    value: any;
    hasValue: boolean;
}

enum FilterVariant {
    Local,
    Global,
}

@Component({
    selector: 'core-filter-value',
    templateUrl: './filter-value.component.html',
    styleUrls: ['./filter-value.component.less'],
    providers: [FormControlService, ToParent],
})
export class FilterValueComponent
    extends SubscribableComponent
    implements AfterViewInit, OnChanges
{
    @Input()
    set state(state: FilterValueState) {
        this.valueState = state;

        this.updateValue();
    }

    @Input() // для локальных фильтров
    set filterMethod(filterMethod: FilterMethod) {
        const map = this.filtersViewService.filterUIType[this.columnType!];

        this.uiType = map?.[filterMethod!] ?? null;

        this.filterVariant = FilterVariant.Local;

        this.updateValue();
    }

    @Input() // для глобальных фильтров
    set filterType(filterTemplateType: FilterTemplateType) {
        this.uiType =
            filterTemplateType === FilterTemplateType.Select
                ? FilterUIType.SelectSuggest
                : FilterUIType.SelectMulti;

        this.filterVariant = FilterVariant.Global;
    }

    @Input()
    dataProvider: DataSearchProvider<any> = () => of({ rows: [], total: 0 });

    @Input()
    getLabelFn: GetLabel<any> = (item) =>
        String(isKeyViewValue(item) ? item[DATA_VIEW_PATH] : item);

    @Input()
    compareFn: CompareFn<any> = (a, b) => {
        if (isKeyViewValue(a) && isKeyViewValue(b)) {
            return isEqual(a[DATA_VIEW_PATH], b[DATA_VIEW_PATH]);
        }

        return isEqual(a, b);
    };

    @Input()
    @WatchChanges()
    showMinMax?: boolean = false;

    @Input()
    aggFn = DefaultValueAggregation.Exact;

    @Input()
    subType?: FilterTemplateUIType;

    @Input()
    treeSelectConfig?: TreeSelectConfig;

    @Input()
    datasetId?: number;

    @Output()
    change = new EventEmitter<FilterValueState>();

    @Output()
    radioMinMaxChange = new EventEmitter<DefaultValueAggregation>();

    @WatchChanges()
    columnType?: ColumnType;

    @WatchChanges()
    uiType: FilterUIType | null = null;

    @ViewChildren('suggest')
    suggest!: QueryList<SuggestComponent>;

    value: any = null;

    readonly FilterUIType = FilterUIType;
    readonly FilterTemplateUIType = FilterTemplateUIType;

    readonly nullMappingProvider: DataSearchProvider<any> = (search, offset) =>
        this.dataProvider(search, offset).pipe(
            tap(({ rows }) => {
                rows.forEach((value, i) => {
                    rows[i] = convertNullValuesToMarker(value);
                });
            }),
        );

    private valueState?: FilterValueState;
    private filterVariant = FilterVariant.Global;

    get shouldDisable(): boolean {
        return (
            Boolean(this.showMinMax) &&
            this.aggFn !== DefaultValueAggregation.Exact
        );
    }

    constructor(
        private filtersViewService: FiltersViewService,
        private changeDetectorRef: ChangeDetectorRef,
    ) {
        super();
    }

    ngAfterViewInit(): void {
        this.suggest.forEach((component) => this.watchSuggest(component));

        if (this.showMinMax) this.setValue(this.valueState?.value);

        this.subscriptions.push(
            this.suggest.changes.subscribe(() =>
                this.suggest.forEach((component) =>
                    this.watchSuggest(component),
                ),
            ),
        );
    }

    ngOnChanges(changes: SimpleChanges): void {
        if ('defaultValue' in changes) {
            this.setValue(changes['defaultValue'].currentValue);
        }

        if ('datasetId' in changes) {
            this.updateTreeSelectConfig();
        }

        if ('treeSelectConfig' in changes) {
            this.updateTreeSelectConfig();
        }
    }

    toArray(value: any): any[] {
        if (not(value)) return [];

        return Array.isArray(value) ? value : [value];
    }

    emitChange(value: any): void {
        this.change.emit({ value, hasValue: checkIfHasDefaultValue(value) });
    }

    emitNumberRange(value: Tupple | null): void {
        if (isNull(value)) {
            this.reset();
        } else {
            this.emitChange(
                value.map((value) =>
                    isNaN(Number(value)) ? value : parseFloat(value),
                ),
            );
        }
    }

    emitFromEvent(event: Event): void {
        this.emitChange(valueOf(event));
    }

    emitChangeNumber(event: Event): void {
        const value = parseFloat(valueOf(event));

        if (Number.isNaN(value)) this.reset();
        else this.emitChange(value);
    }

    emitChangeDateRange(event: Event, i: number): void {
        const item = valueOf(event);
        const value = Array.isArray(this.value)
            ? this.value.slice(0, 2)
            : ['', ''];

        value[0] = i === 0 ? item : value[0];
        value[1] = i === 1 ? item : value[1];

        if (value.every(not)) this.reset();
        else this.emitChange(value);
    }

    onRadioMinMaxChange(state: DefaultValueAggregation): void {
        this.radioMinMaxChange.emit(state);
    }

    onTreeSelectValueChange(value: any[]): void {
        this.emitChange(value);
    }

    private reset(): void {
        this.change.emit({ value: null, hasValue: false });
    }

    private updateValue(): void {
        switch (this.uiType) {
            case FilterUIType.SelectSuggest:
                this.value = getSuggestionValue(
                    this.filterVariant,
                    this.valueState,
                );
                break;
            case FilterUIType.SelectMulti:
                this.value = getMultiSelectValue(
                    this.filterVariant,
                    this.valueState,
                );
                break;
            default:
                this.value = this.valueState?.value ?? null;
        }
    }

    private setValue(value: any): void {
        this.value = value;

        this.changeDetectorRef.detectChanges();
    }

    private updateTreeSelectConfig(): void {
        if (this.treeSelectConfig && this.datasetId) {
            this.treeSelectConfig.dataset_id = this.datasetId;
        }
    }

    private watchSuggest(component: SuggestComponent): void {
        const { onSelect, onClear } = component;

        getComponentSubscriptions(component).push(
            onSelect
                .pipe(
                    skipAfter(onClear.pipe(tap(() => this.reset()))),

                    tap((value) => this.emitChange(value)),
                )
                .subscribe(),
        );
    }
}

function getSuggestionValue(
    filterVariant: FilterVariant = FilterVariant.Global,
    state?: FilterValueState,
): any {
    if (not(state)) return null;

    const { value } = state;

    return setValue(value, state, filterVariant);
}

function getMultiSelectValue(
    filterVariant: FilterVariant = FilterVariant.Global,
    state?: FilterValueState,
): any {
    if (not(state)) return null;

    const { value } = state;

    if (!Array.isArray(value)) return null;

    return value.map((item: any) => {
        return setValue(item, state, filterVariant);
    });
}

function setValue(
    value: any,
    state: FilterValueState,
    filterVariant: FilterVariant = FilterVariant.Global,
): any {
    if (filterVariant === FilterVariant.Local) {
        if (checkIfHasDefaultValue(value)) return value;

        return state.hasValue ? NULL_VALUE : null;
    }

    let view;

    if (checkIfHasDefaultValue(value)) {
        view = isKeyViewValue(value) ? value[DATA_VIEW_PATH] : value;
    } else {
        view = state.hasValue ? NULL_VALUE : null;
    }

    const keyViewValue = {
        view,
        key: isKeyViewValue(value) ? value[DATA_KEY_PATH] : undefined,
    };

    return isNull(view) ? null : keyViewValue;
}

function getComponentSubscriptions(
    component: SubscribableComponent,
): Subscription[] {
    return (component as any as { subscriptions: Subscription[] })
        .subscriptions;
}
