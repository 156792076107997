import { Injectable } from '@angular/core';
import { not } from 'logical-not';
import { enumToArray } from 'ts-enum-to-array';

import { ColumnType } from '../enums/dataset';
import { FilterMethod } from '../enums/filter';
import { FilterUIType } from '../enums/types';

export type Cache<T, Keys extends keyof T> = {
    -readonly [Key in Keys]?: T[Key];
};

const cache: Cache<
    FiltersViewService,
    'filterUIType' | 'filterUIsByColumnType' | 'filterMethods'
> = {};

@Injectable({ providedIn: 'root' })
export class FiltersViewService {
    readonly filterUIType: Record<
        ColumnType,
        Record<FilterMethod, FilterUIType | null>
    >;
    readonly filterUIsByColumnType: Record<ColumnType, FilterUIType[]>;
    readonly filterMethods: Record<
        ColumnType,
        Partial<Record<FilterUIType, FilterMethod[]>>
    >;

    constructor() {
        if (not(cache.filterUIType)) {
            cache.filterUIType = {} as any;
            cache.filterUIsByColumnType = {} as any;
            cache.filterMethods = {} as any;

            const filterMethods = enumToArray(FilterMethod);

            enumToArray(ColumnType).forEach((columnType) =>
                filterMethods.forEach((filterMethod) => {
                    const filterUIType = this.getFilterUIType(
                        columnType,
                        filterMethod,
                    );

                    setFilterUIType(columnType, filterMethod, filterUIType);
                    setFilterUIsByColumnType(columnType, filterUIType);
                    setFilterMethods(columnType, filterUIType, filterMethod);
                }),
            );
        }

        this.filterUIType = cache.filterUIType!;
        this.filterUIsByColumnType = cache.filterUIsByColumnType!;
        this.filterMethods = cache.filterMethods!;
    }

    getFilterUIType(
        columnType: ColumnType,
        filterMethod: FilterMethod,
    ): FilterUIType | null {
        switch (columnType) {
            case ColumnType.String:
                switch (filterMethod) {
                    case FilterMethod.Equal:
                    case FilterMethod.NotEqual:
                        return FilterUIType.SelectSuggest;
                    case FilterMethod.InList:
                    case FilterMethod.NotInList:
                        return FilterUIType.SelectMulti;
                    case FilterMethod.Like:
                    case FilterMethod.Like$CaseIgnore:
                    case FilterMethod.Like$Not:
                    case FilterMethod.Like$Not$CaseIgnore:
                    case FilterMethod.StartsLike:
                    case FilterMethod.StartsLike$CaseIgnore:
                    case FilterMethod.StartsLike$Not:
                    case FilterMethod.StartsLike$Not$CaseIgnore:
                    case FilterMethod.EndsLike:
                    case FilterMethod.EndsLike$CaseIgnore:
                    case FilterMethod.EndsLike$Not:
                    case FilterMethod.EndsLike$Not$CaseIgnore:
                        return FilterUIType.Search;
                    default:
                        return null;
                }
            case ColumnType.Number:
                switch (filterMethod) {
                    case FilterMethod.Equal:
                    case FilterMethod.NotEqual:
                        return FilterUIType.SelectSuggest;
                    case FilterMethod.InList:
                    case FilterMethod.NotInList:
                        return FilterUIType.SelectMulti;
                    case FilterMethod.Range:
                    case FilterMethod.Range$Not:
                        return FilterUIType.NumberRange;
                    default:
                        return FilterUIType.Number;
                }
            case ColumnType.Date:
                switch (filterMethod) {
                    case FilterMethod.Equal:
                    case FilterMethod.NotEqual:
                    case FilterMethod.LessThen:
                    case FilterMethod.GreaterThen:
                    case FilterMethod.GreaterThenOrEqual:
                    case FilterMethod.LessThenOrEqual:
                        return FilterUIType.Date;
                    case FilterMethod.InList:
                    case FilterMethod.NotInList:
                        return FilterUIType.SelectMulti;
                    case FilterMethod.DateRange:
                    case FilterMethod.DateRange$Not:
                        return FilterUIType.DateRange;
                    default:
                        return null;
                }
            case ColumnType.Boolean:
                switch (filterMethod) {
                    case FilterMethod.Equal:
                    case FilterMethod.NotEqual:
                        return FilterUIType.SelectSuggest;
                    case FilterMethod.InList:
                    case FilterMethod.NotInList:
                        return FilterUIType.SelectMulti;
                    default:
                        return null;
                }
            default:
                return null;
        }
    }
}

function setFilterUIType(
    columnType: ColumnType,
    filterMethod: FilterMethod,
    filterUIType: FilterUIType | null,
): void {
    const map = cache.filterUIType!;

    if (not(map[columnType])) map[columnType] = {} as any;

    map[columnType][filterMethod] = filterUIType;
}

function setFilterUIsByColumnType(
    columnType: ColumnType,
    filterUIType: FilterUIType | null,
): void {
    const filterUIsByColumnType = cache.filterUIsByColumnType!;

    if (not(filterUIsByColumnType[columnType]))
        filterUIsByColumnType[columnType] = [];

    if (not(filterUIType)) return;

    if (not(filterUIsByColumnType[columnType].includes(filterUIType)))
        filterUIsByColumnType[columnType].push(filterUIType);
}

function setFilterMethods(
    columnType: ColumnType,
    filterUIType: FilterUIType | null,
    filterMethod: FilterMethod,
): void {
    const map = cache.filterMethods!;

    if (not(map[columnType])) map[columnType] = {} as any;

    if (not(filterUIType)) return;

    if (not(map[columnType][filterUIType])) map[columnType][filterUIType] = [];

    const filterMethods = map[columnType][filterUIType]!;

    if (not(filterMethods.includes(filterMethod)))
        filterMethods.push(filterMethod);
}
