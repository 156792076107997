import { ModuleName } from '../enums/module';

export interface RouteData {
    accessRights?: AccessRightsRouteData;
}

export interface AccessRightsRouteData {
    rights: AccessRightsRouteDataRights;
    noAccessRedirect?: string;
}

type AccessRightsRouteDataRights = Partial<Record<ModuleName, number[]>>;

export function routeDate(data: RouteData): RouteData {
    return data;
}
