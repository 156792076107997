import { Component, Input } from '@angular/core';

import { OverflowParams } from './overflow.directive';

@Component({
    selector: 'core-overflow',
    templateUrl: './overflow.component.html',
    styleUrls: ['./overflow.component.less'],
})
export class OverflowComponent {
    @Input()
    params: OverflowParams = {};
}
