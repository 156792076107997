import { isSingleExpression as i } from "./directive-helpers.js";
import { Directive as t, PartType as e } from "./directive.js";
export { directive } from "./directive.js";
/**
 * @license
 * Copyright 2017 Google LLC
 * SPDX-License-Identifier: BSD-3-Clause
 */
const s = (i, t) => {
    const e = i._$AN;
    if (void 0 === e) return !1;
    for (const i of e) i._$AO?.(t, !1), s(i, t);
    return !0;
  },
  o = i => {
    let t, e;
    do {
      if (void 0 === (t = i._$AM)) break;
      e = t._$AN, e.delete(i), i = t;
    } while (0 === e?.size);
  },
  r = i => {
    for (let t; t = i._$AM; i = t) {
      let e = t._$AN;
      if (void 0 === e) t._$AN = e = new Set();else if (e.has(i)) break;
      e.add(i), c(t);
    }
  };
function h(i) {
  void 0 !== this._$AN ? (o(this), this._$AM = i, r(this)) : this._$AM = i;
}
function n(i, t = !1, e = 0) {
  const r = this._$AH,
    h = this._$AN;
  if (void 0 !== h && 0 !== h.size) if (t) {
    if (Array.isArray(r)) for (let i = e; i < r.length; i++) s(r[i], !1), o(r[i]);else null != r && (s(r, !1), o(r));
  } else s(this, i);
}
const c = i => {
  i.type == e.CHILD && (i._$AP ??= n, i._$AQ ??= h);
};
class f extends t {
  constructor() {
    super(...arguments), this._$AN = void 0;
  }
  _$AT(i, t, e) {
    super._$AT(i, t, e), r(this), this.isConnected = i._$AU;
  }
  _$AO(i, t = !0) {
    i !== this.isConnected && (this.isConnected = i, i ? this.reconnected?.() : this.disconnected?.()), t && (s(this, i), o(this));
  }
  setValue(t) {
    if (i(this._$Ct)) this._$Ct._$AI(t, this);else {
      const i = [...this._$Ct._$AH];
      i[this._$Ci] = t, this._$Ct._$AI(i, this, 0);
    }
  }
  disconnected() {}
  reconnected() {}
}
export { f as AsyncDirective, t as Directive, e as PartType };
