import { Inject, Injectable } from '@angular/core';
import { isNull, isUndefined } from 'lodash';
import { Observable, map, of } from 'rxjs';
import { convertToValidDate } from '../components/filter-template/tools/convert-to-valid-date';
import { setDateFormat } from '../components/filter-template/tools/filter-date-offset';
import { isKeyViewValue } from '../components/filter-value/null.helper';
import { DATA_VIEW_PATH } from '../constants/filter-default-value-keys';
import { FilterTemplateType } from '../enums/filter-template-type';
import { DefaultValueAggregation } from '../enums/radio-min-max-state.enum';

import { DataOption } from '../interfaces/data-option';
import { Dataset } from '../interfaces/dataset';
import {
    FilterTemplate,
    FilterTemplateApi,
    ListViewFilterApi,
} from '../interfaces/filter-template';
import {
    RequestArray,
    ResponseArray,
    ResponseItem,
} from '../interfaces/rest-api';
import { CoreApi } from '../modules/rest/api-injectors';
import { RestService } from '../modules/rest/rest.service';
import { createFilterDataOption } from '../tools/create-filter-data-option';
import { DataApiService, DataResponse } from './data-api.service';

@Injectable({ providedIn: 'root' })
export class FilterTemplateApiService {
    constructor(
        @Inject(CoreApi) private restService: RestService,
        private dataApiService: DataApiService,
    ) {}

    get(id: number): Observable<ResponseItem<FilterTemplateApi>> {
        return this.restService.get(`/filter/${id}`);
    }

    list(params?: RequestArray): Observable<ResponseArray<ListViewFilterApi>> {
        return this.restService.get('/filter', params);
    }

    saveExisted(data: FilterTemplate): Observable<void> {
        return this.restService.put(`/filter/${data.id}`, data);
    }

    saveNew(data: FilterTemplate): Observable<ResponseItem<FilterTemplate>> {
        return this.restService.post('/filter', data);
    }

    getDataset(filterId: number, datasetId: number): Observable<Dataset> {
        return this.restService.get(
            `/filter/${filterId}/datasets/${datasetId}`,
        );
    }

    getFilterDefaultValue(filter: FilterTemplate): Observable<any> {
        const defaultValue = isKeyViewValue(filter.default_value)
            ? filter.default_value[DATA_VIEW_PATH]
            : filter.default_value;

        const hasAggFn =
            defaultValue?.agg_fn === DefaultValueAggregation.Exact ||
            isNull(defaultValue?.agg_fn) ||
            isUndefined(defaultValue?.agg_fn);

        if (hasAggFn) {
            if (filter.type === FilterTemplateType.Date) {
                const dateFormat = setDateFormat(filter.sub_type);

                return of(convertToValidDate(defaultValue, dateFormat));
            }

            return of(filter.default_value);
        }

        const dataOption = createFilterDataOption(
            filter,
            0,
            defaultValue.agg_fn,
        );
        let dataOptions: DataOption[] = [];

        if (dataOption) {
            dataOptions = [
                {
                    ...dataOption,
                },
            ];
        }

        return this.dataApiService
            .getData({
                data_options: dataOptions,
                get_columns: false,
                get_dataset: false,
                limit: 1,
            })
            .pipe(
                map((result: DataResponse) => {
                    if ('httpCode' in result.data) return of();

                    const valueAsObject = result.data.rows[0];

                    return Object.values(valueAsObject)[0];
                }),
            );
    }
}
