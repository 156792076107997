import { CommonModule } from '@angular/common';
import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { TrackByIdModule } from 'ng-track-by';
import { ImageCropperModule } from 'ngx-image-cropper';
import { ShoelaceModule } from 'shoelace-style-angular';

import { TestIdDirectiveModule } from '../../directives/test-id.directive';
import { FormModule } from '../../modules/form/form.module';
import { TooltipifyModule } from '../../modules/tooltipify/tooltipify.module';
import {
    TranslateModule,
    TranslationsKeyInterpolationMap,
} from '../../modules/translate/translate.module';
import { AvatarModule } from '../avatar/avatar.module';
import { FileModule } from '../file/file.module';
import { LogoComponent } from './logo/logo.component';
import { NavigationItemComponent } from './navigation-item/navigation-item.component';
import { NavigationMenuComponent } from './navigation-menu/navigation-menu.component';
import { NavigationComponent } from './navigation.component';
import { EventType } from './notification/enums/event-type';
import { ExportedEntity } from './notification/enums/exported-entity';
import { NotificationModule } from './notification/notification.module';
import { ChangePasswordComponent } from './profile/change-password/change-password.component';
import { CropModalComponent } from './profile/crop-modal/crop-modal.component';
import { ProfileComponent } from './profile/profile.component';
import { TokenComponent } from './profile/token/token.component';
import { TooltipComponent } from './profile/tooltip/tooltip.component';

export * from './navigation.component';
export * from './navigation-item/navigation-item.component';
export * from './navigation-menu/navigation-menu.component';
export * from './navigation.service';

export const navigationKeyInterpolation: TranslationsKeyInterpolationMap = {
    EventType,
    ExportedEntity,
};

@NgModule({
    imports: [
        AvatarModule,
        CommonModule,
        FileModule,
        FormModule,
        ImageCropperModule,
        NotificationModule,
        RouterModule,
        ShoelaceModule,
        TrackByIdModule,
        TranslateModule,
        TooltipifyModule,
        TestIdDirectiveModule,
    ],
    exports: [
        NavigationComponent,
        NavigationItemComponent,
        NavigationMenuComponent,
        LogoComponent,
        ProfileComponent,
    ],
    declarations: [
        ChangePasswordComponent,
        CropModalComponent,
        LogoComponent,
        NavigationComponent,
        NavigationItemComponent,
        NavigationMenuComponent,
        ProfileComponent,
        TooltipComponent,
        TokenComponent,
    ],
    schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class NavigationModule {}
