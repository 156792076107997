<sl-button variant="text" (click)="filterEdit.open()">
    <ng-container *ngIf="filterCounter === 0">
        {{ '_$$.filters.createFilter' | translate }}
    </ng-container>
    <ng-container *ngIf="filterCounter > 0">
        {{ '_$$.filters.editFilter' | translate }}
    </ng-container>
</sl-button>

<core-filter-modal #filterEdit></core-filter-modal>
