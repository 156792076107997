import { CommonModule } from '@angular/common';
import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';

import { TranslateModule } from '../../modules/translate/translate.module';
import { NotifyModule } from '../notify/notify.module';
import { AlertComponent } from './alert.component';

export * from './alert.component';
export * from './alert.service';

@NgModule({
    imports: [CommonModule, NotifyModule, TranslateModule],
    exports: [AlertComponent],
    declarations: [AlertComponent],
    schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class AlertModule {}
