export enum DataOptionMethod {
    Table = 'table',
    Aggregate = 'aggregate',
    PivotTable = 'pivot',
}

export enum DataOptionAggregateFunction {
    Group = 'GROUP',
    Sum = 'SUM',
    Min = 'MIN',
    Max = 'MAX',
    Average = 'AVG',
    Median = 'MEDIAN',
    Count = 'COUNT',
    CountDistinct = 'COUNT_DISTINCT',
}

export enum PivotBlockKey {
    Columns = 'columns',
    Rows = 'rows',
    Values = 'values',
}
