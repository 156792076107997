<sl-dropdown
    #dropdown
    outsideClick
    [hoist]="hoist === ''"
    [placement]="placement"
    [distance]="distance"
    [skidding]="skidding"
    [containingElement]="containingElement"
    (sl-show)="slShowEvent.emit()"
    (sl-hide)="slHideEvent.emit()"
    (sl-after-show)="slAfterShowEvent.emit()"
    (sl-after-hide)="slAfterHideEvent.emit()"
    (clickOutside)="hide()"
>
    <ng-content></ng-content>
</sl-dropdown>
