<ng-container [ngSwitch]="uiType">
    <core-select
        *ngSwitchCase="FilterUIType.SelectSuggest"
        [searchProvider]="provider"
        [mapper]="mapper"
        [selected]="toSelectItem()"
        (changeValue)="emitChange($event)"
    ></core-select>

    <core-multi-select-next
        *ngSwitchCase="FilterUIType.SelectMulti"
        tagsInOneLine
        [searchProvider]="provider"
        [mapper]="mapper"
        [selected]="toMultiSelectItem()"
        (changeValue)="emitChange($event)"
    ></core-multi-select-next>

    <core-input
        *ngSwitchCase="FilterUIType.Search"
        [value]="value"
        (changeValue)="emitChange($event)"
    ></core-input>

    <core-input
        *ngSwitchCase="FilterUIType.Number"
        type="number"
        [value]="value"
        (changeValue)="emitFromNumberInput($event)"
    ></core-input>

    <div *ngSwitchCase="FilterUIType.Date" class="date">
        <core-input
            #input
            [value]="value"
            (changeValue)="emitChange($event)"
        ></core-input>

        <app-has-time-filter
            [inputs]="[input]"
            (change)="emitChange($event)"
        ></app-has-time-filter>
    </div>

    <app-range-filter
        *ngSwitchCase="FilterUIType.DateRange"
        [value]="value"
        (changeData)="emitChange($event)"
    ></app-range-filter>

    <app-range-filter
        *ngSwitchCase="FilterUIType.NumberRange"
        type="number"
        [value]="value"
        (changeData)="emitNumberRange($event)"
    ></app-range-filter>
</ng-container>
