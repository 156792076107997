import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ShoelaceModule } from 'shoelace-style-angular';

import { RelativeTimeComponent } from './relative-time.component';
import { DatePipeModule } from '../../pipes/date.pipe';

export * from './relative-time.component';

@NgModule({
    imports: [CommonModule, DatePipeModule, ShoelaceModule],
    exports: [RelativeTimeComponent],
    declarations: [RelativeTimeComponent],
    schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class RelativeTimeModule {}
