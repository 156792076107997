import { NgModule, Pipe, PipeTransform } from '@angular/core';
import { Column } from '../interfaces/dataset';
import not from 'logical-not';

@Pipe({
    name: 'isColumnResultUnsaved',
})
export class IsColumnResultUnsavedPipe implements PipeTransform {
    transform(column: Column | Partial<Column> | undefined): boolean {
        if (!column) return true;

        if (!column.calculate) return false;

        return (
            not(column.calculate.save_result) || Boolean(column.calculate.error)
        );
    }
}

@NgModule({
    exports: [IsColumnResultUnsavedPipe],
    declarations: [IsColumnResultUnsavedPipe],
})
export class IsColumnResultUnsavedPipeModule {}
