import { AbstractControl } from '@angular/forms';
import { isNull } from 'lodash';
import { Subscription } from 'rxjs';

import { getDate, getTime, setDateFormat } from './filter-date-offset';
import { validate } from '../../../tools/validate';
import { FilterTemplateType } from '../../../enums/filter-template-type';

export function filterDateValidator(
    defaultValue: AbstractControl,
    type: AbstractControl,
    subType: AbstractControl,
): Subscription {
    return validate({
        control: defaultValue,
        depends: [type, subType],
        error() {
            const value = defaultValue.value;
            const dateFormat = setDateFormat(subType.value);

            if (!value || typeof value === 'string') return null;
            if (type.value !== FilterTemplateType.Date) return null;

            let dateTo: number = 0;
            let dateFrom: number = 0;

            if (Array.isArray(value)) {
                if (isNull(value[0]) || isNull(value[1])) return null;

                dateTo =
                    typeof value[0] === 'string'
                        ? getTime(value[0])
                        : getTime(getDate(value[0], dateFormat));
                dateFrom =
                    typeof value[1] === 'string'
                        ? getTime(value[1])
                        : getTime(getDate(value[1], dateFormat));
            }

            return dateTo > dateFrom
                ? {
                      '_$$.filters.validation.dateRange': null,
                  }
                : null;
        },
    });
}
