import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { ModalLeaveEditorConfirmComponent } from './modal-leave-editor-confirm.component';
import { ShoelaceModule } from 'shoelace-style-angular';
import { TestIdDirectiveModule } from '../../directives/test-id.directive';
import { TranslateModule } from '../../modules/translate/translate.module';

@NgModule({
    imports: [ShoelaceModule, TestIdDirectiveModule, TranslateModule],
    exports: [ModalLeaveEditorConfirmComponent],
    declarations: [ModalLeaveEditorConfirmComponent],
    schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class ModalLeaveEditorConfirmModule {}
