import { html as t, svg as r } from "./lit-html.js";
/**
 * @license
 * Copyright 2020 Google LLC
 * SPDX-License-Identifier: BSD-3-Clause
 */
const e = Symbol.for(""),
  o = t => {
    if (t?.r === e) return t?._$litStatic$;
  },
  i = t => ({
    _$litStatic$: t,
    r: e
  }),
  s = (t, ...r) => ({
    _$litStatic$: r.reduce((r, e, o) => r + (t => {
      if (void 0 !== t._$litStatic$) return t._$litStatic$;
      throw Error(`Value passed to 'literal' function must be a 'literal' result: ${t}. Use 'unsafeStatic' to pass non-literal values, but\n            take care to ensure page security.`);
    })(e) + t[o + 1], t[0]),
    r: e
  }),
  a = new Map(),
  l = t => (r, ...e) => {
    const i = e.length;
    let s, l;
    const n = [],
      u = [];
    let c,
      $ = 0,
      f = !1;
    for (; $ < i;) {
      for (c = r[$]; $ < i && void 0 !== (l = e[$], s = o(l));) c += s + r[++$], f = !0;
      $ !== i && u.push(l), n.push(c), $++;
    }
    if ($ === i && n.push(r[i]), f) {
      const t = n.join("$$lit$$");
      void 0 === (r = a.get(t)) && (n.raw = n, a.set(t, r = n)), e = u;
    }
    return t(r, ...e);
  },
  n = l(t),
  u = l(r);
export { n as html, s as literal, u as svg, i as unsafeStatic, l as withStatic };
