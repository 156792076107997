import { FilterLogical, FilterMethod } from '../../../enums/filter';

export enum FilterType {
    Rule,
    Group,
}

export enum BorderType {
    First = 'first',
    Middle = 'middle',
    Last = 'last',
    NextGroup = 'next-group',
}

export enum FilterControlNames {
    Action = 'action',
    Value = 'value',
    Child = 'child',
    ColumnId = 'column_id',
    Logical = 'logical',
    Uid = 'uid',
}

export enum DropAction {
    Before = 'before',
    After = 'after',
    Inside = 'inside',
}

export type Tupple = [string, string];

export type ValidateReturnEntity = { [x: string]: any } | void;

export type RangeType = 'date' | 'number';

export interface RangeForm {
    from: string;
    to: string;
}

export interface DataOptionFilterMapped {
    logical?: FilterLogical;
    uid: string;
    column_id?: number;
    action?: FilterMethod;
    value?: any;
    child?: DataOptionFilterMapped[];
}

export interface DropInfo {
    trackedContainerUid: string;
    action?: DropAction;
}
