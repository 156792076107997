import {
    AfterViewInit,
    Component,
    ElementRef,
    Input,
    OnInit,
    ViewChild,
} from '@angular/core';
import { AbstractControl } from '@angular/forms';
import { SlInput } from '@shoelace-style/shoelace';
import { SubscribableComponent } from 'ngx-subscribable';
import { fromEvent, merge, tap } from 'rxjs';

import { valueOf } from '../../tools/value-of';

enum Type {
    From = 'from',
    To = 'to',
    Interval = 'interval',
}

@Component({
    selector: 'core-interval',
    templateUrl: './interval.component.html',
    styleUrls: ['./interval.component.less'],
})
export class IntervalComponent
    extends SubscribableComponent
    implements OnInit, AfterViewInit
{
    @Input()
    label = '';

    @Input()
    from!: AbstractControl;

    @Input()
    to!: AbstractControl;

    @Input()
    min = 0;

    @Input()
    max = 100;

    readonly Type = Type;

    type!: Type;

    @ViewChild('track', { static: true })
    private track!: ElementRef<HTMLElement>;

    @ViewChild('fromThumb', { static: true })
    private fromThumb!: ElementRef<HTMLElement>;

    @ViewChild('toThumb', { static: true })
    private toThumb!: ElementRef<HTMLElement>;

    @ViewChild('fromInput', { static: true })
    private fromInput!: ElementRef<SlInput>;

    @ViewChild('toInput', { static: true })
    private toInput!: ElementRef<SlInput>;

    private percentages = {
        from: 0,
        to: 0,
    };

    private dragging: {
        target: AbstractControl | null;
        min: number;
        max: number;
        delta: number;
        zeroPosition: number;
        trackWidth: number;
    } = {
        target: null,
        min: 0,
        max: 0,
        delta: 0,
        zeroPosition: 0,
        trackWidth: 0,
    };

    get hasFrom(): boolean {
        return typeof this.from.value === 'number';
    }

    get hasTo(): boolean {
        return typeof this.to.value === 'number';
    }

    ngOnInit(): void {
        const { hasFrom, hasTo } = this;

        if (hasFrom && hasTo) this.type = Type.Interval;
        else if (hasTo) this.type = Type.To;
        else this.type = Type.From;

        this.subscriptions = [
            this.from.valueChanges.subscribe((value) => {
                this.updatePercentages();
                this.updateThumb(this.fromThumb);
                this.updateBackground();

                this.fromInput.nativeElement.value = value;
            }),
            this.to.valueChanges.subscribe((value) => {
                this.updatePercentages();
                this.updateThumb(this.toThumb);
                this.updateBackground();

                this.toInput.nativeElement.value = value;
            }),
        ];
    }

    ngAfterViewInit(): void {
        this.updatePercentages();
        this.updateBackground();

        this.updateThumb(this.fromThumb);
        this.updateThumb(this.toThumb);

        this.subscriptions.push(
            merge(
                fromEvent<MouseEvent>(
                    this.fromThumb.nativeElement,
                    'mousedown',
                ).pipe(
                    tap(() => {
                        this.dragging.target = this.from;
                        this.dragging.min = this.min;
                        this.dragging.max =
                            this.to.value === null ? this.max : this.to.value;

                        this.fromThumb.nativeElement.style.zIndex = '2';
                        this.toThumb.nativeElement.style.zIndex = '1';
                    }),
                ),
                fromEvent<MouseEvent>(
                    this.toThumb.nativeElement,
                    'mousedown',
                ).pipe(
                    tap(() => {
                        this.dragging.target = this.to;
                        this.dragging.min =
                            this.from.value === null
                                ? this.min
                                : this.from.value;
                        this.dragging.max = this.max;

                        this.fromThumb.nativeElement.style.zIndex = '1';
                        this.toThumb.nativeElement.style.zIndex = '2';
                    }),
                ),
            ).subscribe(() => {
                this.dragging.delta = this.max - this.min;

                const { width, left } =
                    this.track.nativeElement.getBoundingClientRect();

                this.dragging.zeroPosition = left;
                this.dragging.trackWidth = width;
            }),
            fromEvent<MouseEvent>(document, 'mousemove').subscribe((event) => {
                if (this.dragging.target) {
                    const { x } = event;
                    const {
                        target,
                        min,
                        max,
                        delta,
                        zeroPosition,
                        trackWidth,
                    } = this.dragging;

                    if (x < zeroPosition && target.value === min) return;
                    if (x > zeroPosition + trackWidth && target.value === max)
                        return;

                    const rate = (x - zeroPosition) / trackWidth;

                    let value = Math.round(delta * rate + this.min);

                    value = Math.max(value, min);
                    value = Math.min(value, max);

                    if (value !== target.value) target.patchValue(value);
                }
            }),
            fromEvent<MouseEvent>(document, 'mouseup').subscribe(() => {
                this.dragging.target = null;
            }),
        );
    }

    changeValue(target: AbstractControl, event: Event): void {
        target.patchValue(+valueOf(event) || 0);
    }

    changeType(type: Type): void {
        switch (type) {
            case Type.From:
                if (this.from.value !== null) {
                    this.from.patchValue(null);
                }
                if (this.to.value === null) {
                    this.to.patchValue(this.max);
                }

                this.updateThumb(this.toThumb);
                break;
            case Type.To:
                if (this.from.value === null) {
                    this.from.patchValue(this.min);
                }
                if (this.to.value !== null) {
                    this.to.patchValue(null);
                }

                this.updateThumb(this.fromThumb);
                break;
            case Type.Interval:
                if (this.from.value === null) {
                    this.from.patchValue(this.min);
                }
                if (this.to.value === null) {
                    this.to.patchValue(this.max);
                }

                this.updateThumb(this.fromThumb);
                this.updateThumb(this.toThumb);
                break;
        }

        this.type = type;

        this.updateBackground();
    }

    toggleFromToType(): void {
        if (this.type === Type.Interval) this.changeType(Type.From);
        else this.changeType(Type.Interval);
    }

    private updatePercentages(): void {
        const { min, max } = this;

        const delta = max - min;

        const absolute = {
            from: this.from.value - min,
            to: this.to.value - min,
        };

        this.percentages = {
            from: (absolute.from / delta) * 100,
            to: (absolute.to / delta) * 100,
        };
    }

    private updateThumb(thumb: ElementRef<HTMLElement>): void {
        const offset =
            thumb === this.fromThumb
                ? this.percentages.from
                : this.percentages.to;

        thumb.nativeElement.style.left = `${offset}%`;
    }

    private updateBackground(): void {
        const { from, to } = this.percentages;

        switch (this.type) {
            case Type.From:
                this.track.nativeElement.style.background = `
                    linear-gradient(
                        to right,
                        var(--sl-color-primary-400) 0%,
                        var(--sl-color-primary-400) ${to}%,
                        var(--sl-color-neutral-200) ${to}%,
                        var(--sl-color-neutral-200) 100%
                    )
                `;
                break;
            case Type.To:
                this.track.nativeElement.style.background = `
                    linear-gradient(
                        to right,
                        var(--sl-color-neutral-200) 0%,
                        var(--sl-color-neutral-200) ${from}%,
                        var(--sl-color-primary-400) ${from}%,
                        var(--sl-color-primary-400) 100%
                    )
                `;
                break;
            case Type.Interval:
                this.track.nativeElement.style.background = `
                    linear-gradient(
                        to right,
                        var(--sl-color-neutral-200) 0%,
                        var(--sl-color-neutral-200) ${from}%,
                        var(--sl-color-primary-400) ${from}%,
                        var(--sl-color-primary-400) ${to}%,
                        var(--sl-color-neutral-200) ${to}%,
                        var(--sl-color-neutral-200) 100%
                    )
                `;
                break;
        }
    }
}
