import { enumToArray } from 'ts-enum-to-array';

import { FilterLabelPosition } from '../enums/filter-label-position';
import { SelectOption } from '../interfaces/select-option';

export const positions: SelectOption<FilterLabelPosition>[] = enumToArray(
    FilterLabelPosition,
).map((value) => ({
    label: `$$.filterTemplate.position.${value}`,
    value,
}));
