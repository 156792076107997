import {
    FormatDateUnitType,
    FormatDateUnitValue,
} from '../../../enums/column.format';
import { FormatDateUnit } from '../../../interfaces/column.format';

const { Year, Month, Day, Hour, Minute, Second, Millisecond } =
    FormatDateUnitType;

export const defaultUnits: FormatDateUnit[] = [];

const units: DateUnit[] = [
    {
        type: Year,
        suffix: '-',
    },
    {
        type: Month,
        suffix: '-',
    },
    {
        type: Day,
        suffix: ' ',
    },
    {
        type: Hour,
        suffix: '-',
    },
    {
        type: Minute,
        suffix: '-',
    },
    {
        type: Second,
    },
    {
        type: Millisecond,
    },
];

units.forEach(({ type, suffix }, i) => {
    const value =
        type === Millisecond ? FormatDateUnitValue.S : FormatDateUnitValue.M;

    defaultUnits.push({
        type,
        value,
        suffix: suffix || '',
        disabled: i === units.length - 1,
    });
});

interface DateUnit {
    type: FormatDateUnit['type'];
    suffix?: FormatDateUnit['suffix'];
}
