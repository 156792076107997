export enum FormatNumberAbbreviation {
    Kilo = 3,
    Mega = 6,
    Giga = 9,
}

export enum FormatDateUnitType {
    Year = 'year',
    Month = 'month',
    Day = 'day',
    Hour = 'hour',
    Minute = 'minute',
    Second = 'second',
    Millisecond = 'millisecond',
}
export enum FormatDateUnitValue {
    XL = 'xl',
    L = 'l',
    M = 'm',
    S = 's',
}
