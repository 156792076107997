import { HttpErrorResponse } from '@angular/common/http';
import {
    Component,
    HostBinding,
    Input,
    OnChanges,
    OnInit,
    SimpleChanges,
    TemplateRef,
} from '@angular/core';
import { SlDialog, SlInput } from '@shoelace-style/shoelace';
import { not } from 'logical-not';
import { WatchChanges } from 'ng-onpush';
import { SubscribableComponent } from 'ngx-subscribable';
import { fromEvent, Observable } from 'rxjs';

import { AccessRight } from '../../../enums/access';
import { Access } from '../../../interfaces/access';
import { RequestArray, ResponseArray } from '../../../interfaces/rest-api';
import { Debounce } from '../../../tools/debounce';

export interface Entity {
    id: number;
}

export type EntitiesProvider = (
    params: RequestArray,
) => Observable<ResponseArray<{ id: number }>>;

export type SelectedEntitiesProvider = () => SelectedEntities;

export type SelectedEntities = Map<
    Entity['id'],
    {
        entity: Entity;
        access: Access;
    }
>;

@Component({
    selector: 'core-entities',
    templateUrl: './entities.component.html',
    styleUrls: ['./entities.component.less'],
})
export class EntitiesComponent
    extends SubscribableComponent
    implements OnInit, OnChanges
{
    @Input()
    @HostBinding('class.show')
    show = false;

    @Input()
    selectedEntitiesProvider!: SelectedEntitiesProvider;

    @Input()
    entitiesProvider!: EntitiesProvider;

    @Input()
    entityTemplate!: TemplateRef<Entity | { $implicit: Entity }>;

    @Input()
    searchInput!: SlInput;

    @Input()
    dialog!: SlDialog;

    @Input()
    limit = 24;

    @WatchChanges()
    selected!: SelectedEntities;

    @WatchChanges()
    entities: Entity[] = [];
    @WatchChanges()
    total = 0;
    @WatchChanges()
    isEmpty = false;
    @WatchChanges()
    loading = false;
    @WatchChanges()
    httpError?: HttpErrorResponse;

    @WatchChanges()
    page = 1;

    @WatchChanges()
    params: RequestArray = {};

    @WatchChanges()
    private inited = false;

    ngOnInit(): void {
        this.subscriptions = [
            fromEvent<CustomEvent>(this.dialog, 'sl-show').subscribe(
                (event) => {
                    if (event.target === this.dialog) {
                        this.selected = this.selectedEntitiesProvider();
                        this.inited = false;

                        if (this.show) this.init();
                    }
                },
            ),

            fromEvent(this.searchInput, 'sl-input').subscribe(() => {
                if (this.show) this.onSearch(this.searchInput.value);
            }),
        ];
    }

    ngOnChanges(changes: SimpleChanges): void {
        if ('show' in changes) {
            if (this.show && this.dialog.open) this.init();
        }
    }

    onToggle(entity: Entity): void {
        const { id } = entity;

        if (not(this.selected.has(id)))
            this.selected.set(id, {
                entity,
                access: {
                    id,
                    access_right: AccessRight.Read,
                },
            });
        else this.selected.delete(id);
    }

    onAccessRightChange(id: Entity['id'], value: AccessRight): void {
        this.selected.get(id)!.access.access_right = value;
    }

    @Debounce(300)
    onSearch(search: string): void {
        this.params.search = search;
        this.params.offset = 0;

        this.page = 1;

        this.load();
    }

    onPageChange(page: number): void {
        this.page = page;
        this.params.offset = (page - 1) * this.params.limit!;

        this.load();
    }

    private init(): void {
        if (not(this.inited)) {
            this.inited = true;

            this.params = {
                search: '',
                limit: this.limit,
                offset: 0,
            };

            this.page = 1;
            this.searchInput.value = '';

            this.load();
        } else {
            this.searchInput.value = this.params.search || '';
        }
    }

    private load(): void {
        this.entities = [];
        this.loading = true;

        this.entitiesProvider(this.params).subscribe({
            next: ({ rows, total }) => {
                this.entities = rows;
                this.total = total;
                this.isEmpty = total === 0;
                this.loading = false;
                this.httpError = undefined;
            },
            error: (error) => {
                this.entities = [];
                this.total = 0;
                this.isEmpty = false;
                this.loading = false;
                this.httpError = error;
            },
        });
    }
}
