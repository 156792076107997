import { Inject, Injectable, InjectionToken, inject } from '@angular/core';
import {
    ActivatedRouteSnapshot,
    CanActivateFn,
    Router,
    RouterStateSnapshot,
    Routes,
    UrlTree,
} from '@angular/router';
import { not } from 'logical-not';
import { Observable, of, switchMap } from 'rxjs';

import { skiproleRedirectParameter } from '../constants/skip-role-redirect-parameter';
import { PageDataService } from '../services/page-data.service';
import { parseUrl } from './url';

const URLS = new InjectionToken<string[]>('URLS');
const dashboardUrlRegExp = /^[\/]?dashboard[\/]?$/;

const MainPageRedirectGuard: CanActivateFn = (_route, currentRoute) =>
    inject(MainPageRedirectService).canActivate(_route, currentRoute);

@Injectable()
class MainPageRedirectService {
    constructor(
        @Inject(URLS) private urls: string[],
        private router: Router,
        private pageDataService: PageDataService,
    ) {}

    canActivate(
        _route: ActivatedRouteSnapshot,
        currentRoute: RouterStateSnapshot,
    ): Observable<boolean | UrlTree> {
        return this.pageDataService.page.pipe(
            switchMap((page) => {
                if (this.skipRedirect()) return this.commonRedirect();

                if (
                    location.pathname !== '/' &&
                    location.pathname !== '/auth'
                ) {
                    return this.commonRedirect();
                }

                const source = page.redirect_link;

                const sourceCondition = source && this.matchUrl(source);

                if (sourceCondition && this.matchUrl(currentRoute.url)) {
                    return this.commonRedirect();
                }

                const url = source ? parseUrl(source) : null;

                if (not(url)) return this.commonRedirect();
                return of(this.router.parseUrl(url!.pathname));
            }),
        );
    }

    private matchUrl(link: string): boolean {
        return dashboardUrlRegExp.test(link);
    }

    private commonRedirect(): Observable<boolean | UrlTree> {
        return of(this.router.parseUrl(this.urls[0]));
    }

    private skipRedirect(): boolean {
        const parameters = new URLSearchParams(location.search);

        return parameters.has(skiproleRedirectParameter);
    }
}

export function mainRoutes(
    routes: Routes,
    modulePrefixPath: '/dashboard/' | '' = '',
): Routes {
    const urls: string[] = [];

    for (let { path } of routes) if (path) urls.push(modulePrefixPath + path);

    return [
        {
            path: '',
            pathMatch: 'full',
            canActivate: [MainPageRedirectGuard],
            children: [],
            providers: [
                MainPageRedirectService,
                {
                    provide: URLS,
                    useValue: urls,
                },
            ],
        },
        ...routes,
    ];
}
