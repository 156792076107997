import { AbstractControl } from '@angular/forms';

export function getFormControl(control: AbstractControl): AbstractControl {
    return getRoot(control);
}

function getRoot(control: AbstractControl): AbstractControl {
    const { parent } = control;

    return parent ? getRoot(parent) : control;
}
