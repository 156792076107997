import { isNull } from 'lodash';
import { getDate } from './filter-date-offset';
import { DateFormat } from '../../../enums/global-filter';

export function convertToValidDate(value: any, dateFormat: DateFormat): any {
    return Array.isArray(value)
        ? value.map((item) => setDate(item, dateFormat))
        : setDate(value, dateFormat);
}

function setDate(value: string | null, dateFormat: DateFormat): string | null {
    let date: string | null = null;

    date =
        typeof value === 'string' || isNull(value)
            ? value
            : getDate(value, dateFormat);

    return date;
}
