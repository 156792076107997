import { Inject, Injectable } from '@angular/core';
import { map, Observable } from 'rxjs';

import { Notification } from '../interfaces/notification';
import { NotificationsApi } from '../../../../modules/rest/api-injectors';
import { RestService } from '../../../../modules/rest/rest.service';
import { RequestArray, ResponseArray } from '../../../../interfaces/rest-api';

@Injectable({ providedIn: 'root' })
export class NotificationsApiService {
    constructor(@Inject(NotificationsApi) private restService: RestService) {}

    list(
        params?: NotificationsListRequest,
    ): Observable<NotificationsListResponse> {
        return this.restService.get('/', params);
    }

    touch(ids: number[] = []): Observable<number> {
        return this.restService.put('/mark_viewed', { ids });
    }

    getUntouchedCount(): Observable<number> {
        type Response = { count: number };

        return this.restService
            .get<Response>('/count_unread')
            .pipe(map(({ count }) => count));
    }

    clear(): Observable<void> {
        return this.restService.delete('/');
    }

    disableNotifications(value: boolean): Observable<void> {
        const endpoint = value ? '/ignore_info' : '/stop_ignore_info';

        return this.restService.put(endpoint);
    }

    isNotificationDisabled(): Observable<boolean> {
        type Response = { is_ignores: boolean };

        return this.restService
            .get<Response>('/is_ignores_info')
            .pipe(map(({ is_ignores }) => is_ignores));
    }

    delete(id: number): Observable<void> {
        return this.restService.delete(`/${id}`);
    }
}

export interface NotificationsListRequest
    extends Pick<RequestArray, 'limit' | 'offset'> {
    is_viewed?: boolean;
}

export interface NotificationsListResponse extends ResponseArray<Notification> {
    unviewed_total: number;
}
