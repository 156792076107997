import {
    Component,
    ElementRef,
    Input,
    OnDestroy,
    OnInit,
    ViewChild,
} from '@angular/core';
import { SlAlert } from '@shoelace-style/shoelace';

import { NotifyInternalService } from './notify.internal';

@Component({
    selector: 'core-notify',
    templateUrl: './notify.component.html',
    styleUrls: ['./notify.component.less'],
})
export class NotifyComponent implements OnInit, OnDestroy {
    @Input()
    variant: SlAlert['variant'] = 'success';

    @Input()
    name?: string;

    @ViewChild('alert', { static: true })
    alert!: ElementRef<SlAlert>;

    constructor(private notifyInternalService: NotifyInternalService) {}

    ngOnInit(): void {
        if (this.name) {
            this.notifyInternalService.append(this, this.name);
        }
    }

    ngOnDestroy(): void {
        if (this.name) {
            this.notifyInternalService.remove(this.name);
        }
    }

    toast(): void {
        const alert = this.alert.nativeElement.cloneNode(true) as SlAlert;

        alert.toast();
    }
}
