import { HttpErrorResponse } from '@angular/common/http';
import { Component, EventEmitter, Output } from '@angular/core';
import { CropperPosition, ImageCroppedEvent } from 'ngx-image-cropper';

import { StorageApiService } from '../../../../api/storage-api.service';
import { AlertService } from '../../../alert/alert.service';
import { User } from '../../../../interfaces/user';
import { LoadingStatus } from '../../../../components/file/file.component';
import { UserApiService } from '../../../../api/user-api.service';

const MB = 1 << 20; // 1024 * 1024

@Component({
    selector: 'core-crop-modal',
    templateUrl: './crop-modal.component.html',
    styleUrls: ['./crop-modal.component.less'],
})
export class CropModalComponent {
    @Output()
    image = new EventEmitter<string>();

    hasImage = false;
    isOpen = false;
    loading = true;

    imageFile?: File;
    croppedImage?: File;

    imageUrl = '';

    loadStatus = LoadingStatus.Default;

    user?: User;

    cropperPosition: CropperPosition = {
        x1: 0,
        x2: 0,
        y1: 0,
        y2: 0,
    };

    readonly LoadingStatus = LoadingStatus;

    get showDeleteButton(): boolean {
        return (
            Boolean(this.imageUrl || this.imageFile) &&
            this.loadStatus !== LoadingStatus.Reject &&
            !this.loading
        );
    }

    constructor(
        private alertService: AlertService,
        private storageApiService: StorageApiService,
        private userApiService: UserApiService,
    ) {}

    show(user: User): void {
        this.isOpen = true;

        this.user = user;
        this.loadStatus = LoadingStatus.Default;

        this.hasImage = Boolean(user.image_path);

        if (this.hasImage) {
            this.imageUrl = this.storageApiService.resolve(user.image_path);
        } else {
            this.loading = false;
            this.imageUrl = '';
        }
    }

    pickFile(file: File): void {
        if (file.size > MB) {
            this.loadStatus = LoadingStatus.Reject;
            this.alertService.show.emit({
                key: '_$.navigation.cropModal.error.fileSize',
            });
            return;
        }

        this.imageFile = file;
    }

    cropperReady(): void {
        this.hasImage = true;
        this.loadStatus = this.imageFile
            ? LoadingStatus.Success
            : LoadingStatus.Default;

        this.cropperPosition = {
            x1: 0,
            x2: 216,
            y1: 0,
            y2: 216,
        };
    }

    imageCropped(imageCroppedEvent: ImageCroppedEvent): void {
        this.loading = false;

        if (imageCroppedEvent.base64) {
            let blobFile = this.baseToBlob(
                imageCroppedEvent.base64,
                'image/png',
            );
            this.croppedImage = new File([blobFile], 'avatar');
        }
    }

    deletePhoto(): void {
        this.loadStatus = LoadingStatus.Default;
        this.imageUrl = '';
        this.imageFile = undefined;
        this.croppedImage = undefined;
        this.hasImage = false;
    }

    savePhoto(): void {
        if (this.croppedImage) {
            this.userApiService.uploadAvatar(this.croppedImage).subscribe({
                next: ({ file_path }) => {
                    this.image.emit(file_path);
                    this.isOpen = false;
                },
                error: (responseError: HttpErrorResponse) => {
                    this.alertService.show.emit({
                        responseError,
                        key: '_$.navigation.profile.avatar.saveDanger',
                    });
                },
            });
        } else {
            this.image.emit(this.imageUrl);
            this.isOpen = false;
        }
    }

    private baseToBlob(
        baseData: string,
        contentType: string,
        sliceSize = 512,
    ): Blob {
        const repData = baseData.replace(/data:image\/\w+;base64,/, '');
        const byteCharacters = atob(repData);
        const byteArrays = [];

        for (
            let offset = 0;
            offset < byteCharacters.length;
            offset += sliceSize
        ) {
            const slice = byteCharacters.slice(offset, offset + sliceSize);

            const byteNumbers = new Array(slice.length);
            for (let i = 0; i < slice.length; i++) {
                byteNumbers[i] = slice.charCodeAt(i);
            }

            const byteArray = new Uint8Array(byteNumbers);
            byteArrays.push(byteArray);
        }

        const blob = new Blob(byteArrays, { type: contentType });
        return blob;
    }
}
