import { CommonModule } from '@angular/common';
import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { ShoelaceModule } from 'shoelace-style-angular';

import { TranslateModule } from '../../modules/translate/translate.module';
import { IntervalComponent } from './interval.component';

export * from './interval.component';

@NgModule({
    imports: [CommonModule, ShoelaceModule, TranslateModule],
    exports: [IntervalComponent],
    declarations: [IntervalComponent],
    schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class IntervalModule {}
