import {
    AfterViewInit,
    Component,
    ElementRef,
    HostBinding,
} from '@angular/core';

@Component({
    selector: 'core-content',
    templateUrl: './content.component.html',
    styleUrls: ['./content.component.less'],
})
export class ContentComponent implements AfterViewInit {
    @HostBinding('style.min-height')
    hostMinHeight = '';
    spinnerMarginTop = '';

    constructor(private host: ElementRef<HTMLElement>) {}

    ngAfterViewInit(): void {
        const { y } = this.host.nativeElement.getBoundingClientRect();

        queueMicrotask(() => {
            this.hostMinHeight = `calc(100vh - ${y}px)`;
            this.spinnerMarginTop = `-${Math.floor(y / 2)}px`;
        });
    }
}
