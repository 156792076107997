// src/components/tab-panel/tab-panel.styles.ts
import { css } from "lit";
var tab_panel_styles_default = css`
  :host {
    --padding: 0;

    display: none;
  }

  :host([active]) {
    display: block;
  }

  .tab-panel {
    display: block;
    padding: var(--padding);
}    
        .tab-panel::-webkit-scrollbar {
            width: 4px;
            height: 4px;
            transition: width 0.2s;
            background: transparent;
        }
        
        .tab-panel::-webkit-scrollbar:hover {
            height: 6px;
            width: 6px;
        }
        
        .tab-panel::-webkit-scrollbar-thumb {
            border-radius: 2px;
            transition: background-color 0.2s;
            background-color: rgb(217, 221, 234);
        }
        
        .tab-panel::-webkit-scrollbar-thumb:hover {
            border-radius: 4px;
            background-color: rgb(0, 174, 213);
        }
    `;
export { tab_panel_styles_default };