import { Inject, Injectable } from '@angular/core';
import { Observable, catchError, map, tap, throwError } from 'rxjs';

import {
    DataOptionAggregateFunction,
    DataOptionMethod,
} from '../enums/data-option';
import { ColumnType } from '../enums/dataset';
import { FilterLogical, FilterMethod } from '../enums/filter';
import { SortDirection } from '../enums/sort';
import {
    DataOption,
    DataOptionDataBlock,
    DataOptionDataBlockColumn,
    DataOptionFilter,
} from '../interfaces/data-option';
import { ColumnWithKey, Dataset } from '../interfaces/dataset';
import {
    RequestArray,
    ResponseArray,
    ResponseError,
} from '../interfaces/rest-api';
import { CoreApi } from '../modules/rest/api-injectors';
import { RestService } from '../modules/rest/rest.service';
import { isResponseError } from '../tools/is-response-error';

@Injectable({ providedIn: 'root' })
export class DataApiService {
    constructor(@Inject(CoreApi) private restService: RestService) {}

    getData(params: DataRequest): Observable<DataResponse> {
        return this.restService.post('/data/dataset', params);
    }

    getAggregateFunctions(
        datasetId: number,
    ): Observable<DataOptionAggregateFunction[]> {
        return this.restService
            .get<{
                funcs: DataOptionAggregateFunction[];
            }>(`/data/aggregate-funcs-implemented/${datasetId}`)
            .pipe(map(({ funcs }) => funcs));
    }

    columnSearch(
        params: ColumnSearchRequest,
    ): Observable<ResponseArray<string>> {
        const {
            dataset_id,
            column_id,
            column_base_type,
            key_column_id,
            search,
            order_direction,
            order_by,
            limit,
            offset,
        } = params;

        const filters: DataOptionFilter[] = params.filters
            ? [...params.filters]
            : [];

        const columns: DataOptionDataBlockColumn[] = [column_id, key_column_id]
            .filter((id) => Boolean(id))
            .map((id, index) => ({
                column_id: id!,
                agg_fn: DataOptionAggregateFunction[index ? 'Min' : 'Group'],
            }));

        if (search)
            filters.push({
                column_id,
                logical: FilterLogical.And,
                action:
                    column_base_type === ColumnType.Number
                        ? FilterMethod.Equal
                        : FilterMethod.Like$CaseIgnore,
                value: search,
            });

        return this.getData({
            data_options: [
                {
                    key: 'data',
                    method: DataOptionMethod.Aggregate,
                    dataset_id,
                    blocks: [
                        {
                            key: 'block',
                            columns,
                        },
                    ],
                    filters,
                    sort: [
                        {
                            column_id: Number(order_by) || column_id,
                            direction: order_direction || SortDirection.Asc,
                            agg_fn: DataOptionAggregateFunction.Min,
                        },
                    ],
                },
            ],
            get_columns: params.getColumns ?? false,
            limit,
            offset,
        }).pipe(
            tap((response) => {
                if (isResponseError(response.data)) throw response.data;
            }),
            catchError((err) => {
                return throwError(() => err);
            }),

            map((response) => {
                let { rows, total, columns } = response.data as DataItem;

                if (rows.length > 0) {
                    const [suggestPath, keyPath] = Object.keys(rows[0]);

                    rows = rows.map((item) => ({
                        view: item[suggestPath],
                        key: item[keyPath],
                    }));
                }

                return { rows, total, columns };
            }),
        );
    }
}

export interface DataRequest {
    blocks?: DataOptionDataBlock[];
    data_options: DataOption[];
    get_columns?: boolean;
    get_dataset?: boolean;
    limit?: number;
    offset?: number;
}

export interface DataResponse {
    [key: string]: DataItem | ResponseError;
}

export interface DataItem extends ResponseArray<any> {
    columns?: ColumnWithKey[];
    dataset?: Dataset;
}

export interface ColumnSearchRequest extends RequestArray {
    dataset_id: number;
    column_id: number;
    column_base_type: ColumnType;
    key_column_id?: number;

    filters?: DataOptionFilter[];
    getColumns?: boolean;
}
