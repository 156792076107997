import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { ModalLeaveEditorConfirmComponent } from '../components/modal-leave-editor-confirm/modal-leave-editor-confirm.component';

@Injectable({
    providedIn: 'root',
})
export class ThemesEditService {
    saveOnexit$ = new BehaviorSubject(false);
    showModal$ = new BehaviorSubject(false);
    nextUrlRedirect$ = new BehaviorSubject<string | undefined>('');
    leaveThemesModal!: ModalLeaveEditorConfirmComponent;

    reset(): void {
        this.saveOnexit$ = new BehaviorSubject(false);
        this.showModal$ = new BehaviorSubject(false);
        this.nextUrlRedirect$ = new BehaviorSubject<string | undefined>('');
    }
}
