import { CommonModule } from '@angular/common';
import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { ShoelaceModule } from 'shoelace-style-angular';

import { TestIdDirectiveModule } from '../../../directives/test-id.directive';
import { TranslateModule } from '../../../modules/translate/translate.module';
import { AccessModule } from '../../access/access.module';
import { FilterTemplateModule } from '../filter-template.module';
import { FilterTemplateModalComponent } from './filter-template-modal.component';
import { DialogModule } from '../../ui-kit-components/dialog/dialog.module';

export * from './filter-template-modal.component';

@NgModule({
    imports: [
        AccessModule,
        CommonModule,
        FilterTemplateModule,
        ShoelaceModule,
        DialogModule,
        TranslateModule,
        TestIdDirectiveModule,
    ],
    exports: [FilterTemplateModalComponent],
    declarations: [FilterTemplateModalComponent],
    schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class FilterTemplateModalModule {}
