import { FormatNumberAbbreviation } from '../../../enums/column.format';
import { Format_Server } from '../../../interfaces/column.format';
import { Arounds } from '../arounds';
import { GetEmptyFormatSettings } from '.';

export class NumberFormatSettings {
    static getEmpty: GetEmptyFormatSettings = () => {
        return {
            digits_separate_type: null,
            separate_digits: false,
            digit_capacity: null,
        };
    };

    readonly arounds: Arounds;
    readonly abbreviate: FormatNumberAbbreviation | null;
    readonly useGrouping: boolean;
    readonly toFixed: number | null = null;
    readonly nullText: string;

    constructor(source: Format_Server) {
        this.arounds = new Arounds(source);
        this.abbreviate = source.digits_separate_type || null;
        this.useGrouping = source.separate_digits || false;
        this.nullText = source.null_format;

        if (typeof source.digit_capacity === 'number')
            this.toFixed = source.digit_capacity;
    }
}
