import { CommonModule } from '@angular/common';
import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { TestIdDirectiveModule } from '../../directives/test-id.directive';

import { TranslateModule } from '../../modules/translate/translate.module';
import { ConfirmComponent } from './confirm.component';

export * from './confirm.component';

@NgModule({
  imports: [CommonModule, TranslateModule, TestIdDirectiveModule],
    exports: [ConfirmComponent],
    declarations: [ConfirmComponent],
    schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class ConfirmModule {}
