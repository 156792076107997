import { HttpErrorResponse } from '@angular/common/http';
import { EventEmitter, Injectable, TemplateRef } from '@angular/core';
import { SlAlert } from '@shoelace-style/shoelace';

type NotifyShow = NotifyShowParams & (KeyNotifyShow | TemplateNotifyShow);

interface KeyNotifyShow {
    key: string;
}

interface TemplateNotifyShow {
    template: TemplateRef<any>;
    context?: any;
}

interface NotifyShowParams {
    variant?: SlAlert['variant'];
    params?: any;
    responseError?: HttpErrorResponse;
    multiValue?: boolean;
}

@Injectable({ providedIn: 'root' })
export class AlertService {
    show = new EventEmitter<NotifyShow>();
}
