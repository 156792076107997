<label (click)="dropdownTarget.focus()">{{ label }}</label>

<button
    #dropdownTarget
    type="button"
    [class.one-line]="oneLineCondition"
    class="outline {{ size }}"
>
    <div class="placeholder">
        {{ placeholder || ('_$.multiSelect.placeholder' | translate) }}
    </div>

    <div #tags class="tags">
        <core-tag
            *ngFor="let item of selected; trackBy: trackByFn"
            removable
            (remove)="unselect(item)"
            (click)="$event.stopPropagation()"
        >
            {{ item.label }}
        </core-tag>

        <core-tag (click)="$event.stopPropagation()">
            <span #moreTagsText class="more"></span>
        </core-tag>
    </div>

    <material-icon class="clear" (click)="clear($event)"> close </material-icon>

    <i #dropdownShevron></i>
</button>

<core-dropdown
    #dropdown
    [button]="dropdownTarget"
    [shevron]="dropdownShevron"
    (show)="onDropdownShow()"
>
    <core-input
        *ngIf="searchProvider"
        autofocus
        clearable
        size="small"
        icon="search"
        (changeValue)="onSearch($event)"
    ></core-input>

    <core-list [controller]="dropdown">
        <core-list-item *ngFor="let option of options">
            <core-checkbox
                [label]="option.label"
                [checked]="isChecked(option.value)"
                (changeValue)="toggle(option)"
            ></core-checkbox>
        </core-list-item>

        <core-lazy-loading
            [class.hidden]="options.length === optionsTotal"
            [load]="more"
        >
        </core-lazy-loading>
    </core-list>
</core-dropdown>
