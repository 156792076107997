import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { not } from 'logical-not';

import { ModuleName } from '../enums/module';
import { AppService } from './app.service';

@Injectable()
export class ModulesRouterService {
    constructor(private appService: AppService, private router: Router) {}

    navigate(module: ModuleName, path: string): void {
        const { appService } = this;

        if (module !== appService.currentModule.name) {
            const target = appService.modules.value.find(
                (item) => item.name === module,
            );

            if (not(target)) return;

            location.href = target.front_url + path;
        } else this.router.navigate([path]);
    }
}
