import { Injectable } from '@angular/core';
import { EnumMap, UnionFrom } from 'ts-type-from-enum';

import { ColumnType } from '../../enums/dataset';
import { Format_Server } from '../../interfaces/column.format';
import { Column, ColumnWithKey } from '../../interfaces/dataset';
import { StringFormatSettings } from './formatter-settings/string.formatter-settings';
import { NumberFormatSettings } from './formatter-settings/number.formatter-settings';
import { DateFormatSettings } from './formatter-settings/date.format-settings';
import { BooleanFormatSettings } from './formatter-settings/boolean.format-settings';
import { FormatSettings as FormatSettingsType } from './formatter-settings';
import { GetEmptyFormatSettings } from './formatter-settings';
import { StringFormatter } from './formatters/string.formatter';
import { NumberFormatter } from './formatters/number.formatter';
import { DateFormatter } from './formatters/date.formatter';
import { BooleanFormatter } from './formatters/boolean.formatter';
import { Arounds } from './arounds';
import { DEFAULT_FORMATTER } from './formatters/default.formatter';
import { isNullOrUndefined } from '../../helpers/is-null-or-undefined';

export * from './formatter-settings/boolean.format-settings';
export * from './formatter-settings/date.format-settings';
export * from './formatter-settings/number.formatter-settings';
export * from './formatter-settings/string.formatter-settings';
export * from './formatters/default.formatter';

export interface Formatter {
    (value: any): string;
}

export type FormatSettings = UnionFrom<
    EnumMap<
        ColumnType,
        {
            [ColumnType.String]: StringFormatSettings;
            [ColumnType.Number]: NumberFormatSettings;
            [ColumnType.Date]: DateFormatSettings;
            [ColumnType.Boolean]: BooleanFormatSettings;
        }
    >
>;

export type ColumnFormatterMap = Record<ColumnWithKey['key'], Formatter>;

const FormatSettingsBy: Record<ColumnType, FormatSettingsType> = {
    [ColumnType.String]: StringFormatSettings,
    [ColumnType.Number]: NumberFormatSettings,
    [ColumnType.Date]: DateFormatSettings,
    [ColumnType.Boolean]: BooleanFormatSettings,
};

const resets: Record<ColumnType, GetEmptyFormatSettings> = {
    [ColumnType.String]: StringFormatSettings.getEmpty,
    [ColumnType.Number]: NumberFormatSettings.getEmpty,
    [ColumnType.Date]: DateFormatSettings.getEmpty,
    [ColumnType.Boolean]: BooleanFormatSettings.getEmpty,
};

@Injectable({ providedIn: 'root' })
export class FormatService {
    constructor(
        private booleanFormatter: BooleanFormatter,
        private dateFormatter: DateFormatter,
        private numberFormatter: NumberFormatter,
        private stringFormatter: StringFormatter,
    ) {}

    format(formatSettings: FormatSettings, value: any): string {
        if (formatSettings instanceof StringFormatSettings)
            return this.stringFormatter.format(formatSettings, value);
        if (formatSettings instanceof NumberFormatSettings)
            return this.numberFormatter.format(formatSettings, value);
        if (formatSettings instanceof DateFormatSettings)
            return this.dateFormatter.format(formatSettings, value);
        if (formatSettings instanceof BooleanFormatSettings)
            return this.booleanFormatter.format(formatSettings, value);

        return '';
    }

    createFormatSettings<T extends ColumnType>(
        type: T,
        source: Format_Server,
    ): FormatSettings {
        return new FormatSettingsBy[type](source);
    }

    createFormatter(column: Column): Formatter {
        const type = column.base_type;

        if (isNullOrUndefined(type)) {
            return String;
        }

        const source: Format_Server = column.formatter || this.getDefault(type);

        const formatSettings = this.createFormatSettings(type, source);

        if (formatSettings instanceof StringFormatSettings)
            return this.stringFormatter.format.bind(
                this.stringFormatter,
                formatSettings,
            );
        if (formatSettings instanceof NumberFormatSettings)
            return this.numberFormatter.format.bind(
                this.numberFormatter,
                formatSettings,
            );
        if (formatSettings instanceof DateFormatSettings)
            return this.dateFormatter.format.bind(
                this.dateFormatter,
                formatSettings,
            );
        if (formatSettings instanceof BooleanFormatSettings)
            return this.booleanFormatter.format.bind(
                this.booleanFormatter,
                formatSettings,
            );

        return String;
    }

    reset<T extends ColumnType>(type: T): Partial<Format_Server> {
        return {
            ...resets[type](),
            ...Arounds.getEmpty(),
        };
    }

    getDefault<T extends ColumnType>(type: T): Format_Server {
        return {
            bool_false_format: '',
            bool_true_format: '',
            date_format: [],
            digit_capacity: null,
            digits_separate_type: null,
            null_format: '',
            prefix: '',
            separate_digits: false,
            suffix: '',

            ...this.reset(type),
        };
    }

    getFormatterMap(
        columns: ColumnWithKey[] | undefined,
        defaultFormatter?: Formatter,
    ): ColumnFormatterMap {
        const formatters: ColumnFormatterMap = {};

        if (!columns) return formatters;

        columns.forEach(({ column, key }) => {
            formatters[key] = defaultFormatter ?? DEFAULT_FORMATTER;
            if (column.formatter) {
                formatters[key] = this.createFormatter(column);
            }
        });

        return formatters;
    }
}
