import {
    HttpClient,
    HttpClientModule,
    HTTP_INTERCEPTORS,
} from '@angular/common/http';
import { Inject, NgModule } from '@angular/core';

import { StorageService } from '../../services/storage.service';
import { Interceptor } from './interception';
import { ApiBaseUrls } from './interfaces';
import { API_SOURCE, scope } from './internal';
import { RestService } from './rest.service';
import { API_BASE_URLS } from './tokens';

export * from './api-injectors';
export * from './interfaces';
export * from './rest.service';
export * from './tokens';
export * from './base-urls';

const projectKey: keyof ApiBaseUrls = 'project';

@NgModule({
    imports: [HttpClientModule],
    providers: [
        RestService,
        {
            provide: API_SOURCE,
            useValue: projectKey,
        },
        {
            provide: HTTP_INTERCEPTORS,
            useClass: Interceptor,
            multi: true,
        },
    ],
})
export class RestServiceModule {
    constructor(
        @Inject(API_BASE_URLS) API_BASE_URLS: ApiBaseUrls,
        httpClient: HttpClient,
        storageService: StorageService,
    ) {
        Object.assign(scope, {
            API_BASE_URLS,
            httpClient,
            storageService,
        });
    }
}
