import { Injectable } from '@angular/core';

import { AppService } from './app.service';

@Injectable({ providedIn: 'root' })
export class DocumentTitleService {
    constructor(private appService: AppService) {}

    update(): void {
        this.appService.theme.subscribe((theme) => {
            if (theme?.system_name) document.title = theme.system_name;
        });
    }
}
